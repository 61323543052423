<script lang="ts">
  import { DownloadCloudIcon } from 'svelte-feather-icons';
  import FormBlock from '../FormBlock.svelte';
  import FormRow from '../FormRow.svelte';
  import { form } from '../../../stores/placeOrder';
  import { Button, UploadInput } from '@xpanseinc/ui-components';
  import { downloadFile } from '../../../utils/download';
  import { renderErrorToast } from '../../../utils/renderToasts';

  import { bulkApi } from '../../../stores/api';

  let uploadInput;
  let isLoading = false;
  let downloading = false;

  const getValidationErrorList = () =>
    Object.keys($form?.loanDetails?.bulkValidationErrors).flatMap((row) =>
      $form.loanDetails.bulkValidationErrors[row].map((entry) => {
        return `Row ${row}: ${entry.message}`;
      }),
    );

  async function downloadCSV() {
    if (downloading) return;
    downloading = true;
    try {
      const response = await $bulkApi.getBulkImportCsvSampleFileRaw({ productType: 'TITLE' });
      await downloadFile(response.raw.blob() as unknown as Blob, `bulk-sample.csv`);
    } catch (error) {
      renderErrorToast('Could not download sample bulk csv file', error);
    } finally {
      downloading = false;
    }
  }
</script>

<FormRow>
  <FormBlock width="800px">
    <div class="loan-details-header">
      <h2 class="header-xl header-xl--bold">Bulk TITLE Import</h2>
      <Button
        name="csv-download"
        label="Download Sample CSV"
        icon="{DownloadCloudIcon}"
        iconLoading="{isLoading || downloading}"
        on:click="{() => downloadCSV()}"
      >
        Download Sample CSV
      </Button>
    </div>
    <div>
      <UploadInput
        accept="text/csv"
        acceptMsg="csv"
        label="Attach File"
        on:remove="{() => {
          $form.loanDetails.bulkFiles = [];
          $form.loanDetails.bulkValidationErrors = null;
        }}"
        bind:this="{uploadInput}"
        bind:files="{$form.loanDetails.bulkFiles}"
      />
    </div>
    {#if $form.loanDetails.bulkValidationErrors}
      <div class="validation-errors">
        <h4 class="v-e-header">Your file has validation errors</h4>
        <ul>
          {#each getValidationErrorList() as err}
            <li class="v-e-item">{err}</li>
          {/each}
        </ul>
      </div>
    {/if}
  </FormBlock>
</FormRow>

<style>
  .loan-details-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0 !important;
    gap: 12px;
  }

  .loan-details-header :global(.btn) {
    margin-top: -4px;
  }

  .validation-errors {
    padding: 20px;
    border-radius: 5px;
    background-color: var(--negativeLight);
    border-left: 5px solid var(--negativeDark);
  }

  .v-e-header {
    padding-bottom: 10px;
  }

  .v-e-item {
    list-style: initial;
    padding-bottom: 5px;
    margin-left: 10px;
  }
</style>
