<script>
  import { XIcon, CopyIcon, Trash2Icon } from 'svelte-feather-icons';
  import { Button, Dropdown } from '@xpanseinc/ui-components';
  import { UserStatusEnum } from '@xpanseinc/authorization-service-api-rest';
  import { mapDropdownOptionsFromEnum } from '../../utils/mapDropdownOptions';
  import { createEventDispatcher } from 'svelte';
  const dispatch = createEventDispatcher();

  export let selectedUsers = {};
  export let numSelectedUsers;
</script>

<div class="bar">
  <Button
    color="basic"
    icon="{XIcon}"
    label="{numSelectedUsers === 1
      ? `${numSelectedUsers} User Selected`
      : `${numSelectedUsers} Users Selected`}"
    name="clear-users"
    on:click="{() => {
      Object.keys(selectedUsers).forEach((user) => {
        selectedUsers[user] = false;
      });
    }}"
  />
  <!-- <Button
    color="basic"
    disabled="{numSelectedUsers !== 1}"
    icon="{CopyIcon}"
    label="Copy User"
    name="copy-user"
    title="copy-user"
    on:click="{() => {
      dispatch('copy');
    }}"
  /> -->
  <Dropdown
    placeholder="Change Status"
    options="{mapDropdownOptionsFromEnum(UserStatusEnum, true, 'key').filter(
      (option) => option.label !== 'Brute Force Locked',
    )}"
    name="change-status"
    on:select="{(e) => {
      dispatch('changeStatus', e.detail.value);
    }}"
  />
  <Button
    color="basic"
    disabled="{numSelectedUsers !== 1}"
    icon="{Trash2Icon}"
    label="Delete User"
    name="delete-user"
    title="delete-user"
    on:click="{() => {
      dispatch('delete');
    }}"
  />
</div>

<style>
  .bar {
    width: 100%;
    background-color: var(--gray11);
    z-index: var(--zMenu);
    display: flex;
    margin-bottom: 11px;
    border-radius: 4px;
    box-shadow: 0 0 2px rgb(19 20 22 / 8%), 0 0 4px rgb(19 20 22 / 12%);
  }

  .bar :global(.btn) {
    display: flex;
    align-self: center;
    color: var(--white);
    font-size: 14px;
  }

  .bar :global(.btn.basic:hover) {
    background: var(--gray11);
    color: var(--white);
  }

  .bar :global(.btn.basic:disabled) {
    background: none !important;
    color: var(--gray6);
  }

  .bar :global(.btn):first-child {
    margin-right: 4rem;
  }

  .bar :global(.btn):last-child {
    margin-left: 4rem;
  }

  .bar :global(.dropdown) {
    margin-top: 0 !important;
    min-width: 175px !important;
  }

  .bar :global(.dropdown.open .dropdown-inner),
  .bar :global(.dropdown-inner) {
    background: var(--gray11);
    box-shadow: none;
    border: none;
  }

  .bar :global(.dropdown .dropdown-inner .header) {
    padding: 8px 12px;
  }

  .bar :global(.dropdown .dropdown-inner .text) {
    font-size: 14px;
  }

  .bar :global(.dropdown .dropdown-inner .option:hover) {
    background-color: var(--gray12);
  }

  .bar :global(.dropdown .dropdown-inner .header:hover) {
    background: var(--gray12);
  }

  .bar :global(.selected) {
    height: auto;
  }

  .bar :global(.input-highlight:focus),
  .bar :global(.input-highlight:focus-within) {
    border: none !important;
  }

  .bar :global(.selected),
  .bar :global(.label),
  .bar :global(.option) {
    color: var(--white);
  }

  .bar :global(.header > button) {
    color: var(--white);
  }
</style>
