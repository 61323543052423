<script>
  import { format, add } from 'date-fns';

  export let date = new Date();

  const dateFormat = 'EEE M/d';

  const labels = [
    '-3 days',
    '-2 days',
    'Yesterday',
    'Today',
    'Tomorrow',
    '2 days',
    '3 days',
    '4 days',
    '5 days',
    '6 days',
  ];
</script>

<div class="container caption">
  <div class="last-updated">
    Data as of {format(date, 'MM/dd/yyyy')} at {format(date, 'h:mm a')}
  </div>
  <div class="dates">
    <div class="date-container">
      <div class="body-small">Older</div>
      Orders
    </div>
    {#each labels as label, i}
      <div class="date-container" class:today="{label === 'Today'}">
        <div class="body-small" class:warning="{label.includes('-') || label === 'Yesterday'}">
          {label}
        </div>
        {format(add(new Date(), { days: i - 3 }), dateFormat)}
      </div>
    {/each}
  </div>
</div>

<style>
  .container {
    width: 100%;
    max-width: 1040px;
    display: inline-flex;
    align-items: center;
  }

  .last-updated {
    width: 168px;
    color: var(--gray6);
  }

  .dates {
    display: inline-flex;
    flex: 1;
    justify-content: space-between;
  }

  .date-container {
    display: flex;
    flex-direction: column;
    text-align: center;
    min-height: 44px;
    justify-content: center;
    width: 76px;
    padding: 4px 8px;
  }

  .warning {
    color: var(--actionDestructHover);
  }

  .today {
    background-color: var(--actionPrimaryDefault);
    border-radius: 4px;
    color: var(--white);
  }

  .today > * {
    color: var(--white);
  }
</style>
