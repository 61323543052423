<script lang="ts">
  import {
    Button,
    Dialog,
    LoadingSpinner,
    ThemeColor,
    UploadInput,
  } from '@xpanseinc/ui-components';
  import { AlertCircleIcon, ThumbsUpIcon } from 'svelte-feather-icons';
  import { userApi } from '../../stores/api';
  import { exportCSVFile } from '../../utils/jsonToCsv';
  import { bulkUserImportTemplate } from '../../constants/csvTemplates';
  import type { BulkUser } from '../../constants/csvTemplates';

  export let visible = false;

  const UploadState = {
    Idle: 'idle',
    Uploading: 'uploading',
    Error: 'error',
    Success: 'success',
  };

  let state = UploadState.Idle;
  let files = [];
  let errors = [];

  function close() {
    state = UploadState.Idle;
    files = [];
    errors = [];
    visible = false;
  }

  async function onStartImportClick(): Promise<void> {
    state = UploadState.Uploading;
    errors = [];
    try {
      await $userApi.createJob({ file: files[0] });
      state = UploadState.Success;
    } catch (response) {
      state = UploadState.Error;
      const { code, message } = await response.json();
      if (code === 400 && message) {
        try {
          errors = JSON.parse(message);
        } catch (error) {
          errors = [{ row: null, message }];
        }
      }
    }
    files = [];
  }

  const downloadTemplate = (): void => {
    const { headers, items } = bulkUserImportTemplate;
    exportCSVFile<BulkUser>(headers, items, 'bulk_user_import_template');
  };
</script>

<Dialog size="large" bind:visible on:close="{close}" title="Bulk User Import">
  <div class="modal-body" slot="body">
    {#if state === UploadState.Idle}
      <UploadInput
        class="file-input"
        label="Attach User File"
        accept="text/csv"
        acceptMsg="CSV"
        bind:files
      />
      <p>
        <span class="link" on:click="{downloadTemplate}">
          Use our sample template to get started.
        </span>
        All fields must be included for each user.
      </p>
      <p>
        Users will be created, then emailed a link to activate their account and set a password.
      </p>
    {/if}
    {#if state === UploadState.Uploading}
      <div class="loading">
        <LoadingSpinner />
        <div class="loading-label">Uploading {files[0].name}</div>
      </div>
    {/if}
    {#if state === UploadState.Error}
      <div class="import-error">
        <div class="import-result">
          <AlertCircleIcon />
          <h1>Ah, shucks.</h1>
        </div>
        <p>
          The file you uploaded doesn't match our sample template. All fields must be included for
          each user. Please adjust your file and try again.
        </p>
        {#if errors.length}
          <p>Here's what we found:</p>
          <div class="error-list body-small">
            <ul>
              {#each errors as error}
                <li>{error.row ? `Row ${error.row}: ` : ''}{error.message}</li>
              {/each}
            </ul>
          </div>
        {/if}
        <p>If you can't find the issue or the problem persists, please contact support.</p>
      </div>
      <UploadInput
        class="file-input"
        label="Attach User File"
        accept="text/csv"
        acceptMsg="CSV"
        bind:files
      />
    {/if}
    {#if state === UploadState.Success}
      <div class="import-success">
        <div class="import-result">
          <ThumbsUpIcon />
          <h1>Looks good!</h1>
        </div>
        <p>
          At first glance, the file looks good. We have begun creating user accounts. Each user will
          be emailed automatically as their account becomes available.
        </p>
      </div>
    {/if}
  </div>
  <footer slot="footer">
    {#if state === UploadState.Idle || state === UploadState.Error}
      <Button
        disabled="{!files.length && false}"
        label="Start User Import"
        name="start-user-import"
        color="{ThemeColor.Primary}"
        on:click="{onStartImportClick}"
      />
    {/if}
  </footer>
</Dialog>

<style>
  .modal-body > :global(.file-input) {
    margin: 16px 0;
  }

  .import-error :global(.feather),
  .import-success :global(.feather) {
    color: var(--gray8);
    height: 96px;
    width: 96px;
  }

  .import-result {
    align-items: center;
    display: flex;
    gap: 24px;
    margin: 0 0 16px;
  }

  .error-list {
    background-color: #fff;
    border-radius: 5px;
    margin: 0 0 8px;
    max-height: 125px;
    overflow: auto;
  }

  .error-list ul {
    list-style: circle;
    padding: 4px 26px;
  }

  .error-list li {
    padding: 4px 0;
  }

  .loading {
    margin: 24px 0 0;
    text-align: center;
  }

  .loading-label {
    margin: 8px 0 0;
  }
</style>
