<script lang="ts">
  import { Button, Dialog } from '@xpanseinc/ui-components';
  import { createEventDispatcher } from 'svelte';
  import FormRow from '../FormRow.svelte';
  import FormBlock from '../FormBlock.svelte';
  import { validate } from '../../../schemas/validate';
  import { subjectPropertySchema } from '../../../schemas/place-order';
  import { getComponent, getProps } from '../renderFromConfig';
  import { addSubjectPropertyConfig, OrgTypeEnum } from '../../../constants/place-order';
  import { fetchStates, usStates } from '../../../stores/usStates';
  import { geoApi } from '../../../stores/api';
  import { form } from '../../../stores/placeOrder';
  import type { ValidationResult } from '../../../schemas/validate';
  import type { SubjectProperty } from '../../../schemas/place-order';

  export let visible = false;
  export let existingItem: SubjectProperty = undefined;
  export let orgType = undefined;
  export let showErrorMsg = false;

  const { landSaleInformation, propertyInformation, subjectProperty } = addSubjectPropertyConfig(
    orgType,
    $form,
  );

  const dispatch = createEventDispatcher();
  let dirtyMap: { [k in keyof SubjectProperty]?: boolean } = {};
  let hasOpened = false;

  fetchStates();

  function getDefaultForm(): SubjectProperty {
    return {
      addressLine1: '',
      addressLine2: '',
      zipCode: null,
      city: '',
      county: '',
      parcelId: '',
      platBlockIdentifier: '',
      platLotIdentifier: '',
      pud: false,
    };
  }

  let stagedSubjectProperty: SubjectProperty = getDefaultForm();

  function saveProperty() {
    if (!validationResult.valid) {
      Object.keys(validationResult.errors).forEach((key) => {
        dirtyMap[key] = true;
      });
      return;
    }
    if (existingItem) {
      dispatch('edited', { existingItem, newItem: stagedSubjectProperty });
    } else {
      dispatch('save', stagedSubjectProperty);
    }
    hasOpened = false;
  }

  async function onZipcodeChange(e) {
    const { value } = e.target;

    stagedSubjectProperty.zipCode = value;
    if (!value) {
      return;
    }

    try {
      const { city, county, state } = await $geoApi.getZipInfo({ zipCode: value });

      stagedSubjectProperty.city = city;
      stagedSubjectProperty.county = county;
      stagedSubjectProperty.state = state;
    } catch (error) {
      console.error(error);
    }
  }

  function deleteProperty() {
    dispatch('delete', stagedSubjectProperty);
    close();
  }

  function close() {
    dispatch('close');
    dirtyMap = {};
    stagedSubjectProperty = {};
    hasOpened = false;
  }

  $: {
    if (!visible) {
      dirtyMap = {};
      stagedSubjectProperty = getDefaultForm();
    }
  }

  $: if (existingItem && !hasOpened) {
    stagedSubjectProperty = { ...existingItem };
    hasOpened = true;
  }

  let validationResult: ValidationResult;
  $: validationResult = validate(subjectPropertySchema, stagedSubjectProperty);
  $: {
    if (showErrorMsg) {
      for (const k in subjectPropertySchema.fields) {
        dirtyMap[k] = true;
      }
    }
  }
</script>

<Dialog
  title="Add Subject Property"
  titleTag="Required Fields *"
  on:close="{close}"
  size="jumbo"
  bind:visible
>
  <div slot="body" class="body">
    <FormRow>
      <FormBlock title="{subjectProperty.title}" width="65%">
        <svelte:component
          this="{getComponent(subjectProperty.fields.addressLine1)}"
          {...getProps(subjectProperty.fields.addressLine1)}
          value="{stagedSubjectProperty.addressLine1}"
          invalid="{dirtyMap.addressLine1 && validationResult.errors.addressLine1}"
          on:blur="{() => {
            dirtyMap.addressLine1 = true;
          }}"
          on:change="{(e) => {
            stagedSubjectProperty.addressLine1 = e.target.value;
          }}"
        />
        <svelte:component
          this="{getComponent(subjectProperty.fields.addressLine2)}"
          {...getProps(subjectProperty.fields.addressLine2)}
          value="{stagedSubjectProperty.addressLine2}"
          on:change="{(e) => {
            stagedSubjectProperty.addressLine2 = e.target.value;
          }}"
        />
        <div class="row">
          <svelte:component
            this="{getComponent(subjectProperty.fields.zipCode)}"
            {...getProps(subjectProperty.fields.zipCode)}
            value="{stagedSubjectProperty.zipCode}"
            invalid="{dirtyMap.zipCode && validationResult.errors.zipCode}"
            on:blur="{() => {
              dirtyMap.zipCode = true;
            }}"
            on:change="{(e) => {
              onZipcodeChange(e);
            }}"
          />
          <svelte:component
            this="{getComponent(subjectProperty.fields.city)}"
            {...getProps(subjectProperty.fields.city)}
            value="{stagedSubjectProperty.city}"
            invalid="{dirtyMap.city && validationResult.errors.city}"
            on:blur="{() => {
              dirtyMap.city = true;
            }}"
            on:change="{(e) => {
              stagedSubjectProperty.city = e.target.value;
            }}"
          />
          <svelte:component
            this="{getComponent(subjectProperty.fields.state)}"
            {...getProps(subjectProperty.fields.state)}
            options="{$usStates}"
            value="{stagedSubjectProperty.state}"
            invalid="{dirtyMap.state && validationResult.errors.state}"
            showTextFilter
            on:blur="{() => {
              dirtyMap.state = true;
            }}"
            on:select="{(e) => {
              stagedSubjectProperty.state = e.detail.value;
            }}"
          />
        </div>
        <div class="row">
          <svelte:component
            this="{getComponent(subjectProperty.fields.county)}"
            {...getProps(subjectProperty.fields.county)}
            value="{stagedSubjectProperty.county}"
            invalid="{dirtyMap.county && validationResult.errors.county}"
            on:blur="{() => {
              dirtyMap.county = true;
            }}"
            on:change="{(e) => {
              stagedSubjectProperty.county = e.target.value;
            }}"
          />
        </div>
      </FormBlock>
      <FormBlock title="{landSaleInformation.title}" width="280px">
        <svelte:component
          this="{getComponent(landSaleInformation.fields.parcelIdType)}"
          {...getProps(landSaleInformation.fields.parcelIdType)}
          value="{stagedSubjectProperty.parcelIdType}"
          on:select="{(e) => {
            stagedSubjectProperty.parcelIdType = e.detail.value;
          }}"
        />
        {#if !(orgType === OrgTypeEnum.BROKER) && !(orgType === OrgTypeEnum.WHOLESALE)}
          <svelte:component
            this="{getComponent(landSaleInformation.fields.platType)}"
            {...getProps(landSaleInformation.fields.platType)}
            value="{stagedSubjectProperty.platType}"
            on:change="{(e) => {
              stagedSubjectProperty.platType = e.target.value;
            }}"
          />
        {/if}
        <svelte:component
          this="{getComponent(landSaleInformation.fields.parcelId)}"
          {...getProps(landSaleInformation.fields.parcelId)}
          value="{stagedSubjectProperty.parcelId}"
          on:change="{(e) => {
            stagedSubjectProperty.parcelId = e.target.value;
          }}"
        />
        <div class="row">
          <svelte:component
            this="{getComponent(landSaleInformation.fields.platBlockIdentifier)}"
            {...getProps(landSaleInformation.fields.platBlockIdentifier)}
            value="{stagedSubjectProperty.platBlockIdentifier}"
            on:change="{(e) => {
              stagedSubjectProperty.platBlockIdentifier = e.target.value;
            }}"
          />
          {#if !(orgType === OrgTypeEnum.BROKER) && !(orgType === OrgTypeEnum.WHOLESALE)}
            <svelte:component
              this="{getComponent(landSaleInformation.fields.platLotIdentifier)}"
              {...getProps(landSaleInformation.fields.platLotIdentifier)}
              value="{stagedSubjectProperty.platLotIdentifier}"
              on:change="{(e) => {
                stagedSubjectProperty.platLotIdentifier = e.target.value;
              }}"
            />
          {/if}
        </div>
        <div class="row">
          <svelte:component
            this="{getComponent(landSaleInformation.fields.pud)}"
            {...getProps(landSaleInformation.fields.pud)}
            value="{stagedSubjectProperty.pud}"
            bind:checked="{stagedSubjectProperty.pud}"
          >
            {landSaleInformation.fields.pud.label}
          </svelte:component>
        </div>
      </FormBlock>
    </FormRow>
    <FormRow>
      <FormBlock title="{propertyInformation.title}" width="850px">
        <div class="row">
          <div class="property-field">
            <svelte:component
              this="{getComponent(propertyInformation.fields.buildingPermissionUsageType)}"
              {...getProps(propertyInformation.fields.buildingPermissionUsageType)}
              value="{stagedSubjectProperty.buildingPermissionUsageType}"
              invalid="{dirtyMap.buildingPermissionUsageType &&
                validationResult.errors.buildingPermissionUsageType}"
              on:blur="{() => {
                dirtyMap.buildingPermissionUsageType = true;
              }}"
              on:select="{(e) => {
                stagedSubjectProperty.buildingPermissionUsageType = e.detail.value;
              }}"
            />
          </div>
          {#if stagedSubjectProperty.buildingPermissionUsageType === 'OTHER'}
            <div class="property-field">
              <svelte:component
                this="{getComponent(
                  propertyInformation.fields.buildingPermissionUsageTypeOtherDescription,
                )}"
                {...getProps(
                  propertyInformation.fields.buildingPermissionUsageTypeOtherDescription,
                )}
                on:change="{(e) => {
                  stagedSubjectProperty.buildingPermissionUsageTypeOtherDescription =
                    e.target.value;
                }}"
              />
            </div>
          {/if}
          <div class="property-field">
            <svelte:component
              this="{getComponent(propertyInformation.fields.constructionMethod)}"
              {...getProps(propertyInformation.fields.constructionMethod)}
              value="{stagedSubjectProperty.constructionMethod}"
              on:select="{(e) => {
                stagedSubjectProperty.constructionMethod = e.detail.value;
              }}"
            />
          </div>
          {#if stagedSubjectProperty.constructionMethod === 'OTHER'}
            <div class="property-field">
              <svelte:component
                this="{getComponent(propertyInformation.fields.constructionMethodTypeDescription)}"
                {...getProps(propertyInformation.fields.constructionMethodTypeDescription)}
                on:change="{(e) => {
                  stagedSubjectProperty.constructionMethodTypeDescription = e.target.value;
                }}"
              />
            </div>
          {/if}
        </div>
        <div class="row">
          <div class="property-field">
            <svelte:component
              this="{getComponent(propertyInformation.fields.estateType)}"
              {...getProps(propertyInformation.fields.estateType)}
              value="{stagedSubjectProperty.estateType}"
              on:select="{(e) => {
                stagedSubjectProperty.estateType = e.detail.value;
              }}"
            />
          </div>
          {#if stagedSubjectProperty.estateType === 'OTHER'}
            <div class="property-field">
              <svelte:component
                this="{getComponent(propertyInformation.fields.estateTypeDescription)}"
                {...getProps(propertyInformation.fields.estateTypeDescription)}
                on:change="{(e) => {
                  stagedSubjectProperty.estateTypeDescription = e.target.value;
                }}"
              />
            </div>
          {/if}
          <div class="property-field">
            <svelte:component
              this="{getComponent(propertyInformation.fields.addressUnitDesignationType)}"
              {...getProps(propertyInformation.fields.addressUnitDesignationType)}
              value="{stagedSubjectProperty.addressUnitDesignationType}"
              on:select="{(e) => {
                stagedSubjectProperty.addressUnitDesignationType = e.detail.value;
              }}"
            />
          </div>
        </div>
        <div class="row">
          <div class="property-field">
            <svelte:component
              this="{getComponent(propertyInformation.fields.occupancyType)}"
              {...getProps(propertyInformation.fields.occupancyType)}
              value="{stagedSubjectProperty.occupancyType}"
              on:select="{(e) => {
                stagedSubjectProperty.occupancyType = e.detail.value;
              }}"
            />
          </div>
        </div>
      </FormBlock>
    </FormRow>
  </div>
  <footer slot="footer" class="footer">
    <div class="{existingItem ? 'button-container' : ''}">
      {#if existingItem}
        <Button name="close-recording-dialog" label="Delete" on:click="{deleteProperty}" />
      {/if}
      <div>
        <Button name="close-recording-dialog" label="Close" on:click="{close}" />
        <Button
          color="primary"
          name="close-recording-dialog"
          label="Save"
          disabled="{!validationResult.valid}"
          on:click="{saveProperty}"
        />
      </div>
    </div>
  </footer>
</Dialog>

<style>
  .body,
  .footer {
    margin: 0 50px;
  }

  .button-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .body {
    overflow: auto;
  }

  .property-field {
    width: 260px;
  }

  .footer :global(button.primary) {
    margin-left: 0.5rem;
  }
</style>
