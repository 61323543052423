<script type="ts">
  import { onMount } from 'svelte';
  import { RadioPillButton } from '@xpanseinc/ui-components';
  import LoanDetailsBulk from './LoanDetailsBulk.svelte';
  import LoanDetailsNew from './LoanDetailsNew.svelte';
  import LoanDetailsExisting from './LoanDetailsExisting.svelte';
  import { form, LoanSource, PlaceOrderProductTypeEnum } from '../../../stores/placeOrder';
  import { push } from 'svelte-spa-router';
  import urls from '../../../urls';
  import { OrgNamesEnum } from '../../../constants/place-order';

  const radioOptions = [
    { label: 'Existing Loan', value: LoanSource.Existing },
    { label: 'New Loan', value: LoanSource.New },
  ];

  if (
    ($form.order.lenderName === OrgNamesEnum.FREEDOM_SERVICING &&
      $form.order.products.length === 2 &&
      $form.order.products[0] === PlaceOrderProductTypeEnum.Title) ||
    ($form.order.products.length === 1 &&
      $form.order.products[0] === PlaceOrderProductTypeEnum.Title)
  ) {
    radioOptions.push({ label: 'Bulk', value: LoanSource.Bulk });
  }

  $: isOnlyPaymentOrder =
    $form.order.products.length === 1 &&
    $form.order.products[0] === PlaceOrderProductTypeEnum.Payment;

  $: isOnlySsnVerificationOrder =
    $form.order.products.length === 1 &&
    $form.order.products[0] === PlaceOrderProductTypeEnum.SSN_Verification;

  onMount(() => {
    // Reroute to beginning of placeOrder flow if navigated here directly
    if ($form.order.lender === '' || $form.order.products.length === 0) {
      push(urls.placeOrder);
    }
    // If there is a product that does not require loan details, go ahead to the next screen
    if (isOnlyPaymentOrder || isOnlySsnVerificationOrder) {
      push(urls.placeOrderProductDetails);
    }
  });
</script>

<div>
  <RadioPillButton options="{radioOptions}" bind:value="{$form.loanDetails.loanSource}" />
</div>

<div class="content">
  {#if $form.loanDetails.loanSource === LoanSource.New}
    <LoanDetailsNew />
  {:else if $form.loanDetails.loanSource === LoanSource.Existing}
    <LoanDetailsExisting />
  {:else if $form.loanDetails.loanSource === LoanSource.Bulk}
    <LoanDetailsBulk />
  {/if}
</div>

<style>
  .content {
    padding-top: 24px;
  }
</style>
