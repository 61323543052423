<script lang="ts">
  import { push } from 'svelte-spa-router';
  import { onMount, setContext } from 'svelte';
  import { startCase, camelCase, sum, uniqueId } from 'lodash-es';
  import { Button, PlaceholderButton } from '@xpanseinc/ui-components';
  import { PlusIcon } from 'svelte-feather-icons';
  import {
    PartyContactTypeEnum,
    GetAppraisalFeesMortgageTypeEnum,
    TermsOfLoanMortgageTypeEnum,
    TermsOfLoanLoanPurposeTypeEnum,
  } from '@xpanseinc/ui-backend-api';
  import FormBlock from '../FormBlock.svelte';
  import FormRow from '../FormRow.svelte';
  import FeesAndQuotes from '../FeesAndQuotes.svelte';
  import Attachment from '../../send-event/Attachment.svelte';
  import Divider from '../Divider.svelte';
  import AddSubjectProperty from '../dialogs/AddSubjectProperty.svelte';
  import AddBorrower from '../dialogs/AddBorrower.svelte';
  import AddContact from '../dialogs/AddContact.svelte';
  import AddPayment from '../dialogs/AddPayment.svelte';
  import {
    PaymentCategoryType,
    PayerType,
    OrgNamesEnum,
    TaxProductsEnum,
    TaxProvidersEnum,
  } from '../../../constants/place-order';
  import {
    appraisalProviderAllocation,
    form,
    productDetailsProductOptions,
    productDetailsProviderOptions,
    selectedAppraisalProduct,
    validationResults,
    PlaceOrderProductTypeEnum,
  } from '../../../stores/placeOrder';
  import { fetchStates } from '../../../stores/usStates';
  import { attachmentCodes, getFilteredAttachmentCodes } from '../../../stores/reports';
  import { feesApi, orderApi, reportApi, geoApi } from '../../../stores/api';
  import { userOrgType } from '../../../stores/profile';
  import { validate } from '../../../schemas/validate';
  import { getTaxProducts } from '../../../constants/place-order/incomeTax';
  import { getComponent, getProps } from '../renderFromConfig';
  import {
    productDetailsConfig,
    loanDetailsConfig,
    PaymentType,
  } from '../../../constants/place-order';
  import IncomeApplication from '../IncomeApplication.svelte';
  import {
    getTitleSchema,
    closeSchema,
    getNewLoanSchema,
    getAppraisalSchema,
    getTaxSchema,
  } from '../../../schemas/place-order';
  import urls from '../../../urls';
  import { mapDropdownOptions } from '../../../utils/mapDropdownOptions';
  import { alphabetically } from '../../../utils/alphabetically';
  import type { Title, Close, Income, Appraisal } from '../../../schemas/place-order';
  import { detectDomain, DomainTypes } from '../../../utils/detectDomain';

  import { PaymentForm } from '../products';
  import { newLoanFromExisting } from '../../../utils/loanUtils';

  if ($form.loanDetails.existingLoan) {
    $form.loanDetails.newLoan = newLoanFromExisting($form.loanDetails.existingLoan);
    $form.productDetails.APPRAISAL.provider = $form.loanDetails.existingLoan.vendorId;
  }

  const { verificationOfTax, appraisal, common, close } = productDetailsConfig;
  const selectedProducts = $form.order.products;
  const { borrowers, contacts, subjectProperty } = loanDetailsConfig(
    $userOrgType,
    $form.order.lenderName,
    selectedProducts,
  );

  let checkValid = false;
  let fnmaFieldsVisible = false;
  let isLoadingFees = false;
  let isFnma =
    $form.loanDetails.newLoan.mortgageLoanType === TermsOfLoanMortgageTypeEnum.Fnma ||
    $form.loanDetails.newLoan.mortgageLoanType === TermsOfLoanMortgageTypeEnum.Fhlmc;
  let isAppraisalProviderDisabled = isFnma;
  let prevState = null;

  const titleSchema: any = getTitleSchema($form.order.lenderName);

  const isProd = detectDomain() === DomainTypes.PROD;
  const taxNotifyEmail = isProd
    ? 'TaxTranscripts@FreedomMortgage.com'
    : 'XPANSQA@FreedomMortgage.com';

  let emailOptions = [{ label: taxNotifyEmail, value: taxNotifyEmail }];

  let dirtyMap = {
    Title: <{ [k in keyof Title]?: boolean }>{},
    Close: <{ [k in keyof Close]?: boolean }>{},
    Appraisal: <{ [k in keyof Appraisal]?: boolean }>{},
    Income: <{ [k in keyof Income]?: boolean }>{},
  };

  const getFullName = (item) => `${item.firstName} ${item.middleName} ${item.lastName}`;
  const getContactHeader = (item) => {
    let header;
    if (item.contactType === PartyContactTypeEnum.Individual) {
      header = getFullName(item);
    } else {
      header = `${item.companyName}`;
    }
    return header;
  };

  // Set context for each module, passing in specific template literals to configure text on their subsequent info cards
  setContext('property', {
    component: AddSubjectProperty,
    getHeader: (item) => {
      const { addressLine1, addressLine2, city, state, zipCode } = item;
      return `${addressLine1} ${addressLine2}, ${city} ${state}, ${zipCode}`;
    },
    getHomeText: (item) => {
      const { buildingPermissionUsageType, constructionMethod } = item;
      return `${startCase(camelCase(buildingPermissionUsageType))} ${
        constructionMethod ? ', ' + startCase(camelCase(constructionMethod)) : ''
      }`;
    },
  });

  setContext('borrower', {
    component: AddBorrower,
    getHeader: getContactHeader,
  });

  setContext('contact', {
    component: AddContact,
    getHeader: getContactHeader,
  });

  setContext('payment', {
    component: AddPayment,
    getHeader: (item) =>
      `$${sum(item.requestedPayments.filter((x) => x.amount !== null).map((x) => x.amount))}`,
    getPaymentText: (item) => {
      if (item.payer === PayerType.Lender) {
        return `${startCase(camelCase(item.type))}: ${item.lender}`;
      }
      return `${startCase(camelCase(item.type))}: ${item.firstName} ${item.lastName}`;
    },
  });

  function DefaultAttachment(product) {
    let usageCode = '',
      usageCodeDescription = '',
      disabled = false;

    if (
      $form.order.lenderName === OrgNamesEnum.FREEDOM_WHOLESALE &&
      $form.productDetails[product].attachments.length === 0 &&
      product === PlaceOrderProductTypeEnum.Appraisal &&
      $form.loanDetails.newLoan.loanPurpose === TermsOfLoanLoanPurposeTypeEnum.Purchase
    ) {
      usageCode = 'PurchaseAgreement';
      usageCodeDescription = 'Purchase Agreement';
      disabled = true;
    }

    return {
      fileName: '',
      mimeTypeId: '',
      usageCode,
      usageCodeDescription,
      disabled,
    };
  }

  function addAttachment(product) {
    $form.productDetails[product].attachments.push(DefaultAttachment(product));
    $form = $form;
    checkValid = false;
  }

  function removeAttachment(product, i) {
    $form.productDetails[product].attachments.splice(i, 1);
    $form = $form;
  }

  onMount(async () => {
    if (!$attachmentCodes) {
      $attachmentCodes = await $reportApi?.getAttachmentCodes();
      $attachmentCodes = $attachmentCodes.sort(alphabetically);
    }

    $form.order.products.forEach(async (product) => {
      if (
        product !== PlaceOrderProductTypeEnum.Income &&
        product !== PlaceOrderProductTypeEnum.Payment
      ) {
        $form.productDetails[product].attachments = [];
        addAttachment(product);
      } else if (
        product === PlaceOrderProductTypeEnum.Income &&
        $form.productDetails[product].email
      ) {
        $form.productDetails[product].email = emailOptions[0].value;
      }

      // Fetch product options if not already present from a previous visit to the screen
      if (!$productDetailsProductOptions[product]?.length) {
        const productsResult = await $reportApi.getProductNames({
          productType: startCase(camelCase(product)),
        });
        $productDetailsProductOptions[product] = [...productsResult].map((x) => ({
          label: x.name,
          value: x.id,
        }));
      }

      // Title and Closing vendors do not differ and use the same dropdown, so do not call the API to get duplicate results for Closing
      // Payment orders do not require a call to get vendors
      if (
        product !== PlaceOrderProductTypeEnum.Closing &&
        product !== PlaceOrderProductTypeEnum.Payment
      ) {
        // Fetch from the Allocation Engine if the order is an Appraisal and has a mortgageType of FNMA, but only once per order session
        if ($form.loanDetails.existingLoan) {
          $productDetailsProviderOptions[PlaceOrderProductTypeEnum.Appraisal] = [
            {
              name: $form.loanDetails.existingLoan.vendorName,
              id: $form.loanDetails.existingLoan.vendorId,
            },
          ];
          $form.productDetails[PlaceOrderProductTypeEnum.Appraisal].provider =
            $form.loanDetails.existingLoan.vendorId;
        } else if (
          product === PlaceOrderProductTypeEnum.Appraisal &&
          isFnma &&
          !$appraisalProviderAllocation
        ) {
          const allocationResponse = await $orderApi.acquireAllocation({
            mortgageType: 'ConventionalFnma',
            allocateRequest: {
              documentationType: 'ConventionalFnma',
            },
          });
          $appraisalProviderAllocation = {
            name: allocationResponse.providerName,
            id: parseInt(allocationResponse.providerId),
          };
          $productDetailsProviderOptions[PlaceOrderProductTypeEnum.Appraisal] = [
            $appraisalProviderAllocation,
          ];
          $form.productDetails[PlaceOrderProductTypeEnum.Appraisal].provider = parseInt(
            $appraisalProviderAllocation?.id,
          );
        } else if (!$productDetailsProviderOptions[PlaceOrderProductTypeEnum.Appraisal].length) {
          const providerResult = await $reportApi.getVendors1({
            productType: startCase(camelCase(product)),
          });
          $productDetailsProviderOptions[product] = providerResult;
        }
      }
    });
    fetchStates();
  });

  function attachmentsRequired(productType) {
    const { attachments } = $form.productDetails[productType];
    return attachments.find((a: any) => a?.disabled) ? '*' : '';
  }

  function addIncomeApplication() {
    $form.productDetails[PlaceOrderProductTypeEnum.Income].applications = [
      ...$form.productDetails[PlaceOrderProductTypeEnum.Income].applications,
      {
        uid: uniqueId(),
        applicationType: '',
        attachmentType: '',
        applicant: [],
        coApplicant: [],
        products: getTaxProducts(),
        attachments: [
          {
            fileName: '',
            mimeTypeId: '',
            usageCode: '',
            usageCodeDescription: '',
          },
        ],
      },
    ];
  }

  async function fetchFees() {
    // Clear previous fee data from state
    $form.productDetails[PlaceOrderProductTypeEnum.Appraisal].fees.appraisalFee = null;
    $form.productDetails[PlaceOrderProductTypeEnum.Appraisal].fees.additionalFees = [];

    const { provider, product } = $form.productDetails[PlaceOrderProductTypeEnum.Appraisal];
    let state;
    let mortgageType;
    let county;
    const lenderId = $form.order.lender;
    state = $form.loanDetails.newLoan.subjectProperty[0]?.state;
    county = $form.loanDetails.newLoan.subjectProperty[0]?.county;
    mortgageType = $form.loanDetails.newLoan.mortgageLoanType;

    if (lenderId && state && provider && product && mortgageType && county) {
      isLoadingFees = true;
      const result = await $feesApi.getAppraisalFees1({
        vendorId: Number(provider),
        productId: Number(product),
        lenderId: Number(lenderId),
        county,
        state,
        mortgageType,
      });

      const { amount, extra } = result;

      if (amount) {
        $form.productDetails[PlaceOrderProductTypeEnum.Appraisal].fees.appraisalFee = {
          id: uniqueId(),
          category: PaymentCategoryType.AppraisalFee,
          description: 'Appraisal Fee',
          amount,
        };
      }

      if (extra?.length) {
        $form.productDetails[PlaceOrderProductTypeEnum.Appraisal].fees.additionalFees = extra.map(
          (fee) => {
            return {
              id: uniqueId(),
              category: PaymentCategoryType.Other,
              description: fee.label,
              amount: fee.amount,
            };
          },
        );
      } else {
        $form.productDetails[PlaceOrderProductTypeEnum.Appraisal].fees.additionalFees = [];
      }

      isLoadingFees = false;
    }
  }

  $: {
    $validationResults.productDetails[PlaceOrderProductTypeEnum.Title] = validate(
      titleSchema,
      $form.productDetails[PlaceOrderProductTypeEnum.Title],
    );
    $validationResults.productDetails[PlaceOrderProductTypeEnum.Closing] = validate(
      closeSchema,
      $form.productDetails[PlaceOrderProductTypeEnum.Closing],
    );
    $validationResults.productDetails[PlaceOrderProductTypeEnum.Appraisal] = validate(
      getAppraisalSchema(fnmaFieldsVisible),
      $form.productDetails[PlaceOrderProductTypeEnum.Appraisal],
    );

    const taxSchema = getTaxSchema($form.productDetails[PlaceOrderProductTypeEnum.Income].product);

    $validationResults.productDetails[PlaceOrderProductTypeEnum.Income] = validate(
      taxSchema,
      $form.productDetails[PlaceOrderProductTypeEnum.Income],
    );

    if (prevState !== $form.loanDetails.newLoan?.subjectProperty[0]?.state) {
      prevState = $form.loanDetails.newLoan?.subjectProperty[0]?.state;
      fetchFees();
    }
  }

  $: {
    const { product } = $form.productDetails[PlaceOrderProductTypeEnum.Appraisal];

    if (product) {
      const appraisalProducts = $productDetailsProductOptions[PlaceOrderProductTypeEnum.Appraisal];
      const fnmaProduct = appraisalProducts?.find((x) => x.label.includes('1004H Value Verify'));
      fnmaFieldsVisible = parseInt(fnmaProduct?.value) === parseInt(product);
    }
  }

  $: {
    const { product } = $form.productDetails[PlaceOrderProductTypeEnum.Income];

    if (product) {
      let taxProvider;
      switch (product) {
        case TaxProductsEnum.PRODUCT_4506_C:
          taxProvider = $productDetailsProviderOptions[PlaceOrderProductTypeEnum.Income].filter(
            ({ name }) => name === TaxProvidersEnum.ADVANCED_DATA,
          );
          $form.productDetails[PlaceOrderProductTypeEnum.Income].provider = taxProvider[0].id;
          break;

        case TaxProductsEnum.PRODUCT_8821:
          taxProvider = $productDetailsProviderOptions[PlaceOrderProductTypeEnum.Income].filter(
            ({ name }) => name === TaxProvidersEnum.HALCYON,
          );
          $form.productDetails[PlaceOrderProductTypeEnum.Income].provider = taxProvider[0].id;
          break;

        default:
          $form.productDetails[PlaceOrderProductTypeEnum.Income].provider = undefined;
          break;
      }
    }
  }

  selectedAppraisalProduct.subscribe(async (product) => {
    if (product) {
      if (
        (product === '1004 D Disaster' ||
          product === '1004D_05 Cert of Completion-Final Insp' ||
          product === '1004D_05 Appraisal Update') &&
        isFnma
      ) {
        isAppraisalProviderDisabled = false;
        const providerOptions = await $reportApi.getVendors1({
          productType: startCase(camelCase(PlaceOrderProductTypeEnum.Appraisal)),
        });
        $productDetailsProviderOptions[PlaceOrderProductTypeEnum.Appraisal] = providerOptions;
      } else if (isFnma) {
        isAppraisalProviderDisabled = true;

        if ($appraisalProviderAllocation) {
          $productDetailsProviderOptions[PlaceOrderProductTypeEnum.Appraisal] = [
            $appraisalProviderAllocation,
          ];
          $form.productDetails[PlaceOrderProductTypeEnum.Appraisal].provider = parseInt(
            $appraisalProviderAllocation?.id,
          );
        }

        if ($form.loanDetails.existingLoan) {
          $form.productDetails[PlaceOrderProductTypeEnum.Appraisal].provider =
            $form.loanDetails.existingLoan.vendorId;
        }
      }
    }
  });
</script>

<div class="product-details">
  {#if $form?.loanDetails?.newLoan?.loanNumber || $form?.loanDetails?.existingLoan?.loanNumber}
    <div class="loan-number header-m">
      Loan {$form.loanDetails.loanSource === 'new'
        ? $form.loanDetails.newLoan.loanNumber
        : $form.loanDetails.existingLoan.loanNumber}
    </div>
  {/if}

  <Divider />

  {#if $form.order.products.includes(PlaceOrderProductTypeEnum.Title) || $form.order.products.includes(PlaceOrderProductTypeEnum.Closing)}
    {#if $form.order.products.includes(PlaceOrderProductTypeEnum.Title) && $form.order.products.includes(PlaceOrderProductTypeEnum.Closing)}
      <h2 class="order-header header-xl header-xl--bold">Title & Close Order</h2>
    {:else if $form.order.products.includes(PlaceOrderProductTypeEnum.Title)}
      <h2 class="order-header header-xl header-xl--bold">Title Order</h2>
    {:else if $form.order.products.includes(PlaceOrderProductTypeEnum.Closing)}
      <h2 class="order-header header-xl header-xl--bold">Close Order</h2>
    {/if}

    <FormRow>
      <FormBlock title="Provider Details" showRequiredTag>
        <div class="row">
          <div class="col min-width--225">
            <svelte:component
              this="{getComponent(common.fields.provider)}"
              {...getProps(common.fields.provider)}
              options="{mapDropdownOptions(
                $productDetailsProviderOptions[PlaceOrderProductTypeEnum.Title],
              ) || []}"
              invalid="{dirtyMap.Title.provider &&
                $validationResults.productDetails[PlaceOrderProductTypeEnum.Title].errors.provider}"
              on:blur="{() => {
                dirtyMap.Title.provider = true;
              }}"
              on:select="{(e) => {
                $form.productDetails[PlaceOrderProductTypeEnum.Title].provider = e.detail.value;
                $form.productDetails[PlaceOrderProductTypeEnum.Closing].provider = e.detail.value;
              }}"
            />
          </div>
          {#if $form.order.lenderName !== OrgNamesEnum.FREEDOM_SERVICING}
            <div class="col min-width--225">
              <svelte:component
                this="{getComponent(common.fields.workflowVersion)}"
                {...getProps(common.fields.workflowVersion)}
                invalid="{dirtyMap.Title.workflowVersion &&
                  $validationResults.productDetails[PlaceOrderProductTypeEnum.Title].errors
                    .workflowVersion}"
                on:blur="{() => {
                  dirtyMap.Title.workflowVersion = true;
                }}"
                on:select="{(e) => {
                  $form.productDetails[PlaceOrderProductTypeEnum.Title].workflowVersion =
                    e.detail.value;
                  $form.productDetails[PlaceOrderProductTypeEnum.Closing].workflowVersion =
                    e.detail.value;
                }}"
              />
            </div>
          {/if}
        </div>
      </FormBlock>
    </FormRow>
  {/if}

  {#if $form.order.products.includes(PlaceOrderProductTypeEnum.Title)}
    <FormRow>
      <FormBlock title="{`Title ${common.title}`}" width="600px">
        <div class="row min-width--225">
          <svelte:component
            this="{getComponent(common.fields.product)}"
            {...getProps(common.fields.product)}
            options="{$productDetailsProductOptions[PlaceOrderProductTypeEnum.Title] || []}"
            invalid="{dirtyMap.Title.product &&
              $validationResults.productDetails[PlaceOrderProductTypeEnum.Title].errors.product}"
            on:blur="{() => {
              dirtyMap.Title.product = true;
            }}"
            on:select="{(e) => {
              $form.productDetails[PlaceOrderProductTypeEnum.Title].product = e.detail.value;
            }}"
          />
        </div>

        <svelte:component
          this="{getComponent(common.fields.orderInstructions)}"
          {...getProps(common.fields.orderInstructions)}
          value="{$form.productDetails[PlaceOrderProductTypeEnum.Title].orderInstructions}"
          on:change="{(e) => {
            $form.productDetails[PlaceOrderProductTypeEnum.Title].orderInstructions =
              e.target.value;
          }}"
        />
      </FormBlock>
    </FormRow>
    {#if $form.productDetails[PlaceOrderProductTypeEnum.Title].attachments}
      <FormRow>
        <FormBlock
          title="Title Order Attachments {attachmentsRequired(PlaceOrderProductTypeEnum.Title)}"
          width="600px"
        >
          {#each $form.productDetails[PlaceOrderProductTypeEnum.Title].attachments as attachment, i (attachment)}
            <Attachment
              on:update="{() => {
                $form = $form;
              }}"
              attachment="{attachment}"
              checkValid="{checkValid}"
              required="{false}"
              options="{$attachmentCodes.filter((code) =>
                code.products.includes(PlaceOrderProductTypeEnum.Title),
              )}"
              showRemove="{$form.productDetails[PlaceOrderProductTypeEnum.Title].attachments
                .length > 1}"
              on:remove="{() => removeAttachment(PlaceOrderProductTypeEnum.Title, i)}"
            />
          {/each}
          <div class="button-container">
            <Button
              icon="{PlusIcon}"
              label="Add Attachment"
              name="add-attachment"
              on:click="{() => addAttachment(PlaceOrderProductTypeEnum.Title)}"
            />
          </div>
        </FormBlock>
      </FormRow>
    {/if}
    <Divider />
  {/if}

  {#if $form.order.products.includes(PlaceOrderProductTypeEnum.Closing)}
    <FormRow>
      <FormBlock title="{`Closing ${common.title}`}" width="600px" showRequiredTag>
        <div class="row min-width--225">
          <svelte:component
            this="{getComponent(common.fields.product)}"
            {...getProps(common.fields.product)}
            options="{$productDetailsProductOptions[PlaceOrderProductTypeEnum.Closing] || []}"
            invalid="{dirtyMap.Close.product &&
              $validationResults.productDetails[PlaceOrderProductTypeEnum.Closing].errors.product}"
            on:blur="{() => {
              dirtyMap.Close.product = true;
            }}"
            on:select="{(e) => {
              $form.productDetails[PlaceOrderProductTypeEnum.Closing].product = e.detail.value;
            }}"
          />
        </div>
        <div class="row">
          <svelte:component
            this="{getComponent(close.fields.closingDate)}"
            {...getProps(close.fields.closingDate)}
            date="{$form.productDetails[PlaceOrderProductTypeEnum.Closing].closingDate}"
            on:change="{({ detail: [closingDate] }) => {
              $form.productDetails[PlaceOrderProductTypeEnum.Closing].closingDate = closingDate;
            }}"
          />
          <svelte:component
            this="{getComponent(close.fields.closingDateTimezone)}"
            {...getProps(close.fields.closingDateTimezone)}
            value="{$form.productDetails[PlaceOrderProductTypeEnum.Closing].closingDateTimezone}"
            on:select="{(e) => {
              $form.productDetails[PlaceOrderProductTypeEnum.Closing].closingDateTimezone =
                e.detail.value;
            }}"
          />
        </div>

        <svelte:component
          this="{getComponent(common.fields.orderInstructions)}"
          {...getProps(common.fields.orderInstructions)}
          value="{$form.productDetails[PlaceOrderProductTypeEnum.Closing].orderInstructions}"
          on:change="{(e) => {
            $form.productDetails[PlaceOrderProductTypeEnum.Closing].orderInstructions =
              e.target.value;
          }}"
        />
      </FormBlock>
    </FormRow>
    {#if $form.productDetails[PlaceOrderProductTypeEnum.Closing].attachments}
      <FormRow>
        <FormBlock
          title="Closing Order Attachments {attachmentsRequired(PlaceOrderProductTypeEnum.Closing)}"
          width="600px"
        >
          {#each $form.productDetails[PlaceOrderProductTypeEnum.Closing].attachments as attachment, i (attachment)}
            <Attachment
              on:update="{() => {
                $form = $form;
              }}"
              attachment="{attachment}"
              checkValid="{checkValid}"
              required="{false}"
              options="{$attachmentCodes.filter((code) =>
                code.products.includes(PlaceOrderProductTypeEnum.Closing),
              )}"
              showRemove="{$form.productDetails[PlaceOrderProductTypeEnum.Closing].attachments
                .length > 1}"
              on:remove="{() => removeAttachment(PlaceOrderProductTypeEnum.Closing, i)}"
            />
          {/each}
          <div class="button-container">
            <Button
              icon="{PlusIcon}"
              label="Add Attachment"
              name="add-attachment"
              on:click="{() => addAttachment(PlaceOrderProductTypeEnum.Closing)}"
            />
          </div>
        </FormBlock>
      </FormRow>
    {/if}
    <Divider />
  {/if}

  {#if $form.order.products.includes(PlaceOrderProductTypeEnum.Appraisal)}
    <h2 class="order-header header-xl header-xl--bold">Appraisal Order</h2>
    <FormRow>
      <FormBlock width="350px">
        <svelte:component
          this="{getComponent(common.fields.provider)}"
          {...getProps(common.fields.provider)}
          options="{mapDropdownOptions(
            $productDetailsProviderOptions[PlaceOrderProductTypeEnum.Appraisal],
          ) || []}"
          value="{$form.productDetails[PlaceOrderProductTypeEnum.Appraisal].provider}"
          invalid="{dirtyMap.Appraisal.provider &&
            $validationResults.productDetails[PlaceOrderProductTypeEnum.Appraisal].errors.provider}"
          disabled="{($form.productDetails[PlaceOrderProductTypeEnum.Appraisal].provider &&
            isAppraisalProviderDisabled) ||
            $form.loanDetails.existingLoan}"
          on:blur="{() => {
            dirtyMap.Appraisal.provider = true;
          }}"
          on:select="{(e) => {
            $form.productDetails[PlaceOrderProductTypeEnum.Appraisal].provider = e.detail.value;
            fetchFees();
          }}"
        />
        <svelte:component
          this="{getComponent(common.fields.product)}"
          {...getProps(common.fields.product)}
          options="{$productDetailsProductOptions[PlaceOrderProductTypeEnum.Appraisal] || []}"
          value="{$form.productDetails[PlaceOrderProductTypeEnum.Appraisal].product}"
          invalid="{dirtyMap.Appraisal.product &&
            $validationResults.productDetails[PlaceOrderProductTypeEnum.Appraisal].errors.product}"
          on:blur="{() => {
            dirtyMap.Appraisal.product = true;
          }}"
          on:select="{(e) => {
            $selectedAppraisalProduct = e.detail.label;
            $form.productDetails[PlaceOrderProductTypeEnum.Appraisal].product = e.detail.value;
            fetchFees();
          }}"
        />

        {#if fnmaFieldsVisible}
          <div class="row">
            <svelte:component
              this="{getComponent(appraisal.fields.duReferenceNumber)}"
              {...getProps(appraisal.fields.duReferenceNumber)}
              options="{mapDropdownOptions(
                $productDetailsProviderOptions[PlaceOrderProductTypeEnum.Appraisal],
              ) || []}"
              value="{$form.productDetails[PlaceOrderProductTypeEnum.Appraisal].duReferenceNumber}"
              invalid="{dirtyMap.Appraisal.duReferenceNumber &&
                $validationResults.productDetails[PlaceOrderProductTypeEnum.Appraisal].errors
                  .duReferenceNumber}"
              on:blur="{() => {
                dirtyMap.Appraisal.duReferenceNumber = true;
              }}"
              on:change="{(e) => {
                $form.productDetails[PlaceOrderProductTypeEnum.Appraisal].duReferenceNumber =
                  e.target.value;
              }}"
            />

            <svelte:component
              this="{getComponent(appraisal.fields.specialFeatureCode)}"
              {...getProps(appraisal.fields.specialFeatureCode)}
              options="{mapDropdownOptions(
                $productDetailsProviderOptions[PlaceOrderProductTypeEnum.Appraisal],
              ) || []}"
              value="{$form.productDetails[PlaceOrderProductTypeEnum.Appraisal].specialFeatureCode}"
              invalid="{dirtyMap.Appraisal.specialFeatureCode &&
                $validationResults.productDetails[PlaceOrderProductTypeEnum.Appraisal].errors
                  .specialFeatureCode}"
              on:blur="{() => {
                dirtyMap.Appraisal.specialFeatureCode = true;
              }}"
              on:change="{(e) => {
                $form.productDetails[PlaceOrderProductTypeEnum.Appraisal].specialFeatureCode =
                  e.target.value;
              }}"
            />
          </div>
        {/if}

        <svelte:component
          this="{getComponent(common.fields.orderInstructions)}"
          {...getProps(common.fields.orderInstructions)}
          value="{$form.productDetails[PlaceOrderProductTypeEnum.Appraisal].orderInstructions}"
          on:change="{(e) => {
            $form.productDetails[PlaceOrderProductTypeEnum.Appraisal].orderInstructions =
              e.target.value;
          }}"
        />
      </FormBlock>
      <FormBlock>
        <div class="row">
          <FeesAndQuotes
            fees="{$form.productDetails[PlaceOrderProductTypeEnum.Appraisal].fees}"
            paymentType="{$form.productDetails[PlaceOrderProductTypeEnum.Appraisal].payments[0]
              ?.type || PaymentType.BillTo}"
            isLoadingFees="{isLoadingFees}"
          />
        </div>
      </FormBlock>
    </FormRow>

    <FormRow>
      <FormBlock title="{subjectProperty.title} *" width="100%">
        <svelte:component
          this="{getComponent(subjectProperty.fields.subjectProperty)}"
          {...getProps(subjectProperty.fields.subjectProperty)}
          itemList="{$form.loanDetails.newLoan.subjectProperty}"
          contextKey="property"
          placeholderButtonLabel="{subjectProperty.fields.subjectProperty.label}"
          maxItems="1"
          on:itemChange="{(e) => {
            $form.loanDetails.newLoan.subjectProperty = e.detail;
            $validationResults.loanDetails = validate(
              getNewLoanSchema(selectedProducts, $form.loanDetails.newLoan),
              $form.loanDetails.newLoan,
            );
          }}"
        />
      </FormBlock>
    </FormRow>

    <FormRow>
      <FormBlock title="{borrowers.title} *" width="100%">
        <svelte:component
          this="{getComponent(borrowers.fields.borrowerInformation)}"
          {...getProps(borrowers.fields.borrowerInformation)}
          itemList="{$form.loanDetails.newLoan.borrowers}"
          contextKey="borrower"
          placeholderButtonLabel="{borrowers.fields.borrowerInformation.label}"
          maxItems="5"
          on:itemChange="{(e) => {
            $form.loanDetails.newLoan.borrowers = e.detail;
            $validationResults.loanDetails = validate(
              getNewLoanSchema(selectedProducts, $form.loanDetails.newLoan),
              $form.loanDetails.newLoan,
            );
          }}"
        />
      </FormBlock>
    </FormRow>

    <FormRow>
      <FormBlock title="{contacts.title}" width="100%">
        <svelte:component
          this="{getComponent(contacts.fields.contacts)}"
          {...getProps(contacts.fields.contacts)}
          itemList="{$form.loanDetails.newLoan.contacts}"
          contextKey="contact"
          placeholderButtonLabel="{contacts.fields.contacts.label}"
          on:itemChange="{(e) => {
            $form.loanDetails.newLoan.contacts = e.detail;
          }}"
        />
      </FormBlock>
    </FormRow>

    <FormRow>
      <FormBlock title="{appraisal.fields.payments.label} *" width="100%">
        {#if !$form.productDetails[PlaceOrderProductTypeEnum.Appraisal].provider || !$form.productDetails[PlaceOrderProductTypeEnum.Appraisal].product}
          <div class="payments-cta">
            A provider and product must be selected before a payment can be created.
            <span
              class="payments-cta-link"
              on:click="{() => {
                window.scrollTo({ top: 0, behavior: 'smooth' });
              }}">Select Provider & Product</span
            >
          </div>
        {:else}
          <svelte:component
            this="{getComponent(appraisal.fields.payments)}"
            {...getProps(appraisal.fields.payments)}
            itemList="{$form.productDetails[PlaceOrderProductTypeEnum.Appraisal].payments}"
            contextKey="payment"
            placeholderButtonLabel="{appraisal.fields.payments.label}"
            maxItems="1"
            on:itemChange="{(e) => {
              $form.productDetails[PlaceOrderProductTypeEnum.Appraisal].payments = e.detail;
            }}"
          />
        {/if}
      </FormBlock>
    </FormRow>

    {#if $form.productDetails[PlaceOrderProductTypeEnum.Appraisal].attachments}
      <FormRow>
        <FormBlock
          title="Attachments {attachmentsRequired(PlaceOrderProductTypeEnum.Appraisal)}"
          width="600px"
        >
          {#each $form.productDetails[PlaceOrderProductTypeEnum.Appraisal].attachments as attachment, i (attachment)}
            <Attachment
              on:update="{() => {
                $form = $form;
              }}"
              attachment="{attachment}"
              checkValid="{checkValid}"
              required="{false}"
              options="{getFilteredAttachmentCodes(
                $attachmentCodes,
                PlaceOrderProductTypeEnum.Appraisal,
                'PlaceOrder',
              )}"
              showRemove="{$form.productDetails[PlaceOrderProductTypeEnum.Appraisal].attachments
                .length > 1}"
              on:remove="{() => removeAttachment(PlaceOrderProductTypeEnum.Appraisal, i)}"
            />
          {/each}
          <div class="button-container">
            <Button
              icon="{PlusIcon}"
              label="Add Attachment"
              name="add-attachment"
              on:click="{() => addAttachment(PlaceOrderProductTypeEnum.Appraisal)}"
            />
          </div>
        </FormBlock>
      </FormRow>
    {/if}
    <Divider />
  {/if}

  {#if $form.order.products.includes(PlaceOrderProductTypeEnum.Income)}
    <h2 class="order-header header-xl header-xl--bold">Income Tax Order</h2>
    <FormRow>
      <FormBlock title="Details" width="600px">
        <div class="row">
          <svelte:component
            this="{getComponent(verificationOfTax.fields.product)}"
            {...getProps(verificationOfTax.fields.product)}
            disabled="{$productDetailsProviderOptions[PlaceOrderProductTypeEnum.Income].length ===
              0}"
            on:select="{(e) => {
              $form.productDetails[PlaceOrderProductTypeEnum.Income].product = e.detail.value;
            }}"
            value="{$form.productDetails[PlaceOrderProductTypeEnum.Income].product}"
          />
          <svelte:component
            this="{getComponent(verificationOfTax.fields.provider)}"
            {...getProps(verificationOfTax.fields.provider)}
            disabled
            options="{mapDropdownOptions(
              $productDetailsProviderOptions[PlaceOrderProductTypeEnum.Income],
            ) || []}"
            value="{$form.productDetails[PlaceOrderProductTypeEnum.Income].provider}"
          />
        </div>
        <div class="row">
          <div style="max-width: 400px;">
            <svelte:component
              this="{getComponent(verificationOfTax.fields.clientContact)}"
              disabled
              {...getProps(verificationOfTax.fields.clientContact)}
              value="{$form.productDetails[PlaceOrderProductTypeEnum.Income].clientContact ||
                'Freedom Call Center'}"
            />
          </div>
          <div style="width: 300px;">
            <svelte:component
              this="{getComponent(verificationOfTax.fields.email)}"
              {...getProps(verificationOfTax.fields.email)}
              options="{emailOptions}"
              value="{$form.productDetails[PlaceOrderProductTypeEnum.Income].email}"
              on:blur="{() => {
                dirtyMap.Income.email = true;
              }}"
              on:select="{(e) => {
                $form.productDetails[PlaceOrderProductTypeEnum.Income].email = e.detail.value;
              }}"
            />
          </div>
        </div>
      </FormBlock>
    </FormRow>
    <FormRow>
      <FormBlock width="100%"
        >{#each $form.productDetails[PlaceOrderProductTypeEnum.Income].applications as application, i (application.uid)}
          <IncomeApplication application="{application}" appIdx="{i}" />
        {/each}
        {#if $form.productDetails[PlaceOrderProductTypeEnum.Income].applications[$form.productDetails[PlaceOrderProductTypeEnum.Income].applications.length - 1].applicationType}
          <PlaceholderButton on:click="{addIncomeApplication}">Add Application</PlaceholderButton>
        {/if}
      </FormBlock>
    </FormRow>
  {/if}

  {#if $form.order.products.includes(PlaceOrderProductTypeEnum.Payment)}
    <PaymentForm />
  {/if}
</div>

<style>
  .button-container {
    margin-bottom: 24px;
  }

  .order-header {
    margin-bottom: 24px;
  }

  .loan-number {
    padding-bottom: 8px;
    color: var(--textDefault);
  }

  .product-details :global(.form-row) {
    display: flex;
    justify-content: start;
    align-content: stretch;
    margin-top: 4px;
    margin-bottom: 16px;
  }

  .product-details :global(.form-item) {
    flex: 0 1 33.33%;
    padding-right: 24px;
  }

  .product-details :global(.form-item.two-col.has-action-col) {
    flex: 0 1 calc(66.66% - 40px);
  }

  .product-details :global(.form-item.action-col) {
    flex: 0 0 40px;
    padding-right: 0;
    align-self: flex-end;
  }

  .payments-cta {
    border: 1px solid var(--gray2);
    align-items: center;
    color: var(--textDefault);
    font-weight: 500;
    background: transparent;
    border-radius: 4px;
    display: flex;
    height: 56px;
    letter-spacing: 0.4px;
    outline: none;
    padding: 16px;
    width: 100%;
  }

  .payments-cta-link {
    padding-left: 5px;
    text-decoration: underline;
  }

  .payments-cta-link:hover {
    cursor: pointer;
    color: var(--textDark);
  }

  .min-width--225 :global(.dropdown) {
    width: 225px !important;
  }

  .product-details :global(.form-row .card) {
    background: var(--white);
    box-shadow: none;
    border: 1px solid var(--gray3);
  }
</style>
