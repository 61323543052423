/* eslint-disable no-nested-ternary */
import { writable, derived } from 'svelte/store';
import { PermissionEnumValueEnum } from '@xpanseinc/authorization-service-api-rest';

export const organizationId = writable(null);
export const permissions = writable(null);
export const allUsers = writable([]);
export const currentUser = writable();

export const currentUserPermissions = derived(currentUser, ($currentUser) => {
  const formattedPermissions = {
    admin: {},
    product: {},
  };

  $currentUser?.adminPermissions?.forEach((role) => {
    formattedPermissions.admin[role.name] = !!role.permission;
  });
  $currentUser?.productPermissions?.forEach((product) => {
    formattedPermissions.product[product.name] = product.permission?.value || null;
  });

  return formattedPermissions;
});

export const currentUserCreatePermissions = derived(
  currentUser,
  ($currentUser) =>
    $currentUser?.productPermissions?.filter(
      (per) => per.permission && per.permission?.value === PermissionEnumValueEnum.Create,
    ) || [],
);

export function getAdminStatus(role) {
  let isAdmin;
  currentUserPermissions.subscribe((store) => {
    isAdmin = store.admin[role.toUpperCase()];
  });

  return isAdmin;
}
