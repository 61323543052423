/* eslint-disable consistent-return */
import createAuth0Client from '@auth0/auth0-spa-js';
import { derived, readable, writable } from 'svelte/store';
import { AUTH0_API_AUDIENCE, AUTH0_CLIENT_ID, AUTH0_DOMAIN } from '../const';

const sessionTimeoutRate =
  window.location.hostname === 'localhost' ? 1000 * 60 * 60 * 8 : 1000 * 60 * 30; // 30 minutes or 8 hours for local development
const tokenRefreshRate = 10 * 60 * 60 * 1000;

const initialAuth = {
  authError: null,
  authToken: '',
  isAuthenticated: false,
  isLoading: true,
  login: null,
  logout: null,
  userInfo: {},
};

export const auth = readable(initialAuth, async (set) => {
  if (!AUTH0_DOMAIN || !AUTH0_CLIENT_ID || !AUTH0_API_AUDIENCE) {
    console.error('Error: Auth ENV vars not set');
    return null;
  }

  const authObj = { ...initialAuth };
  const auth0Client = await createAuth0Client({
    domain: AUTH0_DOMAIN,
    client_id: AUTH0_CLIENT_ID,
    audience: AUTH0_API_AUDIENCE,
    cacheLocation: 'localstorage',
  });

  const params = new URLSearchParams(window.location.search);

  // Check if something went wrong during login redirect
  // and extract the error message
  if (params.has('error')) {
    authObj.authError = new Error(params.get('error_description'));
  }

  if (params.has('code') && !window.location.search.includes('code=success')) {
    // Let the Auth0 SDK do it's stuff - save some state, etc.
    await auth0Client.handleRedirectCallback();
    // Can be smart here and redirect to original path instead of root
    window.history.replaceState({}, document.title, '/');
    authObj.authError = null;
  }

  authObj.isAuthenticated = await auth0Client.isAuthenticated();

  let tokenInterval;
  if (authObj.isAuthenticated) {
    const user = await auth0Client.getUser();
    authObj.userInfo = user;

    // Get the ID token in order to call APIs
    // eslint-disable-next-line
    const token = (await auth0Client.getIdTokenClaims()).__raw;
    authObj.authToken = token;

    // refresh token after specific period or things will stop working
    tokenInterval = setInterval(async () => {
      set({
        ...authObj,
        authToken: await auth0Client.getTokenSilently(),
      });
    }, tokenRefreshRate);
  }

  authObj.isLoading = false;
  authObj.login = async () => {
    await auth0Client.loginWithRedirect({
      redirect_uri: `${window.location.origin}/#/`,
    });
  };
  authObj.logout = () => {
    auth0Client.logout({
      returnTo: `${window.location.origin}/#/`,
    });
  };

  set(authObj);

  return () => {
    clearInterval(tokenInterval);
  };
});

export const lastActivityTime = writable(Date.now());

let sessionTimerTimeout;
export const sessionTimer = derived([auth, lastActivityTime], ([$auth]) => {
  if (!$auth.isAuthenticated) {
    return;
  }

  if (sessionTimerTimeout) {
    clearTimeout(sessionTimerTimeout);
  }
  sessionTimerTimeout = setTimeout(() => {
    $auth.logout();
  }, sessionTimeoutRate);

  return () => {
    if (sessionTimerTimeout) {
      clearTimeout(sessionTimerTimeout);
    }
  };
});
