import { mapDropdownOptionsFromEnum } from '../../utils/mapDropdownOptions';
import { InputTypeEnum, PaymentType } from '../place-order';
import type { Page } from '../place-order';

export const feeModificationConfig: Page = {
  paymentInfo: {
    title: 'Payer Information',
    fields: {
      paymentMode: {
        label: 'Type',
        required: true,
        type: InputTypeEnum.Dropdown,
        options: mapDropdownOptionsFromEnum(PaymentType, true, 'key'),
        dependency: null,
      },
      payer: {
        label: 'Payer',
        required: true,
        type: InputTypeEnum.Dropdown,
        options: [],
        dependency: null,
      },
      billToPartyEmailAddress: {
        label: 'Email',
        required: false,
        type: InputTypeEnum.TextString,
        options: null,
        dependency: null,
      },
      amount: {
        label: 'Amount',
        required: true,
        type: InputTypeEnum.TextCurrency,
        options: null,
        dependency: null,
      },
      paymentProcessingTrackingNbr: {
        label: 'Tracking Number',
        required: false,
        type: InputTypeEnum.TextString,
        options: null,
        dependency: null,
      },
    },
  },
};
