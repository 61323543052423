<script>
  import { slide } from 'svelte/transition';
  import { createEventDispatcher } from 'svelte';
  import { Badge, ListCard, Tooltip } from '@xpanseinc/ui-components';
  import {
    BookIcon,
    EditIcon,
    HomeIcon,
    MailIcon,
    PhoneIcon,
    UserIcon,
  } from 'svelte-feather-icons';
  import ErrorMessage from './ErrorMessage.svelte';
  const dispatch = createEventDispatcher();

  export let headerText = '';
  export let badgeText = '';
  export let emailText = '';
  export let phoneText = '';
  export let homeText = '';
  export let faxText = '';
  export let paymentText = '';
  export let ssnText = '';
  export let showErrorMsg = false;
</script>

<div in:slide="{{ x: -200, duration: 500 }}">
  <ListCard>
    <div class="content">
      <div class="top">
        <div class="title">
          <h4>
            {headerText}
          </h4>
          {#if badgeText}
            <Badge>{typeof badgeText === 'string' ? badgeText : 'Borrower'}</Badge>
          {/if}
        </div>
        <div class="actions">
          <div class="separator"></div>
          <button
            aria-label="edit"
            type="button"
            class="btn-unstyled"
            on:click="{() => dispatch('edit')}"
          >
            <Tooltip text="Edit"><EditIcon size="24" /></Tooltip>
          </button>
        </div>
      </div>
      <div class="details">
        {#if emailText}
          <div class="item">
            <MailIcon size="16" />
            <span class="body-small">
              {emailText}
            </span>
          </div>
        {/if}
        {#if phoneText}
          <div class="item">
            <PhoneIcon size="16" />
            <span class="body-small">
              {phoneText}
            </span>
          </div>
        {/if}
        {#if homeText}
          <div class="item">
            <HomeIcon size="16" />
            <span class="body-small">
              {homeText}
            </span>
          </div>
        {/if}
        {#if faxText}
          <div class="item">
            <BookIcon size="16" />
            <span class="body-small">
              {faxText}
            </span>
          </div>
        {/if}
        {#if paymentText}
          <div class="item">
            <UserIcon size="16" />
            <span class="body-small">
              {paymentText}
            </span>
          </div>
        {/if}
        {#if ssnText}
          <div class="item">
            <UserIcon size="16" />
            <span class="body-small">
              {`***-**-${ssnText}`}
            </span>
          </div>
        {/if}
        {#if showErrorMsg}
          <ErrorMessage />
        {/if}
      </div>
    </div>
  </ListCard>
</div>

<style>
  .content {
    padding: 16px;
    width: 100%;
  }

  .top {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  .title {
    align-items: center;
    display: inline-flex;
    gap: 16px;
  }

  .actions {
    align-items: center;
    display: inline-flex;
    justify-self: flex-end;
  }

  .actions > * {
    margin-left: 16px;
  }

  .actions > button {
    color: var(--gray12);
  }

  .separator {
    background: #c4c4c4;
    height: 32px;
    width: 1px;
  }

  .details {
    display: flex;
    gap: 16px;
    margin-top: 8px;
    justify-content: space-between;
  }

  .item {
    align-items: center;
    display: inline-flex;
    gap: 4px;
  }

  .item :global(.feather) {
    color: var(--gray12);
  }

  .body-small {
    color: var(--gray8);
    line-height: 20px;
  }
</style>
