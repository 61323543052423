import { TaxProductsEnum } from './typesAndEnums';

export function getYearForm() {
  let year = new Date().getFullYear() - 1;
  const years = {};

  for (let i = 0; i < 4; i += 1) {
    years[year] = false;
    year -= 1;
  }

  return years;
}

export const getTaxProducts = (product) => {
  if (product === TaxProductsEnum.PRODUCT_4506_C) {
    return {
      Individual: [
        { name: '1040', productId: '1040', transcriptType: '', years: getYearForm() },
        {
          name: 'Verify Tax Filing',
          productId: 'TFV',
          transcriptType: '',
          years: getYearForm(),
        },
        { name: 'Wages and Income', productId: 'W2', transcriptType: '', years: getYearForm() },
      ],
      Joint: [
        { name: '1040', productId: '1040', transcriptType: '', years: getYearForm() },
        {
          name: 'Verify Tax Filing',
          productId: 'TFV',
          transcriptType: '',
          years: getYearForm(),
        },
        { name: 'Wages and Income', productId: 'W2', transcriptType: '', years: getYearForm() },
      ],
      Business: [
        { name: '1065', productId: '1065', transcriptType: '', years: getYearForm() },
        { name: '1120', productId: 'F1120', transcriptType: '', years: getYearForm() },
        { name: '1120S', productId: 'F1120S', transcriptType: '', years: getYearForm() },
      ],
    };
  }
  if (product === TaxProductsEnum.PRODUCT_8821) {
    return {
      Individual: [
        {
          name: '1040 - Return Transcript',
          productId: '1040 - Return Transcript',
          transcriptType: '',
          years: getYearForm(),
        },
        {
          name: '1040 - Record of Account',
          productId: '1040 - Record of Account',
          transcriptType: '',
          years: getYearForm(),
        },
        {
          name: 'W-2 - Employee Earnings',
          productId: 'W-2 - Employee Earnings',
          transcriptType: '',
          years: getYearForm(),
        },
        {
          name: '1099 - Self-Employed Earnings',
          productId: '1099 - Self-Employed Earnings',
          transcriptType: '',
          years: getYearForm(),
        },
        {
          name: 'All Income Data',
          productId: 'All Income Data',
          transcriptType: '',
          years: getYearForm(),
        },
      ],
      Business: [
        {
          name: '1065 - Partnership Return Transcript',
          productId: '1065 - Partnership Return Transcript',
          transcriptType: '',
          years: getYearForm(),
        },
        {
          name: '1065 - Partnership Record of Account',
          productId: '1065 - Partnership Record of Account',
          transcriptType: '',
          years: getYearForm(),
        },
        {
          name: '1120 - Corporate Return Transcript',
          productId: '1120 - Corporate Return Transcript',
          transcriptType: '',
          years: getYearForm(),
        },
        {
          name: '1120 - Corporate Record of Account',
          productId: '1120 - Corporate Record of Account',
          transcriptType: '',
          years: getYearForm(),
        },
        {
          name: '1120S - S Corporation Return Transcript',
          productId: '1120S - S Corporation Return Transcript',
          transcriptType: '',
          years: getYearForm(),
        },
      ],
    };
  }
  return {
    Individual: [],
    Business: [],
  };
};
