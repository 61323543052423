<script>
  import { Dropdown } from '@xpanseinc/ui-components';
  import { PostEventConditionSatisfactionResponsiblePartyEnum } from '@xpanseinc/ui-backend-api';
  import { getLabel } from '../../constants/sendEventDataLabels';
  export let sendEventForm;
  export let checkValid;

  const buildOptions = (enumOptions) => {
    const options = [];
    Object.keys(enumOptions).forEach((option) => {
      options.push({
        label: option.match(/[A-Z][a-z]+|[0-9]+/g).join(' '),
        value: enumOptions[option],
      });
    });
    return options;
  };

  const options = buildOptions(PostEventConditionSatisfactionResponsiblePartyEnum);
</script>

<h4>Condition Satisfaction Responsible Party</h4>

<div class="form-row">
  <div class="form-item">
    <Dropdown
      invalid="{checkValid && !sendEventForm.conditionSatisfactionResponsibleParty}"
      placeholder="Select One"
      label="{getLabel('conditionSatisfactionResponsibleParty')}*"
      name="conditionSatisfactionResponsibleParty"
      options="{options}"
      on:select="{(e) => {
        sendEventForm.conditionSatisfactionResponsibleParty = e.detail.value;
      }}"
    />
  </div>
</div>
