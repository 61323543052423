export const formatFHA = (fhaCaseIdentifier) => {
  let fhaString = fhaCaseIdentifier;

  // Remove any formatting from the FHA string
  if (fhaString.includes('-')) {
    fhaString = fhaString.split('-').join('');
  }

  // Get each section of the correct FHA format (XXX-XXXXXXX-XXX)
  const first = fhaString.slice(0, 3);
  const middle = fhaString.slice(3, 10);
  const last = fhaString.slice(10, 13);

  return `${first}${middle && `-${middle}`}${last && `-${last}`}`;
};
