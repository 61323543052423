<script lang="ts">
  import { createEventDispatcher } from 'svelte';
  import { Button, Dialog } from '@xpanseinc/ui-components';
  import { PartyContactTypeEnum } from '@xpanseinc/ui-backend-api';
  import { PlusIcon, Trash2Icon } from 'svelte-feather-icons';
  import uniqueId from 'lodash-es/uniqueId';
  import FormRow from '../FormRow.svelte';
  import FormBlock from '../FormBlock.svelte';
  import { validate } from '../../../schemas/validate';
  import { getContactSchema } from '../../../schemas/place-order/index';
  import { getComponent, getProps } from '../renderFromConfig';
  import { addContactConfig } from '../../../constants/place-order/index';
  import { emailIsValid, phoneIsValid } from '../../../utils/validation';
  import type { ValidationResult } from '../../../schemas/validate';
  import type { Contact, ContactMethod } from '../../../schemas/place-order/index';

  export let visible = false;
  export let existingItem = null;
  export let orgType = undefined;
  export let showErrorMsg = false;

  const { top, contactDetails, contactInformation, companyInformation } = addContactConfig(orgType);
  const dispatch = createEventDispatcher();
  let dirtyMap: { [k in keyof Contact]?: boolean } = {};
  let hasOpened = false;

  let stagedContact: Contact = getDefaultForm();

  function getDefaultForm(): Contact {
    return {
      partyRole: null,
      firstName: '',
      middleName: '',
      lastName: '',
      companyName: '',
      relationshipVestingType: '',
      contactMethods: [getNewContactMethod()],
    };
  }

  function saveContact() {
    if (!validationResult.valid) {
      Object.keys(validationResult.errors)
        .filter((key) => key.indexOf('contactMethod') === -1)
        .forEach((key) => {
          dirtyMap[key] = true;
        });
      stagedContact.contactMethods.forEach((contactMethod) => {
        const key = `contactMethods[${contactMethod.id}]`;
        Object.keys(contactMethod).forEach((field) => {
          dirtyMap[`${key}.${field}`] = true;
        });
      });
      return;
    }
    if (existingItem) {
      dispatch('edited', { existingItem, newItem: stagedContact });
    } else {
      dispatch('save', stagedContact);
    }
    hasOpened = false;
  }

  function getNewContactMethod(): ContactMethod {
    return {
      id: uniqueId(),
      contactName: '',
      method: '',
      details: '',
      preferred: false,
    };
  }

  function removeContactMethod(i) {
    const [removedContactMethod] = stagedContact.contactMethods.splice(i, 1);
    stagedContact = stagedContact;

    // remove items from the dirtyMap for this contact method
    Object.keys(dirtyMap)
      .filter((k: string) => k.indexOf(`contactMethods[${removedContactMethod.id}]`) > -1)
      .forEach((k: string) => {
        delete dirtyMap[k];
      });

    if (stagedContact.contactMethods.length === 0) {
      stagedContact.contactMethods = [getNewContactMethod()];
    }
  }

  function deleteContact() {
    dispatch('delete', stagedContact);
    close();
  }

  function close() {
    dispatch('close');
    dirtyMap = {};
    hasOpened = false;
  }

  function validateMethods(contacts) {
    const validMap = contacts.map((contact) => {
      const { method, details } = contact;
      let valid;
      if (method === 'Email') {
        valid = emailIsValid(details);
      } else if (method === 'Phone' || method === 'Fax') {
        valid = phoneIsValid(details);
      } else {
        valid = method !== null && details.length > 0;
      }
      return valid;
    });
    return validMap;
  }

  $: {
    if (!visible) {
      dirtyMap = {};
      stagedContact = getDefaultForm();
    }
  }

  $: if (existingItem && !hasOpened) {
    stagedContact = { ...existingItem };
    hasOpened = true;
  }

  $: contactSchema = getContactSchema(stagedContact.contactType);

  let validationResult: ValidationResult;
  $: validationResult = validate(contactSchema, stagedContact);

  let contactMethodsValidationResult;
  $: contactMethodsValidationResult = validateMethods(stagedContact.contactMethods);

  $: {
    if (showErrorMsg) {
      for (const k in contactSchema.fields) {
        dirtyMap[k] = true;
      }
    }
  }
</script>

<Dialog
  title="Add Property Contact"
  titleTag="Required Fields *"
  on:close="{close}"
  size="jumbo"
  bind:visible
>
  <div slot="body" class="body">
    <FormRow>
      <FormBlock width="100%">
        <div class="row">
          <div style="width: 184px;">
            <svelte:component
              this="{getComponent(top.fields.contactType)}"
              {...getProps(top.fields.contactType)}
              value="{stagedContact.contactType}"
              invalid="{dirtyMap.contactType && validationResult.errors.contactType}"
              on:blur="{() => {
                dirtyMap.contactType = true;
              }}"
              on:select="{(e) => {
                stagedContact.contactType = e.detail.value;
              }}"
            />
          </div>
          <div style="width: 240px;">
            <svelte:component
              this="{getComponent(top.fields.contactRole)}"
              {...getProps(top.fields.contactRole)}
              value="{stagedContact.partyRole}"
              invalid="{dirtyMap.partyRole && validationResult.errors.partyRole}"
              on:blur="{() => {
                dirtyMap.partyRole = true;
              }}"
              on:select="{(e) => {
                stagedContact.partyRole = e.detail.value;
              }}"
            />
          </div>
        </div>
      </FormBlock>
    </FormRow>
    <FormRow>
      {#if !stagedContact.contactType || stagedContact.contactType === PartyContactTypeEnum.Individual}
        <FormBlock title="Contact Information">
          <div class="row">
            <div style="width: 200px;">
              <svelte:component
                this="{getComponent(contactInformation.fields.firstName)}"
                {...getProps(contactInformation.fields.firstName)}
                value="{stagedContact.firstName}"
                invalid="{dirtyMap.firstName && validationResult.errors.firstName}"
                on:blur="{() => {
                  dirtyMap.firstName = true;
                }}"
                on:change="{(e) => {
                  stagedContact.firstName = e.target.value;
                }}"
              />
            </div>
            <div style="width: 160px;">
              <svelte:component
                this="{getComponent(contactInformation.fields.middleName)}"
                {...getProps(contactInformation.fields.middleName)}
                value="{stagedContact.middleName}"
                on:change="{(e) => {
                  stagedContact.middleName = e.target.value;
                }}"
              />
            </div>
            <div style="width: 240px;">
              <svelte:component
                this="{getComponent(contactInformation.fields.lastName)}"
                {...getProps(contactInformation.fields.lastName)}
                value="{stagedContact.lastName}"
                invalid="{dirtyMap.lastName && validationResult.errors.lastName}"
                on:blur="{() => {
                  dirtyMap.lastName = true;
                }}"
                on:change="{(e) => {
                  stagedContact.lastName = e.target.value;
                }}"
              />
            </div>
          </div>
        </FormBlock>
      {:else}
        <FormBlock title="Company Information">
          <div class="row">
            <div style="width: 320px;">
              <svelte:component
                this="{getComponent(companyInformation.fields.companyName)}"
                {...getProps(companyInformation.fields.companyName)}
                value="{stagedContact.companyName}"
                invalid="{dirtyMap.companyName && validationResult.errors.companyName}"
                on:blur="{() => {
                  dirtyMap.companyName = true;
                }}"
                on:change="{(e) => {
                  stagedContact.companyName = e.target.value;
                }}"
              />
            </div>
            <div style="width: 240px;">
              <svelte:component
                this="{getComponent(companyInformation.fields.relationshipVestingType)}"
                {...getProps(companyInformation.fields.relationshipVestingType)}
                value="{stagedContact.relationshipVestingType}"
                invalid="{dirtyMap.relationshipVestingType &&
                  validationResult.errors.relationshipVestingType}"
                on:blur="{() => {
                  dirtyMap.relationshipVestingType = true;
                }}"
                on:select="{(e) => {
                  stagedContact.relationshipVestingType = e.detail.value;
                }}"
              />
            </div>
          </div>
        </FormBlock>
      {/if}
    </FormRow>

    <FormRow>
      <FormBlock title="Contact Methods">
        {#each stagedContact.contactMethods as { id, contactName, details, method, preferred }, index (id)}
          <div class="row">
            {#if stagedContact.contactType && stagedContact.contactType !== PartyContactTypeEnum.Individual}
              <div style="width: 216px;">
                <svelte:component
                  this="{getComponent(contactDetails.fields.contactName)}"
                  {...getProps(contactDetails.fields.contactName)}
                  value="{stagedContact.contactMethods[index].contactName}"
                  invalid="{dirtyMap[`contactMethods[${id}].contactName`] &&
                    validationResult.errors[`contactMethods[${index}].contactName`]}"
                  on:blur="{() => {
                    dirtyMap[`contactMethods[${id}].contactName`] = true;
                  }}"
                  on:change="{(e) => {
                    stagedContact.contactMethods[index].contactName = e.target.value;
                  }}"
                />
              </div>
            {/if}
            <div style="width: 200px;">
              <svelte:component
                this="{getComponent(contactDetails.fields.method)}"
                {...getProps(contactDetails.fields.method)}
                value="{stagedContact.contactMethods[index].method}"
                invalid="{dirtyMap[`contactMethods[${id}].method`] &&
                  validationResult.errors[`contactMethods[${index}].method`]}"
                on:blur="{() => {
                  dirtyMap[`contactMethods[${id}].method`] = true;
                }}"
                on:select="{(e) => {
                  stagedContact.contactMethods[index].method = e.detail.value;
                }}"
              />
            </div>
            <div style="width: 328px;">
              <svelte:component
                this="{getComponent(contactDetails.fields.details)}"
                {...getProps(contactDetails.fields.details)}
                value="{stagedContact.contactMethods[index].details}"
                invalid="{(dirtyMap[`contactMethods[${id}].details`] &&
                  validationResult.errors[`contactMethods[${index}].details`]) ||
                  (dirtyMap[`contactMethods[${id}].details`] &&
                    !contactMethodsValidationResult[index])}"
                on:blur="{() => {
                  dirtyMap[`contactMethods[${id}].details`] = true;
                }}"
                on:change="{(e) => {
                  stagedContact.contactMethods[index].details = e.target.value;
                }}"
              />
            </div>
            <svelte:component
              this="{getComponent(contactDetails.fields.preferred)}"
              {...getProps(contactDetails.fields.preferred)}
              value="{stagedContact.contactMethods[index].preferred}"
              bind:checked="{stagedContact.contactMethods[index].preferred}"
            >
              {contactDetails.fields.preferred.label}
            </svelte:component>
            <button
              class="remove-contact-method btn-unstyled"
              on:click="{() => removeContactMethod(index)}"
            >
              <Trash2Icon size="24" />
            </button>
          </div>
        {/each}
      </FormBlock>
    </FormRow>

    <Button
      color="basic"
      label="Add Contact Method"
      name="addContactMethod"
      icon="{PlusIcon}"
      on:click="{() => {
        stagedContact.contactMethods = [...stagedContact.contactMethods, getNewContactMethod()];
      }}"
    />
  </div>
  <footer slot="footer" class="footer">
    <div class="{existingItem ? 'button-container' : ''}">
      {#if existingItem}
        <Button name="close-recording-dialog" label="Delete" on:click="{deleteContact}" />
      {/if}
      <div>
        <Button name="close-recording-dialog" label="Close" on:click="{close}" />
        <Button
          color="primary"
          name="close-recording-dialog"
          label="Save"
          disabled="{!validationResult.valid || contactMethodsValidationResult.includes(false)}"
          on:click="{saveContact}"
        />
      </div>
    </div>
  </footer>
</Dialog>

<style>
  .body,
  .footer {
    margin: 0 50px;
  }

  .button-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .footer :global(button.primary) {
    margin-left: 0.5rem;
  }
</style>
