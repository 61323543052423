<script lang="ts">
  import FormBlock from './FormBlock.svelte';
  import FormRow from './FormRow.svelte';
  import { getComponent, getProps } from './renderFromConfig';
  import { form, PlaceOrderProductTypeEnum, validationResults } from '../../stores/placeOrder';
  import { geoApi } from '../../stores/api';
  import { usStates } from '../../stores/usStates';
  import { InputTypeEnum } from '../../constants/place-order';

  export let dirtyMap;
  export let productType: PlaceOrderProductTypeEnum = PlaceOrderProductTypeEnum.Payment;

  const line1 = {
    label: 'Address line 1',
    name: 'line1',
    required: true,
    type: InputTypeEnum.TextString,
    options: null,
    dependency: null,
  };

  const line2 = {
    label: 'Address line 2',
    name: 'line2',
    required: false,
    type: InputTypeEnum.TextString,
    options: null,
    dependency: null,
  };

  const zip = {
    label: 'Zip',
    name: 'zip',
    required: true,
    type: InputTypeEnum.TextString,
    options: null,
    dependency: null,
  };

  const city = {
    label: 'City',
    name: 'city',
    required: true,
    type: InputTypeEnum.TextString,
    options: null,
    dependency: null,
  };

  const state = {
    label: 'State',
    name: 'state',
    required: true,
    type: InputTypeEnum.Dropdown,
    options: [],
    dependency: null,
  };

  const county = {
    label: 'County',
    name: 'county',
    required: true,
    type: InputTypeEnum.TextString,
    options: null,
    dependency: null,
  };

  const fields = {
    line1,
    line2,
    city,
    state,
    zip,
    county,
  };

  let fetchingZipInfo = false;

  async function onZipcodeChange(e) {
    const { value } = e.target;
    if (!value) {
      return;
    }
    $form.productDetails[productType]['zip'] = e.target.value;
    fetchingZipInfo = true;
    try {
      const { city, county, state } = await $geoApi.getZipInfo({ zipCode: value });

      // @ts-ignore
      $form.productDetails[productType].city = city;
      // @ts-ignore
      $form.productDetails[productType].state = state;
      // @ts-ignore
      $form.productDetails[productType].county = county;
    } catch (error) {
      console.error(error);
    }
    fetchingZipInfo = false;
  }
</script>

<FormRow>
  <FormBlock title="Property Address" width="100%">
    <div class="row">
      <div style="width: 600px;">
        <svelte:component
          this="{getComponent(fields.line1)}"
          {...getProps(fields.line1)}
          invalid="{dirtyMap.line1 && $validationResults.productDetails[productType].errors.line1}"
          value="{$form.productDetails[productType].line1}"
          on:blur="{() => {
            dirtyMap.line1 = true;
          }}"
          on:change="{(e) => {
            $form.productDetails[productType].line1 = e.target.value;
          }}"
        />
      </div>
    </div>
    <div class="row">
      <div style="width: 600px;">
        <svelte:component
          this="{getComponent(fields.line2)}"
          {...getProps(fields.line2)}
          invalid="{dirtyMap.line2 && $validationResults.productDetails[productType].errors.line2}"
          value="{$form.productDetails[productType].line2}"
          on:blur="{() => {
            dirtyMap.line2 = true;
          }}"
          on:change="{(e) => {
            $form.productDetails[productType].line2 = e.target.value;
          }}"
        />
      </div>
    </div>
    <div class="row">
      <div style="width: 150px;">
        <svelte:component
          this="{getComponent(fields.zip)}"
          {...getProps(fields.zip)}
          invalid="{dirtyMap.zip && $validationResults.productDetails[productType].errors.zip}"
          value="{$form.productDetails[productType].zip}"
          max="{5}"
          loading="{fetchingZipInfo}"
          on:blur="{() => {
            dirtyMap.zip = true;
          }}"
          on:change="{(e) => {
            onZipcodeChange(e);
          }}"
        />
      </div>
      <div style="width: 434px;">
        <svelte:component
          this="{getComponent(fields.city)}"
          {...getProps(fields.city)}
          invalid="{dirtyMap.city && $validationResults.productDetails[productType].errors.city}"
          value="{$form.productDetails[productType].city}"
          loading="{fetchingZipInfo}"
          on:blur="{() => {
            dirtyMap.city = true;
          }}"
          on:change="{(e) => {
            $form.productDetails[productType].city = e.target.value;
          }}"
        />
      </div>
    </div>
    <div class="row">
      <div style="width: 150px;">
        <svelte:component
          this="{getComponent(fields.state)}"
          {...getProps(fields.state)}
          invalid="{dirtyMap.state && $validationResults.productDetails[productType].errors.state}"
          value="{$form.productDetails[productType].state}"
          options="{$usStates}"
          on:blur="{() => {
            dirtyMap.state = true;
          }}"
          on:select="{(e) => {
            $form.productDetails[productType].state = e.detail.value;
          }}"
        />
      </div>
      <div style="width: 434px;">
        <svelte:component
          this="{getComponent(fields.county)}"
          {...getProps(fields.county)}
          invalid="{dirtyMap.county &&
            $validationResults.productDetails[productType].errors.county}"
          value="{$form.productDetails[productType].county}"
          loading="{fetchingZipInfo}"
          on:blur="{() => {
            dirtyMap.county = true;
          }}"
          on:change="{(e) => {
            $form.productDetails[productType].county = e.target.value;
          }}"
        />
      </div>
    </div>
  </FormBlock>
</FormRow>
