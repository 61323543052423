export const milestoneThresholds = [
  {
    code: 'AddNoteAck',
    safeThreshold: 10,
    warningThreshold: 0,
  },
  {
    code: 'ReadyToSchedule',
    safeThreshold: 6,
    warningThreshold: 0,
  },
  {
    code: 'RescheduledSigning',
    safeThreshold: 6,
    warningThreshold: 0,
  },
  {
    code: 'ScheduledSigning',
    safeThreshold: 6,
    warningThreshold: 0,
  },
  {
    code: 'Closing Package Delivered',
    safeThreshold: 5,
    warningThreshold: 0,
  },
  {
    code: 'ClosingPackageApprovedByVendor',
    safeThreshold: 4,
    warningThreshold: 0,
  },
  {
    code: 'ApproveSS',
    safeThreshold: 4,
    warningThreshold: 0,
  },
  {
    code: 'DeliverFinalSS',
    safeThreshold: 4,
    warningThreshold: 0,
  },
  {
    code: 'DeliverClosingPackage',
    safeThreshold: 3,
    warningThreshold: 0,
  },
  {
    code: 'ReceiveClosingPackage',
    safeThreshold: 3,
    warningThreshold: 0,
  },
  {
    code: 'ClearToFund',
    safeThreshold: 3,
    warningThreshold: 0,
  },
  {
    code: 'NotClearToFund',
    safeThreshold: 10,
    warningThreshold: 0,
  },
  {
    code: 'FailedSigning',
    safeThreshold: 10,
    warningThreshold: 0,
  },
  {
    code: 'CompletedSigning',
    safeThreshold: 0,
    warningThreshold: Number.MAX_SAFE_INTEGER,
    isDisabled: true,
  },
  {
    code: 'FundsReceived',
    safeThreshold: 0,
    warningThreshold: Number.MAX_SAFE_INTEGER,
    isDisabled: true,
  },
  {
    code: 'FundedLoanByVendor',
    safeThreshold: 0,
    warningThreshold: Number.MAX_SAFE_INTEGER,
    isDisabled: true,
  },
];
