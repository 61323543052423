<script lang="ts">
  import { createEventDispatcher } from 'svelte';
  import * as yup from 'yup';
  import { Button, Dialog } from '@xpanseinc/ui-components';
  import {
    PartyContactTypeEnum,
    PartyPartyRoleEnum,
    TaxpayerIdentifierTaxpayerIdentifierTypeEnum,
  } from '@xpanseinc/ui-backend-api';
  import FormRow from '../../FormRow.svelte';
  import FormBlock from '../../FormBlock.svelte';
  import { getComponent, getProps } from '../../renderFromConfig';
  import { imask, masks } from '../../../../constants/inputMasking';
  import { InputTypeEnum } from '../../../../constants/place-order';
  import { validate, type ValidationResult } from '../../../../schemas/validate';
  import { mapDropdownOptionsFromEnum } from '../../../../utils/mapDropdownOptions';
  import { dateOfBirthIsValid, ssnIsValid } from '../../../../utils/validation';

  export let visible = false;
  export let existingItem = null;
  // These prevent console and eslint warnings
  export const orgType = null;
  export const showErrorMsg = null;

  type SsnBorrower = {
    firstName: string;
    middleName?: string;
    lastName: string;
    ssn: string;
    taxIdType: TaxpayerIdentifierTaxpayerIdentifierTypeEnum.SocialSecurityNumber;
    dateOfBirth: string;
  };

  let dirtyMap: { [k in keyof SsnBorrower]?: boolean } = {};

  const dispatch = createEventDispatcher();

  const defaultValues = {
    partyRole: PartyPartyRoleEnum.Borrower,
    contactType: PartyContactTypeEnum.Individual,
    firstName: '',
    middleName: '',
    lastName: '',
    taxIdType: TaxpayerIdentifierTaxpayerIdentifierTypeEnum.SocialSecurityNumber,
    ssn: '',
    dateOfBirth: '',
  };

  let stagedBorrower = defaultValues;

  const validationSchema = yup.object({
    firstName: yup.string().required('First Name is required'),
    middleName: yup.string(),
    lastName: yup.string().required('Last Name is required'),
    ssn: yup.string().min(9).required('Tax ID is required'),
    // dashes are included in DOB value
    dateOfBirth: yup.string().min(10).required('Date of Birth is required'),
  });

  const fields = {
    firstName: {
      label: 'First Name',
      required: true,
      type: InputTypeEnum.TextString,
      options: null,
      dependency: null,
    },
    middleName: {
      label: 'Middle Name',
      required: false,
      type: InputTypeEnum.TextString,
      options: null,
      dependency: null,
    },
    lastName: {
      label: 'Last Name',
      required: true,
      type: InputTypeEnum.TextString,
      options: null,
      dependency: null,
    },
    ssn: {
      label: 'Social Security Number',
      required: true,
      type: InputTypeEnum.TextString,
      options: null,
      dependency: null,
    },
    taxIdType: {
      label: 'Tax ID Type',
      required: true,
      type: InputTypeEnum.Dropdown,
      options: mapDropdownOptionsFromEnum(
        TaxpayerIdentifierTaxpayerIdentifierTypeEnum,
        true,
        'key',
      ),
      dependency: null,
    },
    dateOfBirth: {
      label: 'Date of Birth',
      required: true,
      type: InputTypeEnum.TextString,
      options: null,
      dependency: null,
    },
  };

  function deleteBorrower() {
    dispatch('delete', stagedBorrower);
    close();
  }

  function saveBorrower() {
    if (!validationResult.valid) {
      Object.keys(validationResult.errors).forEach((key) => {
        dirtyMap[key] = true;
      });
      return;
    }
    if (existingItem) {
      dispatch('edited', { existingItem, newItem: stagedBorrower });
    } else {
      dispatch('save', stagedBorrower);
    }
  }

  function close() {
    dispatch('close');
    dirtyMap = {};
  }

  $: if (!visible) {
    dirtyMap = {};
    stagedBorrower = defaultValues;
  }

  let validationResult: ValidationResult;
  $: validationResult = validate(validationSchema, stagedBorrower);
</script>

<Dialog
  title="Add Borrower"
  titleTag="Required Fields *"
  on:close="{close}"
  size="jumbo"
  bind:visible="{visible}"
>
  <div slot="body" class="body">
    <FormRow>
      <FormBlock title="Borrower Information">
        <div class="row">
          <div style="width: 200px;">
            <svelte:component
              this="{getComponent(fields.firstName)}"
              {...getProps(fields.firstName)}
              value="{stagedBorrower.firstName}"
              invalid="{dirtyMap.firstName && validationResult.errors.firstName}"
              on:blur="{() => {
                dirtyMap.firstName = true;
              }}"
              on:change="{(e) => {
                stagedBorrower.firstName = e.target.value;
              }}"
            />
          </div>
          <div style="width: 160px;">
            <svelte:component
              this="{getComponent(fields.middleName)}"
              {...getProps(fields.middleName)}
              value="{stagedBorrower.middleName}"
              on:change="{(e) => {
                stagedBorrower.middleName = e.target.value;
              }}"
            />
          </div>
          <div style="width: 240px;">
            <svelte:component
              this="{getComponent(fields.lastName)}"
              {...getProps(fields.lastName)}
              value="{stagedBorrower.lastName}"
              invalid="{dirtyMap.lastName && validationResult.errors.lastName}"
              on:blur="{() => {
                dirtyMap.lastName = true;
              }}"
              on:change="{(e) => {
                stagedBorrower.lastName = e.target.value;
              }}"
            />
          </div>
        </div>
        <div class="row">
          <div style="width: 280px;">
            <svelte:component
              this="{getComponent(fields.taxIdType)}"
              {...getProps(fields.taxIdType)}
              value="{stagedBorrower.taxIdType}"
              invalid="{dirtyMap.taxIdType && validationResult.errors.taxIdType}"
              on:blur="{() => {
                dirtyMap.taxIdType = true;
              }}"
              on:select="{(e) => {
                stagedBorrower.taxIdType = e.detail.value;
              }}"
              locked
            />
          </div>
          <div style="width: 200px;">
            <svelte:component
              this="{getComponent(fields.ssn)}"
              {...getProps(fields.ssn)}
              invalid="{!ssnIsValid(stagedBorrower.ssn) && dirtyMap.ssn}"
              maskPackage="{imask}"
              maskOptions="{masks.ssn}"
              on:blur="{() => {
                dirtyMap.ssn = true;
              }}"
              on:accept="{({ detail: { masked } }) => {
                stagedBorrower.ssn = masked.unmaskedValue;
              }}"
            />
          </div>
        </div>
        <div class="row">
          <div style="width: 200px;">
            <svelte:component
              this="{getComponent(fields.dateOfBirth)}"
              {...getProps(fields.dateOfBirth)}
              invalid="{!dateOfBirthIsValid(stagedBorrower.dateOfBirth) && dirtyMap.dateOfBirth}"
              maskPackage="{imask}"
              maskOptions="{masks.dateOfBirth}"
              on:blur="{() => {
                dirtyMap.dateOfBirth = true;
              }}"
              on:accept="{({ detail: { masked } }) => {
                stagedBorrower.dateOfBirth = masked.displayValue;
              }}"
            />
          </div>
        </div>
      </FormBlock>
    </FormRow>
  </div>
  <footer slot="footer" class="footer">
    <div class="{existingItem ? 'button-container' : ''}">
      {#if existingItem}
        <Button name="close-dialog" label="Delete" on:click="{deleteBorrower}" />
      {/if}
      <div>
        <Button name="close-dialog" label="Close" on:click="{close}" />
        <Button
          color="primary"
          name="close-dialog"
          label="Save"
          disabled="{!validationResult.valid || !dateOfBirthIsValid(stagedBorrower.dateOfBirth)}"
          on:click="{saveBorrower}"
        />
      </div>
    </div>
  </footer>
</Dialog>

<style>
  .body,
  .footer {
    margin: 0 50px;
  }

  .button-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .footer :global(button.primary) {
    margin-left: 0.5rem;
  }

  :global(.text-input) {
    width: 100%;
  }
</style>
