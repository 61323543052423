<script>
  import { PartyContactTypeEnum } from '@xpanseinc/ui-backend-api';
  import { IconButton } from '@xpanseinc/ui-components';
  import { Trash2Icon, Edit2Icon } from 'svelte-feather-icons';
  import { createEventDispatcher } from 'svelte';
  import { startCase, camelCase } from 'lodash-es';

  export let party;

  const dispatch = createEventDispatcher();

  const isIndividual = party?.contactType === PartyContactTypeEnum.Individual;
</script>

<div class="form-row">
  <div class="form-item">
    <h5 class="body">
      <strong>
        {#if isIndividual}
          {party?.firstName} {party?.lastName}
        {:else}
          {party?.fullName}
        {/if}
      </strong>
    </h5>
    <p class="body-small">
      {startCase(camelCase(party?.partyRole))}
      {#if isIndividual && party?.fullName}
        ({party.fullName})
      {/if}
    </p>
  </div>
  <div class="form-item">
    {#if party?.phone}
      <p class="body-small">{party?.phone}</p>
    {/if}
    {#if party?.email}
      <p class="body-small">{party?.email}</p>
    {/if}
  </div>
  <div class="form-item form-item--action">
    <IconButton
      color="warning"
      icon="{Trash2Icon}"
      name="delete-party"
      title="Delete Contact"
      on:click="{() => dispatch('delete')}"
    />
    <IconButton
      icon="{Edit2Icon}"
      name="edit-party"
      title="Edit Contact"
      on:click="{() => dispatch('edit')}"
    />
  </div>
</div>

<style>
  h5 {
    margin-bottom: 8px;
  }

  .body-small {
    color: var(--gray8);
  }

  .form-row {
    margin-bottom: 24px;
  }
</style>
