<script>
  import { Button } from '@xpanseinc/ui-components';
  import { DownloadCloudIcon } from 'svelte-feather-icons';
  import ViewHeader from '../ViewHeader.svelte';
  import { createEventDispatcher } from 'svelte';

  const dispatch = createEventDispatcher();
  export let downloading = false;
</script>

<div class="user-header" data-testid="user-management-header">
  <div class="breadcrumb-container">
    <div class="breadcrumb-item breadcrumb-item--active">User Management</div>
  </div>
  <ViewHeader>Users</ViewHeader>
  <div class="btn-container" data-testid="user-management-actions">
    <Button
      name="downloadCSV"
      icon="{DownloadCloudIcon}"
      iconLoading="{downloading}"
      label="Export all"
      on:click="{() => {
        dispatch('downloadCSV');
      }}"
    />
    <Button
      name="bulkImport"
      label="Bulk import"
      on:click="{() => {
        dispatch('bulkImport');
      }}"
    />
    <Button
      name="addUser"
      color="primary"
      label="Add user"
      on:click="{() => {
        dispatch('addUser');
      }}"
    />
  </div>
</div>

<style>
  .user-header {
    justify-content: space-between;
    width: 100%;
    position: relative;
  }

  .btn-container {
    position: absolute;
    top: 30px;
    width: 100%;
    display: inline-flex;
    justify-content: flex-end;
    gap: 8px;
    max-height: 42px;
  }

  /* Btn - SM */
  .btn-container :global(.btn) {
    font-size: 14px;
    padding: 6px 12px;
  }

  .btn-container :global(.btn svg) {
    width: 12px;
    height: 12px;
  }

  .btn-container :global(.btn .icon) {
    margin-right: 4px;
  }
</style>
