export type BulkUser = {
  users: string;
  name: string;
  email: string;
  permissions: string;
};

export const bulkUserImportTemplate = {
  headers: {
    users: 'users',
    name: 'name',
    email: 'email',
    permissions: 'permissions',
  },
  items: [
    {
      users: '""',
      name: 'John Smith',
      email: 'john.smith@test.com',
      permissions: '"UserAdmin,TitleRead,TitleEdit,ClosingRead"',
    },
    {
      users: '""',
      name: 'Jane Doe',
      email: 'jane.doe@test.com',
      permissions: 'TitleRead',
    },
  ],
};

export const bulkPaymentImportTemplate = {
  headers: {
    firstName: 'First Name',
    lastName: 'Last Name',
    email: 'Email',
    amount: 'Amount',
    licenseNumber: 'License Number',
    line1: 'Line 1',
    line2: 'Line 2',
    city: 'City',
    state: 'State',
    zip: 'Zip',
    county: 'County',
    paymentType: 'Payment Type',
  },
  items: [
    {
      firstName: 'John',
      lastName: 'Smith',
      email: 'john.smith@test.com',
      amount: '100',
      licenseNumber: '12345',
      line1: '123 Main Street',
      line2: '',
      city: 'Anywhere',
      state: 'FL',
      zip: '12345',
      county: 'Nowhere',
      paymentType: 'NewBrokerFee',
    },
    {
      firstName: 'Jane',
      lastName: 'Doe',
      email: 'jane.doe@test.com',
      amount: '200',
      licenseNumber: '12355',
      line1: '123 Secondary Street',
      line2: '',
      city: 'Anywhere',
      state: 'FL',
      zip: '12345',
      county: 'Nowhere',
      paymentType: 'RenewalBrokerFee',
    },
  ],
};
