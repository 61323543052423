import { uniqueId } from 'lodash-es';
import { PartyContactTypeEnum, PartyPartyRoleEnum } from '@xpanseinc/ui-backend-api';
import { array, boolean, number, object, string } from 'yup';
import { getContactMethodSchema } from './contactMethod';
import { ResidenceType } from '../../constants/place-order/addSubjectProperty';
import { zipIsValid } from '../../utils/validation';

import type { Contact } from './contact';

export type Address = {
  line1?: string;
  line2?: string;
  city?: string;
  state?: string;
  zipCode?: string;
  county?: string;
};
// @ts-ignore
function getNewContactMethod(): ContactMethod {
  return {
    id: uniqueId(),
    contactName: '',
    method: null,
    details: '',
    preferred: false,
  };
}

export function getNewBorrower() {
  return {
    partyRole: PartyPartyRoleEnum.Borrower,
    contactType: PartyContactTypeEnum.Individual,
    firstName: '',
    middleName: '',
    lastName: '',
    companyName: null,
    taxIdType: null,
    taxId: null,
    maritalStatus: null,
    bankruptcyLast7: false,
    foreclosureLast7: false,
    partyToLawsuit: false,
    contactMethods: [getNewContactMethod()],
    address: {
      line1: '',
      line2: '',
      state: '',
      zipCode: '',
      county: '',
      city: '',
    },
    sameAsSubjectProperty: false,
  };
}

export type Borrower = Contact & {
  residenceType?: ResidenceType;
  taxIdType?: string | null;
  taxId?: number | null;
  maritalStatus?: string | null;
  bankruptcyLast7?: boolean;
  foreclosureLast7?: boolean;
  partyToLawsuit?: boolean;
  partyRole?: PartyPartyRoleEnum;
  address?: Address;
  fullName?: String;
  sameAsSubjectProperty?: boolean;
};

const baseBorrowerSchema = {
  contactType: string().required('Contact Type is required'),
  taxIdType: string().nullable(),
  taxId: number().nullable(),
  bankruptcyLast7: boolean(),
  foreclosureLast7: boolean(),
  partyToLawsuit: boolean(),
  partyRole: string().required('Contact Role is required'),
  contactMethods: array().min(1, 'At least one Contact Method is required'),
  address: object(),
};

export const individualSchema = {
  firstName: string().required('First Name is required'),
  middleName: string(),
  lastName: string().required('Last Name is required'),
  maritalStatus: string().nullable(),
  residenceType: string().oneOf(Object.values(ResidenceType)),
};

const entitySchema = {
  companyName: string().required('Company Name is required'),
  relationshipVestingType: string().required('Relationship Vesting Type is required'),
};

const addressSchema = object().shape({
  line1: string().required('Address Line 1 is required'),
  line2: string(),
  city: string().required('City is required'),
  state: string().required('State is required'),
  zipCode: string()
    .test('zipIsValid', 'zipCode field must have exactly 5 digits', (zipCode) =>
      zipIsValid(zipCode),
    )
    .required('Zip Code is required'),
  county: string().required('County is required'),
});

export function getBorrowerSchema(contactType?: PartyContactTypeEnum, isTaxOrder?: boolean) {
  const contactMethodSchema = getContactMethodSchema(contactType);
  const borrowerSchema = {
    ...baseBorrowerSchema,
    contactMethods: baseBorrowerSchema.contactMethods.of(contactMethodSchema),
  };
  if (!contactType) {
    return object().shape(borrowerSchema);
  }
  if (contactType === PartyContactTypeEnum.Individual) {
    if (isTaxOrder) {
      const taxBorrowerSchema = {
        ...baseBorrowerSchema,
        address: addressSchema,
      };
      return object().shape({
        ...taxBorrowerSchema,
        ...individualSchema,
      });
    }
    return object().shape({
      ...borrowerSchema,
      ...individualSchema,
    });
  }
  return object().shape({
    ...borrowerSchema,
    ...entitySchema,
  });
}
