<script>
  export let title = undefined;
  export let subtitle = undefined;
  export let showRequiredTag = false;
  export let width = 'auto';
</script>

<div class="form-block" style="width: {width};">
  <div class="header">
    <div class="title">
      {#if title}
        <h3 class="header-m header-m--bold">{title}</h3>
      {/if}
      {#if showRequiredTag}
        <p class="required">Required fields *</p>
      {/if}
    </div>

    <div class="subtitle">
      {#if subtitle}
        <p>{subtitle}</p>
      {/if}
    </div>
  </div>

  <div class="content">
    <slot />
  </div>
  <div class="error">
    <slot name="error" />
  </div>
</div>

<style>
  .form-block {
    display: inline-block;
  }

  .header {
    margin: 0 0 16px;
  }

  .title {
    display: flex;
    align-items: center;
  }

  .subtitle {
    color: var(--gray6);
    font-size: 12px;
  }

  .required {
    margin: 0 0 0 1rem;
    font-size: 0.75rem;
    line-height: 1.25;
  }

  .content {
    display: flex;
    flex-direction: column;
    position: relative;
  }

  .content > :global(:not(:last-child):not(.form-block)) {
    margin: 0 0 16px;
  }

  .content > :global(.form-block:not(:last-child)) {
    margin: 0 0 40px;
  }

  .content :global(.row) {
    display: flex;
    gap: 16px;
    align-items: center;
  }

  .header-m--bold {
    color: var(--textDark);
  }
</style>
