import { array, boolean, number, object, string } from 'yup';
import {
  LoanIdentifierLoanIdentifierTypeEnum,
  RefinanceRefinanceCashOutDeterminationTypeEnum,
  TermsOfLoanMortgageTypeEnum,
  TermsOfLoanLoanPurposeTypeEnum,
} from '@xpanseinc/ui-backend-api';
import { OrgTypeEnum } from '../../constants/place-order/typesAndEnums';
import {
  GovernmentRefinanceType,
  LienPriority,
} from '../../constants/place-order/loanDetailsConfig';
import { PlaceOrderProductTypeEnum } from '../../stores/placeOrder';
import type { SubjectProperty } from './subjectProperty';
import type { Borrower } from './borrower';
import type { Contact } from './contact';

export type XMLUploadStatus = {
  uploaded: boolean;
  hadValidLoanNumber?: boolean;
  hadValidLoanIdentityType?: boolean;
};

export type NewLoan = {
  xml?: XMLUploadStatus;
  loanNumber?: string;
  loanPurpose?: string;
  loanIdType?: string;
  govtRefinanceType?: GovernmentRefinanceType;
  mortgageLoanType?: TermsOfLoanMortgageTypeEnum;
  fhaCaseNumber?: string;
  loanAmount?: number;
  loanAmountMasked?: string;
  salesContractAmount?: number;
  cashOutType?: RefinanceRefinanceCashOutDeterminationTypeEnum;
  cashOutAmount?: number;
  lienPriority?: LienPriority;
  cltv?: number;
  ltv?: number;
  piggyback?: boolean;
  govtBondFinance?: boolean;
  employeeLoan?: boolean;
  serviceExpiditeIndicator?: boolean;
  subjectProperty?: SubjectProperty[];
  borrowers?: Borrower[];
  contacts?: Contact[];
  insuranceAgentFirstName?: string;
  insuranceAgentLastName?: string;
  insuranceCompanyName?: string;
  insuranceAgentPhone?: string;
  productDescription?: string;
};

export const loanNumberValidationRules = string()
  .length(10, 'Must be exactly 10 characters')
  .matches(/^[0-9]+$/, 'Must contain only numbers')
  .required('Loan Number is required');

const baseNewLoanSchema = {
  loanNumber: loanNumberValidationRules,
  loanPurpose: string()
    .required('Loan Purpose is required')
    .oneOf(Object.values(TermsOfLoanLoanPurposeTypeEnum)),
  loanIdType: string()
    .required('Loan Identifier Type is required')
    .oneOf(Object.values(LoanIdentifierLoanIdentifierTypeEnum)),
  govtRefinanceType: string().oneOf(Object.keys(GovernmentRefinanceType)),
  mortgageLoanType: string()
    .required('Mortgage Loan Type is required')
    .oneOf(Object.values(TermsOfLoanMortgageTypeEnum)),
  fhaCaseNumber: string(),
  loanAmount: number().positive().required('Loan Amount is required'),
  salesContractAmount: string(),
  cashOutType: string().oneOf(Object.values(RefinanceRefinanceCashOutDeterminationTypeEnum)),
  cashOutAmount: number(),
  lienPriority: string().oneOf(Object.keys(LienPriority)),
  cltv: number().lessThan(101).moreThan(-1),
  ltv: number().lessThan(101).moreThan(-1),
  piggyback: boolean(),
  govtBondFinance: boolean(),
  employeeLoan: boolean(),
  serviceExpiditeIndicator: boolean(),
  subjectProperty: array().required().min(1, 'At least one Subject Property is required'),
  borrowers: array().required().min(1, 'At least one Borrower is required'),
  contacts: array(),
  attachments: array(),
  insuranceAgentFirstName: string(),
  insuranceAgentLastName: string(),
  insuranceCompanyName: string(),
  insuranceAgentPhone: string(),
};

const incomeTaxNewLoanSchema = {
  loanNumber: loanNumberValidationRules,
  borrowers: array().required().min(1, 'At least one Borrower is required'),
};

const isAppraisalOnly = (products: PlaceOrderProductTypeEnum[]) => {
  if (products.length === 1 && products[0] === PlaceOrderProductTypeEnum.Appraisal) {
    return true;
  }

  return false;
};

export function getNewLoanSchema(products, newLoan, userOrgType?) {
  let newLoanSchema = {
    ...baseNewLoanSchema,
  };

  if (newLoan?.loanPurpose === TermsOfLoanLoanPurposeTypeEnum.Purchase) {
    newLoanSchema = {
      ...newLoanSchema,
      salesContractAmount: string().required('Sales Contract Amount is required'),
    };
  }

  if (newLoan?.mortgageLoanType === TermsOfLoanMortgageTypeEnum.Fha) {
    newLoanSchema = {
      ...newLoanSchema,
      fhaCaseNumber:
        userOrgType !== OrgTypeEnum.WHOLESALE && userOrgType !== OrgTypeEnum.BROKER
          ? string().min(13).required('FHA Case Number is required for FHA loans')
          : string().required('FHA Case Number is required for FHA loans'),
    };
  }

  if (isAppraisalOnly(products)) {
    newLoanSchema = {
      ...newLoanSchema,
      loanAmount: number().positive(),
    };
  }

  if (products.length === 1 && products[0] === PlaceOrderProductTypeEnum.Income) {
    return object().shape({
      ...incomeTaxNewLoanSchema,
    });
  }

  return object().shape({
    ...newLoanSchema,
  });
}
