<script>
  import { Button, Dialog, ThemeColor } from '@xpanseinc/ui-components';
  import { createEventDispatcher } from 'svelte';

  export let visible = false;

  const dispatch = createEventDispatcher();

  function close() {
    visible = false;
  }

  function confirm() {
    close();
    dispatch('confirm');
  }
</script>

<Dialog size="small" bind:visible title="Change Application Type?" on:close="{close}">
  <div slot="body">
    Are you sure you want to change the application type? This will erase product selections.
  </div>
  <footer slot="footer" class="footer">
    <Button name="cancel-discard" label="Cancel" on:click="{close}" />
    <Button
      name="confirm-discard"
      label="Confirm"
      color="{ThemeColor.Warning}"
      on:click="{confirm}"
    />
  </footer>
</Dialog>

<style>
  .footer :global(button.primary) {
    margin-left: 0.5rem;
  }
</style>
