<script>
  import { link } from 'svelte-spa-router';
  import OrderCard from './OrderCard.svelte';
  import { activeFilter, searchFilter } from '../stores/orders';
  import { userOrgType } from '../stores/profile';
  import { saveSearchApi } from '../stores/api';
  import { popToast } from '../utils/toasts';
  import { OrgTypeEnum } from '../constants/place-order';
  import urls from '../urls';

  export let orders = [];
  export let displayedChips;
  export let totalSearchResults = undefined;
  export let foundInKeystone = undefined;
  export let keystoneUrl = undefined;

  const updateSearch = async () => {
    try {
      await $saveSearchApi.updateSavedSearch({
        id: $activeFilter.id,
        saveSearchRequest: {
          name: $activeFilter.name,
          search: JSON.stringify({ ...$searchFilter, pageNumber: 0 }),
        },
      });
      popToast({
        title: 'Search successfully updated',
        type: 'success',
        duration: 6000,
      });
    } catch (error) {
      popToast({
        title: 'Error: Failed to update search',
        description: 'Retry',
        duration: 6000,
        onClick: () => {
          updateSearch();
        },
      });
      console.error('updateSavedSearch:', error);
    }
    visible = false;
  };

  $: {
    $activeFilter.name =
      Object.values(displayedChips).indexOf(true) === -1 ? null : $activeFilter.name;
    $activeFilter.id = Object.values(displayedChips).indexOf(true) === -1 ? null : $activeFilter.id;
  }
</script>

{#if totalSearchResults === 0 && $searchFilter.loan && foundInKeystone}
  <div class="go-to-keystone">
    <h2>Loan {$searchFilter.loan} is in KeyStone</h2>
    <h5>All new orders are in Xpanse, but to find that older order, go to</h5>
    <a class="btn primary" href="{keystoneUrl}" target="_blank" rel="noreferrer noopener">
      KeyStone
    </a>
  </div>
{:else if totalSearchResults === 0 && !$searchFilter.loan}
  <div class="no-orders">
    <h2>Sorry, no orders found</h2>
  </div>
{:else if totalSearchResults === 0 && $searchFilter.loan}
  <div class="no-results">
    <h2>No results</h2>
    <h5>
      We didn't find any orders matching the search filters. Orders before November 12th, 2021 may
      be available in <a href="{keystoneUrl}" target="_blank" rel="noreferrer noopener">
        KeyStone
      </a>
    </h5>
  </div>
{/if}

<div class="orders">
  {#each orders as order}
    <a href="{urls.orderDetail + order.id}" use:link>
      <OrderCard
        city="{order.city}"
        county="{order.county}"
        state="{order.state}"
        clientName="{order.client}"
        eventStatus="{order.eventstatus}"
        loanNumber="{order.loan}"
        orderDate="{order.date}"
        orderStatus="{order.status}"
        productName="{order.product}"
        productSubName="{order.productDesc}"
        transactionCode="{order.code}"
        clientId="{order.tcid}"
        deliveryStatus="{order.deliverystatus}"
      />
    </a>
  {/each}
</div>

<style>
  .orders > a {
    display: block;
    text-decoration: none;
    margin: 0 0 0.5rem;
  }

  .go-to-keystone,
  .no-orders {
    margin: 150px 0 0;
    text-align: center;
  }

  .no-results h2,
  .no-orders h2 {
    color: black;
    margin: 0 0 8px;
  }

  .go-to-keystone h5 {
    margin: 0 0 20px;
  }

  .no-results {
    margin: 150px auto 0;
    max-width: 525px;
    text-align: center;
  }
</style>
