<script>
  import { Badge, Tabs, TabList, TabPanel, Tab } from '@xpanseinc/ui-components';
  import { capitalize } from 'lodash-es';
  import { formatDate } from '../utils/formatDate';
  import { MailIcon, PhoneIcon, ChevronDownIcon, ChevronUpIcon } from 'svelte-feather-icons';
  import { borrowers, parties, rawLogs } from '../stores/orders';
  import { OrderAttribute } from '../constants/place-order';
  import { formatFHA } from '../utils/formatFHA';
  import IconLink from './IconLink.svelte';
  export let loan;

  $: numOfBorrowers = $borrowers?.length;
  let borrowerNumber = 0;
  let showMore = false;
  $: {
    $borrowers?.forEach((borrower) => {
      borrower.partyRole = 'BORROWER';
    });
  }
  const taxRequest = loan?.placeOrderAdditionalData?.taxRequest;

  function formatPartyRoles(party, i) {
    if (party.partyRole === 'BORROWER' && borrowerNumber === 0) {
      borrowerNumber += 1;
    } else if (party.partyRole === 'BORROWER' && borrowerNumber > 0 && numOfBorrowers > 2) {
      party.partyRole = `CO-BORROWER (${borrowerNumber}/${numOfBorrowers - 1})`;
      borrowerNumber += 1;
    } else if (party.partyRole === 'BORROWER' && borrowerNumber > 0 && numOfBorrowers <= 2) {
      party.partyRole = `CO-BORROWER`;
      borrowerNumber += 1;
    }
    // switching tabs persists borrowerNumber so reset at last invocation.
    if ($borrowers.length === i + 1) borrowerNumber = 0;

    return party.partyRole;
  }

  function ecloseProgram(loan) {
    const ecloseType = loan?.attributes.findLast((a) => a.code === OrderAttribute.ECLOSING_TYPE);
    const enoteIndicator = loan?.attributes.findLast(
      (a) => a.code === OrderAttribute.ENOTE_INDICATOR,
    );
    const remoteOnlineNotarizationIndicator = loan?.attributes.findLast(
      (a) => a.code === OrderAttribute.ECLOSING_REMOTE_ONLINE_NOTARIZATION_INDICATOR,
    );

    if (!ecloseType || ecloseType.value === 'None') {
      return 'None';
    }

    if (ecloseType.value === 'Hybrid' && enoteIndicator.value === false) {
      return 'Hybrid Eclose';
    } else if (ecloseType.value === 'Hybrid' && enoteIndicator.value === true) {
      return 'Hybrid Eclose with Enote';
    } else if (
      ecloseType.value === 'Full' &&
      enoteIndicator.value === true &&
      remoteOnlineNotarizationIndicator &&
      remoteOnlineNotarizationIndicator.value === true
    ) {
      return 'RON';
    } else if (ecloseType.value === 'Full' && enoteIndicator.value === true) {
      return 'IPEN';
    }
  }
</script>

<aside class="loan-details">
  {#if loan}
    <Tabs currentTab="0">
      <TabList>
        <Tab
          >{#if loan?.paymentOrderRequest}Payment Details{:else}Loan Details{/if}</Tab
        >
        {#if $rawLogs}
          <Tab>Contact</Tab>
        {/if}
      </TabList>
      <TabPanel>
        {#if loan?.paymentOrderRequest}
          <h3 class="header-l">Amount</h3>
          <h5 class="header-m textDefault">
            {loan.paymentOrderRequest.feeModificationAmount.toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
            })}
          </h5>
        {/if}

        <h3 class="header-l">Loan {loan.loanNumber}</h3>
        <h5 class="header-m textDefault">Order {loan.orderId}</h5>

        {#if loan.orderStatus}
          {#if loan.orderStatus == 'Cancelled'}
            <Badge color="warning">{loan.orderStatus}</Badge>
          {:else if loan.orderStatus == 'Completed'}
            <Badge>{loan.orderStatus}</Badge>
          {:else if loan.orderStatus == 'Denied'}
            <Badge color="warning">{loan.orderStatus}</Badge>
          {:else if loan.orderStatus == 'In Progress'}
            <Badge color="updated">{loan.orderStatus}</Badge>
          {:else if loan.orderStatus == 'New'}
            <Badge color="valid">{loan.orderStatus}</Badge>
          {:else if loan.orderStatus == 'Suspended'}
            <Badge color="warning">{loan.orderStatus}</Badge>
          {:else}<Badge>{loan.orderStatus}</Badge>
          {/if}
        {/if}

        {#if loan.loanAmount}
          <h4 class="label">Loan Amount</h4>
          <p class="body">
            {loan.loanAmount.toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
            })}
          </p>
        {/if}

        {#if loan.loanTypePurpose || loan.mortgageType}
          <h4 class="label">Loan Purpose / Loan Type</h4>
          <p class="body">
            {loan.loanTypePurpose ? loan.loanTypePurpose : 'Unknown'} /
            {loan.mortgageType ? loan.mortgageType : 'Unknown'}
            {loan.fhaCaseIdentifier ? `(${formatFHA(loan.fhaCaseIdentifier.toString())})` : ''}
          </p>
        {/if}

        {#if loan.transactionCode}
          <h4 class="label">Transaction ID</h4>
          <p class="body">{loan.transactionCode}</p>
        {/if}

        {#if loan?.borrowers?.length}
          <h4 class="label">Borrower</h4>
          <p class="body">{loan.borrowers[0]?.fullName}</p>
          {#if loan?.borrowers[0]?.email}
            <p class="body-small contact-method">
              <MailIcon size="15" />
              <a class="link" href="mailto:{loan.borrowers[0].email}">{loan.borrowers[0].email}</a>
            </p>
          {/if}
          {#if loan?.borrowers[0]?.phone}
            <p class="body-small contact-method">
              <PhoneIcon size="15" /><a class="link" href="tel:{loan.borrowers[0]?.phone}"
                >{loan.borrowers[0]?.phone}</a
              >
            </p>
          {/if}
        {/if}
        {#if Object.keys(loan.address).some((f) => loan.address[f])}
          <h4 class="label">Address</h4>
          <p class="body">
            {loan.address?.line1 || ''}
            <br />
            {loan.address?.city || ''}, {loan.address?.state || ''}
            {loan.address?.zip || ''}
            <br />
            {loan.address?.county || ''}
          </p>
        {/if}
        <h4 class="label">Product</h4>
        <p class="body-small">
          {loan.productName}
          {#if (loan.productName.toUpperCase() === 'APPRAISAL' || loan.productName.toUpperCase() === 'TAX') && loan.productSubName}
            - {loan.productSubName}
          {/if}
        </p>
        {#if loan.productName.toUpperCase() === 'CLOSING'}
          <h4 class="label">Eclose Program</h4>
          <p>{ecloseProgram(loan)}</p>
        {/if}
        {#if taxRequest}
          <p class="body-small">
            {#if taxRequest?.returnTranscriptIndicator || taxRequest.accountTransriptIndicator || taxRequest.recordOfAccountIndicator}
              <span class="text-xs">
                Transcript Type:
                {#if taxRequest.returnTranscriptIndicator}A (Return Transcript){/if}
                {#if taxRequest.accountTransriptIndicator}B (Account Transcript){/if}
                {#if taxRequest.recordOfAccountIndicator}C (Record of Account){/if}
              </span><br />
            {/if}
            {#if taxRequest?.years.length}
              <span class="text-xs">{taxRequest.years.join(', ')}</span><br />
            {/if}
            {#if taxRequest?.applicationType}
              <span class="text-xs">
                Application type: {capitalize(taxRequest.applicationType)}
              </span><br />
            {/if}
          </p>
        {/if}
        <p class="body-small">
          {#if loan.vendorName}
            Vendor: {loan.vendorName}<br />
          {/if}
          {#if loan.orderDate}
            <span class="text-xs">
              Order Date: {loan.logs[loan.logs.length - 1].createdAtETString}
            </span>
          {/if}
          {#if loan.closingDate}
            <span class="text-xs">
              Closing Date: {formatDate(loan.closingDate, 'ET')}
            </span>
          {/if}
        </p>
      </TabPanel>
      {#if $rawLogs}
        <TabPanel>
          <ul class="contact-list">
            {#each $borrowers as party, i}
              {#if showMore || i < 2}
                <li>
                  {#if party?.partyRole}
                    <p class="label">
                      {formatPartyRoles(party, i)}
                    </p>
                  {/if}
                  <h5 class="body">
                    {#if party?.fullName}
                      {party.fullName}
                    {:else}
                      {`${party?.firstName} ${party?.lastName}`}
                    {/if}
                  </h5>
                  {#if party?.email}
                    <div class="contact-method">
                      <MailIcon size="15" />
                      <p class="no-margin">
                        <a class="link" href="mailto:{party.email}">{party.email}</a>
                      </p>
                    </div>
                  {/if}
                  {#if party?.phone}
                    <div class="contact-method">
                      <PhoneIcon size="15" />
                      <p class="body-small">
                        <a class="link" href="tel:{party.phone}">{party.phone}</a>
                      </p>
                    </div>
                  {/if}
                </li>
              {/if}
            {/each}
            {#if $borrowers?.length > 2}
              <IconLink
                label="{showMore ? 'Show less' : 'Show more'}"
                icon="{showMore ? ChevronUpIcon : ChevronDownIcon}"
                on:click="{() => {
                  showMore = !showMore;
                }}"
              />
            {/if}
            {#each [...$parties.values()] as party}
              {#if party.partyRole !== 'BORROWER'}
                <li>
                  {#if party?.partyRole}
                    <p class="label">{party.partyRole}</p>
                  {/if}
                  <h5 class="body">
                    {#if party?.fullName}
                      {party.fullName}
                    {:else}
                      {`${party?.firstName} ${party?.lastName}`}
                    {/if}
                  </h5>
                  {#if party?.email}
                    <div class="contact-method">
                      <MailIcon size="15" />
                      <p class="body-small">
                        <a class="link" href="mailto:{party.email}">{party.email}</a>
                      </p>
                    </div>
                  {/if}
                  {#if party?.phone}
                    <div class="contact-method">
                      <PhoneIcon size="15" />
                      <p class="body-small">
                        <a class="link" href="tel:{party.phone}">{party.phone}</a>
                      </p>
                    </div>
                  {/if}
                </li>
              {/if}
            {:else}
              <li>
                <p class="body lighten">
                  This loan has no contacts. Send an event to add a contact.
                </p>
              </li>
            {/each}
          </ul>
        </TabPanel>
      {/if}
    </Tabs>
  {/if}
</aside>

<style>
  .lighten {
    color: var(--gray6);
  }

  h4 {
    margin-top: 16px;
    margin-bottom: 4px;
  }

  h5 {
    margin-bottom: 4px;
    color: var(--textDefault);
    line-height: 24px;
    font-weight: 400;
  }

  .loan-details {
    padding: 0 1rem 1rem 1rem;
  }

  .loan-details :global(svg) {
    display: inline-block;
    margin-right: 4px;
    vertical-align: bottom;
  }

  .contact-method {
    display: inline-flex;
    align-items: center;
    margin-bottom: 8px;
  }

  .contact-method :global(svg) {
    margin-right: 4px;
  }

  .contact-method .body-small {
    margin-bottom: 0 !important;
    font-size: 14px;
  }

  .header-m {
    font-weight: 400;
    line-height: 24px;
  }

  p {
    color: var(--textDefault);
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }

  .contact-list p {
    color: var(--textDefault);
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }

  .contact-list li {
    margin-bottom: 1rem;
  }

  .contact-list .link {
    text-transform: lowercase;
  }

  .label {
    color: var(--textDark) !important;
    font-weight: 700 !important;
    font-size: 14px !important;
    line-height: 20px !important;
    margin-bottom: 4px;
  }

  .no-margin {
    margin: 0 !important;
  }

  .link {
    font-size: 14px;
  }
</style>
