import { writable, derived } from 'svelte/store';
import { OrganizationOrgTypeEnum } from '@xpanseinc/ui-backend-api';
import { OrgTypeEnum, OrgNamesEnum } from '../constants/place-order';

export const profile = writable(null);
export const userOrgType = derived(profile, ($profile) => {
  if ($profile.organization.orgType === OrganizationOrgTypeEnum.PlatformOwner) {
    return OrgTypeEnum.PLATFORM_OWNER;
  }
  if ($profile.organization?.orgType === OrganizationOrgTypeEnum.Broker) {
    return OrgTypeEnum.BROKER;
  }
  if ($profile.organization.name === OrgNamesEnum.FREEDOM_WHOLESALE) {
    return OrgTypeEnum.WHOLESALE;
  }
  return undefined;
});
