<script>
  import { DateInput } from '@xpanseinc/ui-components';
  import { onDestroy } from 'svelte';
  import { getLabel } from '../../constants/sendEventDataLabels';

  export let checkValid = false;
  export let required = false;
  export let sendEventForm;

  let date = '';

  if (!sendEventForm.requestedDueDate) {
    sendEventForm.requestedDueDate = undefined;
  }

  onDestroy(() => {
    delete sendEventForm.requestedDueDate;
  });
</script>

<div class="form-row">
  <div class="form-item">
    <DateInput
      invalid="{checkValid && required && !sendEventForm?.requestedDueDate}"
      label="{getLabel('requestedDueDate')}{required ? '*' : ''}"
      name="requestedDueDate"
      dateFormat="n/j/Y"
      enableTime="{false}"
      on:change="{({ detail: [requestedDueDate] }) => {
        sendEventForm.requestedDueDate = requestedDueDate;
      }}"
      bind:date
    />
  </div>
</div>
