<script>
  export let data = {};
</script>

<div class="chart body">
  {#each data as row}
    <div class="row">
      <div class="label ellipsis" class:no-event="{!row.name}" title="{row.name}">
        {row.name || 'No steps taken'}
      </div>
      <div class="bar-wrap">
        <div class="bar" style="width: {row.percentage}%;"></div>
        <span class="value">{row.count}</span>
      </div>
    </div>
  {/each}
</div>

<style>
  .chart {
    display: flex;
    flex-direction: column;
  }

  .row {
    display: flex;
    gap: 16px;
    margin: 0 0 6px;
  }

  .bar-wrap {
    display: inline-flex;
    flex: 1;
    gap: 16px;
  }

  .bar {
    border-radius: 4px;
    height: 20px;
    min-width: 1px;
  }

  .chart .row:nth-child(1n) .bar {
    background-color: var(--dataViz1);
  }

  .chart .row:nth-child(2n) .bar {
    background-color: var(--dataViz2);
  }

  .chart .row:nth-child(3n) .bar {
    background-color: var(--dataViz3);
  }

  .chart .row:nth-child(4n) .bar {
    background-color: var(--dataViz4);
  }

  .chart .row:nth-child(5n) .bar {
    background-color: var(--dataViz5);
  }

  .label {
    text-align: right;
    width: 175px;
    line-height: 1.35rem;
    font-size: 1rem;
    font-weight: 500;
  }

  .no-event {
    color: var(--gray5);
  }

  .value {
    line-height: 1.35rem;
    font-size: 1rem;
    font-weight: 500;
  }
</style>
