<script>
  import { slide } from 'svelte/transition';
  import { Trash2Icon } from 'svelte-feather-icons';
  import { alphabetically } from '../../utils/alphabetically';
  import { Dropdown, IconButton } from '@xpanseinc/ui-components';

  export let title;
  export let btnLabel;
  export let permissions = [];

  let validRoleOptions = [];

  function buildOptions(perms) {
    return perms
      .map((per) => {
        return per.value
          ? { label: per.name, value: per.value }
          : { label: per.label, value: per.name };
      })
      .sort(alphabetically);
  }

  function removePermission(index) {
    permissions[index].permission = {};
  }

  function updatePermission(role, newPerm) {
    permissions.forEach((per) => {
      if (per.name === role) {
        if (newPerm) {
          per.permission = per.validPermissions.find((vp) => vp.value === newPerm);
        } else {
          per.permission = per.validPermissions[0];
        }
      }
    });

    permissions = permissions;
  }

  $: if (permissions) {
    permissions.sort(alphabetically);
    validRoleOptions = permissions.filter((per) => !per?.permission?.value);
    validRoleOptions = buildOptions(validRoleOptions);
  }
</script>

<h4 class="header-l header-l--bold" data-testid="title">{title}</h4>
{#each permissions as permission, i}
  {#if permission?.permission?.value}
    <div class="permission-container" transition:slide="{{ duration: 500 }}">
      <div class="permission-description">
        <h5 data-testid="permission-label">{permission?.label}</h5>
        {#if permission?.description}
          <div class="body-small">{permission?.description}</div>
        {/if}
      </div>
      <div class="actions">
        {#if permission?.validPermissions?.length > 1}
          <div style="width: 220px;">
            <Dropdown
              name="product-permission"
              options="{buildOptions(permission.validPermissions)}"
              placeholder="{'Set permission'}"
              value="{permission.permission.value || null}"
              on:select="{(e) => {
                updatePermission(permission.name, e.detail.value);
              }}"
            />
          </div>
        {/if}
        <IconButton
          color="basic"
          title="delete"
          icon="{Trash2Icon}"
          name="delete-permission"
          on:click="{() => {
            removePermission(i);
          }}"
        />
      </div>
    </div>
  {/if}
{/each}
{#if validRoleOptions.length === permissions.length}
  <p class="body header-m">No {title}</p>
{/if}
<div class="add-role">
  {#if validRoleOptions.length}
    <Dropdown
      name="add-role"
      options="{validRoleOptions}"
      placeholder="{btnLabel}"
      on:select="{(e) => {
        updatePermission(e.detail.value);
      }}"
    />
  {/if}
</div>

<style>
  .permission-container {
    align-items: center;
    background-color: var(--white);
    border-radius: 4px;
    display: flex;
    flex: 1;
    justify-content: space-between;
    margin-bottom: 8px;
    max-width: 100%;
    min-height: 56px;
    padding: 0 10px 0 16px;
    border: 1px solid var(--gray2);
  }

  .permission-container :global(.dropdown) {
    margin-top: 0 !important;
  }

  h5 {
    font-weight: 700;
  }

  h4 {
    margin-bottom: 12px;
  }

  .body-small {
    font-weight: 400;
  }

  .body {
    margin-bottom: 16px;
    font-weight: 400;
  }

  .add-role {
    display: flex;
    max-width: 200px;
  }

  .add-role :global(.dropdown) {
    min-width: 100%;
    margin-top: 0 !important;
  }

  .actions {
    display: inline-flex;
    gap: 26px;
  }

  .actions :global(.dropdown) {
    min-width: 160px;
  }
</style>
