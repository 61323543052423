<script>
  import { DateInput } from '@xpanseinc/ui-components';
  import { onDestroy } from 'svelte';
  import { getLabel } from '../../constants/sendEventDataLabels';

  export let checkValid = false;
  export let required = false;
  export let sendEventForm;

  let date = '';

  if (!sendEventForm.invoiceDate) {
    sendEventForm.invoiceDate = undefined;
  }

  onDestroy(() => {
    delete sendEventForm.invoiceDate;
  });
</script>

<div class="form-row">
  <div class="form-item">
    <DateInput
      invalid="{checkValid && required && !sendEventForm?.invoiceDate}"
      label="{getLabel('invoiceDate')}{required ? '*' : ''}"
      name="invoiceDate"
      dateFormat="n/j/Y"
      enableTime="{false}"
      on:change="{({ detail: [invoiceDate] }) => {
        sendEventForm.invoiceDate = invoiceDate;
      }}"
      bind:date
    />
  </div>
</div>
