import { popToast } from './toasts';

export function renderErrorToast(text: string, error: Error) {
  if (error) {
    console.error(error);
  }
  popToast({
    title: text,
    type: 'error',
    duration: 6000,
  });
}

export function renderSuccessToast(text: string) {
  popToast({
    title: text,
    type: 'success',
    duration: 6000,
  });
}

export function renderWarningToast(text: string) {
  popToast({
    title: text,
    type: 'warning',
    duration: 6000,
  });
}

export function renderInformationToast(text: string) {
  popToast({
    title: text,
    type: 'info',
    duration: 6000,
  });
}
