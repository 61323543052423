<script>
  import {
    ConfirmationDialog,
    Button,
    DateRangeInput,
    Dropdown,
    Skeleton,
    Tooltip,
  } from '@xpanseinc/ui-components';
  import { PermissionTypeEnum } from '@xpanseinc/authorization-service-api-rest';
  import { OrganizationOrgTypeEnum } from '@xpanseinc/ui-backend-api';
  import { SlaScorecardRequestTimeUnitEnum } from '@xpanseinc/report-service-api';
  import { formatISO } from 'date-fns';
  import { DownloadCloudIcon, MoveIcon, PlusIcon } from 'svelte-feather-icons';
  import { dndzone, SOURCES, TRIGGERS } from 'svelte-dnd-action';
  import { flip } from 'svelte/animate';
  import ViewHeader from '../components/ViewHeader.svelte';
  import Layout from '../components/Layout.svelte';
  import SLACard from '../components/SLACard.svelte';
  import { itemOrder, scorecardFilter } from '../stores/slaScorecard';
  import { fetchStates, usStates } from '../stores/usStates';
  import { fetchProducts, products } from '../stores/products';
  import { profile } from '../stores/profile';
  import { fetchVendors, vendors } from '../stores/reports';
  import { reportSlaApi } from '../stores/api';
  import { downloadFile } from '../utils/download';
  import { popToast } from '../utils/toasts';
  import SLAModal from '../components/SLAModal.svelte';
  import SLAEmptyState from '../components/SLAEmptyState.svelte';
  import { getAdminStatus } from '../stores/users';
  import pageTitle from '../utils/pageTitle';

  const title = 'SLA Scorecard';
  fetchProducts();
  fetchStates();
  fetchVendors();

  let cardToEdit = undefined;
  let cardToDelete = undefined;
  let downloading = false;
  let dragDisabled = true;
  let invalidDate = false;
  let items = [];
  let totleItemCount = 0;
  let productsFilter = $scorecardFilter.service || [];
  let statesFilter = $scorecardFilter.states || [];
  let vendorsFilter = $scorecardFilter.vendor || [];
  let showSLAModal = false;
  let showCardLimitConfirmation = false;
  let showDeleteConfirmation = false;

  const DragNDropEnabledDevFlag = false;
  const cardLimit = 18;
  const flipDurationMs = 200;
  const isLender = $profile.hasLenderAccess;
  const isPlatformOwner = $profile.organization?.orgType === OrganizationOrgTypeEnum.PlatformOwner;
  const isReportAdmin = getAdminStatus(PermissionTypeEnum.ReportAdmin);

  const isSLAAdmin = isReportAdmin && (isLender || isPlatformOwner);

  async function fetchCards() {
    const reports = await $reportSlaApi?.reportSLA({ sLARequest: { ...$scorecardFilter } });
    reports.map((report) => (report.id = report.timers.toString()));
    function sortItems(a, b) {
      return $itemOrder.indexOf(a[1]) - $itemOrder.indexOf(b[1]);
    }
    totleItemCount = Math.max(reports.length, totleItemCount);
    items = reports;
    if ($itemOrder.length === 0) {
      updateItemOrder();
    } else {
      items.sort(sortItems);
    }
  }

  function updateItemOrder() {
    if (items.length === totleItemCount) {
      $itemOrder = [];
      items.forEach((report) => {
        itemOrder.set([...$itemOrder, report.id]);
      });
    }
  }

  // Filtering
  function onDateChange({ detail }) {
    invalidDate = false;
    const [from, to] = detail;
    $scorecardFilter = { ...$scorecardFilter, from, to };
  }

  function onProductChange() {
    $scorecardFilter = {
      ...$scorecardFilter,
      service: productsFilter.length > 0 ? productsFilter : undefined,
    };
  }

  function onProviderChange() {
    $scorecardFilter = {
      ...$scorecardFilter,
      vendor: vendorsFilter.length > 0 ? vendorsFilter : undefined,
    };
  }

  function onStateChange() {
    $scorecardFilter = {
      ...$scorecardFilter,
      state: statesFilter.length > 0 ? statesFilter : undefined,
    };
  }

  // Drag Sort
  function handleDndConsider(e) {
    const {
      items: newItems,
      info: { source, trigger },
    } = e.detail;
    items = newItems;
    // updateItemOrder();
    if (source === SOURCES.KEYBOARD && trigger === TRIGGERS.DRAG_STOPPED) {
      dragDisabled = true;
    }
  }

  function handleDndFinalize(e) {
    const {
      items: newItems,
      info: { source },
    } = e.detail;
    items = newItems;
    updateItemOrder();
    if (source === SOURCES.POINTER) {
      dragDisabled = true;
    }
  }

  function startDrag(e) {
    e.preventDefault();
    dragDisabled = false;
  }
  function handleKeyDown(e) {
    if ((e.key === 'Enter' || e.key === ' ') && dragDisabled) dragDisabled = false;
  }

  async function buildFileName() {
    const from = formatISO($scorecardFilter.from, { representation: 'date' });
    const to = formatISO($scorecardFilter.to, { representation: 'date' });
    return `sla_report_${$profile.organization.name}_${from}_${to}_.csv`;
  }

  async function downloadCSV() {
    if (downloading) {
      return;
    }
    downloading = true;

    const request = {
      from: $scorecardFilter.from,
      to: $scorecardFilter.to,
      service: $scorecardFilter.service ? $scorecardFilter.service : undefined,
      state: statesFilter.length > 0 ? statesFilter : undefined,
      vendor: $scorecardFilter.vendor ? $scorecardFilter.vendor : undefined,
      timer: [],
    };
    const response = await $reportSlaApi
      .downloadRaw({
        sLAReportRequest: request,
      })
      .catch((err) => console.error(err));
    const fileName = (await buildFileName()).toString();
    await downloadFile(response.raw.blob(), fileName);
    downloading = false;
  }

  const deleteConfirmationClick = async () => {
    showDeleteConfirmation = false;
    if (cardToDelete.timers) {
      try {
        await $reportSlaApi.deleteSlaScorecard({
          ids: cardToDelete.timers,
        });
        cardToDelete.timers.forEach((timer) => {
          items = items.filter((e) => !e.timers.includes(timer));
          updateItemOrder();
        });
        cardToDelete = undefined;
        popToast({
          title: 'Card successfully deleted',
          type: 'success',
          duration: 6000,
        });
      } catch (error) {
        console.error('delete card error:', error);
      }
    } else {
      popToast({
        title: 'Error: Please refresh page and try again',
        description: 'Refresh',
        duration: 6000,
        onClick: () => {
          window.location.reload();
        },
      });
      console.error("Error: Can't delete card with no timers");
    }
  };

  const openDeleteConfirmation = ({ detail }) => {
    showDeleteConfirmation = true;
    cardToDelete = detail;
  };

  const onAddSLACard = () => {
    if (items?.length >= cardLimit) {
      showCardLimitConfirmation = true;
    } else {
      showSLAModal = true;
    }
  };

  const onEditScorecard = ({ detail }) => {
    cardToEdit = {
      id: detail.timers,
      endEvent: detail.endEvent,
      endEventDescription: detail.endEventDescription,
      service: detail.service,
      sla: detail.sla,
      stateSlas: detail.stateSlas || [
        { sla: undefined, states: [], timeUnit: SlaScorecardRequestTimeUnitEnum.Days },
      ],
      startEvent: detail.startEvent,
      startEventDescription: detail.startEventDescription,
      target: detail.target,
      timeUnit: detail.timeUnit,
    };
    showSLAModal = true;
  };

  scorecardFilter.subscribe(() => {
    fetchCards();
  });
</script>

<svelte:head>
  <title>{pageTitle(title)}</title>
</svelte:head>

<Layout contentMaxWidth="none">
  <div slot="center" class="sla-wrapper">
    <div class="title-container">
      <div class="breadcrumb-container">
        <div class="breadcrumb-item breadcrumb-item--active">SLA's</div>
      </div>
      <ViewHeader>SLA Scorecards</ViewHeader>
    </div>
    <div class="header">
      <div class="actions">
        {#if isSLAAdmin}
          <Button
            color="primary"
            label="Add SLA Card"
            icon="{PlusIcon}"
            name="addSLAcard"
            on:click="{onAddSLACard}"
          />
        {/if}
        <Button
          color="secondary"
          disabled="{!items?.length}"
          label="Download CSV"
          icon="{DownloadCloudIcon}"
          iconLoading="{downloading}"
          name="downloadCSV"
          on:click="{downloadCSV}"
        />
      </div>
    </div>
    <div class="filter">
      <div class="date-filter">
        <label class="visually-hidden" for="sla-date-range">Date Range</label>
        <DateRangeInput
          dataTestId="sla-date-range"
          id="sla-date-range"
          invalid="{invalidDate}"
          name="sla-date-range"
          prepend="Order Date:"
          from="{$scorecardFilter.from}"
          to="{$scorecardFilter.to}"
          on:change="{onDateChange}"
        />
      </div>
      <div class="filter-dropdown">
        <label class="visually-hidden" for="sla-products">Select Product</label>
        <Dropdown
          data-testid="sla-products"
          id="sla-products"
          name="sla-products"
          multiple="true"
          placeholder="All Products"
          options="{$products}"
          bind:value="{productsFilter}"
          on:select="{onProductChange}"
        />
      </div>
      {#if $profile.hasLenderAccess}
        <div class="filter-dropdown">
          <label class="visually-hidden" for="sla-provider">Select Provider</label>
          <Dropdown
            data-testid="sla-provider"
            id="sla-provider"
            name="sla-provider"
            multiple="true"
            placeholder="All Providers"
            options="{$vendors}"
            showSelectAll
            bind:value="{vendorsFilter}"
            on:select="{onProviderChange}"
          />
        </div>
      {/if}
      <div class="filter-dropdown">
        <label class="visually-hidden" for="sla-state">Select State</label>
        <Dropdown
          data-testid="sla-state"
          id="sla-state"
          name="state"
          multiple="true"
          placeholder="All States"
          options="{$usStates}"
          showSelectAll
          bind:value="{statesFilter}"
          on:select="{onStateChange}"
        />
      </div>
    </div>
    {#await fetchCards()}
      <div class="loading-container">
        {#each Array(8) as _, i}
          <Skeleton height="387px" />
        {/each}
      </div>
    {:then}
      {#if items.length}
        <div
          class="scorecards"
          use:dndzone="{{ items, dragDisabled, flipDurationMs, dropTargetClasses: ['dragging'] }}"
          on:consider="{handleDndConsider}"
          on:finalize="{handleDndFinalize}"
        >
          {#each items as item (item.id)}
            <div class="card" id="{item.id}" animate:flip="{{ duration: flipDurationMs }}">
              {#if DragNDropEnabledDevFlag}
                {#if isSLAAdmin}
                  <div
                    tabindex="{dragDisabled ? 0 : -1}"
                    aria-label="drag-handle"
                    class="drag-handle"
                    style="{dragDisabled ? 'cursor: grab;' : 'cursor: grabbing;'}"
                    on:mousedown="{startDrag}"
                    on:touchstart="{startDrag}"
                    on:keydown="{handleKeyDown}"
                  >
                    <Tooltip text="Sort">
                      <MoveIcon size="20" />
                    </Tooltip>
                  </div>
                {/if}
              {/if}
              <SLACard
                props="{item}"
                showMenu="{isSLAAdmin}"
                on:delete="{openDeleteConfirmation}"
                on:edit="{onEditScorecard}"
              />
            </div>
          {/each}
        </div>
      {:else}
        <SLAEmptyState isSLAAdmin="{isSLAAdmin}" on:click="{onAddSLACard}" />
      {/if}
    {/await}
  </div>
</Layout>
<SLAModal
  slaTimerRequest="{cardToEdit}"
  on:submittedCard="{() => fetchCards()}"
  bind:visible="{showSLAModal}"
/>
<ConfirmationDialog
  confirmColor="warning"
  confirmLabel="Delete SLA Card"
  message="Are you sure? This cannot be undone."
  title="Delete SLA Card"
  on:click="{deleteConfirmationClick}"
  bind:visible="{showDeleteConfirmation}"
/>
<ConfirmationDialog
  confirmColor="primary"
  confirmLabel="OK"
  message="You already have the maximum number of allowed cards. Please delete or edit an existing card."
  showCancel="{false}"
  title="Only {cardLimit} SLA Cards are allowed"
  on:click="{() => (showCardLimitConfirmation = false)}"
  bind:visible="{showCardLimitConfirmation}"
/>

<style>
  .sla-wrapper {
    position: relative;
  }
  @media only screen and (min-width: 1330px) {
    .sla-wrapper {
      padding-left: 56px;
    }
  }
  .loading-container {
    position: relative;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
    gap: 32px;
  }
  .header {
    position: absolute;
    top: 30px;
    right: 0;
  }

  .filter {
    display: flex;
    gap: 8px;
    margin-bottom: 24px;
  }

  .sla-wrapper .filter :global(.dropdown),
  .sla-wrapper .filter :global(.text-input) {
    margin-top: 0 !important;
  }

  .sla-wrapper .filter :global(.dropdown .header) {
    padding: 9px 12px;
  }
  .sla-wrapper .filter :global(.dropdown .dropdown-inner) {
    max-height: 43px;
  }
  .sla-wrapper .filter :global(.dropdown.open .dropdown-inner) {
    max-height: none;
  }

  .actions {
    display: flex;
    justify-content: flex-end;
    gap: 8px;
  }

  /* Btn - SM */
  .actions :global(.btn) {
    font-size: 14px;
    padding: 6px 12px;
  }

  .actions :global(.btn svg) {
    width: 12px;
    height: 12px;
  }

  .actions :global(.btn .icon) {
    margin-right: 4px;
  }

  .filter-dropdown {
    width: 180px;
  }

  .date-filter {
    width: 290px;
  }

  .date-filter :global(.prepend) {
    padding-right: 3px;
  }

  .scorecards {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
    gap: 32px;
    margin-bottom: 48px;
  }

  .scorecards:global(.dragging) {
    background-color: white;
    outline: none !important;
    box-shadow: 0 0 0 12px white, 0 0 0 14px var(--brandBlue1);
    border-radius: 4px;
  }

  .scorecards > .card {
    position: relative;
  }

  :global(.modal .dialog .header .header-l--bold) {
    font-weight: 600;
  }

  :global(#dnd-action-dragged-el) {
    transition: var(--transitionHover);
    border-radius: 4px !important;
    box-shadow: 0 4px 7px 4px rgb(0 0 0 / 20%);
    outline: none;
  }

  .scorecards :global(.sla-card) {
    height: 100%;
  }

  .drag-handle {
    color: white;
    cursor: grab;
    position: absolute;
    top: 18px;
    left: 50%;
    margin-left: -10px;
    z-index: 1;
    opacity: 0;
  }

  .scorecards > .card:hover .drag-handle {
    opacity: 1;
    transition: var(--transitionHover);
  }

  .date-filter :global(.text-input) {
    min-height: 43px;
  }
</style>
