<script>
  import { Badge, Card, Tooltip } from '@xpanseinc/ui-components';
  import { EventConfigProductsEnum } from '@xpanseinc/ui-backend-api';
  import { formatDate } from '../utils/formatDate';
  import { AlertTriangleIcon } from 'svelte-feather-icons';

  export let clientName;
  export let eventStatus;
  export let loanNumber;
  export let orderDate;
  export let orderStatus;
  export let productName;
  export let productSubName;
  export let transactionCode;
  export let city;
  export let county;
  export let state;
  export let clientId;
  export let deliveryStatus;
  export let closingDate = undefined;
</script>

<Card>
  <div class="content">
    <div class="main">
      <div class="row top-row">
        {#if deliveryStatus !== 'Delivered'}
          <Tooltip text="{`DELIVERY NOT CONFIRMED\n${deliveryStatus}`}">
            <AlertTriangleIcon size="1x" />
          </Tooltip>
        {/if}
        <h3 class="loan-number header-l">{loanNumber}</h3>

        <p class="transaction-code">
          {transactionCode}
        </p>
      </div>

      <div class="row">
        <div class="order-data">
          <h5 class="label">Order Date</h5>
          <span class="value">
            {formatDate(orderDate, 'ET', 'M/dd/yyyy')}
          </span>
        </div>

        {#if closingDate}
          <div class="order-data">
            <h5 class="label">Closing Date</h5>
            <span class="value">
              {formatDate(closingDate, 'ET', 'M/dd/yyyy')}
            </span>
          </div>
        {/if}

        <div class="order-data">
          <h5 class="label">Order Step</h5>
          <span class="value" class:no-event="{!eventStatus}">
            {eventStatus ? eventStatus : 'No steps taken'}
          </span>
        </div>
      </div>
      <div class="row row-bottom">
        <div class="badges">
          {#if orderStatus == 'Cancelled'}
            <Badge color="warning">{orderStatus}</Badge>
          {:else if orderStatus == 'Completed'}
            <Badge>{orderStatus}</Badge>
          {:else if orderStatus == 'Denied'}
            <Badge color="warning">{orderStatus}</Badge>
          {:else if orderStatus == 'In Progress'}
            <Badge color="updated">{orderStatus}</Badge>
          {:else if orderStatus == 'New'}
            <Badge color="valid">{orderStatus}</Badge>
          {:else if orderStatus == 'Suspended'}
            <Badge color="warning">{orderStatus}</Badge>
          {:else}<Badge>{orderStatus}</Badge>
          {/if}
          <div class="no-dot">
            <Badge>{productName}</Badge>
          </div>
          {#if (productName.toUpperCase() === 'APPRAISAL' || productName.toUpperCase() === 'TAX') && productSubName}
            <div class="no-dot">
              <Badge>{productSubName}</Badge>
            </div>
          {/if}
          <Badge color="version">{`${clientName} ${clientId ? `(${clientId})` : ''}`}</Badge>
        </div>
      </div>
    </div>
    <div class="property">
      {#if city || state}
        <span class="label">Subject Property</span>
        <span class="value">{city}, {state}</span>
      {/if}
      {#if county}
        <span class="label">County</span>
        <span class="value">{county}</span>
      {/if}
    </div>
  </div>
</Card>

<style>
  .top-row {
    align-items: center;
  }

  .content {
    display: flex;
    justify-content: space-between;
    padding: 1rem 1rem 0.75rem 1rem;
  }

  .property {
    width: 190px;
    padding-left: 15px;
    border-left: 1px solid var(--gray3);
  }

  .property > .value {
    margin-bottom: 10px;
  }

  .row {
    display: flex;
    margin-bottom: 0.75rem;
  }

  .row :global(.target) {
    display: flex;
    color: var(--negativeDark);
    margin-right: 12px;
  }

  .row :global(.tooltip) {
    max-width: 190px;
  }

  .badges :global(.badge:not(:last-child)) {
    margin-right: 4px;
    margin-bottom: 4px;
  }

  .loan-number,
  .order-data {
    padding-right: 24px;
    white-space: nowrap;
  }

  .label {
    display: block;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: var(--textDefault);
  }

  .value {
    display: block;
    padding: 0 8px 0 0;
    color: var(--textDark);
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }

  .no-event {
    color: var(--gray4);
  }

  .transaction-code {
    color: var(--textDefault);
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 0 !important;
  }

  .row-bottom {
    margin-bottom: 0 !important;
  }

  .no-dot {
    display: inline-flex;
    margin-right: 4px;
    margin-bottom: 4px;
  }

  .no-dot :global(.general:not(:empty) .dot) {
    display: none;
  }
</style>
