<script>
  export let active = false;
  export let href;
  export let icon;
  export let label = '';

  export let isExternal = false;
</script>

<a
  class="btn"
  class:active
  href="{href}"
  aria-label="{label}"
  tooltip="{label}"
  tooltip-position="right"
  target="{isExternal ? '_blank' : null}"
  rel="{isExternal ? 'noopener noreferrer' : null}"
>
  <svelte:component this="{icon}" size="24" />
</a>

<style>
  a.btn {
    border-radius: 0;
    background: var(--white);
    color: var(--actionDarkDefault);
    padding: 10px 0;
    min-width: 56px;
    transition: var(--transitionHover);
  }

  a.btn::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 2px;
    height: 100%;
    background-color: transparent;
    border-radius: 0 2px 2px 0;
  }

  a.btn:focus-visible {
    outline: none;
    box-shadow: 0 0 0 4px var(--focusDefault);
  }

  a.btn:active {
    background: var(--focusLight);
    color: var(--actionPrimaryDefault);
    box-shadow: none;
  }

  a.btn.active {
    color: var(--actionPrimaryDefault);
  }

  a.btn.active::after {
    background-color: var(--actionPrimaryDefault);
  }

  a.btn:hover {
    color: var(--actionPrimaryDefault);
  }
</style>
