import {
  PartyContactTypeEnum,
  PartyPartyRoleEnum,
  TermsOfLoanMortgageTypeEnum,
} from '@xpanseinc/ui-backend-api';
import type { NewLoan } from '../schemas/place-order/newLoan';
import type { ExistingLoan } from '../schemas/place-order/existingLoan';

function getContactMethods(borrower) {
  const contactMethods = [];
  if (borrower.email) {
    contactMethods.push({
      method: 'Email',
      details: borrower.email,
    });
  }
  if (borrower.phone) {
    contactMethods.push({
      method: 'Phone',
      details: borrower.phone,
    });
  }
  return contactMethods;
}

function toParty(party: any) {
  return {
    firstName: party.firstName,
    lastName: party.lastName,
    middleName: party.middleName ? party.middleName : '',
    partyRole: PartyPartyRoleEnum[party.role],
    contactType: PartyContactTypeEnum.Individual,
    contactMethods: getContactMethods(party),
    address: {
      city: null,
      state: null,
      zipCode: null,
      county: null,
      line1: null,
      line2: null,
    },
  };
}

export function newLoanFromExisting(existingLoan: ExistingLoan): NewLoan {
  return {
    loanNumber: existingLoan.loanNumber,
    loanPurpose: existingLoan.loanTypePurpose.toUpperCase(),
    loanIdType: 'LENDER_LOAN',
    loanAmount: existingLoan.loanAmount,
    mortgageLoanType: existingLoan.mortgageType as TermsOfLoanMortgageTypeEnum,
    productDescription: existingLoan.productName,
    borrowers: existingLoan.parties
      .filter((p: any) => p.role === 'Borrower' || p.role === 'CoBorrower')
      .map((borrower: any) => toParty(borrower)),
    contacts: existingLoan.parties
      .filter((p: any) => p.role !== 'Borrower' && p.role !== 'CoBorrower')
      .map((contact: any) => toParty(contact)),
    subjectProperty: [
      {
        city: existingLoan.address.city,
        state: existingLoan.address.state,
        zipCode: existingLoan.address.zip,
        county: existingLoan.address.county,
        addressLine1: existingLoan.address.line1,
        addressLine2: existingLoan.address.line2,
        parcelId: null,
        platBlockIdentifier: null,
        platLotIdentifier: null,
        buildingPermissionUsageType: null,
      },
    ],
  };
}
