import { format, utcToZonedTime } from 'date-fns-tz';

export function formatDate(
  date: Date | any,
  timeZoneLabel?: string,
  dtFormat?: string,
  timeZone: string = 'America/New_York',
): string {
  // On the off chance a Date returned from outside XML is not a valid date object or string, return error message
  const isDateValid = !Number.isNaN(Date.parse(date));
  const dateFormat = timeZoneLabel
    ? dtFormat || `M/dd/yyyy 'at' h:mma '(${timeZoneLabel})'`
    : dtFormat || 'M/dd/yyyy';

  return isDateValid
    ? `${format(utcToZonedTime(date, timeZone), dateFormat, { timeZone })}`
    : 'N/A';
}

// Checks the timezone offset of client's local timezone to determine of DST is in effect
export function isCurrentlyDST(d) {
  const jan = new Date(d.getFullYear(), 0, 1).getTimezoneOffset();
  const jul = new Date(d.getFullYear(), 6, 1).getTimezoneOffset();

  return Math.max(jan, jul) !== d.getTimezoneOffset();
}

export function convertUtcOffset(utcString) {
  // Remove minutes after the hour
  const noMinutes = utcString.split(':')[0];
  // Remove UTC characters
  const offsetNum = noMinutes.replace(/[^0-9]/g, '');
  // Remove leading 0
  const noLeadingZero = parseInt(offsetNum, 10);
  // Return correct format
  return `-${noLeadingZero < 10 ? 0 : ''}${noLeadingZero}00`;
}

// Converts UTC offsets from the "UTC-#" format to the corresponding US timezone label
export function getTzFromUtcOffset(date, utcString, isDST = undefined) {
  const isDstNow = isDST || isCurrentlyDST(date);
  const offset = utcString?.split('UTC')[1]?.split(':')[0] || null;

  switch (offset) {
    case '-4':
    case '-04':
      return isDstNow ? 'EDT' : 'AST';
    case '-5':
    case '-05':
      return isDstNow ? 'CDT' : 'EST';
    case '-6':
    case '-06':
      return isDstNow ? 'MDT' : 'CST';
    case '-7':
    case '-07':
      return isDstNow ? 'PDT' : 'MST';
    case '-8':
    case '-08':
      return isDstNow ? 'AKDT' : 'PST';
    case '-9':
    case '-09':
      return isDstNow ? 'HDT' : 'AKST';
    case '-10':
      return isDstNow ? null : 'HST';
    default:
      return null;
  }
}
