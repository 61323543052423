import { InputTypeEnum, OrgNamesEnum, OrgTypeEnum } from '../typesAndEnums';
import { mapDropdownOptions } from '../../../utils/mapDropdownOptions';

const PAYMENT_PURPOSE_OPTIONS = ['Appraisal Deposit'];

const paymentPurpose = {
  label: 'Payment Purpose',
  name: 'paymentPurpose',
  required: true,
  type: InputTypeEnum.Dropdown,
  options: mapDropdownOptions(PAYMENT_PURPOSE_OPTIONS),
  dependency: null,
  width: '200px',
};
const provider = {
  label: 'Provider',
  name: 'provider',
  required: false,
  type: InputTypeEnum.Dropdown,
  options: mapDropdownOptions(['AFS']),
  dependency: null,
  width: '384px',
  isDisabled: true,
};
const loanNumber = {
  label: 'Loan number',
  name: 'loanNumber',
  required: true,
  type: InputTypeEnum.TextString,
  options: null,
  dependency: null,
  width: '292px',
};

const paymentType = {
  label: 'Payment type',
  name: 'paymentType',
  required: true,
  type: InputTypeEnum.Dropdown,
  options: mapDropdownOptions(['Pre-paid']),
  dependency: null,
  width: '155px',
  isDisabled: true,
};

const feeAmount = {
  label: 'Fee amount',
  name: 'feeAmount',
  required: true,
  type: InputTypeEnum.TextCurrency,
  options: null,
  dependency: null,
  width: '145px',
};

const email = {
  label: 'Email',
  name: 'email',
  required: true,
  type: InputTypeEnum.TextString,
  options: null,
  dependency: null,
  width: '268px',
};

const payerFirstName = {
  label: 'Payer first name',
  name: 'firstName',
  required: true,
  type: InputTypeEnum.TextString,
  options: null,
  dependency: null,
  width: '292px',
};

const payerLastName = {
  label: 'Payer last name',
  name: 'lastName',
  required: true,
  type: InputTypeEnum.TextString,
  options: null,
  dependency: null,
  width: '292px',
};

const transactionCode = {
  label: 'Transaction Code',
  name: 'transactionCode',
  required: true,
  type: InputTypeEnum.TextString,
  options: null,
  dependency: null,
  width: '292px',
};

const paymentInformationBlock = {
  title: 'Payment Information',
  rows: [
    {
      fields: [paymentType, feeAmount, email],
    },
    {
      fields: [payerFirstName, payerLastName],
    },
  ],
};

const DEFAULT_CONFIG = [
  {
    title: 'Details',
    rows: [
      {
        fields: [paymentPurpose, provider],
      },
      {
        fields: [loanNumber],
      },
    ],
  },
  paymentInformationBlock,
];

const callCenterPaymentPurpose = {
  ...paymentPurpose,
  options: mapDropdownOptions([...PAYMENT_PURPOSE_OPTIONS, 'Pest Inspection']),
};

const wholesalePaymentPurpose = {
  ...paymentPurpose,
  options: mapDropdownOptions([...PAYMENT_PURPOSE_OPTIONS, 'VA Broker Payment']),
};

const freedomServicingPaymentPurpose = {
  ...paymentPurpose,
  options: mapDropdownOptions([...PAYMENT_PURPOSE_OPTIONS, 'Assumptions']),
};

const callCenterPaymentConfig = {
  default: [
    {
      title: 'Details',
      rows: [
        {
          fields: [callCenterPaymentPurpose, provider],
        },
        {
          fields: [loanNumber],
        },
      ],
    },
    paymentInformationBlock,
  ],
};

const wholesalePaymentConfig = {
  'VA Broker Payment': [
    {
      title: 'Details',
      rows: [
        {
          fields: [wholesalePaymentPurpose, provider],
        },
        {
          fields: [transactionCode],
        },
      ],
    },
  ],
  default: [
    {
      title: 'Details',
      rows: [
        {
          fields: [wholesalePaymentPurpose, provider],
        },
        {
          fields: [loanNumber],
        },
      ],
    },
    paymentInformationBlock,
  ],
};

const freedomServicingPaymentConfig = {
  assumptions: [
    {
      title: 'Details',
      rows: [
        {
          fields: [freedomServicingPaymentPurpose, provider],
        },
        {
          fields: [transactionCode],
        },
      ],
    },
  ],
  default: [
    {
      title: 'Details',
      rows: [
        {
          fields: [freedomServicingPaymentPurpose, provider],
        },
        {
          fields: [loanNumber],
        },
      ],
    },
    paymentInformationBlock,
  ],
};

export const getPaymentFormBlocks = (
  orgType: OrgTypeEnum,
  paymentPurpose: string,
  lenderName: string,
) => {
  let paymentOptions = DEFAULT_CONFIG;

  if (lenderName === OrgNamesEnum.FREEDOM_SERVICING) {
    paymentOptions = freedomServicingPaymentConfig.default;
  } else if (lenderName === OrgNamesEnum.FREEDOM_CALL_CENTER) {
    paymentOptions = callCenterPaymentConfig.default;
  } else if (orgType === OrgTypeEnum.WHOLESALE) {
    paymentOptions = wholesalePaymentConfig[paymentPurpose] || wholesalePaymentConfig.default;
  }

  return paymentOptions;
};
