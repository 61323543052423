<script>
  import Layout from '../components/Layout.svelte';
  import UserInfo from '../components/add-user/UserInfo.svelte';
  import { userApi } from '../stores/api';
  import pageTitle from '../utils/pageTitle';
  import { renderErrorToast, renderSuccessToast } from '../utils/renderToasts';
  import { LoadingModal, Skeleton } from '@xpanseinc/ui-components';
  import ModifyPermissions from '../components/add-user/ModifyPermissions.svelte';
  import AddUserFooter from '../components/add-user/AddUserFooter.svelte';
  import urls from '../urls';
  import ConfirmDeleteUserDialog from '../components/user-management/ConfirmDeleteUserDialog.svelte';
  import { currentUser, organizationId } from '../stores/users';
  import { push } from 'svelte-spa-router';
  import { UserStatusEnum } from '@xpanseinc/authorization-service-api-rest';

  export let params = {};

  $: isEditingExistingUser = window?.location?.hash.includes(urls.editUser.split(':')[0]);
  $: isCopyingUser = window?.location?.hash.includes(urls.copyUser.split(':')[0]);

  function defaultUser() {
    let buildUser = {
      organizationId: $organizationId,
      name: '',
      email: '',
      status: UserStatusEnum.Active,
      adminPermissions: [...($currentUser.adminPermissions || [])],
      productPermissions: [...($currentUser.productPermissions || [])],
    };
    buildUser.adminPermissions.map((r) => (r.permission = {}));
    buildUser.productPermissions.map((r) => (r.permission = {}));
    return buildUser;
  }

  let user = defaultUser();
  let checkValid = false;
  let isUserFetched = false;
  let isDeleteDialogVisible = false;
  let coppiedUserName = '';
  let submittingUserForm = false;

  const genericErrorMessage = 'Something went wrong';

  function resetForm() {
    user = defaultUser();
    checkValid = false;
    coppiedUserName = '';
  }

  async function fetchSelectedUser() {
    try {
      const userDetails = await $userApi.getUser({ userId: params.userId });
      user = userDetails;
      if (isCopyingUser) {
        coppiedUserName = user?.name;
        user.name = '';
        user.email = '';
      }
    } catch (error) {
      renderErrorToast(genericErrorMessage, error);
    }
    isUserFetched = true;
  }

  function cancelAddUser() {
    resetForm();
    push(urls.userManagement);
  }

  async function postUser(apiFn) {
    try {
      await apiFn();
    } catch (error) {
      if (error.status === 409) {
        renderErrorToast('User with that email already exists', error);
      } else {
        renderErrorToast(genericErrorMessage, error);
      }
    }
  }

  async function saveUser() {
    submittingUserForm = true;
    try {
      postUser(async () => {
        let tempUser = {
          organizationId: $organizationId,
          name: user.name,
          email: user.email,
          status: UserStatusEnum.Active,
          adminPermissions: user.adminPermissions,
          productPermissions: user.productPermissions,
        };
        if (isEditingExistingUser) {
          const isEditingSelf = user.id === $currentUser.id;
          const updatedUser = await $userApi.updateUser({ userId: params.userId, user: tempUser });

          if (isEditingSelf) {
            $currentUser = updatedUser;
          }
        } else {
          await $userApi.createUser({ user: tempUser });
        }
      });
      if (isEditingExistingUser) {
        renderSuccessToast(`User ${user.name} was updated`);
      } else {
        renderSuccessToast(`User ${user.name} was successfully created`);
      }
      resetForm();
      setTimeout(() => {
        push(urls.userManagement);
      }, 1000);
    } catch (error) {
      renderErrorToast(genericErrorMessage, error);
    }
    submittingUserForm = false;
  }

  function isFormValid() {
    if (!user.name || !user.email) return false;
    return true;
  }

  function onSubmit() {
    checkValid = true;
    if (!isFormValid()) {
      return;
    }
    saveUser();
  }

  async function deleteUser() {
    try {
      await $userApi.deleteUser({ userId: params.userId });
      renderSuccessToast(`User ${user.name} was successfully deleted`);
    } catch (error) {
      renderErrorToast(genericErrorMessage, error);
    }
    resetForm();
    push(urls.userManagement);
  }

  $: if (isEditingExistingUser || isCopyingUser) {
    fetchSelectedUser();
  }
</script>

<svelte:head>
  <title>{pageTitle(isEditingExistingUser ? 'Edit User' : 'Add User')}</title>
</svelte:head>

<Layout>
  <form class="add-user" slot="center" on:submit|preventDefault="{onSubmit}">
    {#if !!params?.userId && !isUserFetched}
      <div class="load-wrapper">
        <Skeleton height="100%" />
      </div>
    {:else}
      <div class="breadcrumb-container">
        <div class="breadcrumb-item">
          <div class="dummy-link" on:click="{cancelAddUser}">User Management</div>
        </div>
        <div class="breadcrumb-item breadcrumb-item--active">
          {isEditingExistingUser ? user?.name : isCopyingUser ? 'Copy user' : 'Add user'}
        </div>
      </div>
      <div class="user-body-container">
        <UserInfo
          title="{isEditingExistingUser ? user?.name : isCopyingUser ? 'Copy user' : 'Add user'}"
          subtitle="{isCopyingUser ? coppiedUserName : ''}"
          checkValid="{checkValid}"
          on:click="{cancelAddUser}"
          bind:name="{user.name}"
          bind:email="{user.email}"
        />
        <div class="permissions">
          <ModifyPermissions
            btnLabel="Add role"
            permissions="{user?.adminPermissions}"
            title="Administrative roles"
          />
        </div>
        <div class="permissions">
          <ModifyPermissions
            btnLabel="Add product"
            permissions="{user?.productPermissions}"
            title="Products"
          />
        </div>
        <div class="actions">
          <AddUserFooter
            bind:isEditingExistingUser
            on:cancel="{cancelAddUser}"
            on:delete="{() => {
              isDeleteDialogVisible = true;
            }}"
            on:save="{onSubmit}"
          />
        </div>
      </div>
    {/if}
  </form>
</Layout>

{#if submittingUserForm}
  <LoadingModal isFullScreen text="Saving user" />
{/if}
<ConfirmDeleteUserDialog
  user="{user}"
  on:confirm="{() => {
    deleteUser();
  }}"
  bind:visible="{isDeleteDialogVisible}"
/>

<style>
  .add-user {
    max-width: 720px;
    margin-bottom: 48px;
  }

  .permissions {
    margin-bottom: 52px;
  }

  .load-wrapper {
    position: relative;
    width: 100%;
    height: 80vh;
  }
</style>
