<script>
  export let message = 'This section is missing required information';
  import { AlertTriangleIcon } from 'svelte-feather-icons';
</script>

<div class="item error-msg">
  <AlertTriangleIcon size="14" />
  {message}
</div>

<style>
  .error-msg {
    color: #d1000e;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    flex-grow: 1;
  }

  .error-msg :global(.feather) {
    color: #d1000e;
    margin-right: 5px;
  }
</style>
