<script>
  export let counts = [];
  export let range;

  let totalsMap = new Map();
  let totals = [];

  $: {
    totalsMap.set('historical', 0);
    range.forEach((day) => {
      totalsMap.set(day.getUTCDate(), 0);
    });
  }

  $: {
    counts.forEach((count) => {
      count.milestoneEventDates.forEach((milestone) => {
        if (!milestone.isHistorical) {
          totalsMap.set(
            milestone.date.getUTCDate(),
            totalsMap.get(milestone.date.getUTCDate()) + milestone.total,
          );
        } else {
          totalsMap.set('historical', totalsMap.get('historical') + milestone.total);
        }
      });
    });
    totals = Array.from(totalsMap.values());
  }
</script>

<div class="totals-bar">
  <div class="label">Total this date</div>
  <div class="totals">
    {#each totals as dayTotal}
      <div class="total body">
        {dayTotal}
      </div>
    {/each}
  </div>
</div>

<style>
  .totals-bar {
    min-height: 28px;
    max-width: 1040px;
    margin: 12px 0 0;
    display: flex;
    flex: 1;
    align-items: center;
    background-color: var(--gray1);
    border-radius: 4px;
  }

  .label {
    display: flex;
    min-width: 168px;
    justify-content: flex-end;
    font-size: 12px;
  }

  .totals {
    display: flex;
    justify-content: space-between;
  }

  .total {
    font-weight: 400;
    width: 76px;
    text-align: center;
  }
</style>
