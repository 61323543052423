<script>
  export let marginBottom = '24px';
</script>

<div class="divider" style="margin-bottom: {marginBottom}px;"></div>

<style>
  .divider {
    background: var(--gray2);
    height: 1px;
    margin: 0 0 24px;
  }
</style>
