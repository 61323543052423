<script lang="ts">
  import { createEventDispatcher, onMount } from 'svelte';
  import { Button, Dialog, RadioButton } from '@xpanseinc/ui-components';
  import uniqueId from 'lodash-es/uniqueId';
  import { PlusIcon, Trash2Icon } from 'svelte-feather-icons';
  import FormRow from '../FormRow.svelte';
  import FormBlock from '../FormBlock.svelte';
  import { validate } from '../../../schemas/validate';
  import { PartyContactTypeEnum, PartyPartyRoleEnum } from '@xpanseinc/ui-backend-api';
  import { getComponent, getProps } from '../renderFromConfig';
  import { applicantSchema } from '../../../schemas/place-order/index';
  import { addContactConfig } from '../../../constants/place-order';
  import { imask, masks } from '../../../constants/inputMasking';
  import { emailIsValid, phoneIsValid, ssnIsValid } from '../../../utils/validation';
  import { usStates } from '../../../stores/usStates';
  import { geoApi } from '../../../stores/api';
  import { form } from '../../../stores/placeOrder';
  import type { ValidationResult } from '../../../schemas/validate';
  import type { Address, ContactMethod, Applicant } from '../../../schemas/place-order/index';
  import { TaxpayerIdentifierTaxpayerIdentifierTypeEnum } from '@xpanseinc/ui-backend-api';

  export let visible = false;
  export let existingItem = null;
  export let orgType = undefined;

  const { top, companyInformation, contactDetails, contactInformation, address } =
    addContactConfig(orgType);

  const dispatch = createEventDispatcher();
  let dirtyMap: { [k in keyof Applicant]?: boolean } = {};
  let dirtyMapAddress: { [k in keyof Address]?: boolean } = {};
  let hasOpened = false;
  let stagedApplicant: Applicant = getDefaultForm();
  let defaultForm = getDefaultForm();
  let applicants = [];
  let sameAsSubjectProperty = false;
  let appTypeSelected = false;
  let selectedApplicant;

  function getDefaultForm(): Applicant {
    return {
      contactType: PartyContactTypeEnum.Individual,
      partyRole: PartyPartyRoleEnum.Borrower,
      firstName: '',
      middleName: '',
      lastName: '',
      ssn: '',
      contactMethods: [getNewContactMethod()],
      address: {
        line1: '',
        line2: '',
        state: '',
        zipCode: '',
        county: '',
        city: '',
      },
      previousAddress: {
        line1: '',
        line2: '',
        state: '',
        zipCode: '',
        county: '',
        city: '',
      },
    };
  }

  async function onZipcodeChange(e, previous = false) {
    const { value } = e.target;

    let key = previous ? 'previousAddress' : 'address';

    stagedApplicant[key].zipCode = value;
    if (!value) {
      return;
    }

    try {
      const { city, county, state } = await $geoApi.getZipInfo({ zipCode: value });

      stagedApplicant[key].city = city;
      stagedApplicant[key].county = county;
      stagedApplicant[key].state = state;
    } catch (error) {
      console.error(error);
    }
  }

  function getNewContactMethod(): ContactMethod {
    return {
      id: uniqueId(),
      contactName: '',
      method: null,
      details: '',
      preferred: false,
    };
  }
  function removeContactMethod(i) {
    const [removedContactMethod] = stagedApplicant.contactMethods.splice(i, 1);
    stagedApplicant = stagedApplicant;

    // remove items from the dirtyMap for this contact method
    Object.keys(dirtyMap)
      .filter((k: string) => k.indexOf(`contactMethods[${removedContactMethod.id}]`) > -1)
      .forEach((k: string) => {
        delete dirtyMap[k];
      });

    if (stagedApplicant.contactMethods.length === 0) {
      stagedApplicant.contactMethods = [getNewContactMethod()];
    }
  }

  function deleteApplicant() {
    dispatch('delete', stagedApplicant);
    close();
  }

  function saveApplicant() {
    if (!validationResult.valid) {
      Object.keys(validationResult.errors)
        .filter((key) => key.indexOf('contactMethod') === -1)
        .forEach((key) => {
          dirtyMap[key] = true;
        });
      stagedApplicant.contactMethods.forEach((contactMethod) => {
        const key = `contactMethods[${contactMethod.id}]`;
        Object.keys(contactMethod).forEach((field) => {
          dirtyMap[`${key}.${field}`] = true;
        });
      });
      return;
    }
    if (existingItem) {
      dispatch('edited', { existingItem, newItem: stagedApplicant });
    } else {
      dispatch('save', stagedApplicant);
    }
    hasOpened = false;
  }

  function close() {
    dispatch('close');
    dirtyMap = {};
    hasOpened = false;
    appTypeSelected = false;
    selectedApplicant = false;
  }

  function validateMethods(contacts) {
    const validMap = contacts.map((contact) => {
      const { method, details } = contact;
      let valid;
      if (method === 'Email') {
        valid = emailIsValid(details);
      } else if (method === 'Phone' || method === 'Fax') {
        valid = phoneIsValid(details);
      } else {
        valid = method !== null && details.length > 0;
      }
      return valid;
    });
    return validMap;
  }

  $: if (!visible) {
    dirtyMap = {};
    stagedApplicant = getDefaultForm();
  }

  $: if (existingItem && !hasOpened) {
    stagedApplicant = { ...existingItem };
    hasOpened = true;
  }

  let validationResult: ValidationResult;

  $: validationResult = validate(applicantSchema, stagedApplicant);

  let contactMethodsValidationResult;
  $: contactMethodsValidationResult = validateMethods(stagedApplicant.contactMethods);

  const getApplicants = () => {
    if ($form.loanDetails.loanSource !== 'existing') {
      applicants = $form.loanDetails.newLoan.borrowers.map((applicant) => {
        let ssn = '';
        if (
          applicant.taxIdType ===
            TaxpayerIdentifierTaxpayerIdentifierTypeEnum.SocialSecurityNumber &&
          applicant.taxId
        ) {
          ssn = applicant.taxId + '';
        }
        return { ...defaultForm, ...applicant, ssn };
      });
    } else {
      applicants = $form.loanDetails.existingLoan.borrowers.map((applicant) => {
        if (applicant.fullName) {
          let splitName = applicant.fullName.split(' ');
          defaultForm.firstName = splitName[0];
          defaultForm.lastName = splitName[1];
        }
        return { ...defaultForm, ...applicant };
      });
    }
  };
  onMount(getApplicants);

  const handleAppTypeSelected = () => {
    stagedApplicant = selectedApplicant;
    appTypeSelected = true;
  };
</script>

<Dialog
  title="Add Applicant"
  titleTag="Required Fields *"
  on:close="{close}"
  size="{appTypeSelected ? 'jumbo' : ''}"
  bind:visible
>
  <div slot="body" class="{appTypeSelected && existingItem ? 'body' : ''}">
    {#if !appTypeSelected && !existingItem}
      <FormRow>
        <FormBlock width="600px">
          {#each applicants as applicant (applicant)}
            <div class="card-container">
              <div class="radio-card" class:selected="{applicant === selectedApplicant}">
                <div class="radio-container">
                  <div class="radio-button">
                    <RadioButton
                      name="card"
                      options="{[{ label: '', value: applicant }]}"
                      bind:value="{selectedApplicant}"
                    />
                  </div>
                </div>
                <div class="content">
                  <h4 class="header-m">
                    Use {applicant.firstName + ' ' + applicant.lastName}
                  </h4>
                </div>
              </div>
            </div>
          {/each}
          <div class="card-container">
            <div class="radio-card" class:selected="{getDefaultForm() === selectedApplicant}">
              <div class="radio-container">
                <div class="radio-button">
                  <RadioButton
                    name="card"
                    options="{[{ label: '', value: getDefaultForm() }]}"
                    bind:value="{selectedApplicant}"
                  />
                </div>
              </div>
              <div class="content">
                <h4 class="header-m">Add New Applicant</h4>
              </div>
            </div>
          </div>
        </FormBlock>
      </FormRow>
    {/if}

    {#if appTypeSelected}
      <FormRow>
        <FormBlock title="Applicant Details">
          <div class="row">
            <div style="width: 200px;">
              <svelte:component
                this="{getComponent(contactInformation.fields.firstName)}"
                {...getProps(contactInformation.fields.firstName)}
                value="{stagedApplicant.firstName}"
                invalid="{dirtyMap.firstName && validationResult.errors.firstName}"
                on:blur="{() => {
                  dirtyMap.firstName = true;
                }}"
                on:change="{(e) => {
                  stagedApplicant.firstName = e.target.value;
                }}"
              />
            </div>
            <div style="width: 160px;">
              <svelte:component
                this="{getComponent(contactInformation.fields.middleName)}"
                {...getProps(contactInformation.fields.middleName)}
                value="{stagedApplicant.middleName}"
                on:change="{(e) => {
                  stagedApplicant.middleName = e.target.value;
                }}"
              />
            </div>
            <div style="width: 240px;">
              <svelte:component
                this="{getComponent(contactInformation.fields.lastName)}"
                {...getProps(contactInformation.fields.lastName)}
                value="{stagedApplicant.lastName}"
                invalid="{dirtyMap.lastName && validationResult.errors.lastName}"
                on:blur="{() => {
                  dirtyMap.lastName = true;
                }}"
                on:change="{(e) => {
                  stagedApplicant.lastName = e.target.value;
                }}"
              />
            </div>

            <div style="width: 200px;">
              <svelte:component
                this="{getComponent(contactInformation.fields.ssn)}"
                {...getProps(contactInformation.fields.ssn)}
                invalid="{!ssnIsValid(stagedApplicant.ssn) &&
                  dirtyMap.ssn &&
                  validationResult.errors.ssn}"
                maskPackage="{imask}"
                maskOptions="{masks.ssn}"
                on:blur="{() => {
                  dirtyMap.ssn = true;
                }}"
                on:accept="{({ detail: { masked } }) => {
                  stagedApplicant.ssn = masked.unmaskedValue;
                }}"
              />
            </div>
          </div>
        </FormBlock>
      </FormRow>
      <FormRow>
        <FormBlock title="Contact Methods">
          {#each stagedApplicant.contactMethods as { id, contactName, details, method, preferred }, index (id)}
            <div class="row">
              {#if stagedApplicant.contactType && stagedApplicant.contactType !== PartyContactTypeEnum.Individual}
                <div style="width: 216px;">
                  <svelte:component
                    this="{getComponent(contactDetails.fields.contactName)}"
                    {...getProps(contactDetails.fields.contactName)}
                    value="{stagedApplicant.contactMethods[index].contactName}"
                    invalid="{dirtyMap[`contactMethods[${id}].contactName`] &&
                      validationResult.errors[`contactMethods[${index}].contactName`]}"
                    on:blur="{() => {
                      dirtyMap[`contactMethods[${id}].contactName`] = true;
                    }}"
                    on:change="{(e) => {
                      stagedApplicant.contactMethods[index].contactName = e.target.value;
                    }}"
                  />
                </div>
              {/if}
              <div style="width: 300px;">
                <svelte:component
                  this="{getComponent(contactDetails.fields.method)}"
                  {...getProps(contactDetails.fields.method)}
                  value="{stagedApplicant.contactMethods[index].method}"
                  invalid="{dirtyMap[`contactMethods[${id}].method`] &&
                    validationResult.errors[`contactMethods[${index}].method`]}"
                  on:blur="{() => {
                    dirtyMap[`contactMethods[${id}].method`] = true;
                  }}"
                  on:select="{(e) => {
                    stagedApplicant.contactMethods[index].method = e.detail.value;
                  }}"
                />
              </div>
              <div style="width: 328px;">
                <svelte:component
                  this="{getComponent(contactDetails.fields.details)}"
                  {...getProps(contactDetails.fields.details)}
                  value="{stagedApplicant.contactMethods[index].details}"
                  invalid="{(dirtyMap[`contactMethods[${id}].details`] &&
                    validationResult.errors[`contactMethods[${index}].details`]) ||
                    (dirtyMap[`contactMethods[${id}].details`] &&
                      !contactMethodsValidationResult[index])}"
                  on:blur="{() => {
                    dirtyMap[`contactMethods[${id}].details`] = true;
                  }}"
                  on:change="{(e) => {
                    stagedApplicant.contactMethods[index].details = e.target.value;
                  }}"
                />
              </div>
              <svelte:component
                this="{getComponent(contactDetails.fields.preferred)}"
                {...getProps(contactDetails.fields.preferred)}
                value="{stagedApplicant.contactMethods[index].preferred}"
                bind:checked="{stagedApplicant.contactMethods[index].preferred}"
              >
                {contactDetails.fields.preferred.label}
              </svelte:component>
              <div on:click="{() => removeContactMethod(index)}">
                <Trash2Icon size="24" />
              </div>
            </div>
          {/each}
        </FormBlock>
      </FormRow>

      <Button
        color="basic"
        label="Add Contact Method"
        name="addContactMethod"
        icon="{PlusIcon}"
        on:click="{() => {
          stagedApplicant.contactMethods = [
            ...stagedApplicant.contactMethods,
            getNewContactMethod(),
          ];
        }}"
      />
      <FormRow>
        <FormBlock title="Current Address" width="632px">
          {#if !sameAsSubjectProperty}
            <div>
              <div class="row">
                <svelte:component
                  this="{getComponent(address.fields.addressLine1)}"
                  {...getProps(address.fields.addressLine1)}
                  value="{stagedApplicant.address.line1}"
                  invalid="{dirtyMapAddress.line1 && validationResult.errors.line1}"
                  on:blur="{() => {
                    dirtyMapAddress.line1 = true;
                  }}"
                  on:change="{(e) => {
                    stagedApplicant.address.line1 = e.target.value;
                  }}"
                />
              </div>
              <div class="row">
                <svelte:component
                  this="{getComponent(address.fields.addressLine2)}"
                  {...getProps(address.fields.addressLine2)}
                  value="{stagedApplicant.address.line2}"
                  invalid="{dirtyMapAddress.line2 && validationResult.errors.line2}"
                  on:blur="{() => {
                    dirtyMapAddress.line2 = true;
                  }}"
                  on:change="{(e) => {
                    stagedApplicant.address.line2 = e.target.value;
                  }}"
                />
              </div>
              <div class="row">
                <svelte:component
                  this="{getComponent(address.fields.zipCode)}"
                  {...getProps(address.fields.zipCode)}
                  value="{stagedApplicant.address.zipCode}"
                  invalid="{dirtyMapAddress.zipCode && validationResult.errors.zipCode}"
                  on:blur="{() => {
                    dirtyMapAddress.zipCode = true;
                  }}"
                  on:change="{(e) => {
                    onZipcodeChange(e);
                  }}"
                />
                <svelte:component
                  this="{getComponent(address.fields.city)}"
                  {...getProps(address.fields.city)}
                  value="{stagedApplicant.address.city}"
                  invalid="{dirtyMapAddress.city && validationResult.errors.city}"
                  on:blur="{() => {
                    dirtyMapAddress.city = true;
                  }}"
                  on:change="{(e) => {
                    stagedApplicant.address.city = e.target.value;
                  }}"
                />
              </div>
              <div class="row">
                <svelte:component
                  this="{getComponent(address.fields.state)}"
                  {...getProps(address.fields.state)}
                  options="{$usStates}"
                  value="{stagedApplicant.address.state}"
                  invalid="{dirtyMapAddress.state && validationResult.errors.state}"
                  on:blur="{() => {
                    dirtyMapAddress.state = true;
                  }}"
                  on:change="{(e) => {
                    stagedApplicant.address.state = e.target.value;
                  }}"
                />

                <svelte:component
                  this="{getComponent(address.fields.county)}"
                  {...getProps(address.fields.county)}
                  value="{stagedApplicant.address.county}"
                  invalid="{dirtyMapAddress.county && validationResult.errors.county}"
                  on:blur="{() => {
                    dirtyMapAddress.county = true;
                  }}"
                  on:change="{(e) => {
                    stagedApplicant.address.county = e.target.value;
                  }}"
                />
              </div>
            </div>
          {/if}
        </FormBlock>
        <FormBlock title="Previous Address" width="632px">
          {#if !sameAsSubjectProperty}
            <div>
              <div class="row">
                <svelte:component
                  this="{getComponent(address.fields.addressLine1)}"
                  {...getProps(address.fields.addressLine1)}
                  value="{stagedApplicant.previousAddress.line1}"
                  invalid="{dirtyMapAddress.line1 && validationResult.errors.line1}"
                  on:blur="{() => {
                    dirtyMapAddress.line1 = true;
                  }}"
                  on:change="{(e) => {
                    stagedApplicant.previousAddress.line1 = e.target.value;
                  }}"
                />
              </div>
              <div class="row">
                <svelte:component
                  this="{getComponent(address.fields.addressLine2)}"
                  {...getProps(address.fields.addressLine2)}
                  value="{stagedApplicant.previousAddress.line2}"
                  invalid="{dirtyMapAddress.line2 && validationResult.errors.line2}"
                  on:blur="{() => {
                    dirtyMapAddress.line2 = true;
                  }}"
                  on:change="{(e) => {
                    stagedApplicant.previousAddress.line2 = e.target.value;
                  }}"
                />
              </div>
              <div class="row">
                <svelte:component
                  this="{getComponent(address.fields.zipCode)}"
                  {...getProps(address.fields.zipCode)}
                  value="{stagedApplicant.previousAddress.zipCode}"
                  invalid="{dirtyMapAddress.zipCode && validationResult.errors.zipCode}"
                  on:blur="{() => {
                    dirtyMapAddress.zipCode = true;
                  }}"
                  on:change="{(e) => {
                    onZipcodeChange(e, true);
                  }}"
                />
                <svelte:component
                  this="{getComponent(address.fields.city)}"
                  {...getProps(address.fields.city)}
                  value="{stagedApplicant.previousAddress.city}"
                  invalid="{dirtyMapAddress.city && validationResult.errors.city}"
                  on:blur="{() => {
                    dirtyMapAddress.city = true;
                  }}"
                  on:change="{(e) => {
                    stagedApplicant.previousAddress.city = e.target.value;
                  }}"
                />
              </div>
              <div class="row">
                <svelte:component
                  this="{getComponent(address.fields.state)}"
                  {...getProps(address.fields.state)}
                  options="{$usStates}"
                  value="{stagedApplicant.previousAddress.state}"
                  invalid="{dirtyMapAddress.state && validationResult.errors.state}"
                  on:blur="{() => {
                    dirtyMapAddress.state = true;
                  }}"
                  on:change="{(e) => {
                    stagedApplicant.previousAddress.state = e.target.value;
                  }}"
                />

                <svelte:component
                  this="{getComponent(address.fields.county)}"
                  {...getProps(address.fields.county)}
                  value="{stagedApplicant.previousAddress.county}"
                  invalid="{dirtyMapAddress.county && validationResult.errors.county}"
                  on:blur="{() => {
                    dirtyMapAddress.county = true;
                  }}"
                  on:change="{(e) => {
                    stagedApplicant.previousAddress.county = e.target.value;
                  }}"
                />
              </div>
            </div>
          {/if}
        </FormBlock>
      </FormRow>
    {/if}
  </div>

  <footer slot="footer" class="footer {appTypeSelected && existingItem ? 'footer' : ''}">
    <div class="{existingItem ? 'button-container' : ''}">
      {#if existingItem}
        <Button name="close-recording-dialog" label="Delete" on:click="{deleteApplicant}" />
      {/if}
      <div>
        <Button name="close-recording-dialog" label="Close" on:click="{close}" />
        {#if appTypeSelected}
          <Button
            color="primary"
            disabled="{!validationResult.valid || contactMethodsValidationResult.includes(false)}"
            name="close-recording-dialog"
            label="{'Save'}"
            on:click="{saveApplicant}"
          />
        {/if}
        {#if !appTypeSelected}
          <Button
            color="primary"
            disabled="{!selectedApplicant}"
            name="close-recording-dialog"
            label="Continue"
            on:click="{handleAppTypeSelected}"
          />
        {/if}
      </div>
    </div>
  </footer>
</Dialog>

<style>
  .body {
    margin: 0 50px;
  }

  .button-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .card-container {
    margin-top: 16px !important;
    margin-bottom: 0 !important;
  }

  .content {
    padding: 16px;
    background: var(--white);
    border-radius: 0 4px 4px 0;
    color: var(--gray10);
    flex: 1;
  }

  .row {
    display: flex;
    margin-bottom: 8px;
  }

  :global(.radio-button) {
    display: flex;
    width: fit-content;
    cursor: pointer;
    background-color: transparent !important;
  }

  .radio-card {
    border-radius: 4px;
    display: flex;
    box-shadow: 0 0 2px rgb(19 20 22 / 8%), 0 0 4px rgb(19 20 22 / 12%);
  }

  .radio-container {
    padding: 16px 16px;
    background-color: var(--gray1);
    border-radius: 4px 0 0 4px;
    transition: background-color 100ms ease-in-out;
  }

  .radio-card.selected .radio-container {
    background-color: var(--actionPrimaryDefault) !important;
  }

  .radio-card.selected .radio-container :global(.checkbox-dummy) {
    color: var(--white);
  }

  .footer :global(button.primary) {
    margin-left: 0.5rem;
  }
</style>
