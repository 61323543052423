<script lang="ts">
  import { startCase, camelCase } from 'lodash-es';
  import { HashIcon, UploadIcon } from 'svelte-feather-icons';
  import { imask, masks } from '../../../constants/inputMasking';
  import { OrgTypeEnum, OrgNamesEnum } from '../../../constants/place-order/typesAndEnums';
  import { setContext } from 'svelte';
  import ErrorMessage from '../ErrorMessage.svelte';
  import FormBlock from '../FormBlock.svelte';
  import FormRow from '../FormRow.svelte';
  import Divider from '../Divider.svelte';
  import {
    appraisalProviderAllocation,
    form,
    productDetailsProviderOptions,
    PlaceOrderProductTypeEnum,
    validationResults,
    selectedAppraisalProduct,
  } from '../../../stores/placeOrder';
  import { getComponent, getProps } from '../renderFromConfig';
  import { popToast } from '../../../utils/toasts';
  import {
    loanDetailsConfig,
    GovernmentRefinanceType,
  } from '../../../constants/place-order/loanDetailsConfig';
  import { Button } from '@xpanseinc/ui-components';
  import {
    LoanIdentifierLoanIdentifierTypeEnum,
    OrganizationOrgTypeEnum,
    PartyContactTypeEnum,
    PartyMaritalStatusEnum,
    TermsOfLoanMortgageTypeEnum,
    TermsOfLoanLoanPurposeTypeEnum,
    TermsOfLoanLienPriorityTypeEnum,
  } from '@xpanseinc/ui-backend-api';
  import { getNewLoanSchema } from '../../../schemas/place-order/newLoan';
  import type { NewLoan } from '../../../schemas/place-order/newLoan';
  import {
    subjectPropertySchema,
    getBorrowerSchema,
    getContactSchema,
  } from '../../../schemas/place-order';
  import { validate } from '../../../schemas/validate';
  import AddSubjectProperty from '../dialogs/AddSubjectProperty.svelte';
  import AddBorrower from '../dialogs/AddBorrower.svelte';
  import AddContact from '../dialogs/AddContact.svelte';
  import UploadXML from '../dialogs/UploadXML.svelte';
  import { attachmentApi, xmlToJsonApi } from '../../../stores/api';
  import { profile, userOrgType } from '../../../stores/profile';
  import {
    mapDropdownOptions,
    mapDropdownOptionsFromEnum,
  } from '../../../utils/mapDropdownOptions';
  import { getNewSubjectProperty } from '../../../schemas/place-order/subjectProperty';
  import { getNewBorrower } from '../../../schemas/place-order/borrower';

  const selectedProducts = $form.order.products;
  const {
    borrowers,
    contacts,
    financialInformation,
    loanInformation,
    miscellaneous,
    subjectProperty,
  } = loanDetailsConfig($userOrgType, $form.order.lenderName, selectedProducts);
  const dirtyMap: { [k in keyof NewLoan]?: boolean } = {};
  let loanUploadFiles = [];
  let uploading = false;
  let xmlUploaded = false;
  let lastUploadedFile = null;

  const getFullName = (item) => `${item.firstName} ${item.middleName} ${item.lastName}`;
  const getContactHeader = (item) => {
    let header;
    if (item.contactType === PartyContactTypeEnum.Individual) {
      header = getFullName(item);
    } else {
      header = `${item.companyName}`;
    }
    return header;
  };

  // Set context for each module, passing in specific template literals to configure text on their subsequent info cards
  setContext('property', {
    component: AddSubjectProperty,
    getHeader: (item) => {
      const { addressLine1, addressLine2, city, state, zipCode } = item;
      return `${addressLine1} ${addressLine2}, ${city} ${state}, ${zipCode}`;
    },
    getHomeText: (item) => {
      const { buildingPermissionUsageType, constructionMethod } = item;
      return `${startCase(camelCase(buildingPermissionUsageType))} ${
        constructionMethod ? ', ' + startCase(camelCase(constructionMethod)) : ''
      }`;
    },
    isValid: (item) => validate(subjectPropertySchema, item).valid,
  });

  setContext('borrower', {
    component: AddBorrower,
    getHeader: getContactHeader,
    getHomeText: () => null,
    isValid: (item) => validate(getBorrowerSchema(item.contactType), item).valid,
  });

  setContext('contact', {
    component: AddContact,
    getHeader: getContactHeader,
    getHomeText: () => null,
    isValid: (item) => validate(getContactSchema(item.contactType), item).valid,
  });

  $: newLoanSchema = getNewLoanSchema(selectedProducts, $form.loanDetails.newLoan, $userOrgType);
  $: {
    const result = validate(newLoanSchema, $form.loanDetails.newLoan);
    const valid =
      result.valid &&
      $form.loanDetails.newLoan?.borrowers?.every(
        (item) => validate(getBorrowerSchema(item.contactType), item).valid,
      ) &&
      $form.loanDetails.newLoan?.subjectProperty?.every(
        (item) => validate(subjectPropertySchema, item).valid,
      );

    $validationResults.loanDetails = { ...result, valid };
  }

  // If the user has previously selected FNMA or FLHMC as mortgage type and moved to the Product Details screen and received a provider via the Allocation endpoint,
  // the provider options must be reset of the mortgage type is retroactively changed
  $: if (
    ($form.loanDetails.newLoan.mortgageLoanType !== TermsOfLoanMortgageTypeEnum.Fnma ||
      $form.loanDetails.newLoan.mortgageLoanType !== TermsOfLoanMortgageTypeEnum.Fhlmc) &&
    $selectedAppraisalProduct !== '1004 D Disaster' &&
    $selectedAppraisalProduct !== '1004D_05 Cert of Completion-Final Insp' &&
    $selectedAppraisalProduct !== '1004D_05 Appraisal Update'
  ) {
    $productDetailsProviderOptions[PlaceOrderProductTypeEnum.Appraisal] = [];
    $form.productDetails[PlaceOrderProductTypeEnum.Appraisal].provider = null;
  }

  // If the Allocation endpoint was previously called but the user has changed their mortgage type and provider,
  // the original Allocation provider must be used if the user wants to go back to using the FNMA of FHLMC mortgage type
  $: if (
    ($form.loanDetails.newLoan.mortgageLoanType === TermsOfLoanMortgageTypeEnum.Fnma ||
      $form.loanDetails.newLoan.mortgageLoanType === TermsOfLoanMortgageTypeEnum.Fhlmc) &&
    $appraisalProviderAllocation &&
    $selectedAppraisalProduct !== '1004 D Disaster' &&
    $selectedAppraisalProduct !== '1004D_05 Cert of Completion-Final Insp' &&
    $selectedAppraisalProduct !== '1004D_05 Appraisal Update'
  ) {
    $productDetailsProviderOptions[PlaceOrderProductTypeEnum.Appraisal] = [
      $appraisalProviderAllocation,
    ];
    $form.productDetails[PlaceOrderProductTypeEnum.Appraisal].provider = parseInt(
      $appraisalProviderAllocation?.id,
    );
  }

  $: xmlUploaded = $form.loanDetails.newLoan?.xml?.uploaded;
  $: loanNumberDisasbled = xmlUploaded && $form.loanDetails.newLoan?.xml?.hadValidLoanNumber;
  $: loandIdTypeDisabled = xmlUploaded && $form.loanDetails.newLoan?.xml?.hadValidLoanIdentityType;
  const isSoloIncomeOrder =
    selectedProducts.length === 1 && selectedProducts[0] === PlaceOrderProductTypeEnum.Income;
  const isSoloAppraisalOrder =
    selectedProducts.length === 1 && selectedProducts[0] === PlaceOrderProductTypeEnum.Appraisal;

  const govtRefiOptions = () => {
    let options = mapDropdownOptionsFromEnum(GovernmentRefinanceType);

    const filteredOptions = [
      'Streamline With Appraisal',
      'Cash Out VA',
      'Streamline Without Appraisal',
    ];
    if (isSoloAppraisalOrder) {
      options = options.filter((option) => !filteredOptions.includes(option.label));
    }
    return options;
  };

  let uploadModalOpen = false;
  const fileTypeOptions = mapDropdownOptions(['.XML']);

  function toggleUploadModal() {
    uploadModalOpen = !uploadModalOpen;
  }

  function getMortgageTypeFromDescription(description, defaultType) {
    // There are 3 cases where the returned mortgageType needs to be overwritten
    if (description.includes(TermsOfLoanMortgageTypeEnum.Fnma)) {
      return TermsOfLoanMortgageTypeEnum.Fnma;
    }
    if (description.includes(TermsOfLoanMortgageTypeEnum.Fhlmc)) {
      return TermsOfLoanMortgageTypeEnum.Fhlmc;
    }
    if (description.includes(TermsOfLoanMortgageTypeEnum.Usda)) {
      return TermsOfLoanMortgageTypeEnum.Usda;
    }
    // If the description does not match the above criteria, use the original returned mortgageType
    return defaultType;
  }

  let uploadObjectId = '';
  async function populateFieldsFromXml() {
    const convertedJson: any = await $xmlToJsonApi.xmlS3RefToJson({
      objectId: uploadObjectId,
      jsonSpecResourceName: 'WholeSaleAppraisalLoanImport',
    });

    $form.loanDetails.newLoan.productDescription =
      convertedJson.loan?.termsOfLoan?.productDescription;
    $form.loanDetails.newLoan.loanNumber = convertedJson.loan?.loanIdentifiers[0]?.loanIdentifier;
    $form.loanDetails.newLoan.loanPurpose =
      TermsOfLoanLoanPurposeTypeEnum[convertedJson.loan?.termsOfLoan?.loanPurposeType];
    $form.loanDetails.newLoan.loanIdType =
      LoanIdentifierLoanIdentifierTypeEnum[
        convertedJson.loan?.loanIdentifiers[0]?.loanIdentifierType
      ];
    $form.loanDetails.newLoan.mortgageLoanType = getMortgageTypeFromDescription(
      convertedJson.loan?.termsOfLoan?.productDescription,
      convertedJson.loan?.termsOfLoan?.mortgageType,
    );
    $form.loanDetails.newLoan.loanAmount = convertedJson.loan?.termsOfLoan?.baseLoanAmount || '';
    $form.loanDetails.newLoan.loanAmountMasked = $form.loanDetails.newLoan.loanAmount
      ? parseInt(convertedJson.loan?.termsOfLoan?.baseLoanAmount).toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
        })
      : '';

    let importedProperty = getNewSubjectProperty();
    importedProperty.addressLine1 = convertedJson.subjectProperty?.address?.line1 || '';
    importedProperty.zipCode = convertedJson.subjectProperty?.address?.zip || '';
    importedProperty.city = convertedJson.subjectProperty?.address?.city || '';
    importedProperty.state = convertedJson.subjectProperty?.address?.state || '';
    importedProperty.county = convertedJson.subjectProperty?.address?.county || '';
    $form.loanDetails.newLoan.subjectProperty = [importedProperty];
    $form.loanDetails.newLoan.lienPriority =
      TermsOfLoanLienPriorityTypeEnum[convertedJson.loan?.termsOfLoan?.lienPriorityType] || '';
    let jsonBorrowers = convertedJson.borrowers;
    let newBorrowers = [];
    jsonBorrowers.forEach((borrower) => {
      let newBorrower = getNewBorrower();
      newBorrower.bankruptcyLast7 = borrower.declarationDetail?.bankruptcyIndicator || false;
      newBorrower.foreclosureLast7 =
        borrower.declarationDetail?.priorPropertyForeclosureCompletedIndicator || false;
      newBorrower.partyToLawsuit = borrower.declarationDetail?.partyToLawsuitIndicator || false;
      newBorrower.firstName = borrower.firstName || '';
      newBorrower.lastName = borrower.lastName || '';
      newBorrower.middleName = borrower.middleName || '';
      newBorrower.maritalStatus = PartyMaritalStatusEnum[borrower?.maritalStatus] || null;
      newBorrowers.push(newBorrower);
    });
    $form.loanDetails.newLoan.borrowers = newBorrowers;
    for (const k in newLoanSchema.fields) {
      dirtyMap[k] = true;
    }
    const validationResults = validate(newLoanSchema, $form.loanDetails.newLoan);
    $form.loanDetails.newLoan.xml = {
      hadValidLoanNumber: !validationResults.errors.loanNumber,
      hadValidLoanIdentityType: !validationResults.errors.loanIdType,
      uploaded: true,
    };
  }

  async function uploadToS3(url, file) {
    return fetch(url, {
      method: 'PUT',
      body: file,
    })
      .then(async () => {
        popToast({
          title: 'The file uploaded successfully. The loan details were added to this order',
          type: 'success',
          duration: 6000,
        });
        uploading = false;

        lastUploadedFile = loanUploadFiles[0].name;
        loanUploadFiles = [];

        toggleUploadModal();
      })
      .catch((error) => {
        console.error('upload:', error);
        popToast({
          title: 'Upload failed. Please Check the file format and try again',
          duration: 0,
        });
      })
      .finally(async () => {
        await populateFieldsFromXml();
      });
  }

  async function uploadLoanFile() {
    uploading = true;

    const uploadUrls = await $attachmentApi.getUploadUrl({
      count: 1,
    });

    uploadObjectId = uploadUrls[0].objectId;
    const signedUploadUrl = uploadUrls[0].url;
    const file = loanUploadFiles[0];

    return await uploadToS3(signedUploadUrl, file);
  }

  function setLoanUploadFiles({ detail: fileList }) {
    loanUploadFiles = fileList;
  }
</script>

<FormRow>
  <FormBlock width="800px">
    <div class="loan-details-header">
      <h2 class="header-xl header-xl--bold">Loan Details</h2>
      {#if ($profile.organization?.orgType === OrganizationOrgTypeEnum.Broker || $profile.organization?.name === OrgNamesEnum.FREEDOM_WHOLESALE) && !isSoloIncomeOrder}
        <Button
          color="basic"
          label="Upload a Lender XML File"
          icon="{UploadIcon}"
          iconLoading="{uploading}"
          name="uploadFile"
          on:click="{toggleUploadModal}"
        />
      {/if}
    </div>
    <svelte:component
      this="{getComponent(loanInformation.fields.loanNumber)}"
      {...getProps(loanInformation.fields.loanNumber)}
      label="{undefined}"
      value="{$form.loanDetails.newLoan.loanNumber || ''}"
      disabled="{loanNumberDisasbled}"
      invalid="{dirtyMap.loanNumber && $validationResults.loanDetails.errors.loanNumber}"
      placeholder="Enter Loan Number *"
      prependedIcon="{HashIcon}"
      large
      on:blur="{() => {
        dirtyMap.loanNumber = true;
      }}"
      on:change="{(e) => {
        $form.loanDetails.newLoan.loanNumber = e.target.value;
      }}"
    />
  </FormBlock>
</FormRow>

{#if !isSoloIncomeOrder}
  <FormRow>
    <FormBlock title="{loanInformation.title}" width="100%" showRequiredTag>
      <div class="row">
        <div class="field-full">
          <svelte:component
            this="{getComponent(loanInformation.fields.loanPurpose)}"
            {...getProps(loanInformation.fields.loanPurpose)}
            value="{$form.loanDetails.newLoan.loanPurpose}"
            invalid="{dirtyMap.loanPurpose && $validationResults.loanDetails.errors.loanPurpose}"
            on:blur="{() => {
              dirtyMap.loanPurpose = true;
            }}"
            on:select="{(e) => {
              $form.loanDetails.newLoan.loanPurpose = e.detail.value;
            }}"
          />
        </div>

        <div class="field-full">
          <svelte:component
            this="{getComponent(loanInformation.fields.loanIdType)}"
            {...getProps(loanInformation.fields.loanIdType)}
            value="{$form.loanDetails.newLoan.loanIdType}"
            disabled="{loandIdTypeDisabled}"
            invalid="{dirtyMap.loanIdType && $validationResults.loanDetails.errors.loanIdType}"
            on:blur="{() => {
              dirtyMap.loanIdType = true;
            }}"
            on:select="{(e) => {
              $form.loanDetails.newLoan.loanIdType = e.detail.value;
            }}"
          />
        </div>
      </div>

      <div class="row">
        {#if !($userOrgType === OrgTypeEnum.BROKER) && !($userOrgType === OrgTypeEnum.WHOLESALE)}
          <div class="field-full">
            <svelte:component
              this="{getComponent(loanInformation.fields.govtRefinanceType)}"
              {...getProps(loanInformation.fields.govtRefinanceType)}
              value="{$form.loanDetails.newLoan.govtRefinanceType}"
              invalid="{dirtyMap.govtRefinanceType &&
                $validationResults.loanDetails.errors.govtRefinanceType}"
              options="{govtRefiOptions()}"
              on:blur="{() => {
                dirtyMap.govtRefinanceType = true;
              }}"
              on:select="{(e) => {
                $form.loanDetails.newLoan.govtRefinanceType = e.detail.value;
              }}"
            />
          </div>
        {/if}
        <div class="field-full">
          <svelte:component
            this="{getComponent(loanInformation.fields.mortgageLoanType)}"
            {...getProps(loanInformation.fields.mortgageLoanType)}
            value="{$form.loanDetails.newLoan.mortgageLoanType}"
            invalid="{dirtyMap.mortgageLoanType &&
              $validationResults.loanDetails.errors.mortgageLoanType}"
            disabled="{xmlUploaded && $form.loanDetails.newLoan.productDescription}"
            on:blur="{() => {
              dirtyMap.mortgageLoanType = true;
            }}"
            on:select="{(e) => {
              $form.loanDetails.newLoan.mortgageLoanType = e.detail.value;
            }}"
          />
        </div>

        <div class="field-full">
          <svelte:component
            this="{getComponent(loanInformation.fields.fhaCaseNumber)}"
            {...getProps(loanInformation.fields.fhaCaseNumber)}
            label="{`${loanInformation.fields.fhaCaseNumber.label}
            ${
              $form.loanDetails.newLoan.mortgageLoanType === TermsOfLoanMortgageTypeEnum.Fha
                ? '*'
                : ''
            }`}"
            invalid="{dirtyMap.fhaCaseNumber &&
              $validationResults.loanDetails.errors.fhaCaseNumber}"
            disabled="{$form.loanDetails.newLoan.mortgageLoanType !==
              TermsOfLoanMortgageTypeEnum.Fha}"
            maskPackage="{imask}"
            maskOptions="{$userOrgType !== OrgTypeEnum.WHOLESALE &&
            $userOrgType !== OrgTypeEnum.BROKER
              ? masks.fha
              : masks.anystring}"
            on:blur="{() => {
              dirtyMap.fhaCaseNumber = true;
            }}"
            on:accept="{({ detail: { masked } }) => {
              $form.loanDetails.newLoan.fhaCaseNumber = masked.unmaskedValue;
            }}"
          />
        </div>
      </div>
    </FormBlock>
  </FormRow>

  <Divider />
{/if}

{#if !isSoloIncomeOrder}
  <FormRow>
    <FormBlock title="{financialInformation.title}" width="550px">
      <div class="row">
        <div class="field-full">
          <svelte:component
            this="{getComponent(financialInformation.fields.loanAmount)}"
            {...getProps(financialInformation.fields.loanAmount)}
            id="loan-amount-input"
            invalid="{dirtyMap.loanAmount && $validationResults.loanDetails.errors.loanAmount}"
            maskPackage="{imask}"
            maskOptions="{masks.currency}"
            value="{$form.loanDetails.newLoan.loanAmountMasked}"
            on:blur="{() => {
              dirtyMap.loanAmount = true;
            }}"
            on:accept="{({ detail: { masked } }) => {
              $form.loanDetails.newLoan.loanAmount = masked.unmaskedValue;
              $form.loanDetails.newLoan.loanAmountMasked = masked.typedValue;
            }}"
          />
        </div>

        <div class="field-full">
          <svelte:component
            this="{getComponent(financialInformation.fields.salesContractAmount)}"
            {...getProps(financialInformation.fields.salesContractAmount)}
            label="{`${financialInformation.fields.salesContractAmount.label}
            ${
              $form.loanDetails.newLoan.loanPurpose === TermsOfLoanLoanPurposeTypeEnum.Purchase
                ? '*'
                : ''
            }`}"
            invalid="{dirtyMap.salesContractAmount &&
              $validationResults.loanDetails.errors.salesContractAmount}"
            on:blur="{() => {
              dirtyMap.salesContractAmount = true;
            }}"
            maskPackage="{imask}"
            maskOptions="{masks.currency}"
            on:blur="{() => {
              dirtyMap.salesContractAmount = true;
            }}"
            on:accept="{({ detail: { masked } }) => {
              $form.loanDetails.newLoan.salesContractAmount = masked.rawInputValue;
            }}"
          />
        </div>
      </div>

      <div class="row">
        <div class="field-full">
          <svelte:component
            this="{getComponent(financialInformation.fields.cashOutType)}"
            {...getProps(financialInformation.fields.cashOutType)}
            value="{$form.loanDetails.newLoan.cashOutType}"
            on:select="{(e) => {
              $form.loanDetails.newLoan.cashOutType = e.detail.value;
            }}"
          />
        </div>

        <div class="field-full">
          <svelte:component
            this="{getComponent(financialInformation.fields.cashOutAmount)}"
            {...getProps(financialInformation.fields.cashOutAmount)}
            invalid="{dirtyMap.cashOutAmount &&
              $validationResults.loanDetails.errors.cashOutAmount}"
            maskPackage="{imask}"
            maskOptions="{masks.currency}"
            on:blur="{() => {
              dirtyMap.cashOutAmount = true;
            }}"
            on:accept="{({ detail: { masked } }) => {
              $form.loanDetails.newLoan.cashOutAmount = masked.rawInputValue;
            }}"
          />
        </div>
      </div>

      <div class="row">
        <div class="field-full">
          <svelte:component
            this="{getComponent(financialInformation.fields.lienPriority)}"
            {...getProps(financialInformation.fields.lienPriority)}
            value="{$form.loanDetails.newLoan.lienPriority}"
            on:select="{(e) => {
              $form.loanDetails.newLoan.lienPriority = e.detail.value;
            }}"
          />
        </div>

        <div class="field-half">
          <svelte:component
            this="{getComponent(financialInformation.fields.cltv)}"
            {...getProps(financialInformation.fields.cltv)}
            value="{$form.loanDetails.newLoan.cltv || ''}"
            invalid="{dirtyMap.cltv && $validationResults.loanDetails.errors.cltv}"
            on:blur="{() => {
              dirtyMap.cltv = true;
            }}"
            on:change="{(e) => {
              $form.loanDetails.newLoan.cltv = e.target.value;
            }}"
          />
        </div>

        <div class="field-half">
          <svelte:component
            this="{getComponent(financialInformation.fields.ltv)}"
            {...getProps(financialInformation.fields.ltv)}
            value="{$form.loanDetails.newLoan.ltv || ''}"
            invalid="{dirtyMap.ltv && $validationResults.loanDetails.errors.ltv}"
            on:blur="{() => {
              dirtyMap.ltv = true;
            }}"
            on:change="{(e) => {
              $form.loanDetails.newLoan.ltv = e.target.value;
            }}"
          />
        </div>
      </div>
    </FormBlock>

    <FormBlock>
      <div class="checkboxes">
        <div class="checkbox">
          <svelte:component
            this="{getComponent(financialInformation.fields.piggyback)}"
            {...getProps(financialInformation.fields.piggyback)}
            bind:checked="{$form.loanDetails.newLoan.piggyback}"
          >
            {financialInformation.fields.piggyback.label}
          </svelte:component>
        </div>
        <div class="checkbox">
          <svelte:component
            this="{getComponent(financialInformation.fields.govtBondFinance)}"
            {...getProps(financialInformation.fields.govtBondFinance)}
            bind:checked="{$form.loanDetails.newLoan.govtBondFinance}"
          >
            {financialInformation.fields.govtBondFinance.label}
          </svelte:component>
        </div>
        <div class="checkbox">
          <svelte:component
            this="{getComponent(financialInformation.fields.employeeLoan)}"
            {...getProps(financialInformation.fields.employeeLoan)}
            bind:checked="{$form.loanDetails.newLoan.employeeLoan}"
          >
            {financialInformation.fields.employeeLoan.label}
          </svelte:component>
        </div>
        <div class="checkbox">
          <svelte:component
            this="{getComponent(financialInformation.fields.serviceExpiditeIndicator)}"
            {...getProps(financialInformation.fields.serviceExpiditeIndicator)}
            bind:checked="{$form.loanDetails.newLoan.serviceExpiditeIndicator}"
          >
            {financialInformation.fields.serviceExpiditeIndicator.label}
          </svelte:component>
        </div>
      </div>
    </FormBlock>
  </FormRow>

  <Divider />
{/if}

{#if !isSoloIncomeOrder}
  <FormRow>
    <FormBlock title="{subjectProperty.title} *" width="100%">
      <svelte:component
        this="{getComponent(subjectProperty.fields.subjectProperty)}"
        {...getProps(subjectProperty.fields.subjectProperty)}
        itemList="{$form.loanDetails.newLoan.subjectProperty}"
        contextKey="property"
        placeholderButtonLabel="{subjectProperty.fields.subjectProperty.label}"
        maxItems="1"
        orgType="{$userOrgType}"
        showErrorMsg="{xmlUploaded}"
        on:itemChange="{(e) => {
          $form.loanDetails.newLoan.subjectProperty = e.detail;
          $validationResults.loanDetails = validate(newLoanSchema, $form.loanDetails.newLoan);
        }}"
      />
      <div slot="error">
        {#if xmlUploaded && $validationResults.loanDetails.errors.subjectProperty}
          {#each $validationResults.loanDetails.errors.subjectProperty as err}
            <ErrorMessage message="{err.message}" />
          {/each}
        {/if}
      </div>
    </FormBlock>
  </FormRow>

  <Divider />
{/if}

<FormRow>
  <FormBlock title="{borrowers.title} *" width="100%">
    <svelte:component
      this="{getComponent(borrowers.fields.borrowerInformation)}"
      {...getProps(borrowers.fields.borrowerInformation)}
      itemList="{$form.loanDetails.newLoan.borrowers}"
      contextKey="borrower"
      placeholderButtonLabel="{borrowers.fields.borrowerInformation.label}"
      maxItems="5"
      orgType="{$userOrgType}"
      showErrorMsg="{xmlUploaded}"
      on:itemChange="{(e) => {
        $form.loanDetails.newLoan.borrowers = e.detail;
        $validationResults.loanDetails = validate(newLoanSchema, $form.loanDetails.newLoan);
      }}"
    />
    <div slot="error">
      {#if xmlUploaded && $validationResults.loanDetails.errors.borrowers}
        {#each $validationResults.loanDetails.errors.borrowers as err}
          <ErrorMessage message="{err.message}" />
        {/each}
      {/if}
    </div>
  </FormBlock>
</FormRow>

{#if !isSoloIncomeOrder}
  <Divider />
{/if}

{#if !isSoloIncomeOrder}
  <FormRow>
    <FormBlock title="{contacts.title}" width="100%">
      <svelte:component
        this="{getComponent(contacts.fields.contacts)}"
        {...getProps(contacts.fields.contacts)}
        itemList="{$form.loanDetails.newLoan.contacts}"
        contextKey="contact"
        placeholderButtonLabel="{contacts.fields.contacts.label}"
        orgType="{$userOrgType}"
        on:itemChange="{(e) => {
          $form.loanDetails.newLoan.contacts = e.detail;
        }}"
      />
    </FormBlock>
  </FormRow>
{/if}

<UploadXML
  fileTypeOptions="{fileTypeOptions}"
  uploadModalOpen="{uploadModalOpen}"
  lastUploadedFile="{lastUploadedFile}"
  on:setLoanUploadFiles="{setLoanUploadFiles}"
  on:close="{toggleUploadModal}"
  on:uploadLoanFile="{uploadLoanFile}"
/>

<style>
  .loan-details-header {
    display: flex;
    margin-bottom: 0 !important;
    gap: 12px;
  }

  .loan-details-header :global(.btn) {
    margin-top: -4px;
  }

  .field-full {
    width: 240px;
  }

  .field-half {
    width: 104px;
  }

  .checkboxes {
    display: flex;
    flex-direction: column;
    padding-top: 34px;
  }

  .checkbox {
    margin-bottom: 20px;
  }
</style>
