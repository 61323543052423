<script>
  import { Tooltip } from '@xpanseinc/ui-components';
  import { format } from 'date-fns';
  /*
    Generate a Vertical Bar Chart
    Sample Data
    [
      {percentage:90, name:'Title', count: 10000},
      {percentage:10, name:'Closing', count: 1000},
    ]
  */
  export let data = [];
  export let barColor = '#FCD15A';
  export let passColor = '#79CDF6';

  function toolTipText(element) {
    let date = '';
    switch (element.interval) {
      case 'date':
        date = format(element.date, 'MM/dd/yy');
        break;
      case 'week':
        date = `WEEK STARTING ${format(element.date, 'MM/dd/yy')}`;
        break;
      case 'month':
        date = format(element.date, 'MMM yyyy');
        break;
      case 'year':
        date = format(element.date, 'yyyy');
        break;
    }
    return `${date.toLocaleUpperCase()} - Target: ${element.pass} Met, ${
      element.count - element.pass
    } Missed`;
  }

  $: {
    const max = data.reduce((actualMax, current) => Math.max(actualMax, current.count), 0);
    data.forEach((element) => {
      /* eslint-disable no-param-reassign */
      element.passPercent = element.pass / max;
      element.percentage = (element.count - element.pass) / max;
      /* eslint-enable no-param-reassign */
    });
  }
</script>

<div class="chart">
  {#each data as element}
    <Tooltip text="{toolTipText(element)}" position="bottom">
      <div class="chart-col">
        <div class="rounded" style="--barHeight: {element.percentage + element.passPercent};">
          {#if element.percentage}
            <span
              class="percentage"
              class:full="{element.percentage === 1}"
              style="
                --height:
                  {element.passPercent
                ? element.percentage / element.percentage + element.passPercent
                : 1};
                --color: {barColor};"
            >
            </span>
          {/if}
          {#if element.passPercent}
            <span
              class="pass-percent"
              style="
                --height:
                  {element.percentage
                ? element.passPercent / element.percentage + element.passPercent
                : 1};
                --color: {passColor};"
            >
            </span>
          {/if}
        </div>
      </div>
    </Tooltip>
  {/each}
</div>

<style>
  .chart {
    align-items: flex-end;
    display: flex;
    flex-wrap: nowrap;
    height: 100%;
    justify-content: space-evenly;
    width: 408px;
    gap: 5px;
  }

  .chart :global(.target) {
    flex: 0 1 50px;
    height: 100%;
    border-radius: 4px 4px 0 0;
    border-bottom-width: 0;
  }

  .chart :global(.chart-col) {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: flex-end;
  }

  .percentage,
  .pass-percent {
    background: var(--color);
    display: block;
    flex-grow: var(--height);
    min-height: 1px;
  }

  .rounded {
    flex-grow: var(--barHeight);
    border-radius: 4px 4px 0 0;
    margin: 0;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  .percentage:hover::after,
  .percentage:focus::after {
    background-color: #aaa;
  }

  .pass-percent:hover::after,
  .pass-percent:focus::after {
    background-color: #aaa;
  }

  .full {
    flex-grow: 1;
  }
</style>
