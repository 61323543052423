import { array, object, string } from 'yup';
import { OrgNamesEnum } from '../../constants/place-order/typesAndEnums';

export type Title = {
  workflowVersion?: string;
  provider?: string;
  product?: string;
  orderInstructions?: string;
  attachments?: [];
};

export const titleSchema = {
  workflowVersion: string().required('Workflow version is required'),
  provider: string().required('Provider is required'),
  product: string().required('Product is required'),
  orderInstructions: string(),
  attachments: array(),
};

export const getTitleSchema = (lenderName: string) => {
  if (lenderName === OrgNamesEnum.FREEDOM_SERVICING) {
    return object().shape({ ...titleSchema, workflowVersion: string() });
  }
  return object().shape(titleSchema);
};
