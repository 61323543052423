<script>
  import { Button, Dialog } from '@xpanseinc/ui-components';
  import { createEventDispatcher } from 'svelte';

  export let user;
  export let visible = false;

  const dispatch = createEventDispatcher();

  function close() {
    visible = false;
  }

  function confirm() {
    dispatch('confirm');
    close();
  }
</script>

<Dialog size="small" bind:visible on:close="{close}" title="Delete User">
  <div slot="body">
    Are you sure you want to permanently delete {#if user?.email}<strong>{user.email}</strong
      >{:else}this user{/if}?
  </div>
  <footer slot="footer">
    <Button label="Cancel" name="cancel" on:click="{close}" />
    <Button label="Delete User" name="delete-user" color="warning" on:click="{confirm}" />
  </footer>
</Dialog>
