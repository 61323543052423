<script>
</script>

<div class="form-row">
  <slot />
</div>

<style>
  .form-row {
    display: flex;
    position: relative;
    gap: 40px;
    margin: 0 0 24px;
  }
</style>
