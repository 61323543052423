import {
  AddressUnitDesignatorTypeEnum,
  BuildingPermitBuildingPermitUsageStandardTypeEnum,
  PropertyDetailOccupancyTypeEnum,
  PropertyDetailEstateTypeEnum,
  PropertyDetailConstructionMethodTypeEnum,
  PlattedLandPlatTypeEnum,
  ParcelIdentificationParcelIdentificationTypeEnum,
} from '@xpanseinc/ui-backend-api';
import { InputTypeEnum, OrgTypeEnum } from './typesAndEnums';
import type { Page } from './typesAndEnums';
import { mapDropdownOptionsFromEnum } from '../../utils/mapDropdownOptions';
import { PlaceOrderFormState, PlaceOrderProductTypeEnum } from '../../stores/placeOrder';

export enum ResidenceType {
  CURRENT = 'Current',
  PRIOR = 'Prior',
}

const baseSubjectPropertyConfig: Page = {
  subjectProperty: {
    title: 'Subject Property',
    fields: {
      typeOfResidence: {
        label: 'Type of Residence',
        required: false,
        type: InputTypeEnum.Dropdown,
        options: mapDropdownOptionsFromEnum(ResidenceType),
        dependency: null,
      },
      addressLine1: {
        label: 'Address Line 1',
        required: true,
        type: InputTypeEnum.TextString,
        options: null,
        dependency: null,
      },
      addressLine2: {
        label: 'Address Line 2',
        required: false,
        type: InputTypeEnum.TextString,
        options: null,
        dependency: null,
      },
      city: {
        label: 'City',
        required: true,
        type: InputTypeEnum.TextString,
        options: null,
        dependency: null,
      },
      state: {
        label: 'State',
        required: true,
        type: InputTypeEnum.Dropdown,
        options: [],
        dependency: null,
      },
      zipCode: {
        label: 'Zip Code',
        required: true,
        type: InputTypeEnum.TextString,
        options: null,
        dependency: null,
      },
      county: {
        label: 'County',
        required: true,
        type: InputTypeEnum.TextString,
        options: null,
        dependency: null,
      },
    },
  },
  propertyInformation: {
    title: 'Property Information',
    fields: {
      buildingPermissionUsageType: {
        label: 'Building Permit Usage Type',
        required: true,
        type: InputTypeEnum.Dropdown,
        options: mapDropdownOptionsFromEnum(
          BuildingPermitBuildingPermitUsageStandardTypeEnum,
          true,
          'key',
        )
          .map((x) => {
            if (x.value === BuildingPermitBuildingPermitUsageStandardTypeEnum.MobileHome) {
              return { label: 'Manufactured Housing Unit/Mobile Home', value: x.value };
            }
            return x;
          })
          .filter((x) => x.value !== BuildingPermitBuildingPermitUsageStandardTypeEnum.Other),
        dependency: null,
      },
      buildingPermissionUsageTypeOtherDescription: {
        label: 'Usage Type Description',
        required: false,
        type: InputTypeEnum.TextString,
        options: null,
        dependency: null,
      },
      addressUnitDesignationType: {
        label: 'Address Unit Designator Type',
        required: false,
        type: InputTypeEnum.Dropdown,
        options: mapDropdownOptionsFromEnum(AddressUnitDesignatorTypeEnum, true, 'key'),
        dependency: null,
      },
      estateType: {
        label: 'Estate Type',
        required: false,
        type: InputTypeEnum.Dropdown,
        options: mapDropdownOptionsFromEnum(PropertyDetailEstateTypeEnum, true, 'key'),
        dependency: null,
      },
      estateTypeDescription: {
        label: 'Estate Type Description',
        required: false,
        type: InputTypeEnum.TextString,
        options: null,
        dependency: null,
      },
      occupancyType: {
        label: 'Occupancy Type',
        required: false,
        type: InputTypeEnum.Dropdown,
        options: mapDropdownOptionsFromEnum(PropertyDetailOccupancyTypeEnum, true, 'key'),
        dependency: null,
      },
      constructionMethod: {
        label: 'Construction Method',
        required: false,
        type: InputTypeEnum.Dropdown,
        options: mapDropdownOptionsFromEnum(PropertyDetailConstructionMethodTypeEnum, true, 'key'),
        dependency: null,
      },
      constructionMethodTypeDescription: {
        label: 'Construction Method Description',
        required: false,
        type: InputTypeEnum.TextString,
        options: null,
        dependency: 'ConstructionMethod: Other',
      },
    },
  },
  landSaleInformation: {
    title: 'Land Sale Information',
    fields: {
      parcelId: {
        label: 'Parcel ID',
        required: false,
        type: InputTypeEnum.TextString,
        options: null,
        dependency: null,
      },
      parcelIdType: {
        label: 'Parcel ID Type',
        required: false,
        type: InputTypeEnum.Dropdown,
        options: mapDropdownOptionsFromEnum(
          ParcelIdentificationParcelIdentificationTypeEnum,
          true,
          'key',
        ),
        dependency: null,
      },
      platBlockIdentifier: {
        label: 'Plat Block Identifier',
        required: false,
        type: InputTypeEnum.TextString,
        options: null,
        dependency: null,
      },
      platLotIdentifier: {
        label: 'Plat Lot Identifier',
        required: false,
        type: InputTypeEnum.TextString,
        options: null,
        dependency: null,
      },
      platType: {
        label: 'Plat Type',
        required: false,
        type: InputTypeEnum.Dropdown,
        options: mapDropdownOptionsFromEnum(PlattedLandPlatTypeEnum, true, 'key'),
        dependency: null,
      },
      pud: {
        label: 'PUD',
        required: false,
        type: InputTypeEnum.Checkbox,
        options: null,
        dependency: null,
      },
    },
  },
};

const brokerConfig: Page = {
  ...baseSubjectPropertyConfig,
  propertyInformation: {
    title: 'Property Information',
    fields: {
      ...baseSubjectPropertyConfig.propertyInformation.fields,
      buildingPermissionUsageType: {
        label: 'Building Permit Usage Type',
        required: true,
        type: InputTypeEnum.Dropdown,
        options: mapDropdownOptionsFromEnum(
          BuildingPermitBuildingPermitUsageStandardTypeEnum,
          true,
          'key',
          [
            BuildingPermitBuildingPermitUsageStandardTypeEnum.HomeEquity,
            BuildingPermitBuildingPermitUsageStandardTypeEnum.MobileHome,
          ],
        ),
        dependency: null,
      },
      addressUnitDesignationType: {
        label: 'Address Unit Designator Type',
        required: false,
        type: InputTypeEnum.Dropdown,
        options: mapDropdownOptionsFromEnum(AddressUnitDesignatorTypeEnum, true, 'key', [
          AddressUnitDesignatorTypeEnum.Stop,
        ]),
        dependency: null,
      },
    },
  },
};

export const addSubjectPropertyConfig = (orgType, form?: PlaceOrderFormState) => {
  let res: Page;
  if (orgType === OrgTypeEnum.BROKER) {
    res = brokerConfig;
  }
  if (orgType === OrgTypeEnum.WHOLESALE) {
    res = brokerConfig;
  }

  res = baseSubjectPropertyConfig;

  if (
    form &&
    form.order &&
    form.order.products &&
    form.order.products.includes(PlaceOrderProductTypeEnum.Appraisal)
  ) {
    const appraisalConfig: Page = JSON.parse(JSON.stringify(res));
    appraisalConfig.propertyInformation.fields.buildingPermissionUsageType.options =
      appraisalConfig.propertyInformation.fields.buildingPermissionUsageType.options.filter(
        (x: any) =>
          x.value !== BuildingPermitBuildingPermitUsageStandardTypeEnum.Hazardous &&
          x.value !== BuildingPermitBuildingPermitUsageStandardTypeEnum.Commercial &&
          x.value !== BuildingPermitBuildingPermitUsageStandardTypeEnum.Industrial,
      );
    return appraisalConfig;
  }
  return res;
};
