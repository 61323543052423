<script>
  import ProductDetails from '../../components/place-order/sections/ProductDetails.svelte';
  import { form, PlaceOrderProductTypeEnum } from '../../stores/placeOrder';
  import { SsnVerification } from '../../components/place-order/products';
  import PlaceOrder from './PlaceOrder.svelte';
</script>

<PlaceOrder>
  {#if $form.order.products.length === 1 && $form.order.products[0] === PlaceOrderProductTypeEnum.SSN_Verification}
    <SsnVerification />
  {:else}
    <ProductDetails />
  {/if}
</PlaceOrder>
