<script>
  import { productDetailsConfig } from '../../constants/place-order';
  import FormBlock from './FormBlock.svelte';
  import FormRow from './FormRow.svelte';
  import { getComponent, getProps } from './renderFromConfig';
  import Attachment from '../send-event/Attachment.svelte';
  import { CheckboxCard, Checkbox, Button } from '@xpanseinc/ui-components';
  import { getYearForm, getTaxProducts } from '../../constants/place-order/incomeTax';
  import { DocumentTypeEnum } from '../../schemas/place-order/income';
  import { form, PlaceOrderProductTypeEnum } from '../../stores/placeOrder';
  import { TranscriptIndicatorEnum } from '../../schemas/place-order';
  import { PlusIcon } from 'svelte-feather-icons';
  import { TaxProductsEnum } from '../../constants/place-order';
  export let application;
  export let appIdx;
  export let product;

  const currentYear = new Date().getFullYear() - 1;

  const { verificationOfTax } = productDetailsConfig;

  let attachmentPlaceholder = [];
  let checkValid = false;
  const getDocumentTypes = (product) => {
    switch (product) {
      case TaxProductsEnum.PRODUCT_4506_C:
        return [
          { label: DocumentTypeEnum.TYPE_4506_C, value: DocumentTypeEnum.TYPE_4506_C },
          { label: DocumentTypeEnum.TYPE_AUDIT_LOG, value: DocumentTypeEnum.TYPE_AUDIT_LOG },
        ];

      case TaxProductsEnum.PRODUCT_8821:
        return [
          { label: DocumentTypeEnum.TYPE_8821, value: DocumentTypeEnum.TYPE_8821 },
          {
            label: DocumentTypeEnum.TYPE_8821_AUDIT_LOG,
            value: DocumentTypeEnum.TYPE_8821_AUDIT_LOG,
          },
        ];

      default:
        return [];
    }
  };

  function DefaultAttachment() {
    return {
      fileName: '',
      mimeTypeId: '',
      usageCode: '',
      usageCodeDescription: '',
    };
  }

  function addAttachment(product) {
    $form.productDetails[product].applications[appIdx].attachments.push(DefaultAttachment());
    $form = $form;
    checkValid = false;
  }

  function removeAttachment(product, idx) {
    $form.productDetails[product].applications[appIdx].attachments.splice(idx, 1);
    $form = $form;
  }

  let incomeProducts = getTaxProducts();

  let lastFourYears = getYearForm();

  let transcriptTypeOptions = [
    { label: 'A (Return Transcript)', value: TranscriptIndicatorEnum.RETURN },
    { label: 'B (Account Transcript)', value: TranscriptIndicatorEnum.ACCOUNT },
    { label: 'C (Record of Account)', value: TranscriptIndicatorEnum.RECORD },
  ];

  $: incomeProducts = getTaxProducts(product);
  $: $form.productDetails[PlaceOrderProductTypeEnum.Income].applications[appIdx].products =
    incomeProducts;

  function toggleProduct(idx) {
    $form.productDetails[PlaceOrderProductTypeEnum.Income].applications[appIdx].products[
      application.applicationType
    ][idx].selected
      ? ($form.productDetails[PlaceOrderProductTypeEnum.Income].applications[appIdx].products[
          application.applicationType
        ][idx].selected =
          !$form.productDetails[PlaceOrderProductTypeEnum.Income].applications[appIdx].products[
            application.applicationType
          ][idx].selected)
      : ($form.productDetails[PlaceOrderProductTypeEnum.Income].applications[appIdx].products[
          application.applicationType
        ][idx].selected = true);

    if (product === TaxProductsEnum.PRODUCT_8821) {
      if (
        $form.productDetails[PlaceOrderProductTypeEnum.Income].applications[appIdx].products[
          application.applicationType
        ][idx].selected
      ) {
        $form.productDetails[PlaceOrderProductTypeEnum.Income].applications[appIdx].products[
          application.applicationType
        ][idx].years[currentYear] = true;
        $form.productDetails[PlaceOrderProductTypeEnum.Income].applications[appIdx].products[
          application.applicationType
        ][idx].years[currentYear - 1] = true;
      } else {
        $form.productDetails[PlaceOrderProductTypeEnum.Income].applications[appIdx].products[
          application.applicationType
        ][idx].years[currentYear] = false;
        $form.productDetails[PlaceOrderProductTypeEnum.Income].applications[appIdx].products[
          application.applicationType
        ][idx].years[currentYear - 1] = false;
      }
    }
  }
  $form.productDetails[PlaceOrderProductTypeEnum.Income].applications[appIdx].products =
    incomeProducts;
</script>

<div class="container">
  <FormRow>
    <FormBlock>
      {#each incomeProducts[application.applicationType] || [] as product, index (product)}
        <CheckboxCard
          name="{product.name}"
          checked="{application?.products[application.applicationType]?.[index]?.selected}"
          on:change="{() => toggleProduct(index)}"
        >
          <div class="product">
            <h4>{product.name}</h4>
            {#if application.products[application.applicationType]?.[index]?.selected}
              <div class="input-container">
                <FormRow>
                  {#if $form.productDetails[PlaceOrderProductTypeEnum.Income].product === TaxProductsEnum.PRODUCT_4506_C}
                    <svelte:component
                      this="{getComponent(verificationOfTax.fields.transcriptType)}"
                      {...getProps(verificationOfTax.fields.transcriptType)}
                      options="{transcriptTypeOptions}"
                      placeholder="Select Transcript type"
                      value="{application.products[application.applicationType][index]
                        .transcriptType}"
                      on:select="{(e) => {
                        $form.productDetails[PlaceOrderProductTypeEnum.Income].applications[
                          appIdx
                        ].products[application.applicationType][index].transcriptType =
                          e.detail.value;
                        $form = $form;
                      }}"
                    />
                  {/if}

                  <div>
                    <p>Filing Years*</p>
                    <FormRow>
                      {#each Object.keys(lastFourYears).sort( (a, b) => (a > b ? -1 : 1), ) as year (product.name + year)}
                        <Checkbox
                          checked="{application.products[application.applicationType][index].years[
                            year
                          ]}"
                          name="{year}"
                          on:change="{() => {
                            $form.productDetails[PlaceOrderProductTypeEnum.Income].applications[
                              appIdx
                            ].products[application.applicationType][index].years[year] =
                              !$form.productDetails[PlaceOrderProductTypeEnum.Income].applications[
                                appIdx
                              ].products[application.applicationType][index].years[year];
                          }}">{year}</Checkbox
                        >
                      {/each}
                    </FormRow>
                  </div>
                </FormRow>
              </div>
            {/if}
          </div>
        </CheckboxCard>
      {/each}
    </FormBlock>
  </FormRow>
  <FormRow>
    <FormBlock title="Attachments*" width="600px">
      {#each application.attachments as attachment, idx (attachment)}
        <Attachment
          checkValid="{checkValid}"
          required="{false}"
          options="{getDocumentTypes(product)}"
          showRemove="{idx > 0}"
          on:update="{() => {
            $form = $form;
          }}"
          on:remove="{() => removeAttachment(PlaceOrderProductTypeEnum.Income, idx)}"
          bind:this="{attachmentPlaceholder}"
          attachment="{attachment}"
        />
      {/each}
      <div class="button-container">
        <Button
          icon="{PlusIcon}"
          label="Add Attachment"
          name="add-attachment"
          on:click="{() => addAttachment(PlaceOrderProductTypeEnum.Income)}"
        />
      </div>
    </FormBlock>
  </FormRow>
</div>

<style>
  .container {
    padding-left: 1em;
    border-left: 6px solid var(--gray2);
    width: 600px;
  }

  .product {
    min-width: 670px;
  }

  .input-container {
    padding: 1em 1em 0 1em;
  }
</style>
