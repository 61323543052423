<script>
  /* eslint-disable no-restricted-syntax, guard-for-in */
  import DateTime from './DateTime.svelte';
  import { setContext } from 'svelte';
  import { startCase, sum } from 'lodash-es';
  import {
    Button,
    Checkbox,
    Dropdown,
    LoadingModal,
    TextInput,
    DateInput,
  } from '@xpanseinc/ui-components';
  import { attachmentApi, eventApi } from '../../stores/api';
  import { eventConfig } from '../../stores/eventConfig';
  import { parties } from '../../stores/orders';
  import { eventError } from '../../constants/errorMessages';
  import { getLabel } from '../../constants/sendEventDataLabels';
  import { PlaceOrderProductTypeEnum } from '../../stores/placeOrder';
  import { alphabetically } from '../../utils/alphabetically';
  import { popToast } from '../../utils/toasts';
  import { productDetailsConfig } from '../../constants/place-order';
  import { imask, masks } from '../../constants/inputMasking';
  import { EventIdEnum, OrderAttribute } from '../../constants/place-order';
  import ContactMethods from '../ContactMethods.svelte';
  import AppraiserInfo from './AppraiserInfo.svelte';
  import FormBlock from '../place-order/FormBlock.svelte';
  import Attachments from './Attachments.svelte';
  import ClosingData from './ClosingData.svelte';
  import ConditionSatisfaction from './ConditionSatisfaction.svelte';
  import DocumentExpTime from './DocumentExpTime.svelte';
  import InvoiceAmount from './InvoiceAmount.svelte';
  import InvoiceDate from './InvoiceDate.svelte';
  import PointOfContact from './PointOfContact.svelte';
  import Reasons from './Reasons.svelte';
  import RecordingEndorsement from './RecordingEndorsement.svelte';
  import RequestedDueDate from './RequestedDueDate.svelte';
  import TrackingInfo from './TrackingInfo.svelte';
  import { zonedTimeToUtc } from 'date-fns-tz';
  import { getComponent, getProps } from '../place-order/renderFromConfig';
  import AddPayment from './dialogs/AddPayment.svelte';

  export let orderId;
  export let product;
  export let config;
  export let logs;
  export let borrowers;

  let actionRequired = false;
  let checkValid = false;
  let errors = [];
  let eventId = '';
  let partiesInvalid = true;
  let postEventPromise;
  let showActionRequired = true;
  let sendEventForm;
  let sending = false;
  let showUploadError = false;
  let uploading = false;
  let scheduledDate = '';
  let invalidInspectionSchedule = false;
  const totalUploadLimit = 50;
  const individualFileLimit = 20;
  const { appraisal } = productDetailsConfig;

  let { timeZone } = Intl.DateTimeFormat().resolvedOptions() || 'America/New_York';

  function DefaultSendEventForm() {
    return {
      appraiser: {
        contactPoints: [],
      },
      comment: '',
      eventType: undefined,
      inspectedDate: undefined,
      inspectionSchedule: undefined,
      status: '',
      attributes: {},
    };
  }

  function clearForm(keepEventType) {
    actionRequired = false;
    checkValid = false;
    errors = [];
    const eventType = sendEventForm.eventType;
    const status = sendEventForm.status;
    sendEventForm = new DefaultSendEventForm();
    if (keepEventType) {
      sendEventForm.eventType = eventType;
      sendEventForm.status = status;
    }
    showActionRequired = true;
    showUploadError = false;
  }

  async function onEventTypeSelect() {
    sending = false;
    clearForm(true);
    if (config[eventId]?.data?.attachment) {
      sendEventForm.attachments = [];
    }
    if (config[eventId]?.data?.reasons) {
      sendEventForm.reasons = [];
    }
    if (config[eventId]?.data?.parties) {
      sendEventForm.parties = [];
    }
    if (
      sendEventForm.eventType === 'AddNoteAck' ||
      sendEventForm.eventType === 'AddNote' ||
      product.toUpperCase() === PlaceOrderProductTypeEnum.Payment
    ) {
      showActionRequired = false;
    }
    if (eventId === EventIdEnum.FEE_MODIFICATION_APPROVED) {
      sendEventForm.feeModificationPayments = [];
    }
  }

  setContext('payment', {
    component: AddPayment,
    getHeader: (item) =>
      `$${sum(
        item.feeModificationPayments?.filter((x) => x.amount !== null)?.map((x) => x.amount),
      )}`,
    getPaymentText: (item) => {
      return item.feeModificationPayments
        ?.map(({ payer, billToPartyEmailAddress }) => `${payer.type}(${billToPartyEmailAddress})`)
        ?.join(', ');
    },
  });

  async function uploadToS3(url, file) {
    return fetch(url, {
      method: 'PUT',
      body: file,
    }).catch((error) => {
      console.error('upload:', error);
      uploading = false;
      popToast({
        title: 'Error: Failed To Upload, please try again',
        description: 'Retry',
        duration: 0,
        onClick: () => {
          onSubmit();
        },
      });
    });
  }

  async function handleAttachments() {
    uploading = true;
    const uploadUrls = await $attachmentApi.getUploadUrl({
      count: sendEventForm.attachments.length,
    });
    await Promise.all(
      sendEventForm.attachments.map(async (attachment, i) => {
        /* eslint-disable no-param-reassign */
        attachment.objectId = uploadUrls[i].objectId;
        const signedURL = uploadUrls[i].url;
        const { file } = attachment;
        delete attachment.file;
        return uploadToS3(signedURL, file);
        /* eslint-enable no-param-reassign */
      }),
    );
    uploading = false;
  }

  function validate() {
    checkValid = true;
    errors = [];
    if (eventId === '') {
      errors.push(`Please select a ${product} Event`);
    } else {
      if (config[eventId]?.data?.amount && !sendEventForm?.feeModificationAmount) {
        errors.push(eventError.feeModificationAmount.message);
      }
      if (eventId === EventIdEnum.PAYMENT_REFUND_REQUESTED) {
        if (sendEventForm.feeModificationAmount > refundableAmount) {
          errors.push(eventError.paymentRefundRequested.message);
        }
        if (sendEventForm.feeModificationAmount <= 0) {
          errors.push(eventError.paymentRefundRequestedNegative.message);
        }
      }
      if (
        eventId === EventIdEnum.FEE_MODIFICATION_APPROVED &&
        sendEventForm.feeModificationPayments.length == 0
      ) {
        errors.push(eventError.feeModificationPayments.message);
      }
      if (config[eventId]?.data?.appraiser) {
        const { firstName, lastName, license } = sendEventForm.appraiser;
        if (!firstName || !lastName || !license) {
          errors.push(eventError.appraiser.message);
        }
      }
      if (
        sendEventForm?.attachments?.length === 0 &&
        config[eventId]?.data?.attachment?.file?.required
      ) {
        errors.push(eventError.attachments.message);
      }
      if (sendEventForm.attachments && config[eventId]?.data?.attachment?.file?.required) {
        for (const attachment in sendEventForm.attachments) {
          if (
            sendEventForm.attachments.length === 1 &&
            !sendEventForm.attachments[attachment]?.fileName &&
            !sendEventForm.attachments[attachment]?.usageCode
          ) {
            errors.push(eventError.attachments.message);
            break;
          }
          if (!sendEventForm.attachments[attachment]?.fileName) {
            errors.push(eventError.attachments.fileName.message);
            break;
          }
          if (!sendEventForm.attachments[attachment]?.usageCode) {
            errors.push(eventError.attachments.usageCode.message);
            break;
          }
        }
      }
      if (sendEventForm.attachments) {
        let size = 0;
        for (const attachment in sendEventForm.attachments) {
          if (sendEventForm.attachments[attachment]?.fileName) {
            let extension = sendEventForm.attachments[attachment].fileName.split('.');
            extension = extension[extension.length - 1].toLowerCase();
            if (extension !== 'pdf' && extension !== 'xml') {
              errors.push(eventError.attachments.wrongExtension.message);
            }
            if (sendEventForm.attachments[attachment].file.size / 1000000 > individualFileLimit) {
              errors.push(eventError.attachments.fileSizeTooLarge.message);
            }
            if (
              sendEventForm.attachments[attachment]?.usageCode.toUpperCase() === 'OTHER' &&
              !sendEventForm.attachments[attachment]?.usageCodeDescription
            ) {
              errors.push(eventError.attachments.usageCodeDescription.message);
              break;
            }
            size += sendEventForm.attachments[attachment].file.size / 1000000;
            if (size > totalUploadLimit) {
              if (errors.indexOf(eventError.attachments.totalFileSize.message) === -1)
                errors.push(eventError.attachments.totalFileSize.message);
            }
          } else if (
            !config[eventId]?.data?.attachment?.file?.required &&
            !sendEventForm.attachments[attachment]?.fileName
          ) {
            sendEventForm.attachments.splice(attachment, 1);
          }
        }
      }
      if (!sendEventForm.comment && (config[eventId].data?.comment?.required || actionRequired)) {
        errors.push(eventError.comment.message);
      }
      if (!sendEventForm.deliveryType && config[eventId].data?.deliveryType?.required) {
        errors.push(eventError.deliveryType.message);
      }
      if (!sendEventForm.fulfillmentCenter && config[eventId].data?.fulfillmentCenter?.required) {
        errors.push(eventError.fulfillmentCenter.message);
      }
      if (sendEventForm.closingData) {
        if (
          config[eventId].data?.scheduleLocation?.required &&
          (!sendEventForm.closingData.address?.line1 ||
            !sendEventForm.closingData.address?.city ||
            !sendEventForm.closingData.address?.state ||
            !sendEventForm.closingData.address?.zip ||
            !sendEventForm.closingData.address?.county)
        ) {
          errors.push(eventError.scheduleLocation.message);
        }
        if (
          config[eventId].data?.scheduleLocationType?.required &&
          !sendEventForm.closingData.closingLocationType
        ) {
          errors.push(eventError.closingLocationType.message);
        }
        if (
          sendEventForm.closingData.closingLocationType === 'OTHER' &&
          !sendEventForm.closingData.closingLocationDescription
        ) {
          errors.push(eventError.closingLocationDescription.message);
        }
        if (
          config[eventId].data?.scheduleDate?.required &&
          !sendEventForm.closingData.closingDate
        ) {
          errors.push(eventError.scheduleDate.message);
        }
        if (
          config[eventId].data?.mortgagePageCount?.required &&
          (!sendEventForm.closingData.mortgagePageCount ||
            (sendEventForm.closingData.mortgagePageCount &&
              sendEventForm.closingData.mortgagePageCount < 0))
        ) {
          errors.push(eventError.mortgagePageCount.message);
        }
        if (
          config[eventId].data?.deedPageCount?.required &&
          (!sendEventForm.closingData.deedPageCount ||
            (sendEventForm.closingData.deedPageCount &&
              sendEventForm.closingData.deedPageCount < 0))
        ) {
          errors.push(eventError.deedPageCount.message);
        }
      }
      if (
        config[eventId].data?.termsOfLoan?.required &&
        !sendEventForm.termsOfLoan.loanPurposeType
      ) {
        errors.push(eventError.loanPurposeType.message);
      }
      if (
        sendEventForm?.termsOfLoan?.loanPurposeType === 'OTHER' &&
        !sendEventForm.termsOfLoan.loanPurposeTypeOtherDescription
      ) {
        errors.push(eventError.loanPurposeTypeOtherDescription.message);
      }
      if (
        !sendEventForm.conditionSatisfactionResponsibleParty &&
        config[eventId].data?.conditionSatisfactionResponsibleParty?.required
      ) {
        errors.push(eventError.conditionSatisfactionResponsibleParty.message);
      }
      if (!sendEventForm.orderIdentifier && config[eventId].data?.orderIdentifier?.required) {
        errors.push(eventError.orderIdentifier.message);
      }

      if (!sendEventForm.requestedDueDate && config[eventId].data?.requestedDueDate?.required) {
        errors.push(eventError.requestedDueDate.message);
      }

      if (
        sendEventForm.fhaCaseIdentifier < 0 ||
        (!sendEventForm.fhaCaseIdentifier && config[eventId].data?.fhaCaseIdentifier?.required)
      ) {
        errors.push(eventError.fhaCaseIdentifier.message);
      }

      if (!sendEventForm.inspectedDate && config[eventId].data?.inspectedDate?.required) {
        errors.push(eventError.inspectionDate.message);
      }

      invalidInspectionSchedule = false;
      if (!sendEventForm.inspectionSchedule && config[eventId].data?.inspectionSchedule?.required) {
        errors.push(eventError.inspectionSchedule.message);
        invalidInspectionSchedule = true;
      }

      if (sendEventForm.parties?.length === 0 && config[eventId].data?.parties?.required) {
        errors.push(eventError.parties.message);
      }

      if (!sendEventForm.invoiceAmount && config[eventId].data?.invoiceAmount?.required) {
        errors.push(eventError.invoiceAmount.message);
      }

      if (!sendEventForm.invoiceDate && config[eventId].data?.invoiceDate?.required) {
        errors.push(eventError.invoiceDate.message);
      }

      if (sendEventForm.reasons) {
        for (const reason in sendEventForm.reasons) {
          if (
            !sendEventForm.reasons[reason]?.reasonCode &&
            config[eventId].data?.reasons?.reasonCode?.required
          ) {
            errors.push(eventError.reasonCode.message);
            break;
          }
        }
      }
      if (
        sendEventForm.recordingEndorsement &&
        config[eventId].data?.recordingEndorsement?.required
      ) {
        if (
          !sendEventForm.recordingEndorsement?.feeAmount ||
          !sendEventForm.recordingEndorsement?.firstPageNumberValue ||
          !sendEventForm.recordingEndorsement?.instrumentNumberIdentifier ||
          !sendEventForm.recordingEndorsement?.recordedDatetime ||
          !sendEventForm.recordingEndorsement?.recordingEndorsementType ||
          !sendEventForm.recordingEndorsement?.volumeIdentifier ||
          !sendEventForm.recordingEndorsement?.volumeType
        ) {
          errors.push(eventError.recordingEndorsement.message);
        }
        if (
          sendEventForm.recordingEndorsement.recordingEndorsementType === 'OTHER' &&
          !sendEventForm.recordingEndorsement.recordingEndorsementTypeOtherDescription
        ) {
          errors.push(
            eventError.recordingEndorsement.recordingEndorsementTypeOtherDescription.message,
          );
        }
        if (
          sendEventForm.recordingEndorsement.volumeType === 'OTHER' &&
          !sendEventForm.recordingEndorsement.volumeTypeOtherDescription
        ) {
          errors.push(eventError.recordingEndorsement.volumeTypeOtherDescription.message);
        }
      }
      if (sendEventForm.trackingInfo && config[eventId].data?.trackingNumber?.required) {
        for (const input in sendEventForm.trackingInfo) {
          if (!sendEventForm.trackingInfo[input]) {
            errors.push(eventError.trackingInfo.message);
            break;
          }
        }
      }
      if (eventId === EventIdEnum.ENOTE_GENERATED) {
        if (!sendEventForm.attributes[OrderAttribute.ENOTE_VAULT_TRANSACTION_ID]) {
          errors.push(eventError.enoteVaultTransactionId.message);
        }
        if (!sendEventForm.attributes[OrderAttribute.ENOTE_DOC_PROFILE_SID]) {
          errors.push(eventError.enoteDocProfileSID.message);
        }
      }
    }
    return errors.length === 0;
  }

  async function resetForm() {
    config = await $eventApi?.getEventsToSendAsMap({ orderId });
    eventConfig.set(config);
    eventId = '';
    clearForm();
  }

  async function callPostEvent(data) {
    sending = true;
    try {
      postEventPromise = $eventApi.postEvent({ orderId, postEvent: data });
      await postEventPromise;

      popToast({
        title: 'Event successfully sent',
        type: 'success',
        duration: 6000,
      });
    } catch (error) {
      popToast({
        title: 'Error: Failed To Send Event',
        description: 'Retry',
        duration: 6000,
        onClick: () => {
          onSubmit();
        },
      });
      console.error('postEvent:', error);
    }
    sending = false;
  }

  async function onSubmit() {
    showUploadError = false;
    if (!validate()) {
      return;
    }
    if (sendEventForm.attachments) {
      sendEventForm.attachments = sendEventForm.attachments.filter(
        (attachment) => attachment.fileName,
      );
      if (sendEventForm.attachments.length > 0) {
        await handleAttachments(sendEventForm.attachments);
      } else {
        delete sendEventForm.attachments;
      }
    }
    if (sendEventForm?.parties?.length > 0) {
      sendEventForm.parties.forEach((party) => {
        const newParties = new Map();
        const key = party.email || party.fullName;
        newParties.set(key, party);
        $parties.forEach((p) => {
          const k = p.email || p.fullName;
          newParties.set(k, p);
        });
        parties.set(newParties);
      });
    }
    if (sendEventForm?.appraiser?.contactPoints?.length > 0) {
      const contactPoints = sendEventForm.appraiser.contactPoints;
      sendEventForm.appraiser.contactPoints = contactPoints.map((contact) => {
        return {
          email: contact.method === 'Email' ? contact.details : null,
          phone: contact.method === 'Phone' ? contact.details : null,
        };
      });
    }
    if (sendEventForm?.feeModificationPayments?.length > 0) {
      sendEventForm.feeModificationPayments =
        sendEventForm.feeModificationPayments[0].feeModificationPayments;
    }
    await callPostEvent(sendEventForm);
    if (actionRequired) {
      const actionRequiredForm = {
        eventType: 'AddNoteAck',
        comment: sendEventForm.comment,
      };
      await callPostEvent(actionRequiredForm);
    }
    resetForm();
  }

  function onChange(e) {
    let value = e.target.type === 'number' ? parseInt(e.target.value) : e.target.value;
    sendEventForm[e.target.name] = value;
    validate();
  }

  function createUTCDateForISO(dateString) {
    const timeZoneOffset = new Date().getTimezoneOffset();
    const eventDate = Date.parse(dateString) - timeZoneOffset * 60 * 1000;
    const dateAsISO = new Date(eventDate).toISOString().substring(0, 19);
    return dateAsISO;
  }

  function validateParties() {
    partiesInvalid = sendEventForm.parties.length === 0;
  }

  function checkRequirements(event) {
    const requirements = config[event]?.data;
    for (const requirement in requirements) {
      if (requirements[requirement]?.required) {
        return true;
      }
      if (typeof requirements[requirement] === 'object') {
        for (const field in requirements[requirement]) {
          if (requirements[requirement][field]?.required) {
            return true;
          }
        }
      }
    }
    return false;
  }

  sendEventForm = new DefaultSendEventForm();

  $: eventTypeOptions = Object.values(config)
    .map((event) => {
      const status = event.statusName ? `/${event.statusName}` : '';
      return {
        label: event.actionName,
        value: event.eventId + status,
      };
    })
    ?.sort(alphabetically);

  $: if (
    eventId === EventIdEnum.FEE_MODIFICATION_NO_APPROVED ||
    eventId === EventIdEnum.FEE_MODIFICATION_APPROVED
  ) {
    let lastRequestedFee =
      logs.find((log) => log.eventId === 'FeeModification')?.feeModificationAmount || null;
    sendEventForm.feeModificationAmount = lastRequestedFee;
  }

  $: {
    setContext('addPayment', {
      borrowers,
      feeModificationAmount: sendEventForm.feeModificationAmount,
    });
  }

  $: paymentAmount = logs?.find((log) => log.eventId === EventIdEnum.PLACE_ORDER)?.additionalData
    ?.paymentOrderRequest?.feeModificationAmount;
  $: fullRefundAmount =
    logs.find((log) => log.status === 'Refunded')?.additionalData?.paymentOrderRequest
      ?.feeModificationAmount || 0;
  $: partialRefundsTotal = logs
    ?.filter((log) => log.status === 'PartialRefunded')
    .reduce(
      (previous, current) =>
        previous + (current?.additionalData?.paymentOrderRequest?.feeModificationAmount || 0),
      0,
    );
  $: refundableAmount = paymentAmount - fullRefundAmount - partialRefundsTotal;
</script>

<form on:submit|preventDefault="{onSubmit}">
  <div class="form-row">
    <div class="form-item two-col">
      <Dropdown
        disabled="{eventTypeOptions?.length === 0}"
        invalid="{checkValid && !eventId}"
        label="{product} Updates"
        name="eventType"
        options="{eventTypeOptions}"
        placeholder="Select One"
        on:select="{(e) => {
          const [eventType, status] = e.detail.value.split('/');
          sendEventForm.eventType = eventType;
          sendEventForm.status = status || undefined;
          onEventTypeSelect();
        }}"
        bind:value="{eventId}"
      />
    </div>
    {#if eventId && checkRequirements(eventId)}
      <div class="form-item flex">
        <p class="body message">Required Fields *</p>
      </div>
    {/if}
  </div>
  {#if eventId}
    <h3 class="header-l header-l--bold">{config[eventId]?.actionName}</h3>
    {#if config[eventId]?.data?.amount}
      <div class="form-row">
        <div class="form-item">
          <TextInput
            invalid="{checkValid &&
              config[eventId]?.data?.amount?.required &&
              (!sendEventForm.feeModificationAmount ||
                sendEventForm.feeModificationAmount > refundableAmount ||
                sendEventForm.feeModificationAmount <= 0)}"
            label="{getLabel('amount', EventIdEnum.PAYMENT_REFUND_REQUESTED)}{config[eventId]?.data
              ?.amount?.required
              ? '*'
              : ''}"
            name="feeModificationAmount"
            maskPackage="{imask}"
            maskOptions="{masks.currency}"
            disabled="{((eventId === EventIdEnum.FEE_MODIFICATION_APPROVED ||
              eventId === EventIdEnum.FEE_MODIFICATION_NO_APPROVED) &&
              sendEventForm.feeModificationAmount) ||
              refundableAmount <= 0}"
            on:accept="{({ detail: { masked } }) => {
              sendEventForm.feeModificationAmount = masked.unmaskedValue;
            }}"
          />
        </div>
      </div>
      <div class="refund-subtext">
        {#if paymentAmount && refundableAmount > 0}
          ${refundableAmount.toFixed(2).toLocaleString()} is available to refund
        {:else if paymentAmount}
          <p class="body-small text-error">
            Full amount of ${paymentAmount.toFixed(2).toLocaleString()} already refunded
          </p>
        {/if}
      </div>
    {/if}
    {#if eventId === EventIdEnum.FEE_MODIFICATION_APPROVED}
      <div class="form-row">
        <FormBlock title="{appraisal.fields.payments.label} *" width="100%">
          <svelte:component
            this="{getComponent(appraisal.fields.payments)}"
            {...getProps(appraisal.fields.payments)}
            itemList="{sendEventForm.feeModificationPayments}"
            contextKey="payment"
            placeholderButtonLabel="{appraisal.fields.payments.label}"
            maxItems="1"
            on:itemChange="{(e) => {
              sendEventForm.feeModificationPayments = e.detail;
            }}"
          />
        </FormBlock>
      </div>
    {/if}
    {#if config[eventId]?.data?.scheduleDate || config[eventId]?.data?.scheduleLocation}
      <ClosingData
        checkValid="{checkValid}"
        config="{config[eventId].data}"
        bind:sendEventForm="{sendEventForm}"
      />
    {/if}
    {#if config[eventId]?.data?.fulfillmentCenter?.options?.length}
      <div class="form-row">
        <div class="form-item">
          <Dropdown
            invalid="{checkValid &&
              config[eventId]?.data?.fulfillmentCenter.required &&
              !sendEventForm.fulfillmentCenter}"
            label="{config[eventId]?.data?.fulfillmentCenter?.required
              ? 'Fulfillment Center*'
              : 'Fulfillment Center'}"
            placeholder="Select One"
            options="{config[eventId]?.data?.fulfillmentCenter?.options}"
            name="fulfillment-center"
            bind:value="{sendEventForm.fulfillmentCenter}"
          />
        </div>
      </div>
    {/if}
    {#if config[eventId]?.data?.trackingNumber}
      <TrackingInfo
        checkValid="{checkValid}"
        config="{config[eventId].data}"
        bind:sendEventForm="{sendEventForm}"
      />
    {/if}
    {#if config[eventId]?.data?.recordingEndorsement}
      <RecordingEndorsement
        checkValid="{checkValid}"
        config="{config[eventId].data}"
        bind:sendEventForm="{sendEventForm}"
      />
    {/if}
    {#if config[eventId]?.data?.documentExpTime}
      <DocumentExpTime sendEventForm="{sendEventForm}" />
    {/if}
    {#if config[eventId]?.data?.invoiceAmount}
      <InvoiceAmount
        checkValid="{checkValid}"
        required="{config[eventId]?.data?.invoiceAmount.required}"
        sendEventForm="{sendEventForm}"
      />
    {/if}
    {#if config[eventId]?.data?.invoiceDate}
      <InvoiceDate
        checkValid="{checkValid}"
        required="{config[eventId]?.data?.invoiceDate.required}"
        sendEventForm="{sendEventForm}"
      />
    {/if}
    {#if config[eventId]?.data?.attachment}
      <Attachments
        config="{config[eventId].data?.attachment}"
        required="{config[eventId].data.attachment.file.required}"
        bind:checkValid="{checkValid}"
        bind:sendEventForm="{sendEventForm}"
      />
    {/if}
    {#if config[eventId]?.data?.conditionSatisfactionResponsibleParty}
      <ConditionSatisfaction sendEventForm="{sendEventForm}" checkValid="{checkValid}" />
    {/if}
    {#if config[eventId]?.data?.reasons}
      <Reasons
        config="{config[eventId]?.data?.reasons?.reasonCode}"
        bind:checkValid="{checkValid}"
        bind:sendEventForm="{sendEventForm}"
      />
    {/if}
    {#if config[eventId]?.data?.orderIdentifier}
      <div class="form-row">
        <div class="form-item">
          <TextInput
            invalid="{checkValid &&
              config[eventId]?.data?.orderIdentifier.required &&
              !sendEventForm.orderIdentifier}"
            label="{getLabel('orderIdentifier')}{config[eventId]?.data?.orderIdentifier.required
              ? '*'
              : ''}"
            name="orderIdentifier"
            bind:value="{sendEventForm.orderIdentifier}"
            on:change="{onChange}"
          />
        </div>
      </div>
    {/if}
    {#if config[eventId]?.data?.inspectedDate}
      <div class="form-row">
        <div class="form-item">
          <DateInput
            invalid="{checkValid &&
              (config[eventId]?.data?.inspectedDate?.required || actionRequired) &&
              !sendEventForm.inspectedDate}"
            label="{getLabel('inspectedDate')}{config[eventId]?.data?.inspectedDate?.required ||
            actionRequired
              ? '*'
              : ''}"
            name="inspectedDate"
            date="{sendEventForm.inspectedDate || null}"
            dateFormat="n/j/Y"
            enableTime="{false}"
            on:change="{({ detail: [inspectedDate] }) => {
              scheduledDate = inspectedDate;
              sendEventForm.inspectedDate = zonedTimeToUtc(inspectedDate, timeZone);
            }}"
          />
        </div>
      </div>
    {/if}
    {#if config[eventId]?.data?.inspectionSchedule}
      <div class="form-row">
        <DateTime
          checkValid="{checkValid && invalidInspectionSchedule}"
          minToday="{true}"
          dateLabel="{getLabel('inspectionScheduleDate')}{config[eventId]?.data?.inspectionSchedule
            ?.required || actionRequired
            ? '*'
            : ''}"
          timeLabel="{getLabel('inspectionScheduleTime')}{config[eventId]?.data?.inspectionSchedule
            ?.required || actionRequired
            ? '*'
            : ''}"
          on:change="{({ detail: { datetime } }) => {
            sendEventForm.inspectionSchedule = createUTCDateForISO(datetime);
          }}"
        />
      </div>
    {/if}
    {#if config[eventId]?.data?.requestedDueDate}
      <RequestedDueDate
        checkValid="{checkValid}"
        required="{config[eventId]?.data?.requestedDueDate.required}"
        sendEventForm="{sendEventForm}"
      />
    {/if}
    {#if config[eventId]?.data?.fhaCaseIdentifier}
      <div class="form-row">
        <div class="form-item">
          <TextInput
            invalid="{(checkValid &&
              (config[eventId]?.data?.fhaCaseIdentifier?.required || actionRequired) &&
              !sendEventForm.fhaCaseIdentifier) ||
              sendEventForm.fhaCaseIdentifier < 0}"
            label="{getLabel('fhaCaseIdentifier')}{config[eventId]?.data?.fhaCaseIdentifier
              ?.required || actionRequired
              ? '*'
              : ''}"
            maskPackage="{imask}"
            maskOptions="{masks.fha}"
            name="fhaCaseIdentifier"
            on:accept="{({ detail: { masked } }) => {
              sendEventForm.fhaCaseIdentifier = parseInt(masked.unmaskedValue);
            }}"
          />
        </div>
      </div>
    {/if}

    {#if config[eventId]?.data?.parties}
      <PointOfContact
        config="{config[eventId]?.data}"
        sendEventForm="{sendEventForm}"
        on:validateParties="{validateParties}"
      />
    {/if}
    {#if config[eventId]?.data?.appraiser}
      <h3 class="header-m">Details</h3>
      <AppraiserInfo bind:checkValid="{checkValid}" bind:sendEventForm="{sendEventForm}" />

      <h3 class="header-m">Contact Methods</h3>
      <ContactMethods
        on:change="{({ detail }) => {
          sendEventForm.appraiser.contactPoints = detail;
        }}"
      />
    {/if}
    {#if eventId === EventIdEnum.ENOTE_GENERATED}
      <div class="form-row">
        <div class="form-item one-col">
          <TextInput
            invalid="{checkValid &&
              !sendEventForm.attributes[OrderAttribute.ENOTE_VAULT_TRANSACTION_ID]}"
            label="* Vault Transaction Id"
            name="vaultTransactionId"
            on:change="{({ target }) => {
              sendEventForm.attributes[OrderAttribute.ENOTE_VAULT_TRANSACTION_ID] = target.value;
            }}"
          />
        </div>
        <div class="form-item one-col">
          <TextInput
            invalid="{checkValid &&
              !sendEventForm.attributes[OrderAttribute.ENOTE_DOC_PROFILE_SID]}"
            label="* Doc Profile SID"
            name="docProfileSID"
            on:change="{({ target }) => {
              sendEventForm.attributes[OrderAttribute.ENOTE_DOC_PROFILE_SID] = target.value;
            }}"
          />
        </div>
      </div>
    {/if}
    {#if config[eventId]?.data?.comment}
      <div class="form-row">
        <div class="form-item two-col">
          <TextInput
            invalid="{checkValid &&
              (config[eventId]?.data?.comment?.required || actionRequired) &&
              !sendEventForm.comment}"
            label="{getLabel('comment')}{config[eventId]?.data?.comment?.required || actionRequired
              ? '*'
              : ''}"
            name="comment"
            multiline
            bind:value="{sendEventForm.comment}"
          />
        </div>
        {#if showActionRequired}
          <div class="form-item">
            <Checkbox name="actionRequired" bind:checked="{actionRequired}"
              >Action Required</Checkbox
            >
          </div>
        {/if}
      </div>
    {/if}
  {/if}

  <div class="form-row form-item--action">
    <ul class="errors">
      {#each errors as error}
        <li class="body text-body">{error}</li>
      {/each}
    </ul>
    <Button
      color="primary"
      disabled="{eventTypeOptions?.length === 0 ||
        !eventId ||
        (partiesInvalid && config[eventId].data?.parties?.required)}"
      label="Send Update"
      name="send-event"
      on:click="{onSubmit}"
    />
  </div>
</form>
<LoadingModal visible="{uploading}" text="Uploading..." isFullScreen="false" />
{#if sending}
  {#await postEventPromise}
    <LoadingModal visible text="Sending Event..." isFullScreen="false" />
  {/await}
{/if}

<style>
  form {
    padding: 24px 24px 0 0;
    border-radius: 8px;
  }

  form :global(.form-row) {
    display: flex;
    justify-content: start;
    align-content: stretch;
    margin-top: 4px;
    margin-bottom: 1.25rem;
    max-width: 850px;
  }

  form :global(.form-item) {
    flex: 0 1 33.33%;
    padding-right: 24px;
  }

  form :global(.form-item.two-col) {
    flex: 0 1 66.66%;
  }

  form :global(.form-item.three-col) {
    flex: 0 1 100%;
  }

  form :global(.form-item.two-col.has-action-col) {
    flex: 0 1 calc(66.66% - 40px);
  }

  form :global(.form-item.action-col) {
    flex: 0 0 40px;
    padding-right: 0;
    display: flex;
  }

  form :global(.form-item.action-col .icon-btn.secondary) {
    margin-top: auto;
  }

  form :global(.form-item .checkbox) {
    margin-top: 17px;
  }

  form :global(h3),
  form :global(h4) {
    padding-top: 16px;
    padding-bottom: 8px;
  }

  form :global(.message) {
    margin-top: auto;
  }

  form :global(.form-item--action) {
    display: flex;
    justify-content: flex-end;
    gap: 24px;
    padding-top: 2rem;
  }

  .errors {
    flex-grow: 1;
    list-style-type: disc;
    margin-top: 8px;
    margin-left: 32px;
  }

  .errors li {
    margin: 0;
    color: var(--negativeDark);
  }

  .refund-subtext {
    font-size: 12px;
  }
</style>
