<script>
  import { Button, Dialog, ThemeColor } from '@xpanseinc/ui-components';
  import { createEventDispatcher } from 'svelte';

  export let visible = false;

  const dispatch = createEventDispatcher();

  function close() {
    visible = false;
  }

  function confirm() {
    close();
    dispatch('confirm');
  }
</script>

<Dialog size="small" bind:visible title="Quit Place Order?" on:close="{close}">
  <div slot="body">Are you sure? The information will not be saved.</div>
  <footer slot="footer">
    <Button name="cancel-discard" label="Cancel" on:click="{close}" />
    <Button name="confirm-discard" label="Quit" color="{ThemeColor.Warning}" on:click="{confirm}" />
  </footer>
</Dialog>
