<script>
  import { createEventDispatcher } from 'svelte';
  import { DateInput } from '@xpanseinc/ui-components';
  import { format, parse } from 'date-fns';

  const dispatch = createEventDispatcher();
  const today = new Date();

  export let checkValid = false;
  export let maxToday = false;
  export let minToday = false;
  export let dateLabel;
  export let timeLabel;

  let date = '';
  let time = '';

  const getDate = () => {
    const dateStr = format(date, 'yyyy-MM-dd');
    const timeStr = format(time, 'HH:mm') + ':00';
    return parse(`${dateStr} ${timeStr}`, 'yyyy-MM-dd HH:mm:ss', new Date());
  };

  $: datetime = date && time ? getDate() : undefined;
  $: {
    dispatch('change', { datetime });
  }
</script>

<!-- Date component -->
<div class="form-item">
  <DateInput
    invalid="{checkValid && !date}"
    label="{dateLabel}"
    name="dateValue"
    placeholder="Select Date"
    enableTime="{false}"
    dateFormat="n/j/Y"
    minDate="{minToday && today}"
    maxDate="{maxToday && today}"
    on:change="{({ detail: [dateValue] }) => {
      date = dateValue;
    }}"
    bind:date
  />
</div>
<!-- Time component -->
<div class="form-item">
  <DateInput
    invalid="{checkValid && !time}"
    label="{timeLabel}"
    placeholder="Select Time"
    name="timeValue"
    noCalendar="{true}"
    minuteIncrement="30"
    dateFormat="h:i K"
    on:change="{({ detail: [timeValue] }) => {
      time = timeValue;
    }}"
    bind:date="{time}"
  />
</div>
