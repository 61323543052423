import {
  AddressUnitDesignatorTypeEnum,
  BuildingPermitBuildingPermitUsageStandardTypeEnum,
  PropertyDetailOccupancyTypeEnum,
  PropertyDetailEstateTypeEnum,
  PropertyDetailConstructionMethodTypeEnum,
  PlattedLandPlatTypeEnum,
  ParcelIdentificationParcelIdentificationTypeEnum,
} from '@xpanseinc/ui-backend-api';
import { boolean, object, string } from 'yup';
import { zipIsValid } from '../../utils/validation';
import { ResidenceType } from '../../constants/place-order/addSubjectProperty';

export type SubjectProperty = {
  typeOfResidence?: ResidenceType;
  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  state?: string;
  zipCode?: string;
  county?: string;
  buildingPermissionUsageType?: BuildingPermitBuildingPermitUsageStandardTypeEnum;
  buildingPermissionUsageTypeOtherDescription?: string;
  addressUnitDesignationType?: AddressUnitDesignatorTypeEnum;
  estateType?: PropertyDetailEstateTypeEnum;
  estateTypeDescription?: string;
  occupancyType?: PropertyDetailOccupancyTypeEnum;
  constructionMethod?: PropertyDetailConstructionMethodTypeEnum;
  constructionMethodTypeDescription?: string;
  parcelId?: string;
  parcelIdType?: ParcelIdentificationParcelIdentificationTypeEnum;
  platType?: PlattedLandPlatTypeEnum;
  platBlockIdentifier?: string;
  platLotIdentifier?: string;
  pud?: boolean;
};

export function getNewSubjectProperty() {
  return {
    typeOfResidence: null,
    addressLine1: '',
    addressLine2: '',
    city: '',
    state: '',
    zipCode: '',
    county: '',
    buildingPermissionUsageType: null,
    buildingPermissionUsageTypeOtherDescription: '',
    addressUnitDesignationType: null,
    estateType: null,
    estateTypeDescription: '',
    occupancyType: null,
    constructionMethod: null,
    constructionMethodTypeDescription: '',
    parcelId: '',
    parcelIdType: null,
    platType: null,
    platBlockIdentifier: '',
    platLotIdentifier: '',
    pud: false,
  };
}

export const subjectPropertySchema = object().shape({
  typeOfResidence: string()
    .oneOf([null, ...Object.values(ResidenceType)])
    .nullable(),
  addressLine1: string().required('Address Line 1 is required'),
  addressLine2: string(),
  city: string().required('City is required'),
  state: string().required('State is required'),
  zipCode: string()
    .test('zipIsValid', 'zipCode field must have exactly 5 digits', (zipCode) =>
      zipIsValid(zipCode),
    )
    .required('Zip Code is required'),
  county: string().required('County is required'),
  buildingPermissionUsageType: string()
    .required('Building Permit Usage Type is required')
    .oneOf(Object.values(BuildingPermitBuildingPermitUsageStandardTypeEnum)),
  buildingPermissionUsageTypeOtherDescription: string(),
  addressUnitDesignationType: string()
    .nullable()
    .oneOf([null, ...Object.values(AddressUnitDesignatorTypeEnum)]),
  estateType: string()
    .nullable()
    .oneOf([null, ...Object.values(PropertyDetailEstateTypeEnum)]),
  estateTypeDescription: string(),
  occupancyType: string()
    .nullable()
    .oneOf([null, ...Object.values(PropertyDetailOccupancyTypeEnum)]),
  constructionMethod: string()
    .nullable()
    .oneOf([null, ...Object.values(PropertyDetailConstructionMethodTypeEnum)]),
  constructionMethodTypeDescription: string(),
  parcelId: string(),
  parcelIdType: string()
    .nullable()
    .oneOf([null, ...Object.values(ParcelIdentificationParcelIdentificationTypeEnum)]),
  platBlockIdentifier: string(),
  platLotIdentifier: string(),
  pud: boolean(),
});
