export const XPANSE_API_DOMAIN = process.env.VITE_XPANSE_API_DOMAIN;
export const INVOICE_SERVICE_URL = `https://invoicing-service.${XPANSE_API_DOMAIN}`;
export const FULFILLMENT_SERVICE_URL = `https://ui-backend-service.${XPANSE_API_DOMAIN}`;
export const NOTIFICATION_SERVICE_URL = `https://notification-service.${XPANSE_API_DOMAIN}`;
export const REPORT_SERVICE_URL = `https://report-service.${XPANSE_API_DOMAIN}`;
export const TAX_SERVICE_URL = `https://tax-service.${XPANSE_API_DOMAIN}`;
export const TRANSFORMER_SERVICE_URL = `https://transformers-service.${XPANSE_API_DOMAIN}`;
export const USER_SERVICE_URL = `https://authorization-service-v1.${XPANSE_API_DOMAIN}`;
export const PAYMENT_SERVICE_URL = `https://payment-service.${XPANSE_API_DOMAIN}`;
export const EVENT_PROCESSOR_URL = `https://event-processor.${XPANSE_API_DOMAIN}`;

export const AUTH0_API_AUDIENCE = process.env.VITE_AUTH0_API_AUDIENCE;
export const AUTH0_CLIENT_ID = process.env.VITE_AUTH0_CLIENT_ID;
export const AUTH0_DOMAIN = process.env.VITE_AUTH0_DOMAIN;

export const KEYSTONE_CLIENT_URL = process.env.VITE_KEYSTONE_CLIENT_URL;
export const KEYSTONE_VENDOR_URL = process.env.VITE_KEYSTONE_VENDOR_URL;
