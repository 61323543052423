<script>
  import { searchDates } from '../../stores/milestone';

  import {
    ArrowDownIcon,
    ArrowUpIcon,
    DownloadIcon,
    LoaderIcon,
    XIcon,
  } from 'svelte-feather-icons';

  export let total;
  export let downloadBarVisible;
  export let downloading = false;
</script>

<div class="download-bar">
  <div class="orders-selected">
    <button
      class="btn-unstyled basic subtitle"
      on:click="{() => {
        $searchDates = [];
      }}"
    >
      <XIcon size="24" />
      {total} Orders Selected
    </button>
  </div>
  <div class="view-orders">
    <button
      class="btn-unstyled basic subtitle"
      on:click="{() => {
        let orders = downloadBarVisible
          ? document.querySelector('.orders')
          : document.querySelector('.dashboard');
        const y = orders.getBoundingClientRect().top + window.pageYOffset + -120;
        window.scrollTo({ top: y, behavior: 'smooth' });
      }}"
    >
      {#if downloadBarVisible}
        <ArrowDownIcon size="24" />
      {:else}
        <ArrowUpIcon size="24" />
      {/if}
      {downloadBarVisible ? 'View Orders Below' : 'Return To Top'}
    </button>
  </div>
  <div class="download-orders">
    <button class="btn-unstyled basic subtitle" on:click>
      <div class:spin="{downloading}" style="height: 24px;">
        {#if !downloading}
          <DownloadIcon size="24" />
        {:else}
          <LoaderIcon />
        {/if}
      </div>
      Download CSV
    </button>
  </div>
</div>

<style>
  .download-bar {
    max-width: 1040px;
    height: 40px;
    background-color: var(--gray11);
    display: flex;
    flex: 1;
    border-radius: 4px;
    box-shadow: 0 0 2px rgb(19 20 22 / 8%), 0 0 4px rgb(19 20 22 / 12%);
  }

  .download-bar > div {
    display: flex;
  }

  .btn-unstyled {
    display: inline-flex;
    align-items: center;
    gap: 10px;
    font-size: 14px;
    font-weight: 500;
  }

  .download-bar :global(button) {
    color: var(--white);
    padding: 16px 16px;
    max-width: 225px;
    min-width: 130px;
    transition: var(--transitionHover);
  }

  .download-bar :global(button:hover) {
    background-color: var(--gray12);
    border-radius: 4px !important;
  }

  .download-bar :global(button svg) {
    width: 20px;
    height: 20px;
  }

  .orders-selected :global(button) {
    padding: 16px 16px;
  }
</style>
