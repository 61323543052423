export enum InputTypeEnum {
  AttachmentsModule = 'AttachmentsModule',
  BorrowerModule = 'BorrowerModule',
  Checkbox = 'Checkbox',
  ComplexCheckbox = 'ComplexCheckbox',
  ContactsModule = 'ContactsModule',
  DatePicker = 'DatePicker',
  Dropdown = 'Dropdown',
  EmployersModule = 'EmployersModule',
  FeesAndQuotesModule = 'FeesAndQuotesModule',
  PaymentsModule = 'PaymentsModule',
  PropertyModule = 'PropertyModule',
  SubjectProperty = 'SubjectProperty',
  TextArea = 'TextArea',
  TextCurrency = 'TextCurrency',
  TextDecimal = 'TextDecimal',
  TextInteger = 'TextInteger',
  TextString = 'TextString',
}

export enum OrgTypeEnum {
  PLATFORM_OWNER = 'PlatformOwner',
  BROKER = 'Broker',
  WHOLESALE = 'Wholesale',
}

export enum OrgNamesEnum {
  FREEDOM_WHOLESALE = 'Freedom Wholesale',
  FREEDOM_SERVICING = 'Freedom Servicing',
  FREEDOM_CALL_CENTER = 'Freedom Mortgage Call Center',
}

export type Page = {
  [key: string]: Section;
};

export type Section = {
  title: string;
  fields: {
    [key: string]: Field;
  };
};

export type Field = {
  label: string;
  required: boolean;
  type: string;
  options: Object[];
  dependency: string | null;
};

export enum EventIdEnum {
  PLACE_ORDER = 'PlaceOrder',
  FEE_MODIFICATION_APPROVED = 'FeeModificationApproved',
  FEE_MODIFICATION_NO_APPROVED = 'FeeModificationNotApproved',
  PURCHASE_AGREEMENT = 'PurchaseAgreement',
  PAYMENT_REFUND_REQUESTED = 'UpdateStatus/PaymentRefundRequested',
  ENOTE_GENERATED = 'eNoteGenerated',
}

export enum OrderAttribute {
  ENOTE_VAULT_TRANSACTION_ID = 'eclosing.enote.vaultTransactionId',
  ENOTE_DOC_PROFILE_SID = 'eclosing.enote.docProfileSID',
  ENOTE_INDICATOR = 'eclosing.enote.indicator',
  ECLOSING_TYPE = 'eclosing.type',
  ECLOSING_REMOTE_ONLINE_NOTARIZATION_INDICATOR = 'eclosing.remoteOnlineNotarizationIndicator',
}

export enum TaxProvidersEnum {
  ADVANCED_DATA = 'Advanced Data',
  HALCYON = 'Halcyon',
}

export enum TaxProductsEnum {
  PRODUCT_4506_C = '4506-C',
  PRODUCT_8821 = '8821',
}

export enum SsnVerificationAttachmentCode {
  SSA_89 = 'SSA-89',
}
