import { Checkbox, DateInput, Dropdown, TextInput } from '@xpanseinc/ui-components';
import PlaceOrderModule from './modules/PlaceOrderModule.svelte';
import { InputTypeEnum } from '../../constants/place-order/typesAndEnums';

export function getComponent(field) {
  const { type } = field;

  const map = {
    [InputTypeEnum.AttachmentsModule]: null, // TODO: add component when available
    [InputTypeEnum.BorrowerModule]: PlaceOrderModule,
    [InputTypeEnum.Checkbox]: Checkbox,
    [InputTypeEnum.ComplexCheckbox]: null, // TODO: add component when available
    [InputTypeEnum.ContactsModule]: PlaceOrderModule,
    [InputTypeEnum.DatePicker]: DateInput,
    [InputTypeEnum.Dropdown]: Dropdown,
    [InputTypeEnum.EmployersModule]: null, // TODO: add component when available
    [InputTypeEnum.PaymentsModule]: PlaceOrderModule,
    [InputTypeEnum.SubjectProperty]: PlaceOrderModule,
    [InputTypeEnum.TextArea]: TextInput,
    [InputTypeEnum.TextCurrency]: TextInput,
    [InputTypeEnum.TextDecimal]: TextInput,
    [InputTypeEnum.TextInteger]: TextInput,
    [InputTypeEnum.TextString]: TextInput,
  };

  return map[type];
}

export function getProps(field) {
  const { label, options, type } = field;
  let props = {};
  const baseProps = {
    name: label,
    label: field.required ? `${label} *` : label,
  };

  if (type === InputTypeEnum.DatePicker) {
    props = {
      ...baseProps,
      date: '',
    };
  } else if (type === InputTypeEnum.Dropdown) {
    props = {
      ...baseProps,
      placeholder: 'Select One',
      options,
    };
  } else if (type === InputTypeEnum.TextArea) {
    props = {
      ...baseProps,
      multiline: true,
    };
  } else if (
    type === InputTypeEnum.Checkbox ||
    type === InputTypeEnum.TextCurrency ||
    type === InputTypeEnum.TextDecimal ||
    type === InputTypeEnum.TextInteger ||
    type === InputTypeEnum.TextString
  ) {
    props = baseProps;
  }

  return props;
}
