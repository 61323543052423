<script>
  import { Button } from '@xpanseinc/ui-components';
  import { afterUpdate, onMount, onDestroy, createEventDispatcher } from 'svelte';
  import { PlusIcon } from 'svelte-feather-icons';
  import { alphabetically } from '../../utils/alphabetically';
  import Reason from './Reason.svelte';

  const dispatch = createEventDispatcher();

  export let checkValid;
  export let sendEventForm;
  export let config;

  function DefaultReason() {
    return {
      comment: '',
      reasonCode: '',
      reasonCodeDescription: '',
    };
  }

  function addReason() {
    if (!sendEventForm.reasons) {
      sendEventForm.reasons = [];
    }
    sendEventForm.reasons.push(new DefaultReason());
    checkValid = false;
    sendEventForm = sendEventForm;
  }

  function removeReason(i) {
    sendEventForm.reasons.splice(i, 1);
    sendEventForm = sendEventForm;
  }

  onMount(() => {
    addReason();
  });

  afterUpdate(() => {
    if (sendEventForm.reasons.length === 0) {
      addReason();
    }
  });

  onDestroy(() => {
    delete sendEventForm.reasons;
  });
</script>

<h4 class="header-m textDark">Reasons</h4>
{#if config.options.length}
  {#each sendEventForm.reasons as reason, i}
    <div style="margin-bottom: 2rem;">
      <Reason
        checkValid="{checkValid}"
        options="{config.options?.sort(alphabetically)}"
        required="{config?.required}"
        showRemove="{sendEventForm.reasons.length > 1}"
        on:validate="{() => dispatch('validate')}"
        on:remove="{() => removeReason(i)}"
        bind:reason="{reason}"
      />
    </div>
  {/each}
  <div class="form-row">
    <Button icon="{PlusIcon}" label="Add Reason" name="add-reason" on:click="{addReason}" />
  </div>
{/if}
