<script>
  import { Dropdown, Skeleton, Button } from '@xpanseinc/ui-components';
  import {
    ChevronDownIcon,
    ChevronUpIcon,
    BarChart2Icon,
    DownloadCloudIcon,
  } from 'svelte-feather-icons';
  import HorizontalBarChart from './HorizontalBarChart.svelte';
  import { reportApi } from '../stores/api';
  import { searchFilter } from '../stores/orders';
  import { profile } from '../stores/profile';
  import { ReportGroup, selectedReportGroup } from '../stores/reports';

  export let total;
  export let onDownloadCsv = undefined;
  export let downloading = false;

  const showMoreMinimum = 3;
  let reportPromise;
  let showMore = false;
  let showChart = false;
  $selectedReportGroup = 'Hide chart';

  function getReportOptions() {
    const reportOptions = [
      { label: 'Hide chart', value: 'Hide chart' },
      { label: 'Product', value: ReportGroup.Product },
      { label: 'Order step', value: ReportGroup.EventStatus },
    ];
    if (!$profile.hasProviderAccess) {
      reportOptions.push({ label: 'Vendor', value: ReportGroup.Vendor });
    }
    return reportOptions;
  }

  function formatReportResponse(rawReport) {
    const max = rawReport.reduce((actualMax, current) => Math.max(actualMax, current.count), 0);
    return rawReport
      .map((item) => ({
        ...item,
        percentage: Math.ceil((item.count / max) * 100),
      }))
      .sort((a, b) => (a.count > b.count ? -1 : 1));
  }

  async function loadReport(group) {
    let rawReport = await $reportApi.getReportSearchCount({
      searchRequest: {
        ...$searchFilter,
        countBy: group,
      },
    });
    rawReport = rawReport.filter((item) => item !== null);
    return formatReportResponse(rawReport);
  }

  $: reportPromise = loadReport($selectedReportGroup);
  $: $selectedReportGroup, (showMore = false);

  // Show/Hide chart widget based on value of Selected Report Group
  selectedReportGroup.subscribe((value) => {
    if (value != 'Hide chart') {
      showChart = true;
    } else {
      showChart = false;
    }
  });
</script>

<div class="report-viewer">
  <div class="header">
    <h4 class="total header-m header-m--bold">{total.toLocaleString()} Orders</h4>
    <div class="action-container">
      <div class="report-selector">
        <div class="graph-icon">
          <BarChart2Icon size="14" />
        </div>
        <Dropdown
          name="report-selector"
          options="{getReportOptions()}"
          value="{$selectedReportGroup}"
          on:select="{({ detail }) => {
            $selectedReportGroup = detail.value;
          }}"
        />
      </div>
      {#if onDownloadCsv}
        <div class="download-btn">
          <Button
            color="basic"
            label="Download"
            icon="{DownloadCloudIcon}"
            iconLoading="{downloading}"
            name="downloadCSV"
            on:click="{onDownloadCsv}"
          />
        </div>
      {/if}
    </div>
  </div>
  {#if showChart}
    <div class="chart-wrap" class:showMore>
      {#await reportPromise}
        <div class="loading-wrap">
          <Skeleton height="95px" />
        </div>
      {:then report}
        <HorizontalBarChart data="{showMore ? report : report.slice(0, 3)}" />
        {#if report && report.length > showMoreMinimum}
          <div class="show-more-toggle">
            <button
              type="button"
              name="show-more-toggle"
              class="btn-unstyled link link-btn subtitle-small"
              on:click="{() => {
                showMore = !showMore;
              }}"
            >
              {#if showMore}
                <ChevronUpIcon /> Show less
              {:else}
                <ChevronDownIcon /> Show more
              {/if}
            </button>
          </div>
        {/if}
      {/await}
    </div>
  {/if}
</div>

<style>
  .report-viewer {
    position: relative;
  }

  .loading-wrap {
    width: 100%;
    height: 150px;
  }

  .header {
    position: relative;
    width: 100%;
    margin: 1rem 0 0.5rem;
    align-items: center;
    display: flex;
  }

  .action-container {
    margin-left: auto;
    display: inline-flex;
  }

  .download-btn {
    margin-left: 8px;
  }

  .download-btn :global(.btn) {
    font-size: 13px;
    padding: 6px 12px;
  }

  .download-btn :global(.btn svg) {
    width: 14px;
    height: 14px;
  }

  .report-selector {
    position: relative;
    align-self: flex-end;
    display: inline-flex;
  }

  .report-selector .graph-icon {
    position: absolute;
    left: 3px;
    top: 5px;
    z-index: 3;
    color: var(--actionPrimaryDefault);
  }

  .report-selector :global(.dropdown) {
    min-width: 105px;
    width: 105px !important;
    max-height: 32px;
  }

  .report-selector :global(.dropdown.open) {
    max-height: none;
  }

  .report-selector :global(.dropdown .dropdown-inner) {
    border: 1px solid var(--gray1);
    background-color: var(--gray1);
    font-size: 13px;
    max-height: 32px;
    min-height: 32px;
    overflow: hidden;
  }

  .report-selector :global(.dropdown .dropdown-inner .header:hover) {
    background-color: var(--focusLight);
  }

  .report-selector :global(.dropdown.open .dropdown-inner) {
    max-height: none;
    min-height: 32px;
  }

  .report-selector :global(.dropdown-inner svg) {
    width: 14px;
    height: 14px;
  }

  .report-selector :global(.dropdown-inner button) {
    width: 16px;
  }

  .report-selector :global(.dropdown-inner .header) {
    padding: 3px 0 5px 20px;
  }

  .report-selector :global(.dropdown-inner .header .text) {
    font-size: 13px;
    color: var(--actionPrimaryDefault) !important;
    width: 70px;
  }

  .report-selector :global(.dropdown-inner .option-list .text) {
    font-size: 13px;
    line-height: 1.2;
  }

  .report-selector :global(.dropdown.open .dropdown-inner .option-list) {
    padding: 0 4px 4px;
  }

  .report-selector :global(.dropdown-inner .option) {
    padding: 4px 4px;
  }

  .chart-wrap.showMore {
    height: auto;
  }

  .show-more-toggle {
    text-align: center;
    margin-bottom: 1rem;
  }

  .show-more-toggle button {
    align-items: center;
    display: inline-flex;
  }

  .show-more-toggle :global(.feather) {
    height: 18px;
    margin: 0 8px 0 0;
    width: 18px;
  }

  .report-viewer :global(.dropdown) {
    margin-top: 0 !important;
  }
</style>
