<script>
  import { MinusSquareIcon, PlusIcon } from 'svelte-feather-icons';
  import { Button } from '@xpanseinc/ui-components';

  export let isSLAAdmin = false;
</script>

<div class="empty-state-wrapper">
  <MinusSquareIcon size="120" />
  <h3>No SLA Scorecards</h3>
  <p class="body">Administrators can create new cards</p>
  {#if isSLAAdmin}
    <Button color="primary" label="Add SLA Card" icon="{PlusIcon}" name="addSLAcard" on:click />
  {/if}
</div>

<style>
  .empty-state-wrapper {
    min-height: calc(100vh - 270px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .empty-state-wrapper > :global(.feather) {
    color: var(--gray2);
    margin-bottom: 16px;
  }

  .body {
    color: var(--gray11);
    margin-bottom: 16px;
  }
</style>
