import { derived, writable } from 'svelte/store';
import { vendorApi } from './api';

export const vendorId = writable(null);

export const vendorDetails = derived(
  [vendorApi, vendorId],
  ([$vendorApi, $vendorId]) =>
    $vendorId && $vendorApi?.getVendor({ vendorId: $vendorId }).then((details) => details),
);
