<script></script>

<h1><slot /></h1>

<style>
  h1 {
    color: var(--textDark);
    font-weight: 700;
    font-size: 28px;
    line-height: 32px;
    margin-bottom: 2.5rem;
  }
</style>
