<script>
  import { blur } from 'svelte/transition';
  import { formatDate } from '../../../utils/formatDate';
  import { Badge, RadioButton } from '@xpanseinc/ui-components';
  import { form } from '../../../stores/placeOrder';
  export let loan;
  export let i;
</script>

<div class="card-container">
  <div
    class="loan-card"
    class:selected="{loan === $form.loanDetails.existingLoan}"
    in:blur="{{ delay: 25 * i }}"
  >
    <div class="radio-container">
      <div class="radio-button">
        <RadioButton
          name="card"
          options="{[{ label: ' ', value: loan }]}"
          bind:value="{$form.loanDetails.existingLoan}"
        />
      </div>
    </div>
    <div class="content">
      <div class="row">
        <h3 class="loan-number header-l">{loan.loanNumber}</h3>

        <div class="badges">
          <Badge>{loan.productName}</Badge>
          {#if loan.orderStatus == 'Cancelled'}
            <Badge color="warning">{loan.orderStatus}</Badge>
          {:else if loan.orderStatus == 'Completed'}
            <Badge>{loan.orderStatus}</Badge>
          {:else if loan.orderStatus == 'Denied'}
            <Badge color="warning">{loan.orderStatus}</Badge>
          {:else if loan.orderStatus == 'In Progress'}
            <Badge color="updated">{loan.orderStatus}</Badge>
          {:else if loan.orderStatus == 'New'}
            <Badge color="valid">{loan.orderStatus}</Badge>
          {:else if loan.orderStatus == 'Suspended'}
            <Badge color="warning">{loan.orderStatus}</Badge>
          {:else}<Badge>{loan.orderStatus}</Badge>
          {/if}
          <span></span>
        </div>
      </div>

      <div class="content-body">
        <div class="content-left">
          <div class="row">
            <div class="order-data">
              <span class="label">Loan Amount</span>
              {#if loan.loanAmount != null}
                <span>
                  {loan.loanAmount.toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                  })}
                </span>
              {:else}
                <span>N/A</span>
              {/if}
            </div>

            <div class="order-data">
              <span class="label">Subject Property</span>
              <span class="body value">{loan.address.line1}</span>
              {#if loan.address.line2}
                <span class="body value">{loan.address.line2}</span>
              {/if}
              <span class="body value">
                {loan.address.city}
                {loan.address.state}, {loan.address.zip}
              </span>
            </div>
          </div>

          <div class="row">
            <div class="order-data">
              <span class="label">Loan Purpose</span>
              <span class="body value">{loan.loanTypePurpose}</span>
            </div>

            <div class="order-data">
              <span class="label">County</span>
              <span class="body value">{loan.address.county}</span>
            </div>
          </div>
        </div>

        <div class="content-right">
          <div class="row">
            {#if loan.borrowers.length}
              <div class="order-data">
                <span class="label">Borrower</span>
                <span class="body value">{loan.borrowers[0].fullName || ''}</span>
                {#if loan.borrowers[0].dateOfBirth}
                  <span class="label">
                    {formatDate(loan.borrowers[0].dateOfBirth)}
                  </span>
                {/if}
                {#if loan.borrowers[0].ssn4}
                  <span class="label">
                    {`••• - •• - ${loan.borrowers[0].ssn4}`}
                  </span>
                {/if}
              </div>
            {/if}
            {#if loan.borrowers.length > 1}
              {#each loan.borrowers.slice(1) as { fullName, dateOfBirth, ssn4 }}
                <div class="order-data">
                  <span class="label">Co-Borrower</span>
                  <span class="body value">{fullName}</span>
                  {#if dateOfBirth}
                    <span class="label">
                      {formatDate(dateOfBirth)}
                    </span>
                  {/if}
                  {#if ssn4}
                    <span class="label">
                      {`••• - •• - ${ssn4}`}
                    </span>
                  {/if}
                </div>
              {/each}
            {/if}
            <div class="order-data">
              <span class="label">Provider</span>
              <span class="body value">{loan.vendorName}</span>
            </div>
            <div class="order-data">
              <span class="label">Product Name</span>
              <span class="body value">{loan.productSubName}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<style>
  .card-container {
    margin-top: 8px;
  }

  .content {
    padding: 16px;
    background: var(--white);
    border-radius: 0 4px 4px 0;
    color: var(--textDark);
    flex: 1;
  }

  .content-body {
    display: flex;
  }

  .row {
    display: flex;
    margin-bottom: 8px;
  }

  .badges {
    display: flex;
    align-items: center;
  }

  .badges :global(.badge:not(:last-child)) {
    margin-right: 8px;
    margin-bottom: 6px;
  }

  .badges :global(.badge:first-child .dot) {
    display: none;
  }

  .loan-number {
    white-space: nowrap;
    padding-right: 14px;
  }

  .order-data {
    min-width: 125px;
    padding-right: 1rem;
  }

  .label {
    display: block;
  }

  .value {
    display: block;
    padding: 0 8px 3px 0;
    max-width: 200px;
    overflow-wrap: anywhere;
  }

  .radio-button {
    display: flex;
    width: fit-content;
  }

  .radio-button:hover {
    cursor: pointer;
  }

  .loan-card {
    border-radius: 4px;
    box-shadow: 0 0 2px rgba(19, 20, 22, 0.08), 0 0 4px rgba(19, 20, 22, 0.12);
    display: flex;
  }

  .radio-container {
    padding: 24px 16px 16px;
    background-color: var(--gray1);
    border-radius: 4px 0 0 4px;
    transition: background-color 100ms ease-in-out;
  }

  .loan-card .radio-container :global(.radio-button) {
    background-color: transparent !important;
  }

  .loan-card .radio-container :global(.radio-button label) {
    display: none;
  }

  .loan-card.selected .radio-container {
    background-color: var(--actionPrimaryDefault);
  }

  .loan-card.selected .radio-container :global(.checkbox-dummy) {
    color: var(--white);
  }

  .loan-card .label {
    display: block;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: var(--textDefault);
  }

  .loan-card .body.value {
    display: block;
    padding: 0 8px 0 0;
    color: var(--textDark);
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }
</style>
