<script>
  import Layout from '../components/Layout.svelte';
  import { Button } from '@xpanseinc/ui-components';
  import { push } from 'svelte-spa-router';
  import pageTitle from '../utils/pageTitle';

  const title = 'Not Found';
</script>

<svelte:head>
  <title>{pageTitle(title)}</title>
</svelte:head>

<Layout>
  <div class="content" slot="center">
    <h1>Page not found</h1>
    <div class="body">
      We couldn’t find the page you’re looking for. Try going back to the previous page or visit the
      <a href="https://xpansehelp.zendesk.com/">Help Center</a>
      for additional information.
    </div>
    <Button
      label="Home"
      name="home"
      color="primary"
      on:click="{() => {
        push('/orders');
      }}"
    />
  </div>
</Layout>

<style>
  .content {
    display: inline-flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 128px;
  }

  h1 {
    color: var(--gray10);
    margin-bottom: 8px;
  }

  .body {
    max-width: 443px;
    margin-bottom: 36px;
    text-align: center;
  }
</style>
