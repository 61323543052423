<script>
  import { Button } from '@xpanseinc/ui-components';
  import { onDestroy, createEventDispatcher } from 'svelte';
  import { PlusIcon } from 'svelte-feather-icons';
  import { alphabetically } from '../../utils/alphabetically';
  import Attachment from './Attachment.svelte';

  const dispatch = createEventDispatcher();

  export let checkValid;
  export let config;
  export let sendEventForm;
  export let required = false;
  let usageCodeOptions = [];
  let currentEventType = sendEventForm.eventType + sendEventForm.status;
  const sendEventAttachment = [];

  function DefaultAttachment() {
    return {
      fileName: '',
      mimeTypeId: '',
      usageCode: '',
      usageCodeDescription: '',
    };
  }

  function addAttachment() {
    sendEventForm.attachments.push(new DefaultAttachment());
    sendEventForm = sendEventForm;
    checkValid = false;
  }

  function removeAttachment(i) {
    sendEventForm.attachments.splice(i, 1);
    sendEventForm = sendEventForm;
  }

  $: {
    if ((!sendEventForm.attachments || !sendEventForm.attachments.length) && required) {
      sendEventForm.attachments = [];
      addAttachment();
    }
    if (currentEventType !== sendEventForm.eventType + sendEventForm.status) {
      currentEventType = sendEventForm.eventType + sendEventForm.status;
      sendEventAttachment.forEach((attachment) => attachment.resetFiles());
    }
    if (config) {
      usageCodeOptions = config?.usageCode?.options;
    }
  }

  onDestroy(() => {
    delete sendEventForm.attachments;
  });
</script>

<h4 class="header-m textDark">Attachments{config?.file?.required ? '*' : ''}</h4>
{#if usageCodeOptions.length}
  {#if sendEventForm?.attachments?.length}
    {#each sendEventForm.attachments as attachment, i (attachment)}
      <Attachment
        attachment="{attachment}"
        checkValid="{checkValid}"
        required="{config?.file?.required}"
        options="{usageCodeOptions?.sort(alphabetically)}"
        showRemove="{sendEventForm.attachments.length > 1}"
        on:validate="{() => dispatch('validate')}"
        on:remove="{() => removeAttachment(i)}"
        bind:this="{sendEventAttachment[i]}"
      />
    {/each}
  {/if}
  <div class="form-row">
    <Button
      icon="{PlusIcon}"
      label="Add Attachment"
      name="add-attachment"
      on:click="{addAttachment}"
    />
  </div>
{/if}
