import { getDateRange } from '@xpanseinc/ui-components/src/utils/getDateRange';
import { startOfMonth, subDays } from 'date-fns';
import { derived, writable } from 'svelte/store';
import { reportSlaApi } from './api';

export function getDefaultScorecardFilter() {
  const today = new Date();
  const endOfPreviousMonth = subDays(startOfMonth(today), 1);
  const startOfPreviousMonth = startOfMonth(endOfPreviousMonth);
  const [from, to] = getDateRange(startOfPreviousMonth, endOfPreviousMonth);

  return {
    from,
    to,
    state: undefined,
    service: undefined,
    vendor: undefined,
  };
}

export const scorecardFilter = writable(getDefaultScorecardFilter());

export const cards = writable([]);

export const scorecards = derived(
  [reportSlaApi, scorecardFilter],
  async ([$reportSlaApi, $scorecardFilter], set) => {
    const reports = await $reportSlaApi?.reportSLA({ sLARequest: { ...$scorecardFilter } });
    set(reports || []);
  },
);

export const itemOrder = writable(JSON.parse(localStorage.getItem('slaItemOrder')) || []);

itemOrder.subscribe((value) => {
  localStorage.slaItemOrder = JSON.stringify(value);
});
