<script>
  import Layout from '../components/Layout.svelte';
  import { auth } from '../stores/auth';
  import { Button } from '@xpanseinc/ui-components';
  import { push } from 'svelte-spa-router';
  import pageTitle from '../utils/pageTitle';

  const title = 'Permission Denied';
</script>

<svelte:head>
  <title>{pageTitle(title)}</title>
</svelte:head>
<Layout>
  <div class="content" slot="center">
    <h1>Not permitted</h1>
    <div class="body">
      You do not have the necessary permisions to view this page. Please contact your organizations
      administrator or visit the
      <a href="https://xpansehelp.zendesk.com/">Help Center</a>
      for additional information.
    </div>
    <div class="button-row">
      <Button
        label="Sign Out"
        name="sign out"
        color="primary"
        on:click="{() => {
          $auth.logout();
        }}"
      />
      <span>Or</span>
      <Button
        label="Go Home"
        name="home"
        color="secondary"
        on:click="{() => {
          push('/orders');
        }}"
      />
    </div>
  </div>
</Layout>

<style>
  .content {
    display: inline-flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 128px;
  }

  h1 {
    color: var(--gray10);
    margin-bottom: 8px;
  }

  .body {
    max-width: 443px;
    margin-bottom: 36px;
    text-align: center;
  }

  .button-row {
    display: flex;
    align-items: center;
    gap: 8px;
  }
</style>
