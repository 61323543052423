import type { Placement, Theme, ToastType } from 'svelte-toasts/types/common';
import { toasts } from 'svelte-toasts';

interface ToastOptions {
  description?: string;
  title: string;
  uid?: number;
  placement?: Placement;
  type?: ToastType;
  theme?: Theme;
  duration?: number;
  showProgress?: boolean;
  remove?: Function;
  update?: Function;
  onRemove?: Function;
  onClick?: Function;
}

export const popToast = (options: ToastOptions) => {
  const { title, description, uid, placement, type, theme, duration, onClick } = options;
  const toast = toasts.add({
    title,
    description,
    uid,
    placement: placement || 'bottom-left',
    type,
    theme,
    duration: duration || 0,
    onClick,
    onRemove: () => {
      toast.remove();
    },
  });
  return toast;
};
