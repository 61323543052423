import * as yup from 'yup';
import { SsnVerificationAttachmentCode } from '../../../../constants/place-order';

export const loanDetailsSchema = yup.object({
  loanNumber: yup
    .string()
    .length(10, 'Must be exactly 10 characters')
    .matches(/^[0-9]+$/, 'Must contain only numbers')
    .required('Loan Number is required'),
  borrowers: yup.array().required().length(1, 'One Borrower is required'),
});

export const productDetailsSchema = yup.object().shape({
  product: yup.string().required('Product is required'),
  provider: yup.string().required('Provider is required'),
  email: yup.string().required('Email is required'),
  clientContact: yup.string().required('Client Contact is required'),
  attachments: yup.array().test('ssa_89_required', 'SSA 89 document is required', (attachments) => {
    const attachment = attachments?.find(
      (a) => a.usageCode === SsnVerificationAttachmentCode.SSA_89,
    );
    return !attachment || !!attachment?.fileName;
  }),
});
