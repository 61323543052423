import { InputTypeEnum, OrgTypeEnum } from './typesAndEnums';
import type { Page } from './typesAndEnums';
import { mapDropdownOptionsFromEnum } from '../../utils/mapDropdownOptions';

export enum PayerType {
  Lender = 'LENDER',
  Borrower = 'BORROWER',
  Coborrower = 'COBORROWER',
  Contact = 'CONTACT',
  Other = 'OTHER',
}

export enum PaymentType {
  Prepaid = 'PREPAID',
  PostPaid = 'POST_PAID',
  BillTo = 'BILL_TO',
  Other = 'OTHER',
}

export enum PaymentCategoryType {
  AppraisalFee = 'APPRAISAL_FEE',
  ProviderFee = 'PROVIDER_FEE',
  Quote = 'QUOTE',
  Other = 'OTHER',
}

export const baseAddPaymentConfig: Page = {
  payerInformation: {
    title: 'Payer Information',
    fields: {
      type: {
        label: 'Type',
        required: true,
        type: InputTypeEnum.Dropdown,
        options: mapDropdownOptionsFromEnum(PaymentType, true, 'key'),
        dependency: null,
      },
      payer: {
        label: 'Payer',
        required: true,
        type: InputTypeEnum.Dropdown,
        options: [],
        dependency: null,
      },
      firstName: {
        label: 'First Name',
        required: true,
        type: InputTypeEnum.TextString,
        options: null,
        dependency: null,
      },
      lastName: {
        label: 'Last Name',
        required: true,
        type: InputTypeEnum.TextString,
        options: null,
        dependency: null,
      },
      lender: {
        label: 'Lender',
        required: false,
        type: InputTypeEnum.TextString,
        options: null,
        dependency: null,
      },
      email: {
        label: 'Email',
        required: true,
        type: InputTypeEnum.TextString,
        options: null,
        dependency: null,
      },
    },
  },
  requestedPayments: {
    title: 'Requested Payments',
    fields: {
      category: {
        label: 'Category',
        required: true,
        type: InputTypeEnum.Dropdown,
        options: mapDropdownOptionsFromEnum(PaymentCategoryType, true, 'key'),

        dependency: null,
      },
      description: {
        label: 'Description',
        required: true,
        type: InputTypeEnum.TextString,
        options: null,
        dependency: null,
      },
      amount: {
        label: 'Amount',
        required: true,
        type: InputTypeEnum.TextCurrency,
        options: null,
        dependency: null,
      },
    },
  },
};

export const brokerAddPaymentConfig: Page = {
  ...baseAddPaymentConfig,
  payerInformation: {
    ...baseAddPaymentConfig.payerInformation,
    fields: {
      ...baseAddPaymentConfig.payerInformation.fields,
      type: {
        label: 'Type',
        required: true,
        type: InputTypeEnum.Dropdown,
        options: mapDropdownOptionsFromEnum(PaymentType, true, 'key', [
          PaymentType.BillTo,
          PaymentType.PostPaid,
          PaymentType.Other,
        ]),
        dependency: null,
      },
      payer: {
        label: 'Payer',
        required: true,
        type: InputTypeEnum.Dropdown,
        options: mapDropdownOptionsFromEnum(PayerType, true, 'key', [
          PayerType.Lender,
          PayerType.Contact,
          PayerType.Other,
        ]),
        dependency: null,
      },
    },
  },
};

export const wholesaleAddPaymentConfig: Page = {
  ...baseAddPaymentConfig,
  payerInformation: {
    ...baseAddPaymentConfig.payerInformation,
    fields: {
      ...baseAddPaymentConfig.payerInformation.fields,
      payer: {
        label: 'Payer',
        required: true,
        type: InputTypeEnum.Dropdown,
        options: mapDropdownOptionsFromEnum(PayerType, true, 'key', [
          PayerType.Contact,
          PayerType.Other,
        ]),
        dependency: null,
      },
    },
  },
};

export const getPaymentInfoConfig = (orgType) => {
  if (orgType === OrgTypeEnum.BROKER) {
    return brokerAddPaymentConfig;
  }

  if (orgType === OrgTypeEnum.WHOLESALE) {
    return wholesaleAddPaymentConfig;
  }

  return baseAddPaymentConfig;
};
