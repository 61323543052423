<script>
  import { IconButton, TextInput } from '@xpanseinc/ui-components';
  import { emailIsValid, userFullNameIsValid } from '../../utils/validation';
  import { XIcon, ArrowLeftIcon } from 'svelte-feather-icons';
  import ViewHeader from '../ViewHeader.svelte';

  export let title = 'New User';
  export let email = '';
  export let name = '';
  export let subtitle = '';
  export let checkValid = false;

  let emailValid = false;
  let nameValid = false;

  $: {
    if (email) {
      email = email.replace(/\s+/g, '');
      emailValid = emailIsValid(email);
    }

    if (name) {
      nameValid = userFullNameIsValid(name);
    }
  }
</script>

<div class="header">
  <div class="back-header-container">
    <div class="back-container" on:click>
      <ArrowLeftIcon size="24" />
    </div>
    <ViewHeader>{title}</ViewHeader>
  </div>
</div>
{#if subtitle}
  <div class="subtitle-small">Copying {subtitle}</div>
{/if}
<div class="form-row">
  <div>
    <TextInput
      invalid="{(name && !nameValid) || (checkValid && !name)}"
      name="name"
      label="Name*"
      dataTestId="name-input"
      on:change="{(e) => {
        name = e.target.value;
      }}"
      bind:value="{name}"
    />
    <div class="errors">
      {#if name && !nameValid}
        <p class="body-small text-error">
          Name must be at least 2 characters and cannot <br /> contain any special characters
        </p>
      {/if}
      {#if !name && checkValid}
        <p class="body-small text-error">Enter a name</p>
      {/if}
    </div>
  </div>
  <div>
    <TextInput
      invalid="{(email && !emailValid) || (checkValid && !email)}"
      name="email"
      label="Email*"
      bind:value="{email}"
      on:change="{(e) => {
        email = e.target.value;
      }}"
    />
    <div class="errors">
      {#if email && !emailValid}
        <p class="body-small text-error">Invalid email address</p>
      {/if}
      {#if !email && checkValid}
        <p class="body-small text-error">Enter an email address</p>
      {/if}
    </div>
  </div>
</div>

<style>
  .form-row {
    column-gap: 16px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 48px;
  }

  .header {
    display: flex;
    min-height: 82px;
    justify-content: space-between;
  }

  .errors {
    position: absolute;
    padding-top: 8px;
  }

  .back-header-container {
    display: flex;
  }

  .back-container {
    padding: 5px 16px 8px 4px;
    cursor: pointer;
    color: var(--gray11);
    transition: var(--transitionHover);
    height: 26px;
  }

  .back-container:hover {
    color: var(--primaryDefault);
  }
</style>
