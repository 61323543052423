import { PartyContactTypeEnum } from '@xpanseinc/ui-backend-api';
import { boolean, object, string } from 'yup';

export type ContactMethod = {
  id?: string | number;
  contactName?: string;
  method?: string; // Needs to be a dropdown with an enum
  details?: string;
  preferred?: boolean;
};

const individualContactMethodSchema = {
  method: string().required('Contact Method is required'),
  details: string().required('Contact Method Details is required'),
  preferred: boolean(),
};

const entityContactMethodSchema = {
  contactName: string().required('Contact Name is required'),
};

export function getContactMethodSchema(contactType?: PartyContactTypeEnum) {
  if (!contactType || contactType === PartyContactTypeEnum.Individual) {
    return object().shape(individualContactMethodSchema);
  }

  return object().shape({
    ...individualContactMethodSchema,
    ...entityContactMethodSchema,
  });
}
