<script>
  import { DateInput, Dropdown } from '@xpanseinc/ui-components';
  import { onDestroy } from 'svelte';
  import { getLabel } from '../../constants/sendEventDataLabels';
  import { usTimeZoneOptions } from '../../constants/usTimeZoneOptions';

  export let sendEventForm;

  const { timeZone } = Intl.DateTimeFormat().resolvedOptions() || 'America/New_York';
  const today = new Date();
  let date = '';

  if (!sendEventForm.documentExpTime) {
    sendEventForm.documentExpTime = undefined;
    sendEventForm.timezoneId = timeZone;
  }

  onDestroy(() => {
    delete sendEventForm.documentExpTime;
    delete sendEventForm.timezoneId;
  });
</script>

<div class="form-row">
  <div class="form-item">
    <DateInput
      label="{getLabel('documentExpTime')}"
      name="documentExpTime"
      minDate="{today}"
      on:change="{({ detail: [documentExpTime] }) => {
        sendEventForm.documentExpTime = documentExpTime;
      }}"
      bind:date
    />
  </div>
  <div class="form-item">
    <Dropdown
      label="{getLabel('timeZone')}"
      name="timeZone"
      options="{usTimeZoneOptions()}"
      placeholder="Select One"
      bind:value="{sendEventForm.timezoneId}"
    />
  </div>
</div>
