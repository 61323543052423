<script lang="ts">
  import { createEventDispatcher, getContext } from 'svelte';
  import { Button, Dialog } from '@xpanseinc/ui-components';
  import { PlusIcon, Trash2Icon } from 'svelte-feather-icons';
  import uniqueId from 'lodash-es/uniqueId';
  import { startCase, camelCase } from 'lodash-es';
  import FormRow from '../../place-order/FormRow.svelte';
  import FormBlock from '../../place-order/FormBlock.svelte';
  import { validate } from '../../../schemas/validate';
  import { feeModificationPaymentsSchema } from '../../../schemas/send-event/feeModification';
  import { getComponent, getProps } from '../../place-order/renderFromConfig';
  import { form, PlaceOrderProductTypeEnum } from '../../../stores/placeOrder';
  import { feeModificationConfig } from '../../../constants/send-event';
  import { PayerType, PaymentType } from '../../../constants/place-order';
  import type {
    FeeModificationPayments,
    FeeModificationPayment,
  } from '../../../schemas/send-event/feeModification';
  import type { ValidationResult } from '../../../schemas/validate';
  import { sum } from 'lodash';

  export let visible = false;
  export let existingItem = null;
  // @ts-ignore
  const { borrowers, feeModificationAmount } = getContext('addPayment');
  const dispatch = createEventDispatcher();

  let dirtyMap: { [k in keyof FeeModificationPayments]?: boolean } = {};
  let stagedPayment: FeeModificationPayments = getDefaultForm();
  let payerOptions = [];
  let newPaymentEmail = null;

  function getDefaultForm(): FeeModificationPayments {
    return {
      feeModificationAmount,
      feeModificationPayments: [getNewPayment()],
    };
  }

  function savePayment() {
    if (!validationResult.valid) {
      Object.keys(validationResult.errors).forEach((key) => {
        dirtyMap[key] = true;
      });
      stagedPayment.feeModificationPayments.forEach((payment) => {
        const key = `payments[${payment.id}]`;
        Object.keys(payment).forEach((field) => {
          dirtyMap[`${key}.${field}`] = true;
        });
      });
      return;
    }
    if (existingItem) {
      dispatch('edited', { existingItem, newItem: stagedPayment });
    } else {
      dispatch('save', stagedPayment);
    }
  }

  function getNewPayment(isFirst = true): FeeModificationPayment {
    return {
      id: uniqueId(),
      amount: isFirst ? feeModificationAmount : '',
      billToPartyRoleType: null,
      paymentMode: PaymentType.Prepaid,
      billToPartyEmailAddress: '',
      paymentProcessingTrackingNbr: '',
    };
  }

  function removePayment(i) {
    const [removedPayment] = stagedPayment.feeModificationPayments.splice(i, 1);
    stagedPayment = stagedPayment;

    // remove items from the dirtyMap for this contact method
    Object.keys(dirtyMap)
      .filter((k: string) => k.indexOf(`paymentMethods[${removedPayment.id}]`) > -1)
      .forEach((k: string) => {
        delete dirtyMap[k];
      });

    if (stagedPayment.feeModificationPayments.length === 0) {
      stagedPayment.feeModificationPayments = [getNewPayment()];
    }
  }

  function close() {
    dispatch('close');
    dirtyMap = {};
  }

  $: if (visible) {
    if (existingItem) {
      stagedPayment = { ...existingItem };
    }
  }

  $: if (!visible) {
    dirtyMap = {};
    stagedPayment = getDefaultForm();
  }

  // If there is a saved payment and new fees fetched from the API, update the saved payment
  $: if (
    $form.productDetails[PlaceOrderProductTypeEnum.Appraisal].payments[0] &&
    $form.productDetails[PlaceOrderProductTypeEnum.Appraisal].fees.appraisalFee
  ) {
    const { appraisalFee, additionalFees } =
      $form.productDetails[PlaceOrderProductTypeEnum.Appraisal].fees;

    $form.productDetails[
      PlaceOrderProductTypeEnum.Appraisal
    ].payments[0].requestedPayments[0].amount = sum([
      appraisalFee.amount,
      ...additionalFees.map((x) => x.amount),
    ]);
  }

  $: payerOptions = [
    {
      label: 'Lender',
      value: { email: '', type: PayerType.Lender },
    },
    ...borrowers.map((borrower) => {
      return {
        label: `${startCase(camelCase(borrower.partyRole))} (${borrower.fullName})`,
        value: { email: borrower.email, type: PayerType.Borrower },
      };
    }),
  ];

  let validationResult: ValidationResult;
  $: validationResult = validate(feeModificationPaymentsSchema, stagedPayment);
</script>

<Dialog title="Payments" titleTag="Required Fields *" on:close="{close}" size="jumbo" bind:visible>
  <div slot="body">
    <FormRow>
      <FormBlock
        title="Payment Information"
        subtitle="{'Appraisal Fee amount based on the Provider, Product, and Location of the subject property'}"
      >
        {#each stagedPayment.feeModificationPayments as { id }, index (id)}
          <div class="row">
            <svelte:component
              this="{getComponent(feeModificationConfig.paymentInfo.fields.paymentMode)}"
              {...getProps(feeModificationConfig.paymentInfo.fields.paymentMode)}
              value="{stagedPayment.feeModificationPayments[index].paymentMode}"
              invalid="{dirtyMap[`feeModificationPayments[${id}].paymentMode`] &&
                validationResult.errors[`feeModificationPayments[${index}].paymentMode`]}"
              on:blur="{() => {
                dirtyMap[`feeModificationPayments[${id}].paymentMode`] = true;
              }}"
              on:select="{(e) => {
                stagedPayment.feeModificationPayments[index].paymentMode = e.detail.value;
              }}"
            />

            <div style="width: 280px;">
              <svelte:component
                this="{getComponent(feeModificationConfig.paymentInfo.fields.payer)}"
                {...getProps(feeModificationConfig.paymentInfo.fields.payer)}
                options="{[...payerOptions]}"
                value="{stagedPayment.feeModificationPayments[index].payer}"
                invalid="{dirtyMap[`feeModificationPayments[${id}].payer`] &&
                  validationResult.errors[`feeModificationPayments[${index}].payer`]}"
                on:blur="{() => {
                  dirtyMap[`feeModificationPayments[${id}].payer`] = true;
                }}"
                on:select="{(e) => {
                  stagedPayment.feeModificationPayments[index].payer = e.detail.value;
                  stagedPayment.feeModificationPayments[index].billToPartyRoleType =
                    e.detail.value.type;
                  stagedPayment.feeModificationPayments[index].billToPartyEmailAddress =
                    e.detail.value.email;
                }}"
              />
            </div>

            <svelte:component
              this="{getComponent(feeModificationConfig.paymentInfo.fields.amount)}"
              {...getProps(feeModificationConfig.paymentInfo.fields.amount)}
              type="number"
              value="{stagedPayment.feeModificationPayments[index].amount}"
              invalid="{dirtyMap[`feeModificationPayments[${id}].amount`] &&
                validationResult.errors[`feeModificationPayments[${index}].amount`]}"
              on:blur="{() => {
                dirtyMap[`requestedPayments[${id}].amount`] = true;
              }}"
              on:change="{({ target }) => {
                stagedPayment.feeModificationPayments[index].amount = target.value
                  ? parseInt(target.value)
                  : null;
              }}"
            />
          </div>
          <div class="row">
            <svelte:component
              this="{getComponent(
                feeModificationConfig.paymentInfo.fields.billToPartyEmailAddress,
              )}"
              {...getProps(feeModificationConfig.paymentInfo.fields.billToPartyEmailAddress)}
              value="{stagedPayment.feeModificationPayments[index].billToPartyEmailAddress}"
              label="{`${feeModificationConfig.paymentInfo.fields.billToPartyEmailAddress.label}
              ${
                stagedPayment.feeModificationPayments[index].paymentMode === PaymentType.Prepaid
                  ? '*'
                  : ''
              }`}"
              invalid="{dirtyMap[`feeModificationPayments[${id}].billToPartyEmailAddress`] &&
                validationResult.errors[
                  `feeModificationPayments[${index}].billToPartyEmailAddress`
                ]}"
              on:blur="{() => {
                dirtyMap[`requestedPayments[${id}].billToPartyEmailAddress`] = true;
              }}"
              on:change="{({ target }) => {
                stagedPayment.feeModificationPayments[index].billToPartyEmailAddress = target.value;
              }}"
            />

            <svelte:component
              this="{getComponent(
                feeModificationConfig.paymentInfo.fields.paymentProcessingTrackingNbr,
              )}"
              {...getProps(feeModificationConfig.paymentInfo.fields.paymentProcessingTrackingNbr)}
              value="{stagedPayment.feeModificationPayments[index].paymentProcessingTrackingNbr}"
              on:change="{({ target }) => {
                stagedPayment.feeModificationPayments[index].paymentProcessingTrackingNbr =
                  target.value;
              }}"
            />
            {#if stagedPayment.feeModificationPayments.length > 1 && index > 0}
              <button
                class="remove-contact-method btn-unstyled"
                on:click="{() => removePayment(index)}"
              >
                <Trash2Icon size="24" />
              </button>
            {/if}
          </div>
          <hr />
        {/each}
      </FormBlock>
    </FormRow>

    <Button
      color="basic"
      label="Add Approved Payments"
      name="addRequestedPayments"
      icon="{PlusIcon}"
      on:click="{() => {
        stagedPayment.feeModificationPayments = [
          ...stagedPayment.feeModificationPayments,
          getNewPayment(false),
        ];
      }}"
    />
  </div>
  <footer slot="footer">
    <Button name="close-recording-dialog" label="Close" on:click="{close}" />
    <Button
      color="primary"
      name="close-recording-dialog"
      label="Save"
      disabled="{!validationResult.valid}"
      on:click="{savePayment}"
    />
  </footer>
</Dialog>
