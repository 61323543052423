<script>
  import { Dropdown, IconButton, TextInput, UploadInput } from '@xpanseinc/ui-components';
  import { XIcon } from 'svelte-feather-icons';
  import { createEventDispatcher } from 'svelte';
  import { getLabel } from '../../constants/sendEventDataLabels';

  const dispatch = createEventDispatcher();

  export let attachment;
  export let checkValid;
  export let required;
  export let options = [];
  export let showRemove;

  let files = [];
  let uploadInput;
  let sizeExceeded = false;

  let xmlCodes = ['FINALINVXML', 'MISMO26', 'MISMOGSE26', 'GSE26MISMO', '26MISMO'];
  let isXML;

  export const resetFiles = () => uploadInput.resetFiles();

  const handleUpdate = () => {
    dispatch('update', null);
  };

  const handleUsageCodeChange = (e) => {
    const { label, value } = e.detail;
    attachment.usageCode = value;
    attachment.usageCodeDescription = label;
    isXML = xmlCodes.includes(attachment.usageCode);
    handleUpdate();
  };

  $: {
    sizeExceeded = files[0]?.size / 1000000 > 20;
    if (files?.length && attachment.fileName !== files[0].name) {
      attachment.fileName = files[0].name;
      attachment.mimeTypeId = isXML ? 'xml' : 'pdf';
      attachment.file = files.item(0);
      handleUpdate();
    }
    if (files?.length === 0 && attachment.fileName !== '') {
      attachment.fileName = '';
      attachment.mimeTypeId = '';
      delete attachment.file;
      handleUpdate();
    }
  }
</script>

<div class="form-row">
  <div class="form-item">
    <Dropdown
      invalid="{checkValid && required && !attachment.usageCode}"
      label="{getLabel('usageCode')}{required ? '*' : ''}"
      name="attachment-usageCode"
      options="{options}"
      placeholder="Select One"
      disabled="{attachment?.disabled || false}"
      value="{attachment.usageCode}"
      on:select="{handleUsageCodeChange}"
    />
  </div>
  <div class="form-item two-col has-action-col">
    <UploadInput
      invalid="{(checkValid && required && !files.length) || sizeExceeded}"
      accept="{isXML ? 'text/xml' : 'application/pdf'}"
      acceptMsg="{isXML ? 'xml' : 'pdf'}"
      label="{getLabel('files')}{attachment?.disabled || required ? '*' : ''}"
      on:remove="{() => {
        sizeExceeded = false;
        files = [];
        attachment.fileName = '';
        attachment.mimeTypeId = '';
        delete attachment.file;
        handleUpdate();
      }}"
      bind:this="{uploadInput}"
      bind:files
    />
    {#if sizeExceeded}
      <p class="body text-error">File size cannot be greater than 20MB</p>
    {/if}
  </div>
  <div class="form-item action-col">
    {#if showRemove && !attachment?.disabled}
      <IconButton
        icon="{XIcon}"
        title="Remove Attachment"
        name="remove-attachment"
        on:click="{() => dispatch('remove')}"
      />
    {/if}
  </div>
</div>
{#if attachment.usageCode.toUpperCase() === 'OTHER'}
  <div class="form-row">
    <div class="form-item two-col">
      <TextInput
        invalid="{checkValid && attachment.usageCodeDescription === ''}"
        label="{getLabel('usageCodeDescription')}*"
        name="usageCodeDescription"
        on:keyup="{(e) => {
          attachment.usageCodeDescription = e.target.value;
        }}"
      />
    </div>
  </div>
{/if}

<style>
  .text-error {
    margin: 8px 16px 0;
  }
</style>
