import { XPANSE_API_DOMAIN } from './const';

export default {
  home: '/',
  deliveryErrors: '/delivery-errors',
  milestoneDashboard: '/milestone-dashboard',
  billedEvents: '/billed-events',
  orders: '/orders',
  advancedSearch: '/orders/filters',
  orderDetails: '/order-detail/:orderId',
  orderDetail: '/order-detail/',
  sendEvent: '/order-detail/:orderId/send-event',
  placeOrder: '/place-order',
  placeOrderLoanDetails: '/place-order/loan-details',
  placeOrderProductDetails: '/place-order/product-details',
  slaScorecards: '/sla-scorecard',
  userManagement: '/user-management',
  addUser: '/user-management/add-user/',
  editUser: '/user-management/edit-user/:userId',
  copyUser: '/user-management/copy-user/:userId',
  allocation: `https://allocation-engine-ui.${XPANSE_API_DOMAIN}`,
  support: 'https://xpansehelp.zendesk.com/',
  // catch all must always be last
  notFound: '*',
};
