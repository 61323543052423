<script>
  import { SearchIcon } from 'svelte-feather-icons';

  export let title = 'No results';
  export let message = 'Try changing your search filters';
</script>

<div class="empty-state-screen">
  <SearchIcon size="120" />
  <h3>{title}</h3>
  <p class="body">{message}</p>
</div>

<style>
  .empty-state-screen {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .empty-state-screen > :global(.feather) {
    color: var(--gray2);
    margin-bottom: 16px;
  }

  .body {
    color: var(--gray11);
    margin-bottom: 16px;
  }
</style>
