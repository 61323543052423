<script>
  import { MilestoneEventDateStatusEnum } from '@xpanseinc/report-service-api';
  import { searchDates, selectedMilestones } from '../../stores/milestone';

  export let label;
  export let code;
  export let counts = [];
  export let range;
  export let rowIndex;
  export let pageNumber;

  let totals = [];
  let historicalEvent = counts.find((count) => count.isHistorical);
  totals.push(historicalEvent || { total: 0 });

  $: {
    if (historicalEvent) {
      counts.splice(counts.indexOf(historicalEvent), 1);
    }
    let countIndex = 0;
    counts.sort((a, b) => a.date.valueOf() - b.date.valueOf());
    range.forEach((day) => {
      if (countIndex <= counts.length - 1) {
        if (counts[countIndex].date.getUTCDate() === day.getUTCDate()) {
          totals = [...totals, counts[countIndex++]];
        } else {
          totals = [...totals, { total: 0 }];
        }
      }
    });
  }

  $: if (!$searchDates.length) {
    $selectedMilestones[rowIndex] = {};
  }
</script>

<div class="row">
  <div class="event-label body">{label}</div>
  <div class="counts">
    {#each totals as count, i}
      <div class="count">
        {#if count.total}
          <button
            class="btn-unstyled count-btn"
            class:warning-threshold="{count.status === MilestoneEventDateStatusEnum.Warning}"
            class:safe-threshold="{count.status === MilestoneEventDateStatusEnum.Safe}"
            class:before-milestone="{count.status === MilestoneEventDateStatusEnum.Notapplicable}"
            class:selected="{$selectedMilestones[rowIndex][i]}"
            on:click="{() => {
              const milestone = { code: code, date: count.date, isHistorical: count.isHistorical };
              let index = $searchDates.findIndex(
                (element) => JSON.stringify(element) === JSON.stringify(milestone),
              );
              if (index < 0) {
                $searchDates = [...$searchDates, milestone];
                $selectedMilestones[rowIndex][i] = true;
              } else {
                $selectedMilestones[rowIndex][i] = false;
                $searchDates.splice(index, 1);
                $searchDates = $searchDates;
              }
              pageNumber = 0;
            }}">{count.total}</button
          >
        {/if}
      </div>
    {/each}
  </div>
</div>

<style>
  .row {
    min-height: 28px;
    max-width: 1040px;
    margin: 8px 0 0;
    display: flex;
    flex: 1;
    align-items: center;
  }

  .event-label {
    width: 168px;
    padding-right: 4px;
    line-height: 1.35rem;
  }

  .counts {
    display: flex;
    flex: 1;
    align-items: center;
  }

  .count {
    width: 76px;
    display: flex;
    justify-content: center;
  }

  .count-btn {
    width: 68px;
    border-radius: 4px;
    height: 28px;
    border: 2px solid transparent;
    transition: var(--transitionHover);
  }

  .count-btn:hover:not(.selected) {
    border: 4px solid var(--actionPrimaryHover);
    box-sizing: border-box;
    border-radius: 4px;
  }

  .selected {
    border: 4px solid var(--actionPrimaryDefault);
    box-sizing: border-box;
    border-radius: 4px;
  }

  .warning-threshold {
    background: var(--actionDestructHover);
    color: var(--white);
  }

  .safe-threshold {
    background: var(--positiveLight);
  }

  .before-milestone {
    background: var(--gray2);
  }
</style>
