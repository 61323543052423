<script>
  import { Checkbox, Tooltip } from '@xpanseinc/ui-components';
  import { formatDate } from '../../utils/formatDate';
  import {
    BarChart2Icon,
    DollarSignIcon,
    FileIcon,
    LockIcon,
    UsersIcon,
  } from 'svelte-feather-icons';
  import { startCase, camelCase } from 'lodash-es';
  import Badge from './Badge.svelte';
  import { link } from 'svelte-spa-router';
  import urls from '../../urls';

  export let user = {};
  export let checked = false;
  export let disabledCheckbox = false;

  const adminRoleIcons = {
    ORDERADMIN: FileIcon,
    REPORTADMIN: BarChart2Icon,
    INVOICING: DollarSignIcon,
    USERADMIN: UsersIcon,
  };

  function setAdminRoleIcons(role) {
    return adminRoleIcons[role] || UsersIcon;
  }

  function setStatusIcons(status) {
    if (status === 'BruteForceLocked' || status === 'Locked') return LockIcon;
    return null;
  }
</script>

{#if user?.id}
  <div class="card" data-testid="{`${user?.id}-card`}">
    <Checkbox
      dataTestId="{`${user?.id}-checkbox`}"
      disabled="{disabledCheckbox}"
      name="userCard"
      bind:checked
    />

    <div class="name-column">
      {#if user?.name}
        <a
          class="btn-unstyled h5 name"
          data-testid="{`${user?.id}-${user?.name}`}"
          href="{urls.editUser.split(':')[0] + user.id}"
          use:link
          >{user.name}
        </a>
      {/if}
      {#if user?.email}
        <div class="email" data-testid="{`${user?.id}-${user?.email}`}">{user.email}</div>
      {/if}
    </div>
    <div class="products-column" data-testid="{`${user?.id}-product-permissions`}">
      {#if user?.productPermissions?.length}
        {#each user?.productPermissions.filter((permission) => permission.permission !== undefined) as product}
          <Tooltip text="{product.permission.name}">
            <Badge label="{product.label}" class="general" />
          </Tooltip>
        {/each}
      {/if}
    </div>
    <div class="status-column" data-testid="{`${user?.id}-status`}">
      {#if user?.status}
        <Badge
          label="{startCase(camelCase(user?.status))}"
          icon="{setStatusIcons(user?.status)}"
          class="{user?.status?.toLowerCase()}"
        />
      {/if}
    </div>
    <div class="login-column" data-testid="{`${user?.id}-${user?.lastLogin}`}">
      {#if user?.lastLogin?.valueOf()}
        {formatDate(new Date(user.lastLogin))}
      {/if}
    </div>
    <div class="admin-column" data-testid="{`${user?.id}-admin-permissions`}">
      {#if user?.adminPermissions?.length}
        {#each user?.adminPermissions.filter((permission) => permission.permission !== undefined) as role}
          <Badge
            label="{role.label.split(' ')[0]}"
            icon="{setAdminRoleIcons(role.name)}"
            class="border"
          />
        {/each}
      {/if}
    </div>
  </div>
{/if}

<style>
  .card,
  .card > div:not(.name-column) {
    align-items: center;
    display: inline-flex;
    gap: 8px;
    width: 100%;
  }

  .card > div {
    flex-wrap: wrap;
  }

  .name-column {
    display: flex;
    flex-direction: column;
    max-width: 240px;
    width: 100%;
  }

  .name {
    color: var(--actionPrimaryDefault);
    overflow-wrap: anywhere;
    text-align: left;
    text-decoration: none;
  }

  .name:hover,
  .name:active {
    text-decoration: underline;
    color: var(--actionPrimaryHover);
  }

  .email {
    font-weight: 400;
    font-size: 0.75rem;
    color: var(--textDefault);
    line-height: 1.2;
    max-width: 200px;
    overflow-wrap: anywhere;
  }

  .products-column {
    padding-right: 8px;
  }

  .status-column {
    max-width: 154px;
  }

  .login-column {
    color: #25272b;
    font-size: 16px;
    font-weight: 400;
    line-height: 18.75px;
    max-width: 118px;
  }

  .card :global(.checkbox) {
    padding: 0 0 !important;
    background-color: transparent !important;
  }
</style>
