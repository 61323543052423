<script>
  import { Button, Checkbox } from '@xpanseinc/ui-components';
  import { AUTH0_DOMAIN } from '../../const';
  import { createEventDispatcher } from 'svelte';
  import UserCard from './UserCard.svelte';
  export let users = [];
  export let selectedUsers;
  export let page;
  export let hideShowMore;

  const dispatch = createEventDispatcher();
  const isAuth0DevEnv = AUTH0_DOMAIN === 'xpanse-dev.us.auth0.com';

  function selectAll() {
    function toggleSelectedUsers(checked) {
      users.forEach((user) => {
        selectedUsers[user?.id] = checked;
      });
    }
    if (Object.values(selectedUsers).includes(false)) {
      toggleSelectedUsers(true);
    } else {
      toggleSelectedUsers(false);
    }
  }

  function getNextPage() {
    page++;
    dispatch('search');
  }

  let maxSelectedUsersInDev = 2;

  $: numberOfSelectedUsers = Object.values(selectedUsers).filter((user) => user === true).length;
  $: disableUserCardCheckboxes = numberOfSelectedUsers === maxSelectedUsersInDev && isAuth0DevEnv;
</script>

<div class="user-list">
  <div class="header">
    {#if !isAuth0DevEnv}
      <Checkbox
        name="select-all"
        checked="{!Object.values(selectedUsers).includes(false)}"
        on:change="{selectAll}"
      />
    {/if}
    <div class="subtitle name" class:no-select-all="{isAuth0DevEnv}">Name</div>
    <div class="subtitle products">Products</div>
    <div class="subtitle status">Status</div>
    <div class="subtitle login">Last login</div>
    <div class="subtitle admin">Admin roles</div>
  </div>
  {#if users.length}
    {#each users as user}
      <div class="user">
        <UserCard
          user="{user}"
          disabledCheckbox="{disableUserCardCheckboxes && !selectedUsers[user.id]}"
          bind:checked="{selectedUsers[user.id]}"
        />
      </div>
    {/each}
  {/if}
  {#if !hideShowMore}
    <div class="show-more">
      <Button color="primary" label="Show more" name="show-more" on:click="{getNextPage}" />
    </div>
  {/if}
</div>

<style>
  .user-list {
    background-color: white;
    padding: 1.5rem;
    margin-top: 8px;
    border-radius: 4px;
    box-shadow: 0 0 2px rgba(19, 20, 22, 0.08), 0 0 4px rgba(19, 20, 22, 0.12);
    min-width: 1000px;
  }

  .header {
    gap: 8px;
    margin: 0 0 12px;
    width: 100%;
  }

  .header,
  .header > div {
    align-items: center;
    display: inline-flex;
    width: 100%;
  }

  .name {
    max-width: 240px;
  }

  /* TODO: add styles back when the associated columns are added back to the table */

  /* .products,
  .admin {
    max-width: 220px;
  }
  */

  .status {
    max-width: 154px;
  }

  .login {
    max-width: 118px;
  }

  .subtitle {
    line-height: 18.75px;
  }

  .user::before {
    border-top: 1px solid #dee0e3;
    content: '';
    display: flex;
    margin: 12px 0 15px;
    width: 100%;
  }

  .no-select-all {
    margin-left: 46px;
  }

  .show-more {
    display: flex;
    justify-content: center;
    margin-top: 32px;
  }
</style>
