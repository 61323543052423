import { array, object, string } from 'yup';
import { emailIsValid } from '../../../utils/validation';
import { OrgTypeEnum } from '../typesAndEnums';
import { loanNumberValidationRules } from '../../../schemas/place-order';

const paymentPurpose = string().required('Payment purpose is required');
const firstName = string().required('First name is required');
const lastName = string().required('Last name is required');
const email = string()
  .test('emailIsValid', 'email field must be a valid email address', (value) => emailIsValid(value))
  .required();

const detailsSchema = {
  paymentPurpose,
  provider: string(),
  loanNumber: loanNumberValidationRules,
};

const paymentInformationSchema = {
  paymentType: string().required('Payment type is required'),
  feeAmount: string()
    .matches(/(\d+\.?\d+)/)
    .required('Fee amount is required'),
  email,
  firstName,
  lastName,
};

const addressSchema = {
  line1: string().required('Line 1 is required'),
  line2: string(),
  zip: string().required('Zip is required'),
  city: string().required('City is required'),
  state: string().required('State is required'),
  county: string().required('County is required'),
};

const defaultSchema = object().shape({
  ...detailsSchema,
  ...paymentInformationSchema,
  ...addressSchema,
});

const wholesalePaymentSchema = {
  'VA Broker Payment': object().shape({
    paymentPurpose,
    payees: array().min(1, 'At least one payee is required'),
    transactionCode: string().required('Transaction Code is required'),
  }),
  default: defaultSchema,
};
export function getSchema(orgType: OrgTypeEnum, paymentPurpose: string) {
  switch (orgType) {
    case OrgTypeEnum.WHOLESALE: {
      return wholesalePaymentSchema[paymentPurpose] || wholesalePaymentSchema.default;
    }
    default: {
      return defaultSchema;
    }
  }
}
