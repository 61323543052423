<script>
  import { createEventDispatcher } from 'svelte';
  import { Button, Dropdown, TextInput, IconButton } from '@xpanseinc/ui-components';
  import { PlusIcon, Trash2Icon } from 'svelte-feather-icons';
  import { mapDropdownOptions } from '../utils/mapDropdownOptions';
  import uniqueId from 'lodash-es/uniqueId';

  export let methods = [getNewContactMethod()];

  const dispatch = createEventDispatcher();

  function getNewContactMethod() {
    return {
      id: uniqueId(),
      method: null,
      details: '',
    };
  }

  function removeContactMethod(i) {
    methods.splice(i, 1);
    methods = methods;

    if (methods.length === 0) {
      methods = [getNewContactMethod()];
    }
  }

  $: dispatch('change', methods);
</script>

{#each methods as { id }, index (id)}
  <div class="form-row">
    <div class="form-item">
      <Dropdown
        label="Method"
        name="method"
        options="{mapDropdownOptions(['Email', 'Phone'])}"
        placeholder="Select One"
        on:select="{(e) => {
          methods[index].method = e.detail.value;
        }}"
      />
    </div>
    <div class="form-item">
      <TextInput
        label="Details"
        name="orderIdentifier"
        on:change="{(e) => {
          methods[index].details = e.target.value;
        }}"
      />
    </div>
    {#if index > 0}
      <div class="form-item">
        <div>
          <IconButton
            icon="{Trash2Icon}"
            color="primary"
            name="close"
            on:click="{() => removeContactMethod(index)}"
          />
        </div>
      </div>
    {/if}
  </div>
{/each}

<div class="form-row">
  <Button
    color="basic"
    label="Add Contact Method"
    name="addContactMethod"
    icon="{PlusIcon}"
    on:click="{() => {
      methods = [...methods, getNewContactMethod()];
    }}"
  />
</div>
