import { array, number, object, string } from 'yup';
import { emailIsValid } from '../../utils/validation';
import { PaymentType } from '../../constants/place-order/addPaymentConfig';

export type FeeModificationPayments = {
  feeModificationAmount: number;
  feeModificationPayments: FeeModificationPayment[];
};
export type FeeModificationPayment = {
  id?: string;
  amount?: number;
  payer?: {
    email?: string;
    type?: string;
  };
  billToPartyRoleType?: string;
  billToPartyEmailAddress?: string;
  paymentMode?: PaymentType;
  paymentProcessingTrackingNbr?: string;
};

export const feeModificationPayment = object().shape({
  id: string(),
  billToPartyRoleType: string().required('Payer Type is required'),
  billToPartyEmailAddress: string().when(['paymentMode'], {
    is: (paymentMode) => paymentMode === PaymentType.Prepaid,
    then: string()
      .test('emailIsValid', 'email field must be a valid email address', (email) =>
        emailIsValid(email),
      )
      .required(),
  }),
  paymentMode: string().required('Payment Type is required'),
  paymentProcessingTrackingNbr: string(),
  amount: number().positive().required(),
  payer: object().required(),
});

export const feeModificationPaymentsSchema = object().shape({
  feeModificationAmount: number().positive().required(),
  feeModificationPayments: array().of(feeModificationPayment),
});
