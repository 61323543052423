<script>
  import { createEventDispatcher } from 'svelte';
  import { Dropdown } from '@xpanseinc/ui-components';
  import DateTime from './DateTime.svelte';
  import { getLabel } from '../../constants/sendEventDataLabels';
  import { usTimeZoneOptions } from '../../constants/usTimeZoneOptions';
  import { zonedTimeToUtc } from 'date-fns-tz';

  const dispatch = createEventDispatcher();

  export let minToday = false;
  export let maxToday = false;
  export let checkValid = false;
  export let disablePastSelection = false;
  export let dateLabel;
  export let timeLabel;
  export let timeZone = '';

  let _datetime;

  const getDate = () => {
    return zonedTimeToUtc(_datetime, timeZone);
  };

  $: datetime = _datetime && timeZone ? getDate() : undefined;

  $: {
    dispatch('change', { datetime });
  }
</script>

<!-- DateTime component -->

<DateTime
  minToday="{minToday}"
  maxToday="{maxToday}"
  checkValid="{checkValid}"
  disablePastSelection="{disablePastSelection}"
  timeLabel="{timeLabel}"
  dateLabel="{dateLabel}"
  on:change="{(e) => {
    _datetime = e.detail.datetime;
  }}"
/>

<div class="form-item">
  <Dropdown
    invalid="{checkValid && !timeZone}"
    label="{getLabel('timeZone')}*"
    name="timeZone"
    options="{usTimeZoneOptions()}"
    placeholder="Select One"
    on:select="{(e) => {
      timeZone = e.detail.value;
    }}"
    bind:value="{timeZone}"
  />
</div>
