import {
  LoanIdentifierLoanIdentifierTypeEnum,
  RefinanceRefinanceCashOutDeterminationTypeEnum,
  TermsOfLoanLienPriorityTypeEnum,
  TermsOfLoanLoanPurposeTypeEnum,
  TermsOfLoanMortgageTypeEnum,
} from '@xpanseinc/ui-backend-api';
import type { Page } from './typesAndEnums';
import { InputTypeEnum, OrgTypeEnum } from './typesAndEnums';
import { mapDropdownOptionsFromEnum } from '../../utils/mapDropdownOptions';
import { PlaceOrderProductTypeEnum } from '../../stores/placeOrder';

export enum LoanPurpose {
  PURCHASE = 'Purchase',
  REFINANCE = 'Refinance',
  MORTGAGE_MODIFICATION = 'Mortgage Modification',
  OTHER = 'Other',
  UNKNOWN = 'Unknown',
}

export enum LoanIdentifierType {
  AGENCY_CASE = 'Agency Case',
  INVESTOR_CONTRACT = 'Investor Contract',
  INVESTOR_COMMITMENT = 'Investor Commitment',
  INVESTOR_LOAN = 'Investor Loan',
  LENDER_LOAN = 'Lender Loan',
  LOAN_PRICE_QUOTE = 'Loan Price Quote',
  MERS_MIN = 'MERS_MIN',
  MIRATE_QUOTE = 'MIRate Quote',
  NEW_SERVICER_LOAN = 'New Servicer Loan',
  POOL_ISSUER_LOAN = 'Pool Issuer Loan',
  PRICE_RESPONSE = 'Price Response',
  SELLER_RESPONSE = 'Seller Response',
  SERVICER_LOAN = 'Servicer Loan',
  SERVICER_WORKOUTCASE = 'Servicer Workout Case',
  SUBSERVICER_LOAN = 'Subservicer Loan',
  UNIVERSAL_LOAN = 'Universal Loan',
  WHOLESALE_LENDER_LOAN = 'Wholesale Lender Loan',
  OTHER = 'Other',
}

export enum GovernmentRefinanceType {
  OTHER = 'Other',
  STREAMLINE_WITHOUT_APPRAISAL = 'Streamline Without Appraisal',
  STREAMLINE_WITH_APPRAISAL = 'Streamline With Appraisal',
  CASH_OUT_VA = 'Cash Out VA',
  FULL_DOCUMENTATION = 'Full Documentation',
  INTEREST_RATE_REDUCTION_REFINANCE_LOAN = 'Interest Rate Reduction Refinance Loan',
}

export enum LienPriority {
  FIRST_LIEN = 'First Lien',
  SECOND_LIEN = 'Second Lien',
  THIRD_LIEN = 'Third Lien',
  FOURTH_LIEN = 'Fourth Lien',
  OTHER = 'Other',
}

const baseLoanDetailsConfig: Page = {
  loanInformation: {
    title: 'General Information',
    fields: {
      loanNumber: {
        label: 'Loan Number',
        required: true,
        type: InputTypeEnum.TextString,
        options: null,
        dependency: null,
      },
      loanPurpose: {
        label: 'Loan Purpose',
        required: true,
        type: InputTypeEnum.Dropdown,
        options: mapDropdownOptionsFromEnum(TermsOfLoanLoanPurposeTypeEnum, true, 'key', [
          TermsOfLoanLoanPurposeTypeEnum.PartialClaim,
        ]),
        dependency: null,
      },
      loanIdType: {
        label: 'Loan Identifier Type',
        required: true,
        type: InputTypeEnum.Dropdown,
        options: mapDropdownOptionsFromEnum(LoanIdentifierLoanIdentifierTypeEnum, true, 'key'),
        dependency: null,
      },
      govtRefinanceType: {
        label: 'Government Refinance Type',
        required: false,
        type: InputTypeEnum.Dropdown,
        options: [],
        dependency: null,
      },
      mortgageLoanType: {
        label: 'Mortgage/Loan Type',
        required: true,
        type: InputTypeEnum.Dropdown,
        options: mapDropdownOptionsFromEnum(TermsOfLoanMortgageTypeEnum, true, 'key'),
        dependency: null,
      },
      fhaCaseNumber: {
        label: 'FHA Case Number',
        required: false,
        type: InputTypeEnum.TextString,
        options: null,
        dependency: 'Loan_Type: FHA',
      },
    },
  },
  financialInformation: {
    title: 'Financial Information',
    fields: {
      loanAmount: {
        label: 'Loan Amount',
        required: true,
        type: InputTypeEnum.TextCurrency,
        options: null,
        dependency: null,
      },
      salesContractAmount: {
        label: 'Sales Contract Amount',
        required: false,
        type: InputTypeEnum.TextCurrency,
        options: null,
        dependency: null,
      },
      cashOutType: {
        label: 'Cash Out Determination Type',
        required: false,
        type: InputTypeEnum.Dropdown,
        options: mapDropdownOptionsFromEnum(
          RefinanceRefinanceCashOutDeterminationTypeEnum,
          true,
          'key',
        ),
        dependency: null,
      },
      cashOutAmount: {
        label: 'Cash Out Amount',
        required: false,
        type: InputTypeEnum.TextCurrency,
        options: null,
        dependency: null,
      },
      lienPriority: {
        label: 'Lien Priority',
        required: false,
        type: InputTypeEnum.Dropdown,
        options: mapDropdownOptionsFromEnum(TermsOfLoanLienPriorityTypeEnum, true, 'key'),
        dependency: null,
      },
      cltv: {
        label: 'CLTV (%)',
        required: false,
        type: InputTypeEnum.TextDecimal,
        options: null,
        dependency: null,
      },
      ltv: {
        label: 'LTV (%)',
        required: false,
        type: InputTypeEnum.TextDecimal,
        options: null,
        dependency: null,
      },
      piggyback: {
        label: 'Piggyback Loan',
        required: false,
        type: InputTypeEnum.Checkbox,
        options: null,
        dependency: null,
      },
      govtBondFinance: {
        label: 'Government Bond Finance',
        required: false,
        type: InputTypeEnum.Checkbox,
        options: null,
        dependency: null,
      },
      employeeLoan: {
        label: 'Employee Loan Program',
        required: false,
        type: InputTypeEnum.Checkbox,
        options: null,
        dependency: null,
      },
      serviceExpiditeIndicator: {
        label: 'Service Expedite Indicator',
        required: false,
        type: InputTypeEnum.Checkbox,
        options: null,
        dependency: null,
      },
    },
  },
  subjectProperty: {
    title: 'Subject Property',
    fields: {
      subjectProperty: {
        label: 'Add Subject Property',
        required: true,
        type: InputTypeEnum.SubjectProperty,
        options: null,
        dependency: null,
      },
    },
  },
  borrowers: {
    title: 'Borrowers',
    fields: {
      borrowerInformation: {
        label: 'Add Borrower Information',
        required: true,
        type: InputTypeEnum.BorrowerModule,
        options: null,
        dependency: null,
      },
    },
  },
  contacts: {
    title: 'Property Contacts',
    fields: {
      contacts: {
        label: 'Add Contacts',
        required: false,
        type: InputTypeEnum.ContactsModule,
        options: null,
        dependency: null,
      },
    },
  },
  attachments: {
    title: 'Attachments',
    fields: {
      attachments: {
        label: 'Add Attachments',
        required: false,
        type: InputTypeEnum.AttachmentsModule,
        options: null,
        dependency: null,
      },
    },
  },
  hazardInsurance: {
    title: 'Hazard Insurance',
    fields: {
      agentFirstName: {
        label: 'Agent First Name',
        required: false,
        type: InputTypeEnum.TextString,
        options: null,
        dependency: null,
      },
      agentLastName: {
        label: 'Agent Last Name',
        required: false,
        type: InputTypeEnum.TextString,
        options: null,
        dependency: null,
      },
      insuranceCompanyName: {
        label: 'Insurance Company Name',
        required: false,
        type: InputTypeEnum.TextString,
        options: null,
        dependency: null,
      },
      agentPhoneNumber: {
        label: 'Agent Phone Number',
        required: false,
        type: InputTypeEnum.TextString,
        options: null,
        dependency: null,
      },
    },
  },
};

const brokerConfig: Page = {
  ...baseLoanDetailsConfig,
  loanInformation: {
    title: 'General Information',
    fields: {
      ...baseLoanDetailsConfig.loanInformation.fields,
      loanPurpose: {
        label: 'Loan Purpose',
        required: true,
        type: InputTypeEnum.Dropdown,
        options: mapDropdownOptionsFromEnum(TermsOfLoanLoanPurposeTypeEnum, true, 'key', [
          TermsOfLoanLoanPurposeTypeEnum.PartialClaim,
          TermsOfLoanLoanPurposeTypeEnum.Unknown,
        ]),
        dependency: null,
      },
      mortgageLoanType: {
        label: 'Mortgage/Loan Type',
        required: true,
        type: InputTypeEnum.Dropdown,
        options: mapDropdownOptionsFromEnum(TermsOfLoanMortgageTypeEnum, true, 'key', [
          // eslint-disable-next-line no-underscore-dangle
          TermsOfLoanMortgageTypeEnum._203K,
          TermsOfLoanMortgageTypeEnum.Conventional,
          TermsOfLoanMortgageTypeEnum.LocalAgency,
          TermsOfLoanMortgageTypeEnum.PublicAndIndianHousing,
          TermsOfLoanMortgageTypeEnum.StateAgency,
          TermsOfLoanMortgageTypeEnum.UsdaRuralDevelopment,
          TermsOfLoanMortgageTypeEnum.Va,
        ]).map((x) => {
          if (
            x.value === TermsOfLoanMortgageTypeEnum.Fnma ||
            x.value === TermsOfLoanMortgageTypeEnum.Fhlmc
          ) {
            return { label: `Conventional ${x.label}`, value: x.value };
          }
          return x;
        }),
        dependency: null,
      },
    },
  },
  financialInformation: {
    title: 'Financial Information',
    fields: {
      ...baseLoanDetailsConfig.financialInformation.fields,
      lienPriority: {
        label: 'Lien Priority',
        required: false,
        type: InputTypeEnum.Dropdown,
        options: mapDropdownOptionsFromEnum(TermsOfLoanLienPriorityTypeEnum, true, 'key', [
          TermsOfLoanLienPriorityTypeEnum.ThirdLien,
          TermsOfLoanLienPriorityTypeEnum.FourthLien,
        ]),
        dependency: null,
      },
      cashOutType: {
        label: 'Cash Out Determination Type',
        required: false,
        type: InputTypeEnum.Dropdown,
        options: mapDropdownOptionsFromEnum(
          RefinanceRefinanceCashOutDeterminationTypeEnum,
          true,
          'key',
          [RefinanceRefinanceCashOutDeterminationTypeEnum.Unknown],
        ),
        dependency: null,
      },
    },
  },
};

const servicingConfig: Page = {
  ...baseLoanDetailsConfig,
  loanInformation: {
    ...baseLoanDetailsConfig.loanInformation,
    fields: {
      ...baseLoanDetailsConfig.loanInformation.fields,
      loanPurpose: {
        label: 'Loan Purpose',
        required: true,
        type: InputTypeEnum.Dropdown,
        options: mapDropdownOptionsFromEnum(TermsOfLoanLoanPurposeTypeEnum, true, 'key'),
        dependency: null,
      },
    },
  },
};

const isAppraisalOnly = (products: PlaceOrderProductTypeEnum[]) => {
  if (products.length === 1 && products[0] === PlaceOrderProductTypeEnum.Appraisal) {
    return true;
  }

  return false;
};

export const loanDetailsConfig = (orgType, lenderName?, products?) => {
  if (orgType === OrgTypeEnum.BROKER) {
    return brokerConfig;
  }

  if (orgType === OrgTypeEnum.WHOLESALE) {
    if (isAppraisalOnly(products)) {
      return {
        ...brokerConfig,
        financialInformation: {
          ...brokerConfig.financialInformation,
          fields: {
            ...brokerConfig.financialInformation.fields,
            loanAmount: {
              label: 'Loan Amount',
              required: false,
              type: InputTypeEnum.TextCurrency,
              options: null,
              dependency: null,
            },
          },
        },
      };
    }
    return brokerConfig;
  }

  if (lenderName === 'Freedom Servicing') {
    if (isAppraisalOnly(products)) {
      return {
        ...servicingConfig,
        financialInformation: {
          ...servicingConfig.financialInformation,
          fields: {
            ...servicingConfig.financialInformation.fields,
            loanAmount: {
              label: 'Loan Amount',
              required: false,
              type: InputTypeEnum.TextCurrency,
              options: null,
              dependency: null,
            },
          },
        },
      };
    }

    return servicingConfig;
  }

  if (isAppraisalOnly(products)) {
    return {
      ...baseLoanDetailsConfig,
      financialInformation: {
        ...baseLoanDetailsConfig.financialInformation,
        fields: {
          ...baseLoanDetailsConfig.financialInformation.fields,
          loanAmount: {
            label: 'Loan Amount',
            required: false,
            type: InputTypeEnum.TextCurrency,
            options: null,
            dependency: null,
          },
        },
      },
    };
  }

  return baseLoanDetailsConfig;
};
