import { writable } from 'svelte/store';
import { reportApi } from './api';
import { mapDropdownOptions } from '../utils/mapDropdownOptions';
import { profile } from './profile';

export const ReportGroup = {
  Product: 'product',
  Vendor: 'vendor',
  EventStatus: 'status',
};

export const selectedReportGroup = writable(ReportGroup.Product);

export const vendors = writable([]);
export const workflowVersions = writable(null);
export const eventStatuses = writable(null);
export const events = writable(null);
export const attachmentCodes = writable(null);
export const deliveryStatuses = writable(null);

export async function fetchVendors() {
  vendors.subscribe((vendorsArr) => {
    profile.subscribe((user) => {
      if (!vendorsArr.length && user?.hasLenderAccess) {
        reportApi.subscribe(async (api) => {
          if (api) {
            const vendorsRaw = await api.getVendors();
            vendors.set(mapDropdownOptions(vendorsRaw));
          }
        });
      }
    });
  });
}

export function getFilteredAttachmentCodes(codes, product = null, eventCode = null) {
  let filteredAttachmentCodes = [...codes];

  if (codes && product) {
    filteredAttachmentCodes = filteredAttachmentCodes?.filter((code) =>
      code.products.includes(product),
    );
  }

  if (codes && eventCode) {
    filteredAttachmentCodes =
      filteredAttachmentCodes?.filter((code) => code.eventCodes?.includes(eventCode)) || [];
  }

  return filteredAttachmentCodes;
}
