<script>
  import {
    ClosingDataClosingLocationTypeEnum,
    TermsOfLoanLoanPurposeTypeEnum,
  } from '@xpanseinc/ui-backend-api';
  import { Checkbox, Dropdown, TextInput } from '@xpanseinc/ui-components';
  import DateTimeZone from './DateTimeZone.svelte';
  import { onDestroy } from 'svelte';
  import { getLabel } from '../../constants/sendEventDataLabels';
  import { geoApi } from '../../stores/api';
  import { orderDetails } from '../../stores/orders';
  import { fetchStates, usStates } from '../../stores/usStates';
  import { vendorDetails, vendorId } from '../../stores/vendors';
  import { zipIsValid } from '../../utils/validation';

  export let checkValid = false;
  export let config;
  export let sendEventForm;

  let fetchingZipInfo = false;
  let tx50a6 = false;
  let zipValid = false;

  let subjectPropertyAddress;
  orderDetails.subscribe(async (promise) => {
    const value = await promise;
    if (value && value.address) {
      subjectPropertyAddress = value.address;
      zipValid = true;
    }

    if (value?.vendorId) {
      vendorId.set(value.vendorId);
    }
  });

  let providerAddress;
  vendorDetails.subscribe(async (promise) => {
    const value = await promise;
    if (value && value.address) {
      providerAddress = value.address;
      zipValid = true;
    }
  });

  fetchStates();

  async function onZipcodeChange(e) {
    const { value } = e.target;
    if (!value || !zipValid) {
      return;
    }

    fetchingZipInfo = true;
    try {
      const { city, county, state } = await $geoApi.getZipInfo({ zipCode: value });
      sendEventForm.closingData.address.city = city;
      sendEventForm.closingData.address.county = county;
      sendEventForm.closingData.address.state = state;
    } catch (error) {
      console.error(error);
    }
    fetchingZipInfo = false;
  }

  async function onClosingLocationTypeSelect({ detail }) {
    if (detail.value === ClosingDataClosingLocationTypeEnum.SubjectProperty) {
      autofillAddress('subjectProperty');
    } else {
      autofillAddress(null);
    }
  }

  const autofillAddress = (addressType) => {
    if (addressType === 'subjectProperty' && subjectPropertyAddress) {
      const { line1, line2, state, zip, county, city } = subjectPropertyAddress;
      sendEventForm.closingData.address = {
        line1,
        line2,
        state,
        zip,
        county,
        city,
      };
    } else if (addressType === 'provider' && providerAddress) {
      const { line1, line2, state, zip, county, city } = providerAddress;
      sendEventForm.closingData.address = {
        line1,
        line2,
        state,
        zip,
        county,
        city,
      };
    } else {
      sendEventForm.closingData.address = {
        line1: '',
        line2: '',
        state: '',
        zip: '',
        county: '',
        city: '',
      };
    }
  };

  const buildEnumOptions = (optionsEnum) => {
    const options = [];
    Object.entries(optionsEnum).forEach(([key, value]) => {
      options.push({ label: key.match(/[A-Z][a-z]+/g).join(' '), value });
    });
    return options;
  };

  const closingLocationOptions = buildEnumOptions(ClosingDataClosingLocationTypeEnum);
  const loanPurposeOptions = buildEnumOptions(TermsOfLoanLoanPurposeTypeEnum);

  $: {
    if (!sendEventForm.closingData) {
      sendEventForm.closingData = { closingDate: '' };
      sendEventForm.timezoneId = '';
      if (config?.scheduleLocation) {
        sendEventForm.closingData.address = {
          line1: '',
          line2: '',
          state: '',
          zip: '',
          county: '',
          city: '',
        };
      }
      if (config?.scheduleLocationType) {
        sendEventForm.closingData.closingLocationType = null;
        sendEventForm.closingData.closingLocationDescription = '';
      }
      if (config?.mortgagePageCount) {
        sendEventForm.closingData.mortgagePageCount = '';
      }
      if (config?.deedPageCount) {
        sendEventForm.closingData.deedPageCount = '';
      }
    }
    if (!sendEventForm.termsOfLoan && config?.termsOfLoan) {
      sendEventForm.termsOfLoan = {
        loanPurposeType: null,
        loanPurposeTypeOtherDescription: '',
      };
    }
    if (tx50a6) {
      sendEventForm.closingData.closingLocationType = 'CLOSING_AGENT_BRANCH';
      autofillAddress('provider');
    }
  }

  onDestroy(() => {
    delete sendEventForm.closingData;
    delete sendEventForm.termsOfLoan;
    delete sendEventForm.timezoneId;
  });
</script>

{#if sendEventForm.closingData}
  {#if config?.scheduleLocationType}
    <div class="form-row">
      <div class="form-item">
        <Dropdown
          disabled="{tx50a6}"
          invalid="{checkValid &&
            config?.scheduleLocationType?.required &&
            !sendEventForm.closingData.closingLocationType}"
          label="{getLabel('closingLocationType')}{config?.scheduleLocationType?.required
            ? '*'
            : ''}"
          name="closingLocationType"
          options="{closingLocationOptions}"
          placeholder="Select One"
          on:select="{onClosingLocationTypeSelect}"
          bind:value="{sendEventForm.closingData.closingLocationType}"
        />
      </div>
      {#if sendEventForm.eventType !== 'ScheduledSigning'}
        <div class="form-item">
          <Checkbox name="tx50a6" bind:checked="{tx50a6}">TX50a6 Loans</Checkbox>
        </div>
      {/if}
    </div>
    {#if sendEventForm.closingData.closingLocationType === 'OTHER'}
      <div class="form-row">
        <div class="form-item two-col">
          <TextInput
            invalid="{checkValid && !sendEventForm.closingData.closingLocationDescription}"
            label="{getLabel('closingLocationDescription')}*"
            name="closingLocationDescription"
            value="{sendEventForm.closingData.closingLocationDescription}"
            on:keyup="{(e) => {
              sendEventForm.closingData.closingLocationDescription = e.target.value;
            }}"
          />
        </div>
      </div>
    {/if}
  {/if}
  {#if config?.scheduleLocation}
    <div class="form-row">
      <div class="form-item two-col">
        <TextInput
          invalid="{checkValid &&
            config?.scheduleLocation?.required &&
            !sendEventForm.closingData.address.line1}"
          label="{getLabel('line1')}{config?.scheduleLocation?.required ? '*' : ''}"
          name="line1"
          value="{sendEventForm.closingData.address.line1}"
          on:keyup="{(e) => {
            sendEventForm.closingData.address.line1 = e.target.value;
          }}"
        />
      </div>
    </div>
    <div class="form-row">
      <div class="form-item two-col">
        <TextInput
          label="{getLabel('line2')}"
          name="line2"
          value="{sendEventForm.closingData.address.line2}"
          on:keyup="{(e) => {
            sendEventForm.closingData.address.line2 = e.target.value;
          }}"
        />
      </div>
    </div>
    <div class="form-row">
      <div class="form-item">
        <TextInput
          loading="{fetchingZipInfo}"
          invalid="{(checkValid &&
            config?.scheduleLocation?.required &&
            !sendEventForm.closingData.address.zip) ||
            (sendEventForm.closingData.address.zip && !zipValid)}"
          label="{getLabel('zip')}{config?.scheduleLocation?.required ? '*' : ''}"
          name="zip"
          title="Please enter a valid zip code"
          value="{sendEventForm.closingData.address.zip}"
          on:keyup="{(e) => {
            sendEventForm.closingData.address.zip = e.target.value;
            zipValid = zipIsValid(sendEventForm.closingData.address.zip);
          }}"
          on:blur="{onZipcodeChange}"
        />
      </div>
      <div class="form-item">
        <TextInput
          invalid="{checkValid &&
            config?.scheduleLocation?.required &&
            !sendEventForm.closingData.address.city}"
          label="{getLabel('city')}{config?.scheduleLocation?.required ? '*' : ''}"
          name="city"
          value="{sendEventForm.closingData.address.city}"
          on:keyup="{(e) => {
            sendEventForm.closingData.address.city = e.target.value;
          }}"
        />
      </div>
    </div>
    <div class="form-row">
      <div class="form-item">
        <Dropdown
          invalid="{checkValid &&
            config?.scheduleLocation?.required &&
            !sendEventForm.closingData.address.state}"
          label="{getLabel('state')}{config?.scheduleLocation?.required ? '*' : ''}"
          name="state"
          options="{$usStates}"
          placeholder="Select One"
          bind:value="{sendEventForm.closingData.address.state}"
        />
      </div>
      <div class="form-item">
        <TextInput
          invalid="{checkValid &&
            config?.scheduleLocation?.required &&
            !sendEventForm.closingData.address.county}"
          label="{getLabel('county')}{config?.scheduleLocation?.required ? '*' : ''}"
          name="county"
          value="{sendEventForm.closingData.address.county}"
          on:keyup="{(e) => {
            sendEventForm.closingData.address.county = e.target.value;
          }}"
        />
      </div>
    </div>
  {/if}
  {#if config?.termsOfLoan}
    <div class="form-row">
      <div class="form-item">
        <Dropdown
          invalid="{checkValid &&
            config.termsOfLoan?.required &&
            !sendEventForm.termsOfLoan.loanPurposeType}"
          label="{getLabel('loanPurposeType')}{config.termsOfLoan?.required ? '*' : ''}"
          name="loanPurposeType"
          options="{loanPurposeOptions}"
          placeholder="Select One"
          bind:value="{sendEventForm.termsOfLoan.loanPurposeType}"
        />
      </div>
    </div>
    {#if sendEventForm.termsOfLoan.loanPurposeType === 'OTHER'}
      <div class="form-row">
        <div class="form-item two-col">
          <TextInput
            invalid="{checkValid &&
              sendEventForm.closingData.loanTypePurpose === 'OTHER' &&
              !sendEventForm.termsOfLoan.loanPurposeTypeOtherDescription}"
            label="{getLabel('loanPurposeTypeOtherDescription')}*"
            name="loanPurposeTypeOtherDescription"
            value="{sendEventForm.termsOfLoan.loanPurposeTypeOtherDescription}"
            on:keyup="{(e) => {
              sendEventForm.termsOfLoan.loanPurposeTypeOtherDescription = e.target.value;
            }}"
          />
        </div>
      </div>
    {/if}
  {/if}

  <div class="form-row">
    <DateTimeZone
      checkValid="{checkValid}"
      minToday="{true}"
      dateLabel="{getLabel('closingDate')}*"
      timeLabel="{getLabel('closingTime')}*"
      bind:timeZone="{sendEventForm.timezoneId}"
      on:change="{({ detail: { datetime } }) => {
        sendEventForm.closingData.closingDate = datetime;
      }}"
    />
  </div>
  {#if config?.mortgagePageCount || config?.deedPageCount}
    <div class="form-row">
      {#if config?.mortgagePageCount}
        <div class="form-item">
          <TextInput
            invalid="{(checkValid &&
              config?.mortgagePageCount?.required &&
              !sendEventForm.closingData.mortgagePageCount) ||
              (sendEventForm.closingData.mortgagePageCount &&
                sendEventForm.closingData.mortgagePageCount < 0)}"
            label="{getLabel('mortgagePageCount')}{config?.mortgagePageCount?.required ? '*' : ''}"
            min="0"
            name="mortgagePageCount"
            type="number"
            value="{sendEventForm.closingData.mortgagePageCount}"
            on:keyup="{(e) => {
              sendEventForm.closingData.mortgagePageCount = e.target.value;
            }}"
          />
        </div>
      {/if}
      {#if config?.deedPageCount}
        <div class="form-item">
          <TextInput
            invalid="{(checkValid &&
              config?.deedPageCount?.required &&
              !sendEventForm.closingData.deedPageCount) ||
              (sendEventForm.closingData.deedPageCount &&
                sendEventForm.closingData.deedPageCount < 0)}"
            label="{getLabel('deedPageCount')}{config?.deedPageCount?.required ? '*' : ''}"
            min="0"
            name="deedPageCount"
            type="number"
            value="{sendEventForm.closingData.deedPageCount}"
            on:keyup="{(e) => {
              sendEventForm.closingData.deedPageCount = e.target.value;
            }}"
          />
        </div>
      {/if}
    </div>
  {/if}
{/if}
