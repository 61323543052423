<script>
  import { DateInput, Dropdown, TextInput } from '@xpanseinc/ui-components';
  import {
    RecordingEndorsementRecordingEndorsementTypeEnum,
    RecordingEndorsementVolumeTypeEnum,
  } from '@xpanseinc/ui-backend-api';
  import { onDestroy } from 'svelte';
  import { startCase, camelCase } from 'lodash-es';
  import { getLabel } from '../../constants/sendEventDataLabels';
  import { usTimeZoneOptions } from '../../constants/usTimeZoneOptions';

  export let checkValid = false;
  export let config;
  export let sendEventForm;
  const { required } = config.recordingEndorsement;
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions() || 'America/New_York';
  const today = new Date();
  let date = '';
  let showVolDesc = false;
  let showRecEndorseDesc = false;

  function onKeyup(e) {
    sendEventForm.recordingEndorsement[e.target.name] = e.target.value;
  }

  function buildOptions(enumOptions) {
    return Object.values(enumOptions).map((option) => ({
      label: startCase(camelCase(option)),
      value: option,
    }));
  }

  const volumeTypeOptions = buildOptions(RecordingEndorsementVolumeTypeEnum);
  const recEndorseTypeOptions = buildOptions(RecordingEndorsementRecordingEndorsementTypeEnum);

  if (!sendEventForm.recordingEndorsement) {
    sendEventForm.recordingEndorsement = {
      feeAmount: '',
      firstPageNumberValue: '',
      instrumentNumberIdentifier: '',
      recordedDatetime: undefined,
      recordingEndorsementType: undefined,
      recordingEndorsementTypeOtherDescription: '',
      volumeIdentifier: '',
      volumeType: undefined,
      volumeTypeOtherDescription: '',
    };
    sendEventForm.timezoneId = timeZone;
  }

  $: showVolDesc = sendEventForm.recordingEndorsement?.volumeType === 'OTHER';
  $: showRecEndorseDesc = sendEventForm.recordingEndorsement?.recordingEndorsementType === 'OTHER';

  onDestroy(() => {
    delete sendEventForm.recordingEndorsement.feeAmount;
    delete sendEventForm.recordingEndorsement.firstPageNumberValue;
    delete sendEventForm.recordingEndorsement.instrumentNumberIdentifier;
    delete sendEventForm.recordingEndorsement.recordedDatetime;
    delete sendEventForm.recordingEndorsement.recordingEndorsementType;
    delete sendEventForm.recordingEndorsement.recordingEndorsementTypeOtherDescription;
    delete sendEventForm.recordingEndorsement.volumeIdentifier;
    delete sendEventForm.recordingEndorsement.volumeType;
    delete sendEventForm.recordingEndorsement.volumeTypeOtherDescription;
    if (Object.keys(sendEventForm.recordingEndorsement).length === 0) {
      delete sendEventForm.recordingEndorsement;
    }
    delete sendEventForm.timezoneId;
  });
</script>

<h4 class="header-m textDark">Shipping Details</h4>
<div class="form-row">
  <div class="form-item">
    <TextInput
      invalid="{checkValid &&
        required &&
        !sendEventForm.recordingEndorsement?.firstPageNumberValue}"
      label="{getLabel('firstPageNumberValue')}{required ? '*' : ''}"
      name="firstPageNumberValue"
      value="{sendEventForm.recordingEndorsement?.firstPageNumberValue}"
      on:keyup="{onKeyup}"
    />
  </div>
  <div class="form-item">
    <TextInput
      invalid="{checkValid && required && !sendEventForm.recordingEndorsement?.volumeIdentifier}"
      label="{getLabel('volumeIdentifier')}{required ? '*' : ''}"
      name="volumeIdentifier"
      value="{sendEventForm.recordingEndorsement?.volumeIdentifier}"
      on:keyup="{onKeyup}"
    />
  </div>
  <div class="form-item">
    <Dropdown
      invalid="{checkValid && required && !sendEventForm.recordingEndorsement?.volumeType}"
      label="{getLabel('volumeType')}{required ? '*' : ''}"
      name="volumeType"
      options="{volumeTypeOptions}"
      placeholder="Select One"
      on:select="{(e) => {
        sendEventForm.recordingEndorsement.volumeType = e.detail.value;
      }}"
    />
  </div>
</div>
{#if showVolDesc}
  <div class="form-row">
    <div class="form-item two-col">
      <TextInput
        invalid="{checkValid &&
          showVolDesc &&
          !sendEventForm.recordingEndorsement?.volumeTypeOtherDescription}"
        label="{getLabel('volumeTypeOtherDescription')}*"
        name="volumeTypeOtherDescription"
        value="{sendEventForm.recordingEndorsement?.volumeTypeOtherDescription}"
        on:keyup="{onKeyup}"
      />
    </div>
  </div>
{/if}
<div class="form-row">
  <div class="form-item">
    <TextInput
      invalid="{checkValid &&
        required &&
        !sendEventForm.recordingEndorsement?.instrumentNumberIdentifier}"
      label="{getLabel('instrumentNumberIdentifier')}{required ? '*' : ''}"
      name="instrumentNumberIdentifier"
      value="{sendEventForm.recordingEndorsement?.instrumentNumberIdentifier}"
      on:keyup="{onKeyup}"
    />
  </div>
</div>
<div class="form-row">
  <div class="form-item">
    <Dropdown
      invalid="{checkValid &&
        required &&
        !sendEventForm.recordingEndorsement?.recordingEndorsementType}"
      label="{getLabel('recordingEndorsementType')}{required ? '*' : ''}"
      name="recordingEndorsementType"
      options="{recEndorseTypeOptions}"
      placeholder="Select One"
      on:select="{(e) => {
        sendEventForm.recordingEndorsement.recordingEndorsementType = e.detail.value;
      }}"
    />
  </div>
  <div class="form-item">
    <TextInput
      invalid="{checkValid && required && !sendEventForm.recordingEndorsement?.feeAmount}"
      label="{getLabel('feeAmount')}{required ? '*' : ''}"
      min="0"
      name="feeAmount"
      prepend="$"
      type="number"
      value="{sendEventForm.recordingEndorsement?.feeAmount}"
      on:keyup="{onKeyup}"
    />
  </div>
</div>
<div class="form-row">
  <div class="form-item">
    <DateInput
      invalid="{checkValid && required && !sendEventForm.trackingInfo?.recordedDatetime}"
      label="{getLabel('recordedDatetime')}{required ? '*' : ''}"
      name="recordedDatetime"
      maxDate="{today}"
      on:change="{({ detail: [recordedDatetime] }) => {
        sendEventForm.recordingEndorsement.recordedDatetime = recordedDatetime;
      }}"
      bind:date
    />
  </div>
  <div class="form-item">
    <Dropdown
      label="{getLabel('timeZone')}{required ? '*' : ''}"
      name="timeZone"
      options="{usTimeZoneOptions()}"
      placeholder="Select One"
      bind:value="{sendEventForm.timezoneId}"
    />
  </div>
</div>
{#if showRecEndorseDesc}
  <div class="form-row">
    <div class="form-item two-col">
      <TextInput
        invalid="{checkValid &&
          showRecEndorseDesc &&
          !sendEventForm.recordingEndorsement?.recordingEndorsementTypeOtherDescription}"
        label="{getLabel('recordingEndorsementTypeOtherDescription')}*"
        name="recordingEndorsementTypeOtherDescription"
        value="{sendEventForm.recordingEndorsement?.recordingEndorsementTypeOtherDescription}"
        on:keyup="{onKeyup}"
      />
    </div>
  </div>
{/if}
