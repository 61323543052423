<script lang="ts">
  import { onMount, setContext } from 'svelte';
  import { HashIcon, PlusIcon } from 'svelte-feather-icons';
  import { Button } from '@xpanseinc/ui-components';
  import FormBlock from '../../FormBlock.svelte';
  import FormRow from '../../FormRow.svelte';
  import { getComponent, getProps } from '../../renderFromConfig.js';
  import { getBorrowerSchema, type NewLoan } from '../../../../schemas/place-order';
  import { validate } from '../../../../schemas/validate';
  import { reportApi } from '../../../../stores/api';
  import {
    form,
    PlaceOrderProductTypeEnum,
    productDetailsProductOptions,
    productDetailsProviderOptions,
    validationResults,
  } from '../../../../stores/placeOrder';
  import BorrowerModal from './BorrowerModal.svelte';
  import { detectDomain, DomainTypes } from '../../../../utils/detectDomain';
  import Attachment from '../../../send-event/Attachment.svelte';

  import fieldConfig from './field-config';
  import { SsnVerificationAttachmentCode } from '../../../../constants/place-order';
  import { type SsnVerification } from './types';
  import { loanDetailsSchema, productDetailsSchema } from './schemas';

  type FormKeys = NewLoan & SsnVerification;

  const dirtyMap: { [k in keyof FormKeys]?: boolean } = {};

  const isProd = detectDomain() === DomainTypes.PROD;
  const taxNotifyEmail = isProd
    ? 'TaxTranscripts@FreedomMortgage.com'
    : 'XPANSQA@FreedomMortgage.com';

  let emailOptions = [{ label: taxNotifyEmail, value: taxNotifyEmail }];

  setContext('borrower', {
    component: BorrowerModal,
    getHeader: (item) => `${item.firstName} ${item.middleName} ${item.lastName}`,
    getHomeText: () => null,
    isValid: (item) => validate(getBorrowerSchema(item.contactType), item).valid,
  });

  onMount(async () => {
    const vendorsResponse = await $reportApi.getVendors1({
      productType: 'SSNVerification',
    });

    $productDetailsProviderOptions[PlaceOrderProductTypeEnum.SSN_Verification] = [
      ...vendorsResponse,
    ].map((x) => ({
      label: x.name,
      value: x.id,
    }));

    const productsResponse = await $reportApi.getProductNames({
      productType: 'SSNVerification',
    });

    $productDetailsProductOptions[PlaceOrderProductTypeEnum.SSN_Verification] = [
      ...productsResponse,
    ].map((x) => ({
      label: x.name,
      value: x.id,
    }));

    $form.productDetails[PlaceOrderProductTypeEnum.SSN_Verification].clientContact =
      'Freedom Call Center';

    $form.productDetails[PlaceOrderProductTypeEnum.SSN_Verification].attachments = [];
    addAttachment();
  });

  function addAttachment() {
    $form.productDetails[PlaceOrderProductTypeEnum.SSN_Verification].attachments.push({
      fileName: '',
      mimeTypeId: '',
      usageCode: SsnVerificationAttachmentCode.SSA_89,
      usageCodeDescription: SsnVerificationAttachmentCode.SSA_89,
    });
  }

  function removeAttachment(idx) {
    $form.productDetails[PlaceOrderProductTypeEnum.SSN_Verification].attachments.splice(idx, 1);
  }

  $: {
    $validationResults.loanDetails = validate(loanDetailsSchema, $form.loanDetails.newLoan);

    $validationResults.productDetails[PlaceOrderProductTypeEnum.SSN_Verification] = validate(
      productDetailsSchema,
      $form.productDetails[PlaceOrderProductTypeEnum.SSN_Verification],
    );
  }
</script>

<FormRow>
  <FormBlock width="800px">
    <div class="loan-details-header">
      <h2 class="header-xl header-xl--bold">Loan Details</h2>
    </div>

    <svelte:component
      this="{getComponent(fieldConfig.loanNumber)}"
      {...getProps(fieldConfig.loanNumber)}
      label="{undefined}"
      value="{$form.loanDetails.newLoan.loanNumber || ''}"
      invalid="{dirtyMap.loanNumber && $validationResults.loanDetails.errors.loanNumber}"
      placeholder="Enter Loan Number *"
      prependedIcon="{HashIcon}"
      large
      on:blur="{() => {
        dirtyMap.loanNumber = true;
      }}"
      on:change="{(e) => {
        $form.loanDetails.newLoan.loanNumber = e.target.value;
      }}"
    />
  </FormBlock>
</FormRow>

<FormRow>
  <FormBlock title="{fieldConfig.borrowers.title} *" width="100%">
    <svelte:component
      this="{getComponent(fieldConfig.borrowers.fields.borrowerInformation)}"
      {...getProps(fieldConfig.borrowers.fields.borrowerInformation)}
      itemList="{$form.loanDetails.newLoan.borrowers}"
      contextKey="borrower"
      placeholderButtonLabel="{fieldConfig.borrowers.fields.borrowerInformation.label}"
      maxItems="1"
      on:itemChange="{(e) => {
        $form.loanDetails.newLoan.borrowers = e.detail;
        dirtyMap.borrowers = true;
      }}"
    />
  </FormBlock>
</FormRow>

<FormRow>
  <FormBlock title="Product Details" width="600px">
    <div class="row">
      <svelte:component
        this="{getComponent(fieldConfig.provider)}"
        {...getProps(fieldConfig.provider)}
        disabled="{$productDetailsProviderOptions[PlaceOrderProductTypeEnum.SSN_Verification]
          .length === 0}"
        options="{$productDetailsProviderOptions[PlaceOrderProductTypeEnum.SSN_Verification] || [
          { label: '', value: '' },
        ]}"
        on:select="{(e) => {
          $form.productDetails[PlaceOrderProductTypeEnum.SSN_Verification].provider =
            e.detail.value;

          $form.productDetails[PlaceOrderProductTypeEnum.SSN_Verification].product =
            $productDetailsProductOptions[PlaceOrderProductTypeEnum.SSN_Verification][0].value;
        }}"
        value="{$form.productDetails[PlaceOrderProductTypeEnum.SSN_Verification].provider}"
      />
      <svelte:component
        this="{getComponent(fieldConfig.product)}"
        {...getProps(fieldConfig.product)}
        disabled="{$productDetailsProductOptions[PlaceOrderProductTypeEnum.SSN_Verification]
          .length === 0}"
        options="{$productDetailsProductOptions[PlaceOrderProductTypeEnum.SSN_Verification] || []}"
        on:select="{(e) => {
          $form.productDetails[PlaceOrderProductTypeEnum.SSN_Verification].product = e.detail.value;
        }}"
        value="{$form.productDetails[PlaceOrderProductTypeEnum.SSN_Verification].product}"
      />
    </div>
    <div class="row">
      <div style="max-width: 400px;">
        <svelte:component
          this="{getComponent(fieldConfig.clientContact)}"
          disabled
          {...getProps(fieldConfig.clientContact)}
          value="{$form.productDetails[PlaceOrderProductTypeEnum.SSN_Verification].clientContact}"
        />
      </div>
      <div style="width: 300px;">
        <svelte:component
          this="{getComponent(fieldConfig.email)}"
          {...getProps(fieldConfig.email)}
          options="{emailOptions}"
          value="{$form.productDetails[PlaceOrderProductTypeEnum.SSN_Verification].email}"
          invalid="{dirtyMap.email &&
            $validationResults.productDetails[PlaceOrderProductTypeEnum.SSN_Verification].errors
              .email}"
          on:blur="{() => {
            dirtyMap.email = true;
          }}"
          on:select="{(e) => {
            $form.productDetails[PlaceOrderProductTypeEnum.SSN_Verification].email = e.detail.value;
          }}"
        />
      </div>
    </div>
  </FormBlock>
</FormRow>

<FormRow>
  <FormBlock title="Attachments*" width="600px">
    {#each $form.productDetails[PlaceOrderProductTypeEnum.SSN_Verification].attachments || [] as attachment, idx (attachment)}
      <!-- Avoid using this component's built in validation -->
      <Attachment
        checkValid="{false}"
        required="{false}"
        options="{[
          {
            label: SsnVerificationAttachmentCode.SSA_89,
            value: SsnVerificationAttachmentCode.SSA_89,
          },
        ]}"
        showRemove="{idx > 0}"
        on:update="{() => {
          $form = $form;
        }}"
        on:remove="{() => removeAttachment(idx)}"
        attachment="{attachment}"
      />
    {/each}
  </FormBlock>
</FormRow>

<style>
  :global(.form-row) {
    display: flex;
    margin-top: 4px;
    margin-bottom: 16px;
  }

  :global(.form-item) {
    width: 30%;
    padding-right: 24px;
  }

  :global(.form-item.two-col.has-action-col) {
    width: 100%;
  }

  :global(.form-item.action-col) {
    flex: 0 0 40px;
    padding-right: 0;
    align-self: flex-end;
  }
</style>
