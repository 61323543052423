<script>
  import { TrackingInfoDeliveryMethodTypeEnum } from '@xpanseinc/ui-backend-api';
  import { DateInput, Dropdown, TextInput } from '@xpanseinc/ui-components';
  import { startCase, camelCase } from 'lodash-es';
  import { onDestroy } from 'svelte';
  import { alphabetically } from '../../utils/alphabetically';
  import { getLabel } from '../../constants/sendEventDataLabels';
  import { usTimeZoneOptions } from '../../constants/usTimeZoneOptions';

  export let checkValid = false;
  export let sendEventForm;
  export let config;
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions() || 'America/New_York';
  const { required } = config.trackingNumber;
  const today = new Date();
  let date = '';

  function onKeyup(e) {
    sendEventForm.trackingInfo[e.target.name] = e.target.value;
  }

  function buildOptions(enumOptions) {
    const options = [];
    Object.entries(enumOptions).forEach(([key, value]) => {
      if (key !== 'Tty' && key !== 'Usps') {
        options.push({
          label: startCase(camelCase(key)),
          value,
        });
      } else {
        options.push({
          label: key.toUpperCase(),
          value,
        });
      }
    });
    return options.sort(alphabetically);
  }

  const deliveryTypeOptions = buildOptions(TrackingInfoDeliveryMethodTypeEnum);

  $: {
    if (!sendEventForm.trackingInfo) {
      sendEventForm.timezoneId = timeZone;
      sendEventForm.trackingInfo = {
        deliveryInitiateDate: undefined,
        deliveryMethodType: undefined,
        trackingNumber: '',
      };
    }
  }

  onDestroy(() => {
    delete sendEventForm.timezoneId;
    delete sendEventForm.trackingInfo.deliveryInitiateDate;
    delete sendEventForm.trackingInfo.deliveryMethodType;
    delete sendEventForm.trackingInfo.trackingNumber;
    if (Object.keys(sendEventForm.trackingInfo).length === 0) {
      delete sendEventForm.trackingInfo;
    }
  });
</script>

<div class="form-row">
  <div class="form-item">
    <Dropdown
      invalid="{checkValid && required && !sendEventForm.trackingInfo?.deliveryMethodType}"
      label="{getLabel('deliveryMethodType')}{required ? '*' : ''}"
      name="deliveryMethodType"
      options="{deliveryTypeOptions}"
      placeholder="Select One"
      on:select="{(e) => {
        sendEventForm.trackingInfo.deliveryMethodType = e.detail.value;
      }}"
    />
  </div>
  <div class="form-item">
    <TextInput
      invalid="{checkValid && required && !sendEventForm.trackingInfo?.trackingNumber}"
      label="{getLabel('trackingNumber')}{required ? '*' : ''}"
      name="trackingNumber"
      value="{sendEventForm.trackingInfo?.trackingNumber}"
      on:keyup="{onKeyup}"
    />
  </div>
</div>
<div class="form-row">
  <div class="form-item">
    <DateInput
      invalid="{checkValid && required && !sendEventForm.trackingInfo?.deliveryInitiateDate}"
      label="{getLabel('deliveryInitiateDate')}{required ? '*' : ''}"
      name="deliveryInitiateDate"
      maxDate="{today}"
      on:change="{({ detail: [deliveryInitiateDate] }) => {
        sendEventForm.trackingInfo.deliveryInitiateDate = deliveryInitiateDate;
      }}"
      bind:date
    />
  </div>
  <div class="form-item">
    <Dropdown
      label="{getLabel('timeZone')}{required ? '*' : ''}"
      name="timeZone"
      options="{usTimeZoneOptions()}"
      placeholder="Select One"
      bind:value="{sendEventForm.timezoneId}"
    />
  </div>
</div>
