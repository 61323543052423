<script>
  import { formatPrice } from '../../utils/formatPrice';
  import { formatDate } from '../../utils/formatDate';
  import { Badge } from '@xpanseinc/ui-components';
  export let order = {};

  const orderDetails = [
    ['Order Date', formatDate(order?.ordersPlacedDate, 'ET', 'M/dd/yyyy')],
    ['Billing Date', formatDate(order?.ordersInvoicedDate, 'ET', 'M/dd/yyyy')],
    ['Order Count', order?.ordersCount],
    ['Click Fee', formatPrice.format(order?.invoicedPrice)],
    ['Total Invoice', formatPrice.format(order?.totalPrice)],
  ];
</script>

<div class="card">
  <div class="loan-info">
    <div class="provider-product-wrapper">
      <h3 class="header-m header-m--bold textDark">{order.providerName}</h3>
      <Badge>{order.productName}</Badge>
    </div>
    <div class="order-details">
      {#each orderDetails as detail}
        <div class="order-detail">
          <div class="label">{detail[0]}</div>
          {#if detail[1]}
            <div class="body" class:subtitle-small="{detail[0] === 'Total Invoice'}">
              {detail[1]}
            </div>
          {/if}
        </div>
      {/each}
    </div>
  </div>
</div>

<style>
  .card {
    background-color: var(--white);
    display: flex;
    flex: 1;
    max-width: 876px;
    min-height: 96px;
    border-radius: 8px;
  }

  .loan-info {
    width: 100%;
    margin: 1rem 1rem;
    display: inline-flex;
    flex-direction: column;
  }

  .provider-product-wrapper {
    display: flex;
    flex: 1;
    margin: 0 12px 16px 0;
    align-items: center;
  }

  .provider-product-wrapper .header-m {
    margin-right: 0.75rem;
  }

  .provider-product-wrapper :global(.dot) {
    display: none;
  }

  .order-details {
    display: inline-flex;
  }

  .order-detail {
    display: flex;
    flex: 1;
    flex-direction: column;
    max-width: 104px;
    margin-right: 16px;
  }

  .order-detail > .subtitle-small {
    color: var(--gray10);
    line-height: 20px;
  }

  .label {
    display: block;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: var(--textDefault);
  }

  .body {
    display: block;
    padding: 0 8px 0 0;
    color: var(--textDark);
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }
</style>
