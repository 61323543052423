<script>
  import { Badge, Menu } from '@xpanseinc/ui-components';
  import VerticalBarChart from './VerticalBarChart.svelte';
  import { capitalize } from 'lodash-es';
  import { getWeek, differenceInCalendarMonths } from 'date-fns';
  import { createEventDispatcher } from 'svelte';
  import { ArrowRightIcon, Edit2Icon, Trash2Icon } from 'svelte-feather-icons';
  import { scorecardFilter } from '../stores/slaScorecard';

  const dispatch = createEventDispatcher();
  const formatUnit = (unit) => unit.replace('BUSINESS_', '').toLowerCase();

  export let props = {};
  export let showMenu = true;

  $: warning = props.actual < props.target;
  const unit = formatUnit(props.timeUnit);
  let history = {};
  let numberOfMonths = 0;

  $: props.history.forEach((item) => {
    item.week = getWeek(item.date);
    item.month = item.date.getMonth();
    item.year = item.date.getYear();
  });

  const buildHistory = (report, interval) => {
    history = {};
    report.forEach((item) => {
      let step = [item[interval]];
      if (interval === 'week') {
        step = [`${item.year}-${item.week}`];
      }
      if (interval === 'month') {
        step = [`${item.year}-${item.month}`];
      }

      if (!history[step]) {
        history[step] = {
          count: item.count,
          pass: item.pass,
          date: item.date,
          interval: interval,
        };
      } else {
        history[step].count += item?.count;
        history[step].pass += item?.pass;
      }
    });
  };

  function renderPerformerDays(percentile) {
    if (percentile === undefined) {
      return 'no data';
    }
    if (percentile === 0) {
      return `0 ${unit}`;
    }
    return `${percentile} or more ${unit}`;
  }

  $: {
    numberOfMonths = differenceInCalendarMonths($scorecardFilter.to, $scorecardFilter.from);

    if (numberOfMonths <= 1) {
      buildHistory(props.history, 'date');
    } else if (numberOfMonths > 1 && numberOfMonths < 6) {
      buildHistory(props.history, 'week');
    } else if (numberOfMonths >= 6 && numberOfMonths < 24) {
      buildHistory(props.history, 'month');
    } else {
      buildHistory(props.history, 'year');
    }
  }
</script>

<div class="sla-card">
  <div class="header">
    <Badge>{props.service}</Badge>
    <h3 class="header-xl">
      {props.startEventDescription}
      <ArrowRightIcon size="20" />
      {props.endEventDescription}
    </h3>
    {#if showMenu}
      <Menu
        dataTestId="test-menu"
        offset="{[-8, -6]}"
        placement="bottom-end"
        items="{[
          { label: props.lastModifiedUser, separator: true },
          { label: 'Edit', icon: Edit2Icon, onClick: () => dispatch('edit', props) },
          { label: 'Delete', icon: Trash2Icon, onClick: () => dispatch('delete', props) },
        ]}"
      />
    {/if}
  </div>
  <div class="dataset">
    <div class="col">
      <h4>
        <span class="subtitle-small">Actual</span>
        <span class="value--big primary" class:warning title="{warning ? 'Below target' : ''}">
          {props.actual > -1 ? props.actual : '--'}%
        </span>
      </h4>
      <h5 class="subtitle-small">Performance</h5>
      {#if props.pass > -1}
        <p class="body-small"><strong>Met SLA</strong> {props.pass}</p>
      {/if}
      {#if props.total > -1}
        <p class="body-small"><strong>Total</strong> {props.total}</p>
      {/if}
    </div>
    <div class="col">
      {#if props.target > -1}
        <h4>
          <span class="subtitle-small">Target</span>
          <span class="value--big">{props.target}%</span>
        </h4>
      {/if}
      <h5 class="subtitle-small">Worst performers</h5>
      <p class="body-small">
        <strong>1%</strong>
        {renderPerformerDays(props.p99)}
      </p>
      <p class="body-small">
        <strong>5%</strong>
        {renderPerformerDays(props.p95)}
      </p>
      <p class="body-small">
        <strong>10%</strong>
        {renderPerformerDays(props.p90)}
      </p>
    </div>
    <div class="col">
      {#if props.sla > -1}
        <h4>
          <span class="subtitle-small">SLA</span>
          <span class="value--big">
            {props.sla} <span class="h5">{capitalize(unit)}</span>
          </span>
        </h4>
      {/if}
      {#if props.stateSlas?.length}
        <h5 class="subtitle-small">State SLAs</h5>
        {#each props.stateSlas as stateSla}
          <p class="body-small">
            <strong>{stateSla.sla} {formatUnit(stateSla.timeUnit)}</strong>
            {#each stateSla.states as state, i}
              <span>{i < stateSla.states.length - 1 ? `${state}, ` : state}</span>
            {/each}
          </p>
        {/each}
      {/if}
    </div>
  </div>
  {#if Object.values(history).length > 0}
    <div class="trends">
      <h6>Trend</h6>
      <div class="chart-container">
        <VerticalBarChart data="{Object.values(history)}" />
      </div>
    </div>
  {:else}
    <div class="trends">
      <p class="chart-empty subtitle text-center">No data for that time frame</p>
    </div>
  {/if}
</div>

<style>
  .sla-card {
    min-height: 387px;
    background: linear-gradient(135deg, rgb(90 82 255 / 83%) 0%, var(--actionPrimaryActive) 99.99%);
    border-radius: 4px;
    color: var(--white);
    padding: 20px;
    display: flex;
    position: relative;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: flex-start;
  }

  .header :global(.badge) {
    margin-bottom: 4px;
    padding: 0 8px !important;
    color: var(--primaryDefault) !important;
  }

  .header :global(.badge .dot) {
    display: none;
  }

  .header :global(.feather-arrow-right) {
    height: 32px;
    vertical-align: bottom;
    position: relative;
    top: 3px;
  }

  .header :global(.menu) {
    position: absolute;
    top: 8px;
    right: 8px;
  }

  .header :global(.menu .popover) {
    max-width: 250px;
  }

  .header :global(.menu-btn.btn.basic) {
    color: var(--white);
  }

  .header :global(.menu-btn.btn.basic:hover) {
    background: var(--actionPrimaryHover);
  }

  .header :global(.menu-item.overline) {
    color: var(--textDefault);
    padding: 8px 8px;
  }

  p {
    margin-bottom: 2px;
    font-weight: 400;
  }

  p strong {
    font-weight: 700;
  }

  h3 {
    margin-bottom: 12px;
    min-height: 64px;
  }

  h4 {
    margin-bottom: 7px;
  }

  h4 .subtitle-small {
    display: block;
  }

  .subtitle-small {
    padding-bottom: 6px;
    color: var(--white);
  }

  .value--big {
    font-size: 48px;
    line-height: 50px;
    color: var(--focusLight);
  }

  .value--big.primary,
  .value--big.warning {
    color: var(--white);
  }

  .value--big.primary.warning {
    color: var(--cautionDefault);
  }

  h3,
  h6,
  .body-small,
  .h5,
  .subtitle {
    color: inherit;
  }

  .dataset {
    display: flex;
    flex: 1;
    justify-content: space-between;
  }

  .col {
    flex-grow: 1;
    flex-basis: 0;
  }

  .col:nth-child(1) .body-small strong {
    display: inline-block;
    min-width: 55px;
  }

  .col:nth-child(2) .body-small strong {
    display: inline-block;
    min-width: 30px;
  }

  .trends {
    color: var(--focusLight);
    margin-top: 16px;
  }

  .chart-container {
    height: 70px;
    border-radius: 4px;
    overflow: hidden;
  }

  .chart-container :global(.chart) {
    display: flex;
    width: auto;
    justify-content: space-between;
    border-bottom: 2px solid var(--neutralDefault);
  }

  .chart-empty {
    margin-top: 16px;
    opacity: 0.5;
  }
</style>
