<script>
  import { Dropdown, IconButton, TextInput } from '@xpanseinc/ui-components';
  import { XIcon } from 'svelte-feather-icons';
  import { createEventDispatcher } from 'svelte';
  import { getLabel } from '../../constants/sendEventDataLabels';

  const dispatch = createEventDispatcher();

  export let checkValid;
  export let reason;
  export let options;
  export let showRemove;
  export let required;

  function onReasonCodeSelect({ detail: option }) {
    reason.reasonCode = option.value;
    reason.reasonCodeDescription = option.label;
  }
</script>

<div class="form-row">
  <div class="form-item">
    <Dropdown
      invalid="{checkValid && required && !reason.reasonCode}"
      label="{getLabel('reasonCode')}{required ? '*' : ''}"
      name="reasonCode"
      options="{options}"
      showTextFilter="{options.length > 10}"
      placeholder="Select One"
      value="{reason.reasonCode}"
      on:select="{onReasonCodeSelect}"
    />
  </div>
  <div class="form-item two-col has-action-col">
    <TextInput
      label="{getLabel('comment')}"
      multiline
      name="reason-comment"
      bind:value="{reason.comment}"
    />
  </div>
  <div class="form-item action-col">
    {#if showRemove}
      <IconButton
        icon="{XIcon}"
        title="Remove Reason"
        name="remove-reason"
        on:click="{() => dispatch('remove')}"
      />
    {/if}
  </div>
</div>
