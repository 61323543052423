import {
  PartyContactTypeEnum,
  PartyMaritalStatusEnum,
  PartyPartyRoleEnum,
  PartyRelationshipVestingEnum,
  TaxpayerIdentifierTaxpayerIdentifierTypeEnum,
} from '@xpanseinc/ui-backend-api';
import { InputTypeEnum, OrgTypeEnum } from './typesAndEnums';
import type { Page } from './typesAndEnums';
import { mapDropdownOptions, mapDropdownOptionsFromEnum } from '../../utils/mapDropdownOptions';

const baseAddContactConfig: Page = {
  top: {
    title: undefined,
    fields: {
      contactRole: {
        label: 'Contact Role',
        required: true,
        type: InputTypeEnum.Dropdown,
        options: mapDropdownOptionsFromEnum(PartyPartyRoleEnum, true, 'key'),
        dependency: null,
      },
      contactType: {
        label: 'Contact Type',
        required: true,
        type: InputTypeEnum.Dropdown,
        options: mapDropdownOptionsFromEnum(PartyContactTypeEnum, true, 'key'),
        dependency: null,
      },
    },
  },
  subjectProperty: {
    title: 'Subject Property',
    fields: {
      typeOfResidence: {
        label: 'Provider',
        required: false,
        type: InputTypeEnum.Dropdown,
        options: [],
        dependency: null,
      },
      addressLine1: {
        label: 'Address Line 1',
        required: true,
        type: InputTypeEnum.TextString,
        options: null,
        dependency: null,
      },
      addressLine2: {
        label: 'Address Line 2',
        required: false,
        type: InputTypeEnum.TextString,
        options: null,
        dependency: null,
      },
      city: {
        label: 'City',
        required: true,
        type: InputTypeEnum.TextString,
        options: null,
        dependency: null,
      },
      state: {
        label: 'State',
        required: true,
        type: InputTypeEnum.Dropdown,
        options: [],
        dependency: null,
      },
      zipcode: {
        label: 'Zip Code',
        required: true,
        type: InputTypeEnum.TextString,
        options: null,
        dependency: null,
      },
      county: {
        label: 'County',
        required: false,
        type: InputTypeEnum.TextString,
        options: null,
        dependency: null,
      },
    },
  },
  taxInformation: {
    title: 'Tax Information',
    fields: {
      taxIdType: {
        label: 'Tax ID Type',
        required: false,
        type: InputTypeEnum.Dropdown,
        options: mapDropdownOptionsFromEnum(
          TaxpayerIdentifierTaxpayerIdentifierTypeEnum,
          true,
          'key',
        ),
        dependency: null,
      },
      taxId: {
        label: 'Tax ID',
        required: false,
        type: InputTypeEnum.TextInteger,
        options: null,
        dependency: null,
      },
      maritalStatus: {
        label: 'Marital Status',
        required: false,
        type: InputTypeEnum.Dropdown,
        options: mapDropdownOptionsFromEnum(PartyMaritalStatusEnum, true, 'key'),
        dependency: null,
      },
      bankruptcyInLast7Years: {
        label: 'Bankruptcy in the last 7 years',
        required: false,
        type: InputTypeEnum.Checkbox,
        options: null,
        dependency: null,
      },
      foreclosureInTheLast7years: {
        label: 'Foreclosure in the last 7 years',
        required: false,
        type: InputTypeEnum.Checkbox,
        options: null,
        dependency: null,
      },
      partyToLawsuit: {
        label: 'Party to Lawsuit',
        required: false,
        type: InputTypeEnum.Checkbox,
        options: null,
        dependency: null,
      },
      contactDifferentThanProperty: {
        label: 'Contact address is different than property address',
        required: false,
        type: InputTypeEnum.Checkbox,
        options: null,
        dependency: null,
      },
      sameAsSubjectProperty: {
        label: 'Same as Subject Property',
        required: false,
        type: InputTypeEnum.Checkbox,
        options: null,
        dependency: null,
      },
    },
  },
  contactInformation: {
    title: 'Contact Information',
    fields: {
      firstName: {
        label: 'First Name',
        required: true,
        type: InputTypeEnum.TextString,
        options: null,
        dependency: null,
      },
      middleName: {
        label: 'Middle Name',
        required: false,
        type: InputTypeEnum.TextString,
        options: null,
        dependency: null,
      },
      lastName: {
        label: 'Last Name',
        required: true,
        type: InputTypeEnum.TextString,
        options: null,
        dependency: null,
      },
      ssn: {
        label: 'Social Security Number',
        required: true,
        type: InputTypeEnum.TextString,
        options: null,
        dependency: null,
      },
    },
  },
  companyInformation: {
    title: 'Company Information',
    fields: {
      companyName: {
        label: 'Company Name',
        required: true,
        type: InputTypeEnum.TextString,
        options: null,
        dependency: null,
      },
      relationshipVestingType: {
        label: 'Relationship Vesting Type',
        required: true,
        type: InputTypeEnum.Dropdown,
        options: mapDropdownOptionsFromEnum(PartyRelationshipVestingEnum, true, 'key'),
        dependency: null,
      },
    },
  },
  contactDetails: {
    title: 'Contact Details',
    fields: {
      contactName: {
        label: 'Contact Name',
        required: true,
        type: InputTypeEnum.TextString,
        options: null,
        dependency: null,
      },
      method: {
        label: 'Method',
        required: true,
        type: InputTypeEnum.Dropdown,
        options: mapDropdownOptions(['Email', 'Phone', 'Fax', 'Other']),
        dependency: null,
      },
      details: {
        label: 'Details',
        required: true,
        type: InputTypeEnum.TextArea,
        options: null,
        dependency: null,
      },
      preferred: {
        label: 'Preferred',
        required: false,
        type: InputTypeEnum.Checkbox,
        options: null,
        dependency: null,
      },
    },
  },
  address: {
    title: 'Address',
    fields: {
      addressLine1: {
        label: 'Address Line 1',
        required: false,
        type: InputTypeEnum.TextString,
        options: null,
        dependency: null,
      },
      addressLine2: {
        label: 'Address Line 2',
        required: false,
        type: InputTypeEnum.TextString,
        options: null,
        dependency: null,
      },
      city: {
        label: 'City',
        required: false,
        type: InputTypeEnum.TextString,
        options: null,
        dependency: null,
      },
      state: {
        label: 'State',
        required: false,
        type: InputTypeEnum.Dropdown,
        options: [],
        dependency: null,
      },
      zipCode: {
        label: 'Zip Code',
        required: false,
        type: InputTypeEnum.TextString,
        options: null,
        dependency: null,
      },
      county: {
        label: 'County',
        required: false,
        type: InputTypeEnum.TextString,
        options: null,
        dependency: null,
      },
    },
  },
};

const brokerAddContactConfig: Page = {
  ...baseAddContactConfig,
  top: {
    title: undefined,
    fields: {
      contactRole: {
        label: 'Contact Role',
        required: true,
        type: InputTypeEnum.Dropdown,
        options: mapDropdownOptionsFromEnum(PartyPartyRoleEnum, true, 'key').filter(
          (x) =>
            x.value === PartyPartyRoleEnum.Borrower ||
            x.value === PartyPartyRoleEnum.Builder ||
            x.value === PartyPartyRoleEnum.MortgageBroker ||
            x.value === PartyPartyRoleEnum.Other ||
            x.value === PartyPartyRoleEnum.PropertyAccessContact ||
            x.value === PartyPartyRoleEnum.PropertySeller ||
            x.value === PartyPartyRoleEnum.RealEstateAgent ||
            x.value === PartyPartyRoleEnum.Spouse,
        ),
        dependency: null,
      },
      contactType: {
        label: 'Contact Type',
        required: true,
        type: InputTypeEnum.Dropdown,
        options: mapDropdownOptionsFromEnum(PartyContactTypeEnum, true, 'key'),
        dependency: null,
      },
    },
  },
};

export const addContactConfig = (orgType) => {
  if (orgType === OrgTypeEnum.BROKER || orgType === OrgTypeEnum.WHOLESALE) {
    // If there are org specific configs that need to be returned, return them in their corresponding if blocks that check the OrgTypeEnum
    // See loanDetailsConfig.ts for an example that handles the Broker org type
    return brokerAddContactConfig;
  }

  return baseAddContactConfig;
};
