import { InputTypeEnum } from '../../../../constants/place-order';

const fieldConfig = {
  loanNumber: {
    label: 'Loan Number',
    required: true,
    type: InputTypeEnum.TextString,
    options: null,
    dependency: null,
  },
  borrowers: {
    title: 'Borrower',
    fields: {
      borrowerInformation: {
        label: 'Add Borrower Information',
        required: true,
        type: InputTypeEnum.BorrowerModule,
        options: null,
        dependency: null,
      },
    },
  },
  clientContact: {
    label: 'Client Contact',
    required: false,
    type: InputTypeEnum.TextString,
    options: null,
    dependency: null,
  },
  email: {
    label: 'Email',
    required: true,
    type: InputTypeEnum.Dropdown,
    options: [],
    dependency: null,
  },
  product: {
    label: 'Product',
    required: false,
    type: InputTypeEnum.Dropdown,
    options: [],
    dependency: null,
  },
  provider: {
    label: 'Provider',
    required: false,
    type: InputTypeEnum.Dropdown,
    options: [],
    dependency: null,
  },
};

export default fieldConfig;
