import { PartyContactTypeEnum, PartyPartyRoleEnum } from '@xpanseinc/ui-backend-api';
import { array, object, string } from 'yup';
import { getContactMethodSchema } from './contactMethod';
import type { ContactMethod } from './contactMethod';

export type Contact = {
  contactType?: PartyContactTypeEnum;
  firstName?: string;
  middleName?: string;
  lastName?: string;
  companyName?: string;
  relationshipVestingType?: string;
  contactMethods?: ContactMethod[];
  partyRole?: PartyPartyRoleEnum;
};

const baseContactSchema = {
  contactType: string().required('Contact Type is required'),
  contactMethods: array().min(1, 'At least one Contact Method is required'),
  partyRole: string().required('Contact Role is required'),
};

const individualSchema = {
  firstName: string().required('First Name is required'),
  middleName: string(),
  lastName: string().required('Last Name is required'),
};

const entitySchema = {
  companyName: string().required('Company Name is required'),
  relationshipVestingType: string().required('Relationship Vesting Type is required'),
};

export function getContactSchema(contactType?: PartyContactTypeEnum) {
  const contactMethodSchema = getContactMethodSchema(contactType);
  const contactSchema = {
    ...baseContactSchema,
    contactMethods: baseContactSchema.contactMethods.of(contactMethodSchema),
  };
  if (!contactType) {
    return object().shape(contactSchema);
  }
  if (contactType === PartyContactTypeEnum.Individual) {
    return object().shape({
      ...contactSchema,
      ...individualSchema,
    });
  }
  return object().shape({
    ...contactSchema,
    ...entitySchema,
  });
}
