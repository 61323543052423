<script>
  import { PermissionTypeEnum } from '@xpanseinc/authorization-service-api-rest';
  import { ConfirmationDialog, Popover, Skeleton } from '@xpanseinc/ui-components';
  import { saveSearchApi } from '../../stores/api';
  import { activeFilter, savedSearches, searchFilter } from '../../stores/orders';
  import { Edit2Icon, MoreHorizontalIcon, Trash2Icon } from 'svelte-feather-icons';
  import AdvancedSearchSaveModal from './AdvancedSearchSaveModal.svelte';
  import { profile } from '../../stores/profile';
  import { renderErrorToast, renderSuccessToast } from '../../utils/renderToasts';
  import { currentUser, getAdminStatus } from '../../stores/users';
  import { differenceInYears, sub } from 'date-fns';

  let confirmationModalVisible = false;
  let saveSearchModalVisible = false;
  let searchName = '';
  let previousSearchName = '';
  let isPublic = false;
  let searchToEdit;
  const isReportAdmin = getAdminStatus(PermissionTypeEnum.ReportAdmin);

  const getSavedSearches = async () => {
    if (!$savedSearches) {
      const results = await $saveSearchApi.getSavedSearch();

      savedSearches.set(
        results.map((search) => {
          const newSearch = (search.search = {
            ...JSON.parse(search.search),
          });
          search.search = {
            ...newSearch,
            from: new Date(newSearch.from),
            to: new Date(newSearch.to),
          };
          return { ...search, isOpen: false };
        }),
      );
    }
  };

  const changeFilter = (search) => {
    const { from, to } = search.search;
    // TEMPORARY FIX FOR PDM-1045: If the selected search has a date range of 49 years or greater, it is an "All Time" search and needs to be dynamic
    if (differenceInYears(to, from) >= 49) {
      // Replace the saved dates with the same range, but updated to use the current date as TO and 50 years ago (current "All Time" range) as FROM
      const fiftyYearsAgo = sub(new Date(), { years: 50 });
      search.search.from = fiftyYearsAgo;
      search.search.to = new Date();
    }
    // NOTE: this fix does not change the saved search in the database, only alters the date range that is used once the search is loaded in the UI
    $searchFilter = search.search;
    $activeFilter = { ...search };
  };

  const openMenu = (i) => {
    if (!$savedSearches[i].isOpen) {
      closeAll();
    }
    searchToEdit = undefined;
    $savedSearches[i].isOpen = !$savedSearches[i].isOpen;
  };

  const closeAll = () => {
    $savedSearches.forEach((search) => {
      search.isOpen = false;
    });
  };

  const syncOpenState = (e, i) => {
    $savedSearches[i].isOpen = e.detail.isOpen;
  };

  const openRenameModal = (search) => {
    isPublic = search.isPublic;
    searchToEdit = { ...search };
    searchName = searchToEdit.name;
    previousSearchName = search.name;
    saveSearchModalVisible = true;
  };

  const openDeleteConfirmation = (search) => {
    confirmationModalVisible = true;
    searchToEdit = search;
  };

  const deleteConfirmationClick = async () => {
    try {
      await $saveSearchApi.deleteSavedSearch({
        id: searchToEdit.id,
      });
      renderSuccessToast(
        'Search successfully deleted. This change may take a few minutes to reflect',
      );
      $savedSearches = [...$savedSearches.filter((search) => search.id !== searchToEdit.id)];
    } catch (error) {
      renderErrorToast('Error: Failed to delete search', error);
    }
    confirmationModalVisible = false;
  };

  const renameSavedSearch = async () => {
    if (searchName.length) {
      try {
        await $saveSearchApi.updateSavedSearch({
          id: searchToEdit.id,
          saveSearchRequest: {
            name: searchName,
            search: JSON.stringify(searchToEdit.search),
            isPublic,
          },
        });
        $savedSearches.map((search) => {
          if (search.id === searchToEdit.id) {
            search.name = searchName;
          }
        });
        $savedSearches = [...$savedSearches];
        renderSuccessToast(
          'Search successfully saved. This change may take a few minutes to reflect',
        );
      } catch (error) {
        renderErrorToast('Error: Failed to save search', error);
      }
    } else {
      renderErrorToast('Error: Please enter a name for your search');
    }
    saveSearchModalVisible = false;
  };

  $: if (!saveSearchModalVisible && !confirmationModalVisible) {
    searchToEdit = undefined;
  }

  getSavedSearches();
</script>

<div class="saved-searches">
  <div class="searches">
    <div class="header">Team saved searches</div>
    {#if !$savedSearches}
      <Skeleton height="300px" />
    {:else if !$savedSearches.length}
      <div class="body-small">
        You have no saved searches. Save a search and it will appear here.
      </div>
    {:else}
      <div class="search-list">
        {#each $savedSearches as search, i}
          <Popover
            position="right"
            isOpen="{search.isOpen}"
            on:openStateChange="{(e) => syncOpenState(e, i)}"
          >
            <div slot="target">
              <div
                class="saved-search"
                class:active="{$activeFilter.id === search.id || $savedSearches[i].isOpen}"
              >
                <button
                  class="btn basic"
                  name="search"
                  on:click="{() => {
                    changeFilter(search);
                  }}"
                >
                  {search?.name || 'No saved name'}
                </button>
                {#if isReportAdmin}
                  <button
                    name="popover-menu-btn"
                    class="btn basic popover-menu-btn"
                    class:show-popover-menu-btn="{$savedSearches[i].isOpen}"
                    on:click="{() => {
                      openMenu(i);
                    }}"
                  >
                    <MoreHorizontalIcon size="16" />
                  </button>
                {/if}
              </div>
            </div>

            <div slot="content" class="popover">
              <div class="popover-title">{search?.name || 'No saved name'}</div>
              <div class="popover-created-by">
                {search?.user ? `by ${search.user}` : 'User unavailable'}
              </div>
              <button
                class="saved-search-action rename btn-unstyled"
                disabled="{!(
                  search?.orgId === $currentUser?.externalOrganizationId ||
                  search?.orgType === 'PlatformOwner'
                )}"
                on:click="{() => openRenameModal(search)}"
              >
                <Edit2Icon size="16" />
                <span class="action-text ">
                  {$profile?.hasLenderAccess ? 'Edit name and sharing' : 'Edit name'}
                </span>
              </button>
              <button
                class="saved-search-action delete btn-unstyled"
                disabled="{!(
                  search?.orgId === $currentUser?.externalOrganizationId ||
                  search?.orgType === 'PlatformOwner'
                )}"
                on:click="{() => openDeleteConfirmation(search)}"
              >
                <Trash2Icon size="16" />
                <span class="action-text"> Delete </span>
              </button>
            </div>
          </Popover>
        {/each}
      </div>
    {/if}
  </div>
</div>

<ConfirmationDialog
  confirmColor="warning"
  confirmLabel="Delete Search"
  message="Are you sure? This will remove the Search Shortcut for everyone on the team. This cannot be undone."
  title="Delete Search Shortcut"
  on:click="{deleteConfirmationClick}"
  bind:visible="{confirmationModalVisible}"
/>

{#if saveSearchModalVisible}
  <AdvancedSearchSaveModal
    buttonLabel="Save"
    title="Saved search"
    previousSearchName="{previousSearchName}"
    bind:visible="{saveSearchModalVisible}"
    bind:isPublic
    bind:searchName
    on:click="{() => {
      renameSavedSearch();
    }}"
  />
{/if}

<style>
  .saved-searches {
    max-width: 240px;
  }

  .searches {
    color: var(--textDark);
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.25rem;
    letter-spacing: -0.05px;
  }

  .search-list {
    max-height: 80vh;
    overflow-y: auto;
    overflow-x: hidden;
    margin-left: -16px;
  }

  .body-small {
    color: var(--gray5);
  }

  .header {
    margin-bottom: 0.75rem;
  }

  .saved-search {
    display: inline-flex;
    justify-content: space-between;
    width: 100%;
    color: var(--actionPrimaryDefault);
    border-radius: 4px;
    gap: 0.75rem;
  }

  .active,
  .saved-search:hover {
    background-color: var(--focusLight);
    color: var(--actionPrimaryHover) !important;
    transition: var(--transitionHover);
  }

  .btn.basic:focus-visible,
  .btn.basic:hover,
  .btn.basic:active {
    background-color: transparent;
    box-shadow: none;
  }

  .btn {
    min-width: initial;
    text-align: left;
    font-size: 16px;
    height: auto;
    word-wrap: break-word;
    padding: 8px 16px;
  }

  .popover-menu-btn {
    opacity: 0;
    transition: opacity 0.2s;
  }

  .show-popover-menu-btn {
    opacity: 1;
  }

  .saved-search:hover .popover-menu-btn {
    opacity: 1;
  }

  .popover {
    padding: 16px;
  }

  .popover-title {
    font-size: 14px;
    font-weight: 500;
    padding-bottom: 4px;
    color: var(--grey10);
  }

  .popover-created-by {
    font-size: 11px;
    font-weight: 400;
    color: var(--gray8);
    padding-bottom: 8px;
    border-bottom: 1px solid var(--gray2);
  }

  .saved-search-action {
    font-size: 16px;
    color: var(--actionPrimaryDefault);
    display: flex;
    padding-top: 10px;
    cursor: pointer;
    transition: color 0.3s;
  }

  .saved-search-action:disabled {
    color: var(--gray4);
  }

  .delete:hover:not([disabled]) {
    color: var(--negativeDefault);
  }

  .action-text {
    margin-left: 11px;
  }
</style>
