import { writable } from 'svelte/store';
import { reportSlaApi } from './api';
import { alphabetically } from '../utils/alphabetically';

export const usStates = writable([]);

export async function fetchStates() {
  usStates.subscribe((states) => {
    if (!states.length) {
      reportSlaApi.subscribe(async (api) => {
        if (api) {
          const statesRaw = await api.getStates();
          usStates.set([
            ...statesRaw
              .map(({ code, name }) => ({
                label: name,
                value: code,
              }))
              .sort(alphabetically),
          ]);
        }
      });
    }
  });
}
