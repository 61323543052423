export enum DomainTypes {
  LOCAL = 'LOCAL',
  DEV = 'DEV',
  INT = 'INT',
  EXT = 'EXT',
  UAT = 'UAT',
  PROD = 'PROD',
}

export function detectDomain(): string {
  const currentHost = window.location.host;

  if (currentHost.indexOf('localhost') > -1) {
    return DomainTypes.LOCAL;
  }

  if (currentHost.indexOf('-dev') > -1) {
    return DomainTypes.DEV;
  }

  if (currentHost.indexOf('-qa-int') > -1) {
    return DomainTypes.INT;
  }

  if (currentHost.indexOf('-qa-ext') > -1) {
    return DomainTypes.EXT;
  }

  if (currentHost.indexOf('-uat') > -1) {
    return DomainTypes.UAT;
  }

  return DomainTypes.PROD;
}

export function detectDomainForTransformerPath(): string {
  const currentHost = window.location.host;

  if (currentHost.indexOf('-dev') > -1 || currentHost.indexOf('localhost') > -1) {
    return 'dev';
  }

  if (currentHost.indexOf('-qa-int') > -1) {
    return 'qa-int';
  }

  if (currentHost.indexOf('-qa-ext') > -1) {
    return 'qa-ext';
  }

  if (currentHost.indexOf('-uat') > -1) {
    return 'uat';
  }

  return undefined;
}

export function detectDomainTitle(): string {
  const currentHost = window.location.host;

  if (currentHost.indexOf('localhost') > -1) {
    return 'Local:';
  }

  if (currentHost.indexOf('-dev') > -1) {
    return 'DEV:';
  }

  if (currentHost.indexOf('-qa-int') > -1) {
    return 'INT:';
  }

  if (currentHost.indexOf('-qa-ext') > -1) {
    return 'EXT:';
  }

  return undefined;
}

export function isDevEnv(): boolean {
  const domain = detectDomain();
  return domain === DomainTypes.LOCAL || domain === DomainTypes.DEV;
}
