<script>
  import { Dropdown, IconButton, TextInput } from '@xpanseinc/ui-components';
  import {
    PartyContactTypeEnum,
    PartyPartyRoleEnum,
    PartyRelationshipVestingEnum,
    PartyEmailRoleEnum,
    PartyPhoneRoleEnum,
  } from '@xpanseinc/ui-backend-api';
  import { startCase, camelCase } from 'lodash-es';
  import { createEventDispatcher } from 'svelte';
  import { SaveIcon, XIcon } from 'svelte-feather-icons';
  import { geoApi } from '../../stores/api';
  import { fetchStates, usStates } from '../../stores/usStates';
  import { getLabel } from '../../constants/sendEventDataLabels';
  import { imask, masks } from '../../constants/inputMasking';
  import { emailIsValid, phoneIsValid, zipIsValid } from '../../utils/validation';

  export let currentContact;
  export let sendEventForm;
  export let disabled = false;

  fetchStates();

  const dispatch = createEventDispatcher();
  let checkValid = false;
  let isValid = false;
  let emailValid = false;
  let phoneValid = false;
  let zipValid = false;
  let fetchingZipInfo = false;
  delete PartyEmailRoleEnum.Mobile;
  currentContact.phoneMasked = '';

  function buildOptions(enumOptions) {
    return Object.values(enumOptions).map((option) =>
      option === 'LLC'
        ? {
            label: option,
            value: option,
          }
        : {
            label: startCase(camelCase(option)),
            value: option,
          },
    );
  }

  function onKeyup(e) {
    currentContact[e.target.name] = e.target.value;
  }

  async function onZipcodeChange(e) {
    const { value } = e.target;
    if (!value || !zipValid) {
      return;
    }

    fetchingZipInfo = true;
    try {
      const { city, county, state } = await $geoApi.getZipInfo({ zipCode: value });
      currentContact.address.city = city;
      currentContact.address.county = county;
      currentContact.address.state = state;
    } catch (error) {
      console.error(error);
    }
    fetchingZipInfo = false;
  }

  function onAddressChange(e) {
    currentContact.address[e.target.name] = e.target.value;
  }

  function onSaveContact() {
    checkValid = true;
    if (isValid) {
      dispatch('save');
    }
  }

  $: {
    emailValid = emailIsValid(currentContact.email);
    phoneValid = phoneIsValid(currentContact.phone);
    zipValid = zipIsValid(currentContact.address.zip);
    if (
      !currentContact.partyRole ||
      !currentContact.contactType ||
      (currentContact.email && !emailValid) ||
      (currentContact.phone && !phoneValid) ||
      (currentContact.address.zip && !zipValid)
    ) {
      isValid = false;
    } else if (isIndividual) {
      isValid =
        !!currentContact.firstName &&
        !!currentContact.lastName &&
        emailValid &&
        !!currentContact.emailRole &&
        phoneValid &&
        !!currentContact.phoneRole;
    } else if (!isIndividual) {
      isValid = !!currentContact.fullName;
    }
  }

  if (sendEventForm.eventType === 'NotaryAssigned') {
    currentContact.contactType = PartyContactTypeEnum.Individual;
    currentContact.partyRole = PartyPartyRoleEnum.Notary;
  }

  $: isIndividual = currentContact.contactType === PartyContactTypeEnum.Individual;
</script>

<div class="form-row">
  <div class="form-item">
    <Dropdown
      disabled="{disabled}"
      invalid="{checkValid && !currentContact.contactType}"
      label="{getLabel('contactType')}*"
      name="contactType"
      options="{buildOptions(PartyContactTypeEnum)}"
      placeholder="Select One"
      bind:value="{currentContact.contactType}"
      on:select="{({ detail }) => {
        currentContact.contactType = detail.value;
        currentContact.fullName = '';
        if (detail.value !== 'INDIVIDUAL') {
          currentContact.firstName = '';
          currentContact.lastName = '';
          currentContact.phone = '';
          currentContact.phoneRole = null;
          currentContact.email = '';
          currentContact.emailRole = null;
        }
      }}"
    />
  </div>
  <div class="form-item">
    <Dropdown
      disabled="{disabled}"
      invalid="{checkValid && !currentContact.partyRole}"
      label="{getLabel('partyRole')}*"
      name="partyRole"
      options="{buildOptions(PartyPartyRoleEnum)}"
      placeholder="Select One"
      bind:value="{currentContact.partyRole}"
      on:select="{({ detail }) => {
        currentContact.partyRole = detail.value;
      }}"
    />
  </div>
  <div class="form-item form-item--action">
    <IconButton
      color="warning"
      icon="{XIcon}"
      name="cancel-party"
      title="Cancel Contact"
      on:click="{() => dispatch('cancel')}"
    />
    <IconButton
      color="valid"
      icon="{SaveIcon}"
      name="save-party"
      title="Save Contact"
      on:click="{onSaveContact}"
    />
  </div>
</div>

<div class="form-row">
  <div class="form-item">
    <TextInput
      disabled="{isIndividual}"
      invalid="{!isIndividual && checkValid && !currentContact.fullName}"
      label="{getLabel('fullName')}{isIndividual ? '' : '*'}"
      name="fullName"
      value="{currentContact.fullName}"
      on:keyup="{onKeyup}"
    />
  </div>
  {#if !sendEventForm.eventType === 'NotaryAssigned'}
    <div class="form-item">
      <Dropdown
        label="{getLabel('relationshipVesting')}"
        name="relationshipVesting"
        options="{buildOptions(PartyRelationshipVestingEnum)}"
        placeholder="Select One"
        bind:value="{currentContact.relationshipVesting}"
        on:select="{({ detail }) => {
          currentContact.relationshipVesting = detail.value;
        }}"
      />
    </div>
  {/if}
</div>
<div class="form-row">
  <div class="form-item two-col">
    <TextInput
      label="{getLabel('line1')}"
      name="line1"
      value="{currentContact.address.line1}"
      on:keyup="{onAddressChange}"
    />
  </div>
</div>
<div class="form-row">
  <div class="form-item two-col">
    <TextInput
      label="{getLabel('line2')}"
      name="line2"
      value="{currentContact.address.line2}"
      on:keyup="{onAddressChange}"
    />
  </div>
</div>
<div class="form-row">
  <div class="form-item">
    <TextInput
      label="{getLabel('city')}"
      name="city"
      value="{currentContact.address.city}"
      on:keyup="{onAddressChange}"
    />
  </div>
  <div class="form-item">
    <TextInput
      label="{getLabel('county')}"
      name="county"
      value="{currentContact.address.county}"
      on:keyup="{onAddressChange}"
    />
  </div>
</div>
<div class="form-row">
  <div class="form-item">
    <TextInput
      invalid="{currentContact.address.zip && !zipValid}"
      label="{getLabel('zip')}"
      loading="{fetchingZipInfo}"
      name="zip"
      inputmode="numeric"
      pattern="[0-9]*"
      title="Please enter a valid zip code"
      value="{currentContact.address.zip}"
      on:keyup="{onAddressChange}"
      on:blur="{onZipcodeChange}"
    />
  </div>
  <div class="form-item">
    <Dropdown
      label="{getLabel('state')}"
      name="state"
      options="{$usStates}"
      placeholder="Select One"
      bind:value="{currentContact.address.state}"
    />
  </div>
</div>

<div class="form-row">
  <div class="form-item">
    <TextInput
      disabled="{!isIndividual}"
      invalid="{isIndividual && checkValid && !currentContact.firstName}"
      label="{getLabel('firstName')}{isIndividual ? '*' : ''}"
      name="firstName"
      value="{currentContact.firstName}"
      on:keyup="{onKeyup}"
    />
  </div>
  <div class="form-item">
    <TextInput
      disabled="{!isIndividual}"
      invalid="{isIndividual && checkValid && !currentContact.lastName}"
      label="{getLabel('lastName')}{isIndividual ? '*' : ''}"
      name="lastName"
      value="{currentContact.lastName}"
      on:keyup="{onKeyup}"
    />
  </div>
</div>
<div class="form-row">
  <div class="form-item">
    <TextInput
      disabled="{!isIndividual}"
      invalid="{(isIndividual && checkValid && !currentContact.email) ||
        (currentContact.email && !emailValid)}"
      label="{getLabel('email')}{isIndividual ? '*' : ''}"
      name="email"
      title="Please enter a valid email address"
      type="email"
      value="{currentContact.email}"
      on:keyup="{onKeyup}"
    />
  </div>
  <div class="form-item">
    <Dropdown
      disabled="{!isIndividual}"
      invalid="{isIndividual && checkValid && !currentContact.emailRole}"
      label="{getLabel('emailRole')}{isIndividual ? '*' : ''}"
      name="emailRole"
      options="{buildOptions(PartyEmailRoleEnum)}"
      placeholder="Select One"
      bind:value="{currentContact.emailRole}"
    />
  </div>
</div>
<div class="form-row">
  <div class="form-item">
    <TextInput
      maskPackage="{imask}"
      maskOptions="{masks.phone}"
      disabled="{!isIndividual}"
      invalid="{(isIndividual && checkValid && !currentContact.phone) ||
        (currentContact.phone && !phoneValid)}"
      label="{getLabel('phone')}{isIndividual ? '*' : ''}"
      name="phone"
      title="Please enter a valid phone mumber"
      value="{currentContact.phoneMasked}"
      on:accept="{({ detail: { masked } }) => {
        onKeyup({
          target: {
            name: 'phone',
            value: masked.unmaskedValue,
          },
        });
        onKeyup({
          target: {
            name: 'phoneMasked',
            value: masked.typedValue,
          },
        });
      }}"
    />
  </div>
  <div class="form-item">
    <Dropdown
      disabled="{!isIndividual}"
      invalid="{isIndividual && checkValid && !currentContact.phoneRole}"
      label="{getLabel('phoneRole')}{isIndividual ? '*' : ''}"
      name="phoneRole"
      options="{buildOptions(PartyPhoneRoleEnum)}"
      placeholder="Select One"
      bind:value="{currentContact.phoneRole}"
    />
  </div>
</div>
