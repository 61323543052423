<script>
  import {
    Button,
    Checkbox,
    DateRangeInput,
    Dropdown,
    IconButton,
    LoadingModal,
    TextInput,
    Tooltip,
  } from '@xpanseinc/ui-components';
  import { differenceInDays } from 'date-fns';
  import { MinusIcon, PlusIcon, InfoIcon, ArrowLeftIcon } from 'svelte-feather-icons';
  import ViewHeader from '../components/ViewHeader.svelte';
  import { zipIsValid } from '../utils/validation';
  import { fetchProducts, products } from '../stores/products';
  import { link } from 'svelte-spa-router';
  import { profile } from '../stores/profile';
  import { searchFilter, stagedFilter } from '../stores/orders';
  import { currentUserPermissions } from '../stores/users';
  import { PlaceOrderProductTypeEnum } from '../stores/placeOrder';
  import { mapDropdownOptions, mapDropdownOptionsFromEnum } from '../utils/mapDropdownOptions';
  import { alphabetically } from '../utils/alphabetically';
  import IconLink from '../components/IconLink.svelte';
  import {
    attachmentCodes,
    eventStatuses,
    workflowVersions,
    vendors,
    fetchVendors,
    events,
    deliveryStatuses,
  } from '../stores/reports';
  import { attachmentApi, reportApi, workflowVersionApi } from '../stores/api';
  import { SearchRequestOrderStatusesEnum } from '@xpanseinc/ui-backend-api';
  import { SearchRequestEventSentFromEnum } from '@xpanseinc/report-service-api';
  import { push } from 'svelte-spa-router';
  import { XIcon } from 'svelte-feather-icons';
  import Layout from '../components/Layout.svelte';
  import urls from '../urls';
  import { fetchStates, usStates } from '../stores/usStates';
  import pageTitle from '../utils/pageTitle';
  import { OrderAttribute } from '../constants/place-order';
  const title = 'Filters';
  let attachmentLabels = [];
  let stateOptions = [];
  let eventStatusOptions = [];
  let vendorOptions = [];
  let eventOptions = [];
  let deliveryStatusOptions = [];
  let loadingOptions = true;
  let versionOptions = [];
  const eventRecievedStatusOptions = ['Is the latest', 'Has not occurred', 'Has occurred'];
  const today = new Date();

  fetchProducts();
  fetchStates();
  fetchVendors();

  async function fetchEventStatuses() {
    if (!$eventStatuses) {
      $eventStatuses = await $reportApi?.getEventCodes({});
    }
  }

  async function fetchEvents() {
    if (!$events) {
      $events = await $reportApi?.getEventCodes({ showAll: true });
    }
  }

  async function fetchDeliveryStatuses() {
    if (!$deliveryStatuses) {
      $deliveryStatuses = await $reportApi?.getDeliveryStatuses({});
    }
  }

  async function fetchAttachments() {
    if (!$attachmentCodes) {
      $attachmentCodes = await $reportApi?.getAttachmentCodes();
    }
  }

  async function fetchWorkflowVersions() {
    if (!$workflowVersions) {
      $workflowVersions = await $workflowVersionApi?.findAll();
    }
  }

  async function fetchMenuOptions() {
    await Promise.all([
      fetchEventStatuses(),
      fetchAttachments(),
      fetchEvents(),
      fetchDeliveryStatuses(),
      fetchWorkflowVersions(),
    ]);

    eventStatusOptions = $eventStatuses
      ?.filter((es) => !!es)
      .map((es) => ({ label: es.name, value: es.name, products: es.products }))
      .sort(alphabetically);
    eventOptions = $events
      ?.filter((es) => !!es)
      .map((es) => ({ label: es.name, value: es.name, products: es.products }))
      .sort(alphabetically);

    versionOptions = Array.from($workflowVersions)?.map((c) => ({
      label: `${c.code} - ${c.label}`,
      value: c.code,
    }));

    deliveryStatusOptions = mapDropdownOptions($deliveryStatuses);
    attachmentLabels = await $attachmentCodes;
    stateOptions = $usStates;
    loadingOptions = false;
  }

  fetchMenuOptions();

  const options = {};

  let showMoreFilters = false;
  let validZip;
  let missingAttachmentOptions;
  let attachmentOptions;
  let invalidDate = false;
  $: {
    validZip = zipIsValid($stagedFilter?.zip);

    if (
      (!$stagedFilter?.event?.length && $searchFilter.eventReceivedStatus !== 'Is the latest') ||
      ($stagedFilter?.deliveryStatus?.length &&
        $stagedFilter.eventReceivedStatus !== 'Is the latest')
    ) {
      $stagedFilter.eventReceivedStatus = 'Is the latest';
      $searchFilter.eventReceivedStatus = 'Is the latest';
    }
    if (!$stagedFilter?.event?.length) {
      $stagedFilter.eventSentFrom = 'BOTH';
      $searchFilter.eventSentFrom = 'BOTH';
    }
    if ($stagedFilter.isClearToCloseReport) {
      $stagedFilter = { ...$stagedFilter, document: [], noDocument: [] };
    }

    attachmentOptions = filterAttachmentOptions($stagedFilter?.noDocument, $stagedFilter?.product);

    missingAttachmentOptions = filterAttachmentOptions(
      $stagedFilter?.document,
      $stagedFilter?.product,
    );

    eventStatusOptions = filterEventOptions($eventStatuses, $stagedFilter?.product);

    eventOptions = filterEventOptions($events, $stagedFilter?.product);

    $stagedFilter = $stagedFilter;
  }

  function filterAttachmentOptions(attachmentCounterpart, products) {
    return (
      attachmentLabels
        .filter((attachment) => !attachmentCounterpart?.includes(attachment.value))
        // Filter based on the selected product(s)
        .filter(
          (attachment) =>
            products?.length === 0 ||
            products?.some((product) => attachment?.products?.includes(product.toUpperCase())),
        )
        .sort(alphabetically)
    );
  }

  function filterEventOptions(eventArr, products) {
    return (
      eventArr
        ?.filter((es) => !!es)
        .map((es) => ({ label: es.name, value: es.name, products: es.products }))
        // Filter based on the selected product(s)
        .filter(
          (event) =>
            products?.length === 0 ||
            products?.some((product) => event?.products?.indexOf(product) >= 0),
        )
        .sort(alphabetically)
    );
  }

  function filterStagedFilterByProduct() {
    attachmentOptions = filterAttachmentOptions($stagedFilter?.noDocument, $stagedFilter?.product);
    missingAttachmentOptions = filterAttachmentOptions(
      $stagedFilter?.document,
      $stagedFilter?.product,
    );
    eventStatusOptions = filterEventOptions($eventStatuses, $stagedFilter?.product);
    eventOptions = filterEventOptions($events, $stagedFilter?.product);

    $stagedFilter.document = $stagedFilter?.document?.filter((document) =>
      attachmentOptions?.find((option) => option.value === document),
    );
    $stagedFilter.noDocument = $stagedFilter?.noDocument?.filter((document) =>
      missingAttachmentOptions?.find((option) => option.value === document),
    );
    $stagedFilter.eventDate =
      eventOptions?.find((option) => option.value === $stagedFilter.eventDate)?.value ?? '';
    $stagedFilter.event = $stagedFilter.event?.filter((event) =>
      eventStatusOptions?.find((option) => option.value === event),
    );

    if ($stagedFilter.product.includes('Closing')) {
      $stagedFilter.attributes = {
        [OrderAttribute.ECLOSING_TYPE]: null,
        [OrderAttribute.ENOTE_INDICATOR]: null,
        [OrderAttribute.ECLOSING_REMOTE_ONLINE_NOTARIZATION_INDICATOR]: null,
      };
    } else {
      $stagedFilter.attributes = {};
    }
  }

  const buildMenuOptions = async (attachments, missinngAttachments) => {
    options.orderStatus = mapDropdownOptions(Object.values(SearchRequestOrderStatusesEnum));
    options.product = $products || [];
    options.states = stateOptions;
    options.attachments = attachments;
    options.noAttachments = missinngAttachments;
    options.received = [...mapDropdownOptions(eventRecievedStatusOptions)];
    options.sentBy = [
      ...mapDropdownOptionsFromEnum(SearchRequestEventSentFromEnum, true, 'key').filter(
        (item) => item.label !== 'Predecessor Receiver',
      ),
    ];
    options.version = versionOptions;
  };

  const onDateChange = ({ detail }) => {
    invalidDate = false;
    const [from, to] = detail;

    const isRelative = to.getDate() === today.getDate();
    let relative = null;
    if (isRelative) {
      relative = differenceInDays(today, from);
    }

    $stagedFilter = { ...$stagedFilter, from, to, relative };
  };

  $: buildMenuOptions(attachmentOptions, missingAttachmentOptions);
</script>

<svelte:head>
  <title>{pageTitle(title)}</title>
</svelte:head>

<Layout>
  <div slot="center">
    {#if loadingOptions}
      <LoadingModal text="Loading" isFullScreen="true" />
    {:else}
      <div class="container advanced-search-container" data-testid="advanced-search">
        <div class="breadcrumb-container">
          <div class="breadcrumb-item">
            <a href="{urls.orders}" use:link>Orders</a>
          </div>
          <div class="breadcrumb-item breadcrumb-item--active">Filters</div>
        </div>
        <div class="back-header-container">
          <div
            class="back-container"
            on:click="{() => {
              push(urls.orders);
            }}"
          >
            <ArrowLeftIcon size="24" />
          </div>
          <ViewHeader>Filters</ViewHeader>
        </div>
        <div class="main-filters">
          <div class="filter-container">
            <div class="left-column">
              <h4 class="header-l header-l--bold">General filters</h4>
              <div class="id">
                <TextInput
                  label="Loan no. or Transaction ID"
                  name="id"
                  value="{$stagedFilter.loan}"
                  dataTestId="advanced-search-loan-no"
                  on:change="{(e) => {
                    $stagedFilter.loan = e.target.value;
                  }}"
                />
              </div>
              <div class="product">
                <Dropdown
                  label="Product"
                  name="product"
                  multiple
                  showSelectAll
                  options="{options.product}"
                  placeholder="All"
                  on:select="{filterStagedFilterByProduct}"
                  bind:value="{$stagedFilter.product}"
                />
              </div>
              {#if $stagedFilter.product.includes('Closing')}
                <Dropdown
                  label="Eclose Type"
                  name="ecloseType"
                  options="{[
                    { label: 'None', value: null },
                    { label: 'Full', value: 'Full' },
                    { label: 'Hybrid', value: 'Hybrid' },
                  ]}"
                  bind:value="{$stagedFilter.attributes[OrderAttribute.ECLOSING_TYPE]}"
                />
                <Dropdown
                  label="Enote Indicator"
                  name="enoteIndicator"
                  options="{[
                    { label: 'None', value: null },
                    { label: 'True', value: true },
                    { label: 'False', value: false },
                  ]}"
                  bind:value="{$stagedFilter.attributes[OrderAttribute.ENOTE_INDICATOR]}"
                />
                <Dropdown
                  label="RON Indicator"
                  name="eronIndicator"
                  options="{[
                    { label: 'None', value: null },
                    { label: 'True', value: true },
                    { label: 'False', value: false },
                  ]}"
                  bind:value="{$stagedFilter.attributes[
                    OrderAttribute.ECLOSING_REMOTE_ONLINE_NOTARIZATION_INDICATOR
                  ]}"
                />
              {/if}
              {#if $currentUserPermissions.product[PlaceOrderProductTypeEnum.Closing] || $currentUserPermissions.product[PlaceOrderProductTypeEnum.Title]}
                <div class="version">
                  <Dropdown
                    label="Version"
                    name="version"
                    options="{options.version}"
                    placeholder="All"
                    bind:value="{$stagedFilter.vtpClientId}"
                  />
                </div>
              {/if}
              <div class="order-status">
                <Dropdown
                  label="Order state"
                  multiple
                  showSelectAll
                  name="orderStatus"
                  options="{options.orderStatus}"
                  placeholder="All"
                  bind:value="{$stagedFilter.status}"
                />
              </div>
              {#if $profile?.hasLenderAccess}
                <div class="provider">
                  <Dropdown
                    label="Provider"
                    multiple
                    showSelectAll
                    name="provider"
                    options="{$vendors}"
                    placeholder="All"
                    bind:value="{$stagedFilter.vendor}"
                  />
                </div>
              {/if}
            </div>
            <div class="right-column">
              <div class="date">
                <h4 class="header-l header-l--bold">Date</h4>
                <div class="date-container">
                  <div class="date-applies">
                    <Dropdown
                      label="Show orders where..."
                      name="eventDate"
                      options="{[...eventOptions, { label: 'Order Date', value: '' }].sort(
                        alphabetically,
                      )}"
                      bind:value="{$stagedFilter.eventDate}"
                    />
                  </div>
                  <div class="date-range">
                    <DateRangeInput
                      label="...Occurs within"
                      allowInput="true"
                      name="dateRange"
                      from="{$stagedFilter.from}"
                      to="{$stagedFilter.to}"
                      position="auto right"
                      on:change="{onDateChange}"
                      on:invalidSelection="{() => {
                        invalidDate = true;
                      }}"
                      bind:invalid="{invalidDate}"
                    />
                    {#if invalidDate}
                      <div class="body-small text-error">
                        Must be a date range that occurs in the past (mm/dd/yy-mm/dd/yy)
                      </div>
                    {/if}
                  </div>
                </div>
              </div>
              <div class="events">
                <h4 class="header-l header-l--bold">Order steps</h4>
                <div class="order-steps">
                  <div class="event-status">
                    <Dropdown
                      label="Show order steps..."
                      multiple
                      showSelectAll
                      name="eventStatus"
                      options="{eventStatusOptions}"
                      placeholder="All"
                      bind:value="{$stagedFilter.event}"
                    />
                  </div>
                  <div class="event-received">
                    <Dropdown
                      disabled="{!$stagedFilter.event.length ||
                        $stagedFilter.deliveryStatus.length}"
                      label="...where the step"
                      name="eventReceived"
                      options="{options.received}"
                      bind:value="{$stagedFilter.eventReceivedStatus}"
                    />
                  </div>
                  <div>
                    <Dropdown
                      disabled="{!$stagedFilter.event.length}"
                      label="Sent by"
                      name="sentBy"
                      options="{options.sentBy}"
                      bind:value="{$stagedFilter.eventSentFrom}"
                    />
                  </div>
                </div>
              </div>
              <div class="attachment">
                <h4 class="header-l header-l--bold">Attachments</h4>
                <div class="attachments-container">
                  <div class="attachments">
                    <Dropdown
                      disabled="{$stagedFilter.isClearToCloseReport || !options.attachments.length}"
                      label="Attachments received"
                      multiple
                      showSelectAll
                      name="attachments"
                      options="{options.attachments}"
                      placeholder="All"
                      bind:value="{$stagedFilter.document}"
                    />
                  </div>
                  <div class="missing-attachments">
                    <Dropdown
                      disabled="{$stagedFilter.isClearToCloseReport || !options.attachments.length}"
                      label="Attachments not received"
                      name="missingAttachments"
                      multiple
                      showSelectAll
                      options="{options.noAttachments}"
                      placeholder="None"
                      bind:value="{$stagedFilter.noDocument}"
                    />
                  </div>
                  <div class="clear-to-close">
                    <Checkbox
                      name="clearToClose"
                      bind:checked="{$stagedFilter.isClearToCloseReport}"
                      >Clear to Close Documentation Report</Checkbox
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="filter-toggle">
          <IconLink
            label="{showMoreFilters ? 'Fewer filters' : 'More filters'}"
            icon="{showMoreFilters ? MinusIcon : PlusIcon}"
            on:click="{() => {
              showMoreFilters = !showMoreFilters;
            }}"
          />
        </div>
        {#if showMoreFilters}
          <h4 class="header-l header-l--bold">
            Delivery status
            <Tooltip
              text="Show only orders with the delivery status of the latest order state and/or order step."
            >
              <InfoIcon size="1x" />
            </Tooltip>
          </h4>
          <div class="delivery-status">
            <Dropdown
              label="Latest delivery status"
              options="{deliveryStatusOptions}"
              multiple
              showSelectAll
              name="state"
              placeholder="All"
              bind:value="{$stagedFilter.deliveryStatus}"
            />
          </div>
          <h4 class="header-l header-l--bold">Borrower and property</h4>
          <div class="more-filters">
            <div class="name-container">
              <div class="first-name">
                <TextInput
                  label="Borrower first name"
                  name="firstName"
                  value="{$stagedFilter.fname}"
                  on:change="{(e) => {
                    $stagedFilter.fname = e.target.value;
                  }}"
                />
              </div>
              <div class="last-name">
                <TextInput
                  label="Borrower last name"
                  name="lastName"
                  value="{$stagedFilter.lname}"
                  on:change="{(e) => {
                    $stagedFilter.lname = e.target.value;
                  }}"
                />
              </div>
            </div>
            <div class="street-and-zip-container">
              <div class="street-address">
                <TextInput
                  label="Street number or street address"
                  name="streetAddress"
                  value="{$stagedFilter.address}"
                  on:change="{(e) => {
                    $stagedFilter.address = e.target.value;
                  }}"
                />
              </div>
              <div class="zip-code">
                <TextInput
                  invalid="{$stagedFilter.zip && !validZip}"
                  label="Zip Code"
                  name="zipCode"
                  value="{$stagedFilter.zip}"
                  on:change="{(e) => {
                    $stagedFilter.zip = e.target.value;
                  }}"
                />
              </div>
            </div>
            <div class="state-container">
              <div class="state">
                <Dropdown
                  label="State"
                  options="{stateOptions}"
                  multiple
                  showSelectAll
                  name="state"
                  placeholder="All"
                  bind:value="{$stagedFilter.state}"
                />
              </div>
              <div class="city">
                <TextInput
                  label="City"
                  name="city"
                  value="{$stagedFilter.city}"
                  on:change="{(e) => {
                    $stagedFilter.city = e.target.value;
                  }}"
                />
              </div>
              <div class="county">
                <TextInput
                  label="County"
                  name="county"
                  value="{$stagedFilter.county}"
                  on:change="{(e) => {
                    $stagedFilter.county = e.target.value;
                  }}"
                />
              </div>
            </div>
          </div>
        {/if}
        <div class="search-btn">
          <Button
            color="primary"
            label="Search"
            disabled="{$stagedFilter.zip && !validZip}"
            name="search"
            on:click="{() => {
              $stagedFilter.pageNumber = 0;
              $searchFilter = $stagedFilter;
              push(urls.orders);
            }}"
          />
        </div>
      </div>
    {/if}
  </div>
</Layout>

<style>
  .back-header-container {
    display: flex;
  }

  .back-container {
    padding: 5px 16px 8px 4px;
    cursor: pointer;
    color: var(--gray11);
    transition: var(--transitionHover);
    height: 26px;
  }

  .back-container:hover {
    color: var(--primaryDefault);
  }

  .advanced-search-container {
    position: relative;
    max-width: 782px;
  }

  .main-filters {
    position: relative;
  }

  .filter-container {
    display: inline-flex;
    width: 100%;
  }

  .filter-container .left-column {
    position: relative;
    width: 250px;
    max-width: 250px;
    display: flex;
    flex-direction: column;
    margin-right: 2rem;
  }

  .filter-container .left-column :global(.text-input),
  .filter-container .left-column :global(.dropdown) {
    width: 100% !important;
    max-width: 250px;
    margin-bottom: 1rem;
  }

  .filter-container .right-column {
    position: relative;
    width: calc(500px + 1rem);
    max-width: calc(500px + 1rem);
    display: flex;
    flex-direction: column;
  }

  .filter-container .right-column :global(.text-input),
  .filter-container .right-column :global(.dropdown) {
    width: 100% !important;
    max-width: 250px;
    min-width: 250px;
  }

  .filter-container .right-column :global(.dropdown .text-input) {
    min-width: auto;
  }

  .filter-container .right-column .date-container {
    display: inline-flex;
  }

  .filter-container .right-column .date-applies {
    margin-right: 1rem;
  }

  .filter-container .right-column .events .header-l {
    margin-top: 1rem;
  }

  .filter-container .right-column .attachment .header-l {
    margin-top: 1rem;
  }

  .attachments-container {
    display: inline-flex;
    flex-wrap: wrap;
  }

  .attachments-container .attachments {
    margin-right: 1rem;
    margin-bottom: 0.5rem;
  }

  .date {
    padding-bottom: 16px;
  }

  .filter-container .right-column .order-steps {
    display: inline-flex;
    flex-wrap: wrap;
    margin-bottom: 1rem;
  }

  .filter-container .right-column .order-steps .event-status {
    margin-right: 1rem;
    margin-bottom: 1rem;
  }

  .body-small {
    margin-top: 8px;
    max-width: 220px;
  }

  .search-btn {
    margin-top: 1rem;
    position: relative;
    width: 100%;
    display: inline-flex;
  }

  .search-btn :global(button) {
    margin-left: auto;
  }

  .delivery-status {
    position: relative;
    width: 250px;
    max-width: 250px;
    margin-bottom: 2rem;
  }

  .delivery-status :global(.dropdown) {
    width: 100% !important;
    max-width: 250px;
    margin-bottom: 1rem;
  }

  .name-container {
    display: inline-flex;
    width: calc(500px + 1rem);
    max-width: calc(500px + 1rem);
  }

  .name-container .first-name {
    margin-right: 1rem;
  }

  .name-container :global(.text-input) {
    width: 100% !important;
    max-width: 250px;
    min-width: 250px;
  }

  .street-and-zip-container {
    display: flex;
    width: calc(750px + 2rem);
    max-width: calc(750px + 2rem);
    margin: 1rem 0;
  }

  .street-and-zip-container .street-address {
    margin-right: 1rem;
  }

  .street-and-zip-container .street-address :global(.text-input) {
    width: 100% !important;
    max-width: calc(500px + 1rem);
    min-width: calc(500px + 1rem);
  }

  .street-and-zip-container .zip-code :global(.text-input) {
    width: 100% !important;
    max-width: 250px;
    min-width: 250px;
  }

  .state-container {
    display: flex;
    width: calc(750px + 2rem);
    max-width: calc(750px + 2rem);
    margin: 1rem 0;
  }

  .state-container :global(.text-input),
  .state-container :global(.dropdown) {
    width: 100% !important;
    max-width: 250px;
    min-width: 250px;
  }

  .state-container .city {
    margin: 0 1rem;
  }

  h4 :global(.target) {
    display: inline-block;
    color: var(--actionPrimaryDefault);
    margin-left: 10px;
  }

  h4 :global(.tooltip) {
    max-width: 300px;
    line-height: 16px;
  }

  .header-l {
    position: relative;
    padding-bottom: 10px;
  }
</style>
