<script>
  import { TextInput } from '@xpanseinc/ui-components';
  import { getLabel } from '../../constants/sendEventDataLabels';

  export let sendEventForm;
  export let checkValid;
</script>

<div class="form-row">
  <div class="form-item">
    <TextInput
      invalid="{(checkValid && !sendEventForm.appraiser?.firstName) ||
        sendEventForm.appraiser?.firstName < 0}"
      label="{getLabel('firstName')} *"
      name="firstName"
      on:change="{(e) => {
        sendEventForm.appraiser.firstName = e.target.value;
      }}"
    />
  </div>
  <div class="form-item">
    <TextInput
      label="{getLabel('middleName')}"
      name="middleName"
      on:change="{(e) => {
        sendEventForm.appraiser.middleName = e.target.value;
      }}"
    />
  </div>
  <div class="form-item">
    <TextInput
      invalid="{(checkValid && !sendEventForm.appraiser?.lastName) ||
        sendEventForm.appraiser?.lastName < 0}"
      label="{getLabel('lastName')} *"
      name="lastName"
      on:change="{(e) => {
        sendEventForm.appraiser.lastName = e.target.value;
      }}"
    />
  </div>
</div>

<div class="form-row">
  <div class="form-item">
    <TextInput
      label="{getLabel('appraiserCompany')}"
      name="appraiserCompany"
      on:change="{(e) => {
        sendEventForm.appraiser.company = e.target.value;
      }}"
    />
  </div>
  <div class="form-item">
    <TextInput
      invalid="{(checkValid && !sendEventForm.appraiser?.license) ||
        sendEventForm.appraiser?.license < 0}"
      label="{getLabel('appraiserLicense')} *"
      name="appraiserLicense"
      on:change="{(e) => {
        sendEventForm.appraiser.license = e.target.value;
      }}"
    />
  </div>
</div>
