<script>
  import { createEventDispatcher } from 'svelte';
  import { camelCase, startCase, sum } from 'lodash-es';
  import { LoadingSpinner } from '@xpanseinc/ui-components';
  import { slide } from 'svelte/transition';
  import Divider from './Divider.svelte';
  const dispatch = createEventDispatcher();

  export let fees;
  export let paymentType;
  export let isLoadingFees = false;

  let total;

  $: total = sum([fees?.appraisalFee?.amount, ...fees?.additionalFees.map((x) => x.amount)]);

  function formatAmount(str) {
    return str ? `$${parseInt(str).toFixed(2)}` : 'N/A';
  }
</script>

<div class="container">
  <div class="header">
    <p>Appraisal Fee</p>
  </div>
  <div class="content">
    {#if isLoadingFees}
      <LoadingSpinner />
    {:else if fees.appraisalFee || fees.additionalFees.length}
      <div class="line-item top" in:slide>
        <div class="label">Appraisal Fee:</div>
        <div class="value">{formatAmount(fees?.appraisalFee?.amount)}</div>
      </div>

      {#each fees.additionalFees as fee (fee.id)}
        <div class="line-item top" in:slide>
          <div class="label">{fee.description}:</div>
          <div class="value">{formatAmount(fee.amount)}</div>
        </div>
      {/each}

      <Divider marginBottom="10" />

      <div class="line-item">
        <div class="label">Total:</div>
        <div class="value">{formatAmount(total)}</div>
      </div>
      <div class="prepaid">{startCase(camelCase(paymentType))}</div>
    {:else}
      Please select a provider and a product to get fee information
    {/if}
  </div>
</div>

<style>
  .container {
    width: 240px;
    border: 1px solid var(--gray3);
    border-radius: 4px;
    margin-top: 22px;
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--gray1);
    padding: 16px;
    border-radius: 3px 3px 0 0;
  }

  .header p {
    font-weight: bold;
    margin: 0;
  }

  .content {
    padding: 16px;
    background-color: var(--white);
    border-radius: 0 0 4px 4px;
  }

  .line-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .top {
    margin-bottom: 10px;
  }

  .prepaid {
    display: flex;
    justify-content: flex-end;
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    letter-spacing: 0.13px;
    color: var(--textDefault);
  }

  .label {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: var(--textDefault);
  }

  .value {
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
  }
</style>
