<script>
  import { Button } from '@xpanseinc/ui-components';
  import { createEventDispatcher } from 'svelte';
  import { Trash2Icon } from 'svelte-feather-icons';
  const dispatch = createEventDispatcher();

  export let isEditingExistingUser;
</script>

<div class="btn-container">
  {#if isEditingExistingUser}
    <Button
      color="warning"
      icon="{Trash2Icon}"
      label="Delete user"
      name="delete-user"
      on:click="{() => {
        dispatch('delete');
      }}"
    />
  {/if}
  <div>
    <Button
      label="Cancel"
      name="cancel"
      on:click="{() => {
        dispatch('cancel');
      }}"
    />
    <Button
      color="primary"
      label="Save Changes"
      name="save-user"
      on:click="{() => {
        dispatch('save');
      }}"
    />
  </div>
</div>

<style>
  .btn-container {
    display: inline-flex;
    width: 100%;
  }

  .btn-container > div {
    display: flex;
    gap: 16px;
    margin-left: auto;
  }
</style>
