<script>
  import { Dropdown, TextInput } from '@xpanseinc/ui-components';
  import { StatusEnum } from '@xpanseinc/authorization-service-api-rest';
  import { mapDropdownOptionsFromEnum } from '../../utils/mapDropdownOptions';
  import { createEventDispatcher } from 'svelte';

  export let status;
  export let query;
  export let page = undefined;

  const dispatch = createEventDispatcher();

  function handleSearch() {
    page = 0;
    dispatch('search');
  }
</script>

<div id="search" class="search-bar" data-testid="search-bar">
  <TextInput
    appendedButton
    dataTestId="user-search"
    type="search"
    name="name"
    placeholder="Search users"
    on:click="{handleSearch}"
    on:clear="{handleSearch}"
    on:keyup="{(e) => {
      if (e.key === 'Enter') {
        handleSearch();
      }
    }}"
    on:change="{(e) => {
      query = e.target.value;
    }}"
    bind:value="{query}"
  />
  <!-- TODO: implement the product and admin filters when the backend supports the corresponding parameters in the AuthorizationServiceApiListUsersRequest interface (PDM-813) -->
  <!-- <Dropdown dataTestId="products-filter" name="products" placeholder="Products: All" /> -->
  <!-- <Dropdown dataTestId="admin-role-filter" name="admin" placeholder="Admin: All" /> -->
  <Dropdown
    dataTestId="status-filter"
    name="status"
    options="{mapDropdownOptionsFromEnum(StatusEnum, false, 'key')}"
    placeholder="Status: All"
    prependSelectedText="Status: "
    on:select="{handleSearch}"
    bind:value="{status}"
  />
</div>

<style>
  .search-bar {
    display: inline-flex;
    gap: 8px;
    min-width: 100%;
    align-items: center;
  }

  .search-bar :global(.text-input) {
    flex: 1;
    max-width: 360px;
    min-width: 0;
    margin-top: 0 !important;
  }

  .search-bar :global(.text-input) :global(.btn) {
    min-width: fit-content;
    padding: 5px;
  }

  .search-bar :global(.dropdown) {
    margin-top: 0 !important;
  }

  .search-bar :global(.dropdown .header) {
    padding: 9px 12px;
  }

  .search-bar :global(.dropdown .dropdown-inner) {
    max-height: 43px;
  }

  .search-bar :global(.dropdown.open .dropdown-inner) {
    max-height: none;
  }
</style>
