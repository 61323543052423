<script>
  import {
    Button,
    Checkbox,
    Dropdown,
    LoadingSpinner,
    RadioButton,
    Skeleton,
  } from '@xpanseinc/ui-components';
  import { link } from 'svelte-spa-router';
  import { PermissionAccessEnum } from '@xpanseinc/authorization-service-api-rest';
  import ViewHeader from '../components/ViewHeader.svelte';
  import { UploadIcon, ArrowLeftIcon } from 'svelte-feather-icons';
  import { push } from 'svelte-spa-router';
  import { onDestroy, onMount } from 'svelte';
  import Layout from '../components/Layout.svelte';
  import LoanDetails from '../components/LoanDetails.svelte';
  import urls from '../urls';
  import OrderLog from '../components/OrderLog.svelte';
  import { eventApi, orderApi, orderDetailApi } from '../stores/api';
  import {
    orderDetails,
    logs,
    logFilter,
    logSortOptions,
    rawLogs,
    viewingOrderId,
    previousScreen,
  } from '../stores/orders';
  import { alphabetically } from '../utils/alphabetically';
  import { currentUserPermissions } from '../stores/users';
  import pageTitle from '../utils/pageTitle';
  export let params = {};

  $: if (params?.orderId && $viewingOrderId !== params.orderId) {
    viewingOrderId.set(params.orderId);
  }

  const title = `Order ${params.orderId}`;

  let events = {};
  let followOptions = [];
  let followMenuValue = [];
  let partiallyProcessedTimeout;
  const logFilterOptions = [
    { label: 'All', value: '' },
    { label: 'With Comments', value: 'Comments' },
    { label: 'With Attachments', value: 'Attachments' },
  ];

  async function getEventsToFollow() {
    const rawEvents = await $eventApi?.getEventsToFollowAsMap({ orderId: $viewingOrderId });

    events = Object.keys(rawEvents).reduce((map, key) => {
      const { displayName, eventId, statusName } = rawEvents[key];
      // Remove the / character from the key to make lookups easier
      // and remove all the useless undefined fields from the raw response
      // eslint-disable-next-line no-param-reassign
      map[key.replace('/', '')] = { displayName, eventId, statusName };
      return map;
    }, {});

    followOptions = Object.keys(events)
      .map((key) => ({
        label: events[key].displayName,
        value: key,
      }))
      .sort(alphabetically);
  }

  async function getSelectedFollowOptions() {
    const followedEvents = await $orderApi.getFollowOrderEvents({ orderId: $viewingOrderId });
    followMenuValue = followedEvents.map(
      ({ eventTypeId, statusName }) => `${eventTypeId}${statusName}`,
    );
  }

  async function onFollowOptionSelect() {
    $orderApi.followOrderEvents({
      orderId: $viewingOrderId,
      followOrderEvent: followMenuValue.map((value) => ({
        eventTypeId: events[value].eventId,
        statusName: events[value].statusName,
      })),
    });
  }

  function checkForPartiallyProcessedEvents(eventLogs) {
    let hasPartiallyProcessedEvents = false;
    eventLogs?.forEach((log) => {
      if (log.deliveryStatus === 'Partially Processed' || log.deliveryStatus === 'Processed') {
        hasPartiallyProcessedEvents = true;
      }
    });

    if (hasPartiallyProcessedEvents) {
      partiallyProcessedTimeout = setTimeout(async () => {
        const { logs: newLogs } = await $orderDetailApi?.getOrderDetail2({
          orderId: $viewingOrderId,
        });
        $rawLogs = newLogs;
      }, 3000);
    }
  }

  $: if ($rawLogs?.length) {
    checkForPartiallyProcessedEvents($rawLogs);
  }

  onDestroy(() => {
    viewingOrderId.set(null);
    if (partiallyProcessedTimeout) {
      clearTimeout(partiallyProcessedTimeout);
    }
  });

  let hasPermission;
  let product;
  let orderAttributes;

  onMount(async () => {
    await $orderDetails.then(async (details) => {
      const userProductPermission =
        $currentUserPermissions.product[details.productName.toUpperCase()];
      hasPermission =
        userProductPermission === PermissionAccessEnum.Edit.toUpperCase() ||
        userProductPermission === PermissionAccessEnum.Create.toUpperCase();
      product = details.productName.toUpperCase();
      orderAttributes = details.attributes;
    });
  });

  function filterLogAttributes(log) {
    return orderAttributes.filter((attr) => attr.transactionEventId === log.id);
  }
</script>

<svelte:head>
  <title>{pageTitle(title)}</title>
</svelte:head>

<Layout>
  <div slot="left" class="filters">
    <div class="breadcrumb-container">
      <div class="breadcrumb-item">
        <a href="{urls.orders}" use:link>Orders</a>
      </div>
      <div class="breadcrumb-item breadcrumb-item--active">Order Details</div>
    </div>
    <div
      class="back-header-container"
      on:click="{() => {
        push(urls.orders);
      }}"
    >
      <div class="back-container">
        <ArrowLeftIcon size="24" />
      </div>
      <ViewHeader>Order {params.orderId}</ViewHeader>
    </div>
    <Dropdown
      disabled="{followOptions.length === 0}"
      multiple="true"
      name="follow-options"
      options="{followOptions.length
        ? followOptions
        : [{ label: 'empty field', value: 'empty field' }]}"
      placeholder="{followOptions.length === 0 ? 'No Follow Options' : 'Follow Order'}"
      on:select="{onFollowOptionSelect}"
      bind:value="{followMenuValue}"
    />
    <Dropdown options="{logSortOptions}" bind:value="{$logFilter.sort}" name="sortOptions" />
    <Checkbox bind:checked="{$logFilter.provider}" name="notes" dataTestId="checkbox-provider">
      Provider
    </Checkbox>
    <Checkbox bind:checked="{$logFilter.lender}" name="notes" dataTestId="checkbox-lender">
      Lender
    </Checkbox>
    <hr />
    <h6>Show Updates</h6>
    <RadioButton name="logFilter" options="{logFilterOptions}" bind:value="{$logFilter.events}" />
  </div>
  <div slot="center">
    {#await Promise.all([$orderDetails, getEventsToFollow(), getSelectedFollowOptions()])}
      <div class="loading-wrap">
        <Skeleton height="100%" />
      </div>
    {:then [{ orderId }]}
      <div class="actions">
        {#if hasPermission}
          <Button
            icon="{UploadIcon}"
            label="Update order"
            name="update-order"
            color="primary"
            on:click="{() => push(`/order-detail/${orderId}/send-event`)}"
          />
        {/if}
      </div>
      <div class="logs">
        {#if $logs.length > 0}
          {#each $logs as log}
            <OrderLog log="{log}" product="{product}" attributes="{filterLogAttributes(log)}" />
          {/each}
        {:else}
          <h4 class="text-center">No updates</h4>
        {/if}
      </div>
    {/await}
  </div>
  <div slot="right" class="order-details-sidebar">
    {#await $orderDetails then loan}
      <LoanDetails loan="{loan}" />
    {/await}
  </div>
</Layout>

<style>
  .back-header-container {
    display: flex;
  }

  .back-container {
    padding: 5px 16px 8px 4px;
    cursor: pointer;
    color: var(--gray11);
    transition: var(--transitionHover);
    height: 26px;
  }

  .back-container:hover {
    color: var(--primaryDefault);
  }

  h6 {
    line-height: 16px;
    font-weight: 600;
    color: var(--gray8);
    margin-bottom: 12px;
  }

  .loading-wrap {
    position: relative;
    width: 650px;
    margin-top: 6.5rem;
    height: 100vh;
  }

  .filters :global(.dropdown) {
    margin: 0 0 1rem !important;
  }

  .actions {
    display: flex;
    justify-content: flex-end;
    max-width: 650px;
    height: 106px;
  }

  /* Btn - SM */
  .actions :global(.btn) {
    margin: 0 0 0 14px;
    font-size: 14px;
    padding: 6px 12px;
    height: 32px;
    top: 30px;
  }

  .actions :global(.btn svg) {
    width: 14px;
    height: 14px;
  }

  .actions :global(.btn .icon) {
    margin-right: 6px;
  }

  .logs {
    background-color: var(--white);
    box-shadow: 0 0 2px rgba(19, 20, 22, 0.08), 0 0 4px rgba(19, 20, 22, 0.12);
    border-radius: 4px;
    padding: 16px 16px;
  }

  .order-details-sidebar {
    background-color: var(--white);
    box-shadow: 0 0 2px rgba(19, 20, 22, 0.08), 0 0 4px rgba(19, 20, 22, 0.12);
    border-radius: 4px;
    margin: 105px 0 0;
    max-height: 80vh;
    overflow-y: auto;
    padding: 0 0 !important;
    height: fit-content;
  }
</style>
