import { isToday, sub } from 'date-fns';
import { startCase, camelCase } from 'lodash-es';
import { formatDate } from '../utils/formatDate';

const sevenDaysAgo = sub(new Date(), { days: 6 });
const thirtyDaysAgo = sub(new Date(), { days: 29 });
const fourMonthsAgo = sub(new Date(), { months: 4 });
const twelveMonthsAgo = sub(new Date(), { months: 12 });
const fiftyYearsAgo = sub(new Date(), { years: 50 });

const dateLabels = {
  [formatDate(sevenDaysAgo)]: 'Last 7 Days',
  [formatDate(thirtyDaysAgo)]: 'Last 30 Days',
  [formatDate(fourMonthsAgo)]: 'Last 4 Months',
  [formatDate(twelveMonthsAgo)]: 'Last 12 months',
  [formatDate(fiftyYearsAgo)]: 'All time',
};

function getDateLabel(from: Date, to: Date): string {
  if (isToday(to) && dateLabels[formatDate(from)]) {
    return dateLabels[formatDate(from)];
  }
  return `${formatDate(from)}-${formatDate(to)}`;
}

export function searchFilterLabel(searchFilter, vendorMap) {
  const eventDate = searchFilter.eventDate ? searchFilter.eventDate : 'Order Date';
  return {
    loan: searchFilter.loan,
    from: `${eventDate}: ${getDateLabel(searchFilter.from, searchFilter.to)}`,
    status: `Order State: ${
      searchFilter?.status?.length > 1
        ? `${searchFilter.status.length} selected`
        : searchFilter.status
    }`,
    vendor: `Vendors: ${
      searchFilter?.vendor?.length > 1
        ? `${searchFilter.vendor.length} selected`
        : vendorMap[searchFilter.vendor]
    }`,
    vtpClientId: `Version:  ${searchFilter?.vtpClientId}`,
    product: `Product: ${
      searchFilter?.product?.length > 1
        ? `${searchFilter.product.length} selected`
        : searchFilter.product
    }`,
    document: `Attachments: ${
      searchFilter.document?.length > 1
        ? `${searchFilter.document.length} selected`
        : searchFilter.document
    }`,
    deliveryStatus: `Delivery Status: ${
      searchFilter?.deliveryStatus?.length > 1
        ? `${searchFilter.deliveryStatus.length} selected`
        : searchFilter.deliveryStatus
    }`,
    event: `Order Step ${searchFilter.eventReceivedStatus}: ${
      searchFilter?.event?.length > 1 ? `${searchFilter.event.length} selected` : searchFilter.event
    }`,
    noDocument: `Missing Attachments: ${
      searchFilter?.noDocument?.length > 1
        ? `${searchFilter.noDocument.length} selected`
        : searchFilter.noDocument
    }`,
    fname: `First Name: ${searchFilter.fname}`,
    lname: `Last Name: ${searchFilter.lname}`,
    address: `Street Address: ${searchFilter.address}`,
    state: `States: ${
      searchFilter?.state?.length > 1 ? `${searchFilter.state.length} selected` : searchFilter.state
    }`,
    city: `City: ${searchFilter.city}`,
    county: `County: ${searchFilter.county}`,
    zip: `Zip Code: ${searchFilter.zip}`,
    isClearToCloseReport: 'Special Report: Clear to Close',
    eventSentFrom: `Order step is sent by: ${startCase(camelCase(searchFilter.eventSentFrom))}`,
  };
}
