<script>
  import { AuthMenu } from '@xpanseinc/ui-components';
  import { LogOutIcon } from 'svelte-feather-icons';
  import { auth } from '../stores/auth';
  import { profile } from '../stores/profile';
  import { version } from '../../package.json';

  const organizationLabel = `${$profile?.hasLenderAccess ? 'Lender • ' : ''}${
    $profile?.hasProviderAccess ? 'Provider • ' : ''
  }${$profile?.organization?.name || 'Unavailabe'}`;
</script>

<div class="account-details">
  <div>
    {#if $auth.isAuthenticated}
      <AuthMenu
        organization="{organizationLabel}"
        version="{version}"
        name="{$auth.userInfo?.name || ''}"
        items="{[
          {
            label: 'Sign Out',
            icon: LogOutIcon,
            onClick: $auth.logout,
          },
        ]}"
      />
    {:else}
      <a href="#/" on:click="{$auth.login}">Log In</a>
    {/if}
  </div>
</div>

<style>
  .account-details {
    position: fixed;
    bottom: 16px;
    left: 10px;
    z-index: 6;
  }
</style>
