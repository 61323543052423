import { object, string } from 'yup';

import type { Contact } from './contact';
import type { Address } from './borrower';
import { individualSchema } from './borrower';

export type Applicant = Contact & {
  ssn: string;
  address: Address;
  previousAddress: Address;
};
export const addressSchema = {
  line1: string(),
  line2: string(),
  city: string(),
  state: string(),
  zipCode: string(),
  county: string(),
};
export const applicantSchema = object().shape({
  ssn: string()
    .required()
    .matches(/^[0-9]+$/, 'Must be only digits')
    .min(9, 'Must be exactly 9 digits')
    .max(9, 'Must be exactly 9 digits'),
  ...individualSchema,
  address: object().shape({ ...addressSchema }),
  previousAddress: object(),
});
