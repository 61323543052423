<script>
  import Layout from '../components/Layout.svelte';
  import { invoicingApi, invoicingConfigHeaders } from '../stores/api';
  import BilledEventsOrderCard from '../components/billed-events/BilledEventsOrderCard.svelte';
  import { Button, DateRangeInput, Dropdown } from '@xpanseinc/ui-components';
  import { mapDropdownOptions } from '../utils/mapDropdownOptions';
  import { vendors, fetchVendors } from '../stores/reports';
  import { fetchProducts, products } from '../stores/products';
  import ViewHeader from '../components/ViewHeader.svelte';
  import {
    InvoicingRequestProductTypeEnum,
    InvoicingRequestDateTypeEnum,
  } from '@xpanseinc/invoicing-api';
  import sub from 'date-fns/sub';
  import { DownloadCloudIcon } from 'svelte-feather-icons';
  import OrderCardPlaceholders from '../components/OrderCardPlaceholders.svelte';
  import { downloadFile } from '../utils/download';
  import { formatDate } from '../utils/formatDate';
  import BilledEventsEmptyState from '../components/billed-events/BilledEventsEmptyState.svelte';
  import { profile } from '../stores/profile';
  import pageTitle from '../utils/pageTitle';
  const title = 'Billing & Invoices';
  let orders = [];
  let totalOrderCount = 0;
  let totalGroupedOrders = 0;
  let from = sub(new Date(), {
    days: 30,
  });
  let to = new Date();
  let pageNumber = 0;
  let invalidDate = false;
  let loadingOrders = false;
  let downloading = false;
  $: invoicingRequest = {
    dateType: 'OrderPlaced',
    from: from,
    to: to,
    providerIds: [],
    productType: [],
    pageNumber,
    pageSize: 25,
  };

  fetchVendors();
  fetchProducts();

  function resetPageNumber() {
    pageNumber = 0;
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  const onDateChange = ({ detail }) => {
    resetPageNumber();
    const [from, to] = detail;
    invoicingRequest = {
      ...invoicingRequest,
      from: from,
      to: to,
    };
  };

  async function fetchOrders(invoicingRequest) {
    loadingOrders = true;
    $invoicingConfigHeaders = {};
    let response = await $invoicingApi.searchInvoicedOrders({ invoicingRequest });
    if (pageNumber === 0) {
      orders = response.invoicedOrders;
    } else {
      orders = [...orders, ...response.invoicedOrders];
    }
    totalOrderCount = response.totalOrderCount;
    totalGroupedOrders = response.total;
    loadingOrders = false;
  }

  async function downloadCSV() {
    downloading = true;
    $invoicingConfigHeaders = { Accept: 'text/csv' };
    let response = await $invoicingApi.searchInvoicedOrdersRaw({
      invoicingRequest: { ...invoicingRequest, pageSize: totalOrderCount },
    });
    await downloadFile(response.raw.blob(), `Invoicing-${formatDate(from)}-${formatDate(to)}.csv`);
    $invoicingConfigHeaders = {};
    downloading = false;
  }

  $: fetchOrders(invoicingRequest);
</script>

<svelte:head>
  <title>{pageTitle(title)}</title>
</svelte:head>

<Layout>
  <div class="filters" slot="left">
    <div class="breadcrumb-container">
      <div class="breadcrumb-item breadcrumb-item--active">Billing Events</div>
    </div>
    <ViewHeader>Billing & Invoices</ViewHeader>
    <div class="filter-container">
      <Dropdown
        label="Date Type"
        name="dateType"
        options="{Object.values(InvoicingRequestDateTypeEnum).map((option) => {
          return { label: option.match(/[A-Z][a-z]+/g).join(' '), value: option };
        })}"
        on:select="{resetPageNumber}"
        bind:value="{invoicingRequest.dateType}"
      />
      <DateRangeInput
        from="{from}"
        to="{to}"
        label="Date Range"
        name="dateRange"
        on:change="{onDateChange}"
        bind:invalid="{invalidDate}"
      />
      {#if $profile?.hasLenderAccess}
        <Dropdown
          label="Provider"
          multiple
          name="provider"
          options="{$vendors}"
          placeholder="All"
          on:select="{resetPageNumber}"
          bind:value="{invoicingRequest.providerIds}"
        />
      {/if}
      <Dropdown
        label="Product"
        multiple
        name="product"
        options="{$products}"
        placeholder="All"
        on:select="{resetPageNumber}"
        bind:value="{invoicingRequest.productType}"
      />
    </div>
  </div>
  <div slot="center" class="orders">
    {#if loadingOrders && pageNumber === 0}
      <OrderCardPlaceholders />
    {:else if orders.length}
      <div class="orders-header">
        <h4 class="header-m header-m--bold">{totalGroupedOrders} Invoices</h4>
        <div class="orders-header-btns">
          <Button
            color="basic"
            label="Download CSV"
            icon="{DownloadCloudIcon}"
            iconLoading="{downloading}"
            name="downloadCSV"
            on:click="{downloadCSV}"
          />
        </div>
      </div>
      {#each orders as order}
        <div class="order">
          <BilledEventsOrderCard order="{order}" />
        </div>
      {/each}
      {#if orders.length < totalGroupedOrders}
        <div class="view-more">
          <Button
            label="View More"
            name="viewMore"
            color="primary"
            on:click="{() => {
              pageNumber++;
            }}"
          />
        </div>
      {/if}
    {:else}
      <BilledEventsEmptyState />
    {/if}
  </div>
</Layout>

<style>
  .filters {
    position: relative;
  }

  .filters :global(.dropdown) {
    margin-bottom: 2rem;
  }

  .filters :global(.text-input) {
    margin-bottom: 2rem;
  }

  .filters :global(.option-list .text-input) {
    margin-top: 4px !important;
    margin-bottom: 4px;
  }

  .orders-header {
    display: inline-flex;
    width: 100%;
    justify-content: space-between;
    align-items: baseline;
  }

  .orders-header-btns {
    display: inline-flex;
  }

  /* Btn - SM */
  .orders-header-btns :global(.btn) {
    font-size: 14px;
    padding: 6px 12px;
  }

  .orders-header-btns :global(.btn svg) {
    width: 12px;
    height: 12px;
  }

  .orders-header-btns :global(.btn .icon) {
    margin-right: 4px;
  }

  .orders {
    margin-left: 1.5rem;
    margin-top: 6rem;
  }

  .order {
    border: 1px solid var(--gray2);
    border-radius: 4px;
    background-color: var(--white);
    margin-bottom: 8px;
  }

  .view-more {
    display: flex;
    justify-content: center;
    margin: 22px 0;
  }

  .view-more :global(.btn) {
    width: 160px;
  }

  .filter-container {
    margin-top: 4rem;
  }
</style>
