<script>
  import { allUsers } from '../stores/users';
  import { Skeleton } from '@xpanseinc/ui-components';
  import { userApi } from '../stores/api';
  import { StatusEnum } from '@xpanseinc/authorization-service-api-rest';
  import pageTitle from '../utils/pageTitle';
  import Layout from '../components/Layout.svelte';
  import UserList from '../components/user-management/UserList.svelte';
  import UserManagementHeader from '../components/user-management/UserManagementHeader.svelte';
  import UserSearchBar from '../components/user-management/UserSearchBar.svelte';
  import UserManagementEmptyState from '../components/user-management/UserManagementEmptyState.svelte';
  import EditSelectedUsers from '../components/user-management/EditSelectedUsers.svelte';
  import { push } from 'svelte-spa-router';
  import urls from '../urls';
  import ConfirmDeleteUserDialog from '../components/user-management/ConfirmDeleteUserDialog.svelte';
  import {
    renderErrorToast,
    renderInformationToast,
    renderSuccessToast,
  } from '../utils/renderToasts';
  import BulkImportUploadDialog from '../components/user-management/BulkImportUploadDialog.svelte';
  import { downloadFile } from '../utils/download';

  let page = 0;
  let pageCount = 0;
  let status = StatusEnum.All;
  let query = '';
  let isValidQuery = true;

  let selectedUsers = {};
  let selectedUser = null;
  $: numSelectedUsers = Object.values(selectedUsers).filter((user) => user === true).length;
  $: selectedUserIds = Object.keys(selectedUsers).filter(
    (userId) => selectedUsers[userId] === true,
  );
  $: selectedUserNames = $allUsers
    ?.filter((user) => selectedUserIds?.includes(user.id))
    ?.map((user) => user?.name);

  let loadingUsers = false;
  let downloading = false;
  let isDeleteDialogVisible = false;
  let isBulkImportDialogVisible = false;

  const genericErrorMessage = 'Something went wrong';
  const statusChangeSuccessMessage = (userString) =>
    `Successfully changed status for the following user(s): ${userString}. Please allow 2-3 minutes for the changes to appear everywhere.`;
  const deletedUserSuccessMessage = (name) =>
    `The user ${name} was deleted successfully. Please allow 2-3 minutes for the changes to appear everywhere.`;

  $: if (
    Object.keys(selectedUsers).filter((userId) => selectedUsers[userId] === true).length === 1
  ) {
    let selectedUserId = Object.keys(selectedUsers).find(
      (userId) => selectedUsers[userId] === true,
    );
    selectedUser = $allUsers.find((user) => user.id === selectedUserId);
  } else {
    selectedUser = null;
  }

  async function fetchUsers() {
    if ((query && query.length >= 3) || !query) {
      isValidQuery = true;
      loadingUsers = true;
      if (page === 0) {
        $allUsers = [];
      }
      const { totalSize, users, totalPages } = await $userApi?.listUsers({
        query,
        status,
        page,
        size: 25,
      });
      const usersLookup = {};
      users.forEach((user) => {
        usersLookup[user.id] = {
          ...user,
          updatedAt: user.updatedAt === 'null' ? null : new Date(user.updatedAt),
          lastLogin: user.lastLogin === 'null' ? null : new Date(user.lastLogin),
        };
      });
      if (page > 0) {
        $allUsers = [...$allUsers, ...Object.values(usersLookup)];
      } else {
        $allUsers = Object.values(usersLookup);
      }
      if (totalSize > 1000) {
        renderInformationToast(
          `Showing the first 1,000 users. Please use search to quickly find specific users.`,
        );
      }
      pageCount = totalPages;
      loadingUsers = false;
    } else {
      isValidQuery = false;
    }
  }

  async function changeStatus(newStatus) {
    try {
      await Promise.all(
        Object.keys(selectedUsers)
          .filter((user) => selectedUsers[user] === true)
          .map((userId) =>
            $userApi.updateUser({
              userId,
              user: { ...$allUsers.find((user) => user.id === userId), status: newStatus },
            }),
          ),
      );
      page = 0;
      selectedUsers = {};
      fetchUsers();
      renderSuccessToast(statusChangeSuccessMessage(selectedUserNames.join(', ')));
    } catch (error) {
      renderErrorToast(genericErrorMessage, error);
    }
  }

  async function deleteSelectedUser() {
    try {
      await $userApi.deleteUser({ userId: selectedUser.id });
      renderSuccessToast(deletedUserSuccessMessage(selectedUser?.name));
    } catch (error) {
      renderErrorToast(genericErrorMessage, error);
    }
    selectedUsers = {};
  }

  function addUser() {
    push(urls.addUser);
  }

  function copyUser() {
    push(
      urls.copyUser.split(':')[0] +
        Object.keys(selectedUsers).find((user) => selectedUsers[user] === true),
    );
  }

  async function downloadCSV() {
    if (downloading) return;
    downloading = true;
    try {
      const response = await $userApi.getCsvForUsersRaw();
      await downloadFile(response.raw.blob(), `Users.csv`);
    } catch (error) {
      renderErrorToast(genericErrorMessage, error);
      console.error(error);
    } finally {
      downloading = false;
    }
  }

  fetchUsers();
</script>

<svelte:head>
  <title>{pageTitle('User Management')}</title>
</svelte:head>

<Layout>
  <div slot="center" style="position: absolute; z-index: 5;">
    {#if loadingUsers && page === 0}
      <div class="loading">
        <Skeleton height="100%" />
      </div>
    {:else}
      <UserManagementHeader
        downloading="{downloading}"
        on:addUser="{addUser}"
        on:bulkImport="{() => {
          isBulkImportDialogVisible = true;
        }}"
        on:downloadCSV="{downloadCSV}"
      />
      {#if !Object.values(selectedUsers).includes(true)}
        <UserSearchBar bind:page bind:query bind:status on:search="{fetchUsers}" />
      {:else}
        <EditSelectedUsers
          numSelectedUsers="{numSelectedUsers}"
          on:copy="{copyUser}"
          on:changeStatus="{(e) => {
            changeStatus(e.detail);
          }}"
          on:delete="{() => {
            isDeleteDialogVisible = true;
          }}"
          bind:selectedUsers
        />
      {/if}
      {#if !isValidQuery}
        <div class="empty-state">
          <UserManagementEmptyState />
        </div>
      {:else if !$allUsers.length}
        <div class="empty-state">
          <UserManagementEmptyState
            title="No users found"
            message="Please try changing your filter(s)"
          />
        </div>
      {:else}
        <UserList
          hideShowMore="{page === pageCount - 1}"
          bind:users="{$allUsers}"
          bind:page
          bind:selectedUsers
          on:search="{fetchUsers}"
        />
      {/if}
    {/if}
  </div>
</Layout>

<ConfirmDeleteUserDialog
  user="{selectedUser}"
  bind:visible="{isDeleteDialogVisible}"
  on:confirm="{deleteSelectedUser}"
/>

<BulkImportUploadDialog bind:visible="{isBulkImportDialogVisible}" />

<style>
  .loading {
    margin-top: 177px;
    width: 100%;
    height: 100vh;
  }

  .empty-state {
    margin-top: 10vh;
  }
</style>
