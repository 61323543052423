<script>
  import { IconButton, LoadingSpinner } from '@xpanseinc/ui-components';
  import { PermissionAccessEnum } from '@xpanseinc/authorization-service-api-rest';
  import { currentUserPermissions } from '../stores/users';
  import { onDestroy, onMount } from 'svelte';
  import { XIcon, ArrowLeftIcon } from 'svelte-feather-icons';
  import { push } from 'svelte-spa-router';
  import Layout from '../components/Layout.svelte';
  import LoanDetails from '../components/LoanDetails.svelte';
  import SendEventForm from '../components/send-event/SendEventForm.svelte';
  import { eventApi } from '../stores/api';
  import { eventConfig } from '../stores/eventConfig';
  import { orderDetails, viewingOrderId } from '../stores/orders';
  import urls from '../urls';
  import pageTitle from '../utils/pageTitle';
  import ViewHeader from '../components/ViewHeader.svelte';
  import { link } from 'svelte-spa-router';

  const title = 'Update Order';

  export let params = {};

  if ($viewingOrderId !== params.orderId) {
    viewingOrderId.set(params.orderId);
  }

  async function getEventConfig() {
    $eventConfig = await $eventApi?.getEventsToSendAsMap({ orderId: $viewingOrderId });
  }

  function closeSendEventForm() {
    push(urls.orderDetail + params.orderId);
  }

  onDestroy(() => {
    viewingOrderId.set(null);
  });

  let hasPermission;
  onMount(async () => {
    await $orderDetails.then(async (details) => {
      const userProductPermission =
        $currentUserPermissions.product[details.productName.toUpperCase()];
      hasPermission =
        userProductPermission === PermissionAccessEnum.Edit.toUpperCase() ||
        userProductPermission === PermissionAccessEnum.Create.toUpperCase();

      if (hasPermission === false) {
        push(urls.orders);
      }
    });
  });
</script>

<svelte:head>
  <title>{pageTitle(title)}</title>
</svelte:head>

<Layout>
  <main slot="center">
    <div class="breadcrumb-container">
      <div class="breadcrumb-item">
        <a href="{urls.orders}" use:link>Orders</a>
      </div>
      <div class="breadcrumb-item">
        <a href="/order-detail/{params.orderId}" use:link>Order {params.orderId}</a>
      </div>
      <div class="breadcrumb-item breadcrumb-item--active">Update Order</div>
    </div>
    <div class="back-header-container" on:click="{closeSendEventForm}">
      <div class="back-container">
        <ArrowLeftIcon size="24" />
      </div>
      <ViewHeader>Update Order</ViewHeader>
    </div>
    <div class="update-order-center-container">
      {#await Promise.all([$orderDetails, getEventConfig()])}
        <div class="spinner">
          <LoadingSpinner />
        </div>
      {:then [{ logs, orderId, borrowers, productName }]}
        <SendEventForm
          config="{$eventConfig}"
          orderId="{orderId}"
          product="{productName}"
          logs="{logs}"
          borrowers="{borrowers}"
        />
      {/await}
    </div>
  </main>
  <div class="update-order-sidebar" slot="right">
    <div class="sidebar-header"></div>
    {#await $orderDetails then loan}
      <div class="loan-details-sidebar">
        <LoanDetails loan="{loan}" />
      </div>
    {/await}
  </div>
</Layout>

<style>
  .back-header-container {
    display: flex;
  }

  .back-container {
    padding: 5px 16px 8px 4px;
    cursor: pointer;
    color: var(--gray11);
    transition: var(--transitionHover);
    height: 26px;
  }

  .back-container:hover {
    color: var(--primaryDefault);
  }

  .spinner {
    margin-top: 120px;
    margin-bottom: 120px;
  }

  .sidebar-header {
    display: flex;
    justify-content: flex-end;
    position: sticky;
    margin-bottom: 105px;
  }

  .update-order-center-container {
    padding: 1rem 1rem;
    background-color: var(--white);
    border-radius: 4px;
    box-shadow: 0 0 2px rgba(19, 20, 22, 0.08), 0 0 4px rgba(19, 20, 22, 0.12);
    max-width: 916px;
  }

  .update-order-center-container :global(form:first-child) {
    padding-top: 0 !important;
    padding-right: 0 !important;
  }

  .update-order-sidebar {
    padding: 0 0 !important;
  }

  :global(.form-item--action) {
    max-width: none !important;
  }

  .loan-details-sidebar {
    max-height: 80vh;
    width: 290px;
    overflow-y: auto;
    background-color: var(--white);
    margin-top: 65px;
    border-radius: 4px;
    box-shadow: 0 0 2px rgba(19, 20, 22, 0.08), 0 0 4px rgba(19, 20, 22, 0.12);
  }

  .update-order-center-container :global(.dropdown-inner.disabled) {
    background-color: var(--gray1) !important;
  }
</style>
