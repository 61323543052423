import { getDateRange } from '@xpanseinc/ui-components/src/utils/getDateRange';
import { sub } from 'date-fns';
import { derived, writable } from 'svelte/store';
import { orderDetailApi } from './api';

export function getDefaultSearchFilter() {
  const today = new Date();
  const [from, to] = getDateRange(sub(today, { years: 50 }), today);
  return {
    from,
    to,
    loan: '',
    eventDate: '',
    event: [],
    deliveryStatus: [],
    status: [],
    eventSentFrom: 'BOTH',
    vendor: [],
    product: [],
    document: [],
    eventReceivedStatus: 'Current',
    isClearToCloseReport: false,
    noDocument: [],
    fname: '',
    lname: '',
    address: '',
    zip: '',
    state: [],
    city: '',
    county: '',
    pageSize: 25,
    pageNumber: 0,
    vtpClientId: '',
    attributes: {},
  };
}

export const searchFilter = writable(getDefaultSearchFilter());
export const stagedFilter = writable(getDefaultSearchFilter());
export const allSearchResults = writable([]);
export const totalSearchResults = writable(null);

export const viewingOrderId = writable(null);

export const rawLogs = writable(null);

export const parties = writable(null);

export const borrowers = writable([]);

export const savedSearches = writable(null);

export const activeFilter = writable({ name: null, id: null });

export const previousScreen = writable({ label: 'Orders', url: '/orders' });

// Find the last log that contains parties
function findLastLogId(details) {
  return details.logs.reduce(
    (prev, current) => {
      // Only update if the current log has parties and is newer than the previous
      if (current.parties?.length > 0 && current.id > prev.id) {
        return current;
      }
      return prev;
    },
    { id: 0, message: '', parties: [] },
  );
}

export const orderDetails = derived(
  [orderDetailApi, viewingOrderId],
  ([$orderDetailApi, $viewingOrderId]) =>
    $viewingOrderId &&
    $orderDetailApi?.getOrderDetail2({ orderId: $viewingOrderId }).then((details) => {
      rawLogs.set(details?.logs || []);
      if (details.borrowers) {
        borrowers.set(details.borrowers || []);
      }
      const partyMap = new Map();
      if (details?.logs && details.logs.length > 0) {
        // Find the latest log with parties
        const latestLog = findLastLogId(details);
        latestLog.parties.sort((a, b) => a.firstName - b.firstName);
        latestLog.parties.forEach((party) => {
          // Create a key with the role and identifier to prevent duplicates
          let key = party.partyRole;
          if (party.email) {
            key += party.email;
          } else {
            key += party.fullName;
          }

          partyMap.set(key, party);
        });
      }
      parties.set(partyMap);
      return details;
    }),
);

export const logSortOptions = [
  { label: 'Most Recent', value: ['createdAt', false] },
  { label: 'Oldest First', value: ['createdAt', true] },
];

export function getDefaultLogFilter() {
  return {
    sort: logSortOptions[0].value,
    lender: true,
    provider: true,
    events: '',
  };
}

export const logFilter = writable(getDefaultLogFilter());

export const logs = derived([rawLogs, logFilter], ([$rawLogs, $logFilter], set) => {
  if (!$rawLogs) {
    set([]);
  }

  const hasComment = (log) =>
    !!(log.comments?.length || (log.reasons && log.reasons.find((reason) => reason.comment)));

  const newLogs = $rawLogs
    ?.filter(
      (log) =>
        ($logFilter.provider && log.eventFrom === 'VENDOR') ||
        ($logFilter.lender && (log.eventFrom === 'LENDER' || log.eventFrom === 'XPANSE')),
    )
    ?.filter(
      (log) =>
        ($logFilter.events === 'Attachments' && Array.isArray(log.attachments)) ||
        ($logFilter.events === 'Comments' && hasComment(log)) ||
        $logFilter.events === '',
    )
    ?.sort((a, b) =>
      $logFilter.sort[1]
        ? a[$logFilter.sort[0]] - b[$logFilter.sort[0]]
        : b[$logFilter.sort[0]] - a[$logFilter.sort[0]],
    );

  set(newLogs || []);
});
