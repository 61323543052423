import { array, date, object, string } from 'yup';
import type { AppraisalFees } from './fee';
import type { PayerInformation } from './payment';
import type { SubjectProperty } from './subjectProperty';
import { EventIdEnum } from '../../constants/place-order';

export type Appraisal = {
  provider?: number;
  product?: string;
  duReferenceNumber?: string;
  specialFeatureCode?: string;
  closingDate?: Date;
  closingDateTimezone?: string;
  orderInstructions?: string;
  fees?: AppraisalFees;
  payments?: PayerInformation[];
  attachments?: [];
  subjectProperty?: SubjectProperty[];
  borrowers?: Object[];
  contacts?: Object[];
};

export const baseAppraisalSchema = {
  provider: string().required('Provider is required'),
  product: string().required('Product is required'),
  closingDate: date(),
  closingDateTimezone: string(),
  orderInstructions: string(),
  fees: object().required(),
  payments: array().required().min(1, 'At least one Payment is required'),
  attachments: array().test(
    'purchase_agreement_required',
    'Purchase Agreement document is required',
    (attachments) => {
      const attachment = attachments.find(
        (a) => a.disabled && a.usageCode === EventIdEnum.PURCHASE_AGREEMENT,
      );
      return !attachment || attachment?.fileName;
    },
  ),
  contacts: array(),
};

const fnmaSchema = {
  duReferenceNumber: string().required('DU Reference Number is required'),
  specialFeatureCode: string(),
};

export function getAppraisalSchema(isFNMA) {
  if (isFNMA) {
    return object().shape({
      ...baseAppraisalSchema,
      ...fnmaSchema,
    });
  }
  return object().shape(baseAppraisalSchema);
}
