<script>
  export let label;
  export let icon = undefined;
  export let size = '12';
</script>

<div data-testid="{`${label}-badge`}" class="{`badge ${$$props.class}`}">
  {#if icon}
    <svelte:component this="{icon}" size="{size}" />
  {/if}
  {label}
</div>

<style>
  .badge {
    align-items: center;
    border-radius: 4px;
    display: flex;
    font-size: 13px;
    font-weight: 500;
    justify-content: center;
    padding: 2px 8px;
    line-height: 20px;
    letter-spacing: 0.13px;
    cursor: default;
  }

  .badge :global(svg) {
    margin-left: -2px;
    margin-right: 5px;
  }

  .border {
    border: 1px solid #dee0e3;
  }

  .active {
    background-color: #e8f8f2;
    color: #127d52;
  }

  .inactive {
    background-color: var(--gray1);
    color: #b2b6bd;
  }

  .locked,
  .bruteforcelocked {
    background-color: #fef2d7;
    color: #875f03;
  }

  .general {
    background-color: var(--gray1);
  }
</style>
