import { array, object, string, number } from 'yup';
import type { SchemaOf } from 'yup';
import { SlaScorecardRequestTimeUnitEnum } from '@xpanseinc/report-service-api';

export type SLATimerRequest = {
  id: number[];
  endEvent: string;
  endEventDescription: string;
  service: string;
  sla: number;
  stateSlas: { sla: number; states: Array<string>; timeUnit: string }[];
  startEvent: string;
  startEventDescription: string;
  target: number;
  timeUnit: string;
};

export const slaTimerRequestSchema: SchemaOf<SLATimerRequest> = object().shape({
  id: array().of(number()).nullable(),
  endEvent: string().label('End Event').required(),
  endEventDescription: string().label('End Event').required(),
  service: string().label('Product').required(),
  sla: number().min(1).max(100).label('Default Days').required(),
  stateSlas: array().of(
    object().shape({
      sla: number().min(1).max(100).label('Days'),
      states: array().min(0).label('States'),
      timeUnit: string()
        .oneOf(Object.values(SlaScorecardRequestTimeUnitEnum))
        .label('Time Frame')
        .required(),
    }),
  ),
  startEvent: string().label('Start Event').required(),
  startEventDescription: string().label('Start Event').required(),
  target: number().min(5).max(100).label('Target').required(),
  timeUnit: string()
    .oneOf(Object.values(SlaScorecardRequestTimeUnitEnum))
    .label('Time Frame')
    .required(),
});
