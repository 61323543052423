import { TaxRequestApplicationTypeEnum } from '@xpanseinc/tax-service-api';
import { InputTypeEnum, TaxProductsEnum } from './typesAndEnums';
import type { Page } from './typesAndEnums';
import { usTimeZoneOptions } from '../usTimeZoneOptions';
import { mapDropdownOptionsFromEnum } from '../../utils/mapDropdownOptions';
import { detectDomain, DomainTypes } from '../../utils/detectDomain';

let oldVersionValue;
let newVersionValue;

const domain = detectDomain();
switch (domain) {
  case DomainTypes.PROD:
    oldVersionValue = 203;
    newVersionValue = 303;
    break;
  case DomainTypes.UAT:
    oldVersionValue = 300;
    newVersionValue = 4;
    break;
  default:
    oldVersionValue = 100;
    newVersionValue = 102;
}

const workflowVersionOptions = [
  { label: `New - ${newVersionValue}`, value: newVersionValue },
  { label: `Old - ${oldVersionValue}`, value: oldVersionValue },
];

export const productDetailsConfig: Page = {
  common: {
    title: 'Order Details',
    fields: {
      workflowVersion: {
        label: 'Workflow Version',
        required: true,
        type: InputTypeEnum.Dropdown,
        options: workflowVersionOptions,
        dependency: null,
      },
      provider: {
        label: 'Provider',
        required: true,
        type: InputTypeEnum.Dropdown,
        options: [],
        dependency: null,
      },
      product: {
        label: 'Product',
        required: true,
        type: InputTypeEnum.Dropdown,
        options: [],
        dependency: null,
      },
      orderInstructions: {
        label: 'Order Instructions',
        required: false,
        type: InputTypeEnum.TextArea,
        options: null,
        dependency: null,
      },
      subjectProperty: {
        label: 'Subject Property',
        required: true,
        type: InputTypeEnum.SubjectProperty,
        options: null,
        dependency: null,
      },
      borrowers: {
        label: 'Borrowers',
        required: true,
        type: InputTypeEnum.ContactsModule,
        options: null,
        dependency: null,
      },
      applicant: {
        label: 'Applicant/Co-Applicant',
        required: true,
        type: InputTypeEnum.ContactsModule,
        options: null,
        dependency: null,
      },
      contacts: {
        label: 'Contacts',
        required: true,
        type: InputTypeEnum.ContactsModule,
        options: null,
        dependency: null,
      },
      addAttachments: {
        label: 'Attachments',
        required: false,
        type: InputTypeEnum.AttachmentsModule,
        options: null,
        dependency: null,
      },
    },
  },
  appraisal: {
    title: 'Appraisal',
    fields: {
      feesAndQuotes: {
        label: 'Fees & Quotes',
        required: false,
        type: InputTypeEnum.FeesAndQuotesModule,
        options: null,
        dependency: null,
      },
      payments: {
        label: 'Payments',
        required: false,
        type: InputTypeEnum.PaymentsModule,
        options: null,
        dependency: null,
      },
      duReferenceNumber: {
        label: 'DU Reference Number',
        required: true,
        type: InputTypeEnum.TextString,
        options: null,
        dependency: null,
      },
      specialFeatureCode: {
        label: 'Special Feature Code',
        required: false,
        type: InputTypeEnum.TextString,
        options: null,
        dependency: null,
      },
    },
  },
  avm: {
    title: 'AVM',
    fields: {
      comparables: {
        label: 'Comparables',
        required: false,
        type: InputTypeEnum.ComplexCheckbox,
        options: null,
        dependency: null,
      },
      customSearchDetail: {
        label: 'Custom Search Detail',
        required: false,
        type: InputTypeEnum.ComplexCheckbox,
        options: null,
        dependency: null,
      },
      documentImageFinance: {
        label: 'Document Image (Finance)',
        required: false,
        type: InputTypeEnum.ComplexCheckbox,
        options: null,
        dependency: null,
      },
      financeDocumentId: {
        label: 'Finance Document ID',
        required: true,
        type: InputTypeEnum.TextString,
        options: null,
        dependency: null,
      },
      financeRecordingDate: {
        label: 'Finance Recording Date',
        required: true,
        type: InputTypeEnum.DatePicker,
        options: null,
        dependency: null,
      },
      documentImageSale: {
        label: 'Document Image (Sale)',
        required: false,
        type: InputTypeEnum.ComplexCheckbox,
        options: null,
        dependency: null,
      },
      saleDocumentId: {
        label: 'Sale Document ID',
        required: true,
        type: InputTypeEnum.TextString,
        options: null,
        dependency: null,
      },
      saleRecordingDate: {
        label: 'Sale Recording Date',
        required: true,
        type: InputTypeEnum.DatePicker,
        options: null,
        dependency: null,
      },
      foreclosureActivity: {
        label: 'Foreclosure Activity',
        required: false,
        type: InputTypeEnum.ComplexCheckbox,
        options: null,
        dependency: null,
      },
      listeningMarketingActivityImage: {
        label: 'Listing & Marketing Activity Image',
        required: false,
        type: InputTypeEnum.ComplexCheckbox,
        options: null,
        dependency: null,
      },
      listeningMarketingActivityReport: {
        label: 'Listing & Marketing Activity Report',
        required: false,
        type: InputTypeEnum.ComplexCheckbox,
        options: null,
        dependency: null,
      },
      propertyDetail: {
        label: 'Property Detail',
        required: false,
        type: InputTypeEnum.ComplexCheckbox,
        options: null,
        dependency: null,
      },
      streetMap: {
        label: 'Street Map',
        required: false,
        type: InputTypeEnum.ComplexCheckbox,
        options: null,
        dependency: null,
      },
      transactionHistory: {
        label: 'Transaction History',
        required: false,
        type: InputTypeEnum.ComplexCheckbox,
        options: null,
        dependency: null,
      },
      voluntaryInvoluntaryLien: {
        label: 'Voluntary & Involuntary Lien',
        required: false,
        type: InputTypeEnum.ComplexCheckbox,
        options: null,
        dependency: null,
      },
      ownerFirstName: {
        label: 'Owner First Name',
        required: true,
        type: InputTypeEnum.TextString,
        options: null,
        dependency: null,
      },
      ownerLastName: {
        label: 'Owner Last Name',
        required: true,
        type: InputTypeEnum.TextString,
        options: null,
        dependency: null,
      },
      voluntaryLien: {
        label: 'Voluntary Lien',
        required: false,
        type: InputTypeEnum.ComplexCheckbox,
        options: null,
        dependency: null,
      },
      freddieMacHVE: {
        label: "Freddie Mac's HVE",
        required: false,
        type: InputTypeEnum.ComplexCheckbox,
        options: null,
        dependency: null,
      },
      geoAVMCore: {
        label: 'GeoAVM Core',
        required: false,
        type: InputTypeEnum.ComplexCheckbox,
        options: null,
        dependency: null,
      },
      pass: {
        label: 'PASS',
        required: false,
        type: InputTypeEnum.ComplexCheckbox,
        options: null,
        dependency: null,
      },
      valuepoint4: {
        label: 'Valuepoint 4',
        required: false,
        type: InputTypeEnum.ComplexCheckbox,
        options: null,
        dependency: null,
      },
      collateralManager: {
        label: 'Collateral Manager',
        required: false,
        type: InputTypeEnum.ComplexCheckbox,
        options: null,
        dependency: null,
      },
    },
  },
  close: {
    title: 'Close',
    fields: {
      closingDate: {
        label: 'Closing Date',
        required: false,
        type: InputTypeEnum.DatePicker,
        options: null,
        dependency: null,
      },
      closingDateTimezone: {
        label: 'Timezone',
        required: false,
        type: InputTypeEnum.Dropdown,
        options: usTimeZoneOptions(),
        dependency: null,
      },
    },
  },
  credit: {
    title: 'Credit',
    fields: {
      requestType: {
        label: 'Request Type',
        required: true,
        type: InputTypeEnum.Dropdown,
        options: null,
        dependency: null,
      },
      reportType: {
        label: 'Report Type',
        required: true,
        type: InputTypeEnum.Dropdown,
        options: null,
        dependency: null,
      },
      experian: {
        label: 'Experian',
        required: false,
        type: InputTypeEnum.Checkbox,
        options: null,
        dependency: null,
      },
      equifax: {
        label: 'Equifax',
        required: false,
        type: InputTypeEnum.Checkbox,
        options: null,
        dependency: null,
      },
      transUnion: {
        label: 'Trans Union',
        required: false,
        type: InputTypeEnum.Checkbox,
        options: null,
        dependency: null,
      },
    },
  },
  employment: {
    title: 'Employment',
    fields: {
      workflow: {
        label: 'Workflow',
        required: true,
        type: InputTypeEnum.Dropdown,
        options: null,
        dependency: null,
      },
      employmentHistory: {
        label: 'Employment History',
        required: true,
        type: InputTypeEnum.EmployersModule,
        options: null,
        dependency: null,
      },
      closingDate: {
        label: 'Closing Date',
        required: false,
        type: InputTypeEnum.DatePicker,
        options: null,
        dependency: null,
      },
    },
  },
  // flood: {
  //   title: 'Flood',
  //   fields: {
  //     floodCertificationNumber: {
  //       label: 'Flood Certification Number',
  //       required: true,
  //       type: InputTypeEnum.TextString,
  //       options: null,
  //       dependency: null,
  //     },
  //   },
  // },
  verificationOfTax: {
    title: 'Verification of Tax',
    fields: {
      clientContact: {
        label: 'Client Contact',
        required: false,
        type: InputTypeEnum.TextString,
        options: null,
        dependency: null,
      },
      email: {
        label: 'Email',
        required: true,
        type: InputTypeEnum.Dropdown,
        options: [],
        dependency: null,
      },
      provider: {
        label: 'Provider',
        required: false,
        type: InputTypeEnum.Dropdown,
        options: [],
        dependency: null,
      },
      applicationType: {
        label: 'Application Type',
        required: false,
        type: InputTypeEnum.Dropdown,
        options: mapDropdownOptionsFromEnum(TaxRequestApplicationTypeEnum, true, 'key'),
        dependency: null,
      },
      attachmentType: {
        label: 'Attachment Type',
        required: true,
        type: InputTypeEnum.Dropdown,
        options: [],
        dependency: null,
      },
      documentType: {
        label: 'Document Type',
        required: true,
        type: InputTypeEnum.Dropdown,
        options: [],
        dependency: null,
      },
      applicantInformation: {
        label: 'Add Applicant',
        required: true,
        type: InputTypeEnum.BorrowerModule,
        options: null,
        dependency: null,
      },
      coapplicantInformation: {
        label: 'Add Co-Applicant',
        required: true,
        type: InputTypeEnum.BorrowerModule,
        options: null,
        dependency: null,
      },
      addApplication: {
        label: 'Add Application',
        required: false,
        type: InputTypeEnum.BorrowerModule,
        options: null,
        dependency: null,
      },
      product: {
        label: 'Product',
        required: false,
        type: InputTypeEnum.Dropdown,
        options: [
          { label: TaxProductsEnum.PRODUCT_4506_C, value: TaxProductsEnum.PRODUCT_4506_C },
          { label: TaxProductsEnum.PRODUCT_8821, value: TaxProductsEnum.PRODUCT_8821 },
        ],
        dependency: null,
      },
      businessName: {
        label: 'Business Name',
        required: true,
        type: InputTypeEnum.TextString,
        options: null,
        dependency: null,
      },
      signersTitle: {
        label: 'Signers Title',
        required: false,
        type: InputTypeEnum.TextString,
        options: null,
        dependency: null,
      },
      ein: {
        label: 'EIN',
        required: true,
        type: InputTypeEnum.TextString,
        options: null,
        dependency: null,
      },
      1040: {
        label: '1040',
        required: true,
        type: InputTypeEnum.ComplexCheckbox,
        options: null,
        dependency: null,
      },
      filingYears: {
        label: 'Filing Years (Last 4)',
        required: true,
        type: InputTypeEnum.Checkbox,
        options: null,
        dependency: null,
      },
      transcriptType: {
        label: 'Transcript Type',
        required: true,
        type: InputTypeEnum.Dropdown,
        options: [],
        dependency: null,
      },
      verifyTaxFiling: {
        label: 'Verify Tax Filing',
        required: true,
        type: InputTypeEnum.ComplexCheckbox,
        options: null,
        dependency: null,
      },
      wagesAndIncome: {
        label: 'Wages & Income',
        required: true,
        type: InputTypeEnum.ComplexCheckbox,
        options: null,
        dependency: null,
      },
      attachments: {
        label: 'Attachments',
        required: false,
        type: InputTypeEnum.AttachmentsModule,
        options: null,
        dependency: null,
      },
    },
  },
};
