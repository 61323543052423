<script>
  import {
    BarChart2Icon,
    HelpCircleIcon,
    FileIcon,
    PlusCircleIcon,
    SlidersIcon,
    UsersIcon,
  } from 'svelte-feather-icons';
  import Layout from '../components/Layout.svelte';
  import pageTitle from '../utils/pageTitle';
  import urls from '../urls.js';

  const title = 'Home';

  const homeItems = [
    {
      icon: PlusCircleIcon,
      label: 'Place Order',
      route: `/#${urls.placeOrder}`,
    },
    {
      icon: FileIcon,
      label: 'Order List',
      route: `/#${urls.orders}`,
    },
    {
      icon: BarChart2Icon,
      label: 'Scorecards',
      route: `/#${urls.slaScorecards}`,
    },
    {
      icon: SlidersIcon,
      label: 'Allocation',
      route: urls.allocation,
      isExternal: true,
    },
    {
      icon: UsersIcon,
      label: 'User Management',
      route: `/#${urls.userManagement}`,
    },
    {
      icon: HelpCircleIcon,
      label: 'Support',
      route: urls.support,
      isExternal: true,
    },
  ];
</script>

<svelte:head>
  <title>{pageTitle(title)}</title>
</svelte:head>

<Layout>
  <div class="content" slot="center">
    <div class="podium-header">
      <img src="/podium-logo.png" alt="Podium Logo" />
    </div>
    <div class="body">
      <div class="grid">
        {#each homeItems as { label, icon, route, isExternal }, i}
          <a
            class="home-item"
            class:active="{route}"
            class:disabled="{!route}"
            href="{route}"
            target="{isExternal ? '_blank' : null}"
            rel="{isExternal ? 'noopener noreferrer' : null}"
          >
            <div class="home-item-icon">
              <svelte:component this="{icon}" />
            </div>
            <div class="home-item-label">
              {label}
            </div>
          </a>
        {/each}
      </div>
    </div>
  </div>
</Layout>

<style>
  .content {
    display: inline-flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 128px;
  }

  .grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 25px;
  }

  .podium-header {
    display: flex;
    align-items: center;
  }

  .podium-header img {
    max-width: 500px;
    padding-bottom: 20px;
  }

  .home-item {
    padding: 30px 0;
    width: 200px;
    text-align: center;
    border-radius: 6px;
    transition: all 0.3s;
    text-decoration: none;
    color: var(--textDark);
  }

  .home-item.active:hover {
    color: var(--primaryDefault);
    box-shadow: 1px 0 5px 1px rgba(0, 0, 0, 0.17);
    cursor: pointer;
  }

  .home-item.disabled {
    color: var(--gray5);
  }

  .home-item-icon {
    height: 75px;
    padding-top: 12px;
  }

  .home-item-label {
    margin-top: 20px;
    font-size: 20px;
    padding: 12px 0;
  }
</style>
