<script>
  import { SearchIcon } from 'svelte-feather-icons';

  export let title = '3 characters required';
  export let message = 'Please use at least 3 characters in your search';
</script>

<div class="content">
  <SearchIcon size="120" />
  <h3>{title}</h3>
  <div class="body">{message}</div>
</div>

<style>
  .content {
    margin: 0 auto;
    text-align: center;
  }

  h3 {
    margin-bottom: 8px;
  }

  .content :global(.feather) {
    color: var(--gray2);
  }
</style>
