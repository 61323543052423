/* eslint-disable */
interface Option {
  label: string;
  value: string;
}
export const alphabetically = (a: Option, b: Option): number => {
  if (a.label.toLocaleLowerCase() < b.label.toLocaleLowerCase()) return -1;
  if (a.label.toLocaleLowerCase() > b.label.toLocaleLowerCase()) return 1;
  return 0;
};
