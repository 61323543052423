<script>
  import { PartyContactTypeEnum } from '@xpanseinc/ui-backend-api';
  import { Button, Dialog } from '@xpanseinc/ui-components';
  import { UserPlusIcon } from 'svelte-feather-icons';
  import { createEventDispatcher } from 'svelte';
  import AddContact from './AddContact.svelte';
  import Contact from './Contact.svelte';

  export let sendEventForm;
  export let config;

  const dispatch = createEventDispatcher();

  let currentContact;
  let editing = -1;
  let toDelete;

  function DefaultParty() {
    return {
      partyRole: '',
      contactType: undefined,
      fullName: '',
      firstName: '',
      middleName: '',
      lastName: '',
      address: {
        line1: '',
        line2: '',
        city: '',
        county: '',
        zip: '',
        state: '',
      },
      email: '',
      emailRole: null,
      phone: '',
      phoneRole: null,
      relationshipVesting: null,
    };
  }

  function onEditContact(i) {
    editing = i;
    currentContact = { ...sendEventForm.parties[i] };
  }

  function deleteContact(i) {
    sendEventForm.parties.splice(i, 1);
    toDelete = undefined;
    dispatch('validateParties');
  }

  function onAddContact() {
    currentContact = new DefaultParty();
  }

  function onCancelContact() {
    currentContact = undefined;
  }

  function onSaveContact() {
    if (!sendEventForm.parties) {
      sendEventForm.parties = [];
    }
    if (editing !== -1) {
      deleteContact(editing);
    }
    sendEventForm.parties.push(currentContact);
    editing = -1;
    dispatch('validateParties');
    onCancelContact();
  }
</script>

<h4 class="header-m textDark">Points Of Contact{config?.parties.required ? '*' : ''}</h4>
{#if currentContact}
  <AddContact
    sendEventForm="{sendEventForm}"
    on:save="{onSaveContact}"
    on:cancel="{onCancelContact}"
    bind:currentContact
  />
{:else}
  {#if sendEventForm.parties}
    {#each sendEventForm.parties as party, i}
      <Contact
        party="{party}"
        on:edit="{() => onEditContact(i)}"
        on:delete="{() => {
          toDelete = i;
        }}"
      />
    {/each}
  {/if}
  <div class="form-row">
    <Button icon="{UserPlusIcon}" label="Add Contact" name="add-party" on:click="{onAddContact}" />
  </div>
{/if}

{#if toDelete >= 0 && toDelete !== null}
  <Dialog
    visible="true"
    size="small"
    title="Delete Contact"
    on:close="{() => {
      toDelete = undefined;
    }}"
  >
    <p slot="body">
      Are you sure you want to delete contact:
      <strong>
        {#if sendEventForm.parties[toDelete].contactType === PartyContactTypeEnum.Individual}
          {sendEventForm.parties[toDelete].firstName}
          {sendEventForm.parties[toDelete].lastName}
        {:else}
          {sendEventForm.parties[toDelete].fullName}
        {/if}
      </strong>?
    </p>
    <footer slot="footer" class="footer">
      <Button
        label="Cancel"
        name="cancel"
        on:click="{() => {
          toDelete = undefined;
        }}"
      />
      <Button
        color="warning"
        label="Delete Contact"
        name="delete-contact"
        on:click="{() => deleteContact(toDelete)}"
      />
    </footer>
  </Dialog>
{/if}

<style>
  .footer :global(button.primary) {
    margin-left: 0.5rem;
  }
</style>
