import type { AnyObjectSchema, ValidationError } from 'yup';

export type ValidationResult = {
  errors: { [path: string]: ValidationError[] };
  valid: boolean;
};

export function validate(schema: AnyObjectSchema, value): ValidationResult {
  try {
    schema.validateSync(value, { abortEarly: false });
  } catch (validationError) {
    const result: ValidationResult = { errors: {}, valid: false };
    (validationError as ValidationError).inner.forEach((error) => {
      if (!result[error.path]) {
        result.errors[error.path] = [];
      }
      result.errors[error.path].push(error);
    });
    return result;
  }

  return { errors: {}, valid: true };
}
