<script>
  import { Tooltip, XpanseLogo } from '@xpanseinc/ui-components';
  import NavButton from './NavButton.svelte';

  export let homeHref = '/#';
  export let items = [];
</script>

<nav>
  <a class="xpanse-logo" href="{homeHref}" aria-label="Xpanse homepage">
    <XpanseLogo type="simple" height="40" width="40" theme="black" />
  </a>
  {#each items as item}
    <Tooltip text="{item.label}" position="right">
      <NavButton
        href="{item.href}"
        icon="{item.icon}"
        label="{item.label}"
        active="{item.active}"
        isExternal="{item.isExternal}"
      />
    </Tooltip>
  {/each}
</nav>

<style>
  nav {
    position: fixed;
    align-items: center;
    background-color: var(--white);
    box-shadow: 0 0 4px rgba(19, 20, 22, 0.08), 0 4px 8px rgba(19, 20, 22, 0.12);
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 56px;
    z-index: 6;
  }

  nav > :global(.target) {
    margin: 10px 0 6px 0;
  }

  .xpanse-logo {
    background-color: transparent;
    border: 0;
    box-shadow: none;
    outline: none;
    opacity: 1;
    margin: 10px 0 6px 0;
    transition: var(--transitionHover);
  }

  .xpanse-logo:hover {
    opacity: 0.8;
  }

  .xpanse-logo:focus-visible {
    opacity: 0.8;
    outline: none;
    box-shadow: 0 0 0 4px var(--focusDefault);
  }
</style>
