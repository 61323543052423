// See the imask Svelte plugin docs for more info on how to configure masks
// https://github.com/uNmAnNeR/imaskjs/tree/master/packages/svelte-imask
import { imask } from '@imask/svelte';

type Masks = {
  [key: string]: {
    mask?: any;
    blocks?: object;
  };
};

const masks: Masks = {
  currency: {
    mask: '$num',
    blocks: {
      num: {
        mask: Number,
        padFractionalZeros: true,
        scale: 2,
        signed: false,
        thousandsSeparator: ',',
        radix: '.',
      },
    },
  },
  ein: {
    mask: '00-0000000',
  },
  fha: {
    mask: '000-0000000-000',
  },
  phone: {
    mask: '(000) 000-0000',
  },
  ssn: {
    mask: '000-00-0000',
  },
  anystring: {
    mask: /./,
  },
  dateOfBirth: {
    mask: '0000-00-00',
  },
};

export { imask, masks };
