<script>
  import { Skeleton } from '@xpanseinc/ui-components';
</script>

<div class="placeholders">
  {#each Array(8) as _, i}
    <Skeleton height="142px" />
  {/each}
</div>

<style>
  .placeholders {
    padding: 8px 0 0;
  }

  .placeholders > :global(div) {
    margin: 0 0 8px;
  }
</style>
