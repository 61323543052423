<script>
  import { TextInput } from '@xpanseinc/ui-components';
  import { onDestroy } from 'svelte';
  import { getLabel } from '../../constants/sendEventDataLabels';
  import { imask, masks } from '../../constants/inputMasking';

  export let checkValid = false;
  export let required = false;
  export let sendEventForm;

  if (!sendEventForm.invoiceAmount) {
    sendEventForm.invoiceAmount = 0;
  }

  onDestroy(() => {
    delete sendEventForm.invoiceAmount;
  });
</script>

<div class="form-row">
  <div class="form-item">
    <TextInput
      invalid="{checkValid && required && !sendEventForm?.invoiceAmount}"
      label="{getLabel('invoiceAmount')}{required ? '*' : ''}"
      name="invoiceAmount"
      maskPackage="{imask}"
      maskOptions="{masks.currency}"
      on:accept="{({ detail: { masked } }) => {
        sendEventForm.invoiceAmount = masked.rawInputValue;
      }}"
    />
  </div>
</div>
