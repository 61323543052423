export const usTimeZoneOptions = () => [
  { label: 'Atlantic Time (Puerto Rico)', value: 'America/Puerto_Rico' },
  { label: 'Eastern Time (New York)', value: 'America/New_York' },
  { label: 'Central Time (Chicago)', value: 'America/Chicago' },
  { label: 'Mountain Time (Denver)', value: 'America/Denver' },
  { label: 'Mountain Standard Time (Phoenix)', value: 'America/Phoenix' },
  { label: 'Pacific Time (Los Angeles)', value: 'America/Los_Angeles' },
  { label: 'Alaskan Time (Anchorage)', value: 'America/Anchorage' },
  { label: 'Hawaii-Aleutian Time (Honolulu)', value: 'Pacific/Honolulu' },
];
