<script>
  import { isEmpty } from 'lodash-es';
  import { ChevronsRightIcon, LoaderIcon } from 'svelte-feather-icons';
  import LogDetailDialog from './LogDetailDialog.svelte';

  export let details = {};
  export let downloading = false;
  export let icon;
  export let title = '';
  export let theme;
  let showDialog = false;
</script>

<div class="detail {theme}">
  <div class="icon" class:spin="{downloading}">
    {#if downloading}
      <LoaderIcon size="24" />
    {:else}
      <svelte:component this="{icon}" size="24" />
    {/if}
  </div>
  <div class="label">
    <slot name="label" />
  </div>
  {#if $$slots.info}
    <slot name="info" />
  {/if}
  {#if !isEmpty(details)}
    <button
      class="btn-unstyled link"
      name="show-dialog"
      on:click="{() => {
        showDialog = true;
      }}"
    >
      Details <ChevronsRightIcon size="16" />
    </button>
  {/if}
</div>
{#if false}<slot />{/if}
<LogDetailDialog details="{details}" title="{title}" bind:visible="{showDialog}" />

<style>
  .detail {
    align-items: center;
    display: flex;
    border-radius: 4px;
    height: 100%;
    padding: 8px 8px;
    border: 1px solid var(--gray2);
    width: 100%;
    flex-wrap: wrap;
  }

  .icon {
    height: 20px;
    width: 20px;
  }

  .icon :global(svg) {
    height: 20px;
    width: 20px;
  }

  .label {
    align-items: center;
    display: flex;
    flex: 1;
    overflow: hidden;
    padding-left: 8px;
    color: var(--textDark);
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
  }

  .contact {
    padding-left: 16px;
  }

  .email {
    display: none; /* NEW */
    background: #c8c8c8;
    margin-left: 28px;
    padding: 10px;
    position: absolute;
    z-index: 1000;
    width: 200px;
    height: 100px;
  }

  .blue {
    background-color: var(--primaryLight);
    color: var(--primaryDefault);
  }

  .green {
    background-color: var(--positiveLight);
    color: var(--positiveDark);
  }

  .gold {
    background-color: var(--neutralLight);
    color: var(--neutralDark);
  }

  .orange {
    background-color: var(--cautionLight);
    color: var(--cautionDark);
  }

  .link {
    color: inherit;
    display: flex;
    gap: 4px;
    font-weight: 600;
    font-size: 12px;
    align-items: center;
  }

  .link :global(svg) {
    position: relative;
  }

  .link:hover {
    color: var(--textDark);
  }
</style>
