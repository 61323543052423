<script>
  import { createEventDispatcher } from 'svelte';
  import { Button, Dialog, Dropdown, UploadInput } from '@xpanseinc/ui-components';
  import FormBlock from '../FormBlock.svelte';
  import FormRow from '../FormRow.svelte';

  const dispatch = createEventDispatcher();

  export let fileTypeOptions = [];
  export let uploadModalOpen = false;
  export let lastUploadedFile = null;

  let uploadInput;
  let files = [];
  let hasAttachedDuplicateFile = false;

  $: if (files.length) {
    hasAttachedDuplicateFile = false;
    dispatch('setLoanUploadFiles', files);
  }

  $: if (!uploadModalOpen) {
    hasAttachedDuplicateFile = false;
    files = [];
    uploadInput?.resetFiles();
  }

  $: if (files.length && files[0].name === lastUploadedFile) {
    hasAttachedDuplicateFile = true;
  }

  function uploadLoanFile() {
    dispatch('uploadLoanFile');
  }
</script>

<Dialog title="Upload Loan File" size="x-large" on:close bind:visible="{uploadModalOpen}">
  <div slot="body">
    <div class="row subtitle-small">Upload a loan file from your computer</div>
    <FormRow>
      <FormBlock width="800px">
        <div class="row">
          <Dropdown
            label="File Type"
            name="fileType"
            options="{fileTypeOptions}"
            value="{fileTypeOptions.find((x) => x.value.includes('.XML')).value || null}"
            disabled
          />
          <UploadInput
            class="file-input"
            label="Attach User File"
            accept="text/xml"
            acceptMsg="XML"
            bind:this="{uploadInput}"
            bind:files
            on:remove="{() => {
              files = [];
            }}"
          />
        </div>
        {#if hasAttachedDuplicateFile}
          <div class="error-message">
            This file is already in use. Choose another file or make changes to the existing
            imported fields.
          </div>
        {/if}
      </FormBlock>
    </FormRow>
  </div>
  <footer slot="footer" class="footer">
    <Button
      name="cancel"
      label="Cancel"
      on:click="{() => {
        uploadModalOpen = false;
      }}"
    />
    <Button
      name="import"
      label="Import"
      color="primary"
      on:click="{uploadLoanFile}"
      disabled="{!files.length || hasAttachedDuplicateFile}"
    />
  </footer>
</Dialog>

<style>
  .row > :global(*) {
    flex: 1;
  }

  .error-message {
    padding-left: 5px;
    color: var(--negativeDark);
  }

  .footer :global(button.primary) {
    margin-left: 0.5rem;
  }
</style>
