<script>
  import { TextInput, Button, ConfirmationDialog } from '@xpanseinc/ui-components';
  import { PermissionTypeEnum } from '@xpanseinc/authorization-service-api-rest';
  import { createEventDispatcher } from 'svelte';
  import { FilterIcon, PlusCircleIcon, SaveIcon, RefreshCcwIcon } from 'svelte-feather-icons';
  import { stagedFilter, searchFilter, activeFilter } from '../../stores/orders';
  import { currentUserCreatePermissions, currentUser, getAdminStatus } from '../../stores/users';
  import AdvancedSearchChips from './AdvancedSearchChips.svelte';
  import { userOrgType } from '../../stores/profile';
  import { OrgTypeEnum } from '../../constants/place-order';
  import { saveSearchApi } from '../../stores/api';
  import { push } from 'svelte-spa-router';
  import { popToast } from '../../utils/toasts';
  import urls from '../../urls';
  export let displayedChips;

  let visible = false;
  const isOrderAdmin = getAdminStatus(PermissionTypeEnum.OrderAdmin);

  $: message = `The Search Shortcut "${$activeFilter.name}" will update with the current search settings.`;
  const dispatch = createEventDispatcher();

  const saveFilters = () => {
    dispatch('save');
  };

  const getOrders = async () => {
    $stagedFilter.pageNumber = 0;
    $searchFilter = $stagedFilter;
  };

  const loanNumberKeyup = (e) => {
    $stagedFilter.loan = e.target.value;
    if (e.key === 'Enter') {
      getOrders();
    }
  };

  const updateSearch = async () => {
    try {
      await $saveSearchApi.updateSavedSearch({
        id: $activeFilter.id,
        saveSearchRequest: {
          name: $activeFilter.name,
          search: JSON.stringify({ ...$searchFilter, pageNumber: 0 }),
        },
      });
      popToast({
        title: 'Search successfully updated',
        type: 'success',
        duration: 6000,
      });
    } catch (error) {
      popToast({
        title: 'Error: Failed to update search',
        description: 'Retry',
        duration: 6000,
        onClick: () => {
          updateSearch();
        },
      });
      console.error('updateSavedSearch:', error);
    }
    visible = false;
  };
</script>

<div class="search-bar">
  <div class="search-bar-top-actions">
    {#if $userOrgType !== OrgTypeEnum.BROKER}
      <button
        class="btn secondary"
        class:hidden="{Object.values(displayedChips).indexOf(true) === -1 || !isOrderAdmin}"
        on:click="{saveFilters}"
      >
        <SaveIcon size="14" />Save search
      </button>
    {/if}

    {#if $activeFilter?.orgId === $currentUser?.externalOrganizationId || $activeFilter?.orgType === 'PlatformOwner'}
      <button
        class="btn secondary"
        class:hidden="{(!$activeFilter.name && !activeFilter.id) || !isOrderAdmin}"
        on:click="{() => {
          visible = true;
        }}"
      >
        <RefreshCcwIcon size="14" />Update existing
      </button>
    {/if}

    {#if $currentUserCreatePermissions && $currentUserCreatePermissions.length}
      <Button
        color="primary"
        label="New order"
        icon="{PlusCircleIcon}"
        name="placeOrder"
        on:click="{() => push(urls.placeOrder)}"
      />
    {/if}
  </div>
  <div class="search-bar-textbox">
    <label class="visually-hidden" for="advancedSearch">
      Search the Loan number or Transaction ID
    </label>
    <TextInput
      name="advancedSearch"
      id="advancedSearch"
      class="search-input"
      type="search"
      placeholder="Loan no. or Transaction ID"
      value="{$stagedFilter.loan}"
      on:keyup="{loanNumberKeyup}"
    />
    <Button
      color="secondary"
      label="Filters"
      name="addContactMethod"
      icon="{FilterIcon}"
      on:click="{() => {
        push(urls.advancedSearch);
      }}"
    />
  </div>
</div>
<AdvancedSearchChips getOrders="{getOrders}" bind:displayedChips on:clearedField />

<ConfirmationDialog
  confirmLabel="Update Existing"
  title="Update Existing Search Shortcut"
  message="{message}"
  bind:visible
  on:click="{updateSearch}"
/>

<style>
  .search-bar {
    margin-top: 30px;
  }

  .search-bar-textbox {
    display: inline-flex;
  }

  .search-bar-textbox :global(.search-input) {
    min-width: 375px;
    margin-right: 8px;
  }

  .search-bar-top-actions {
    margin-bottom: 2rem;
    display: flex;
    justify-content: flex-end;
    height: 32px;
  }

  .search-bar-top-actions .btn.hidden {
    display: none;
  }

  /* Btn - SM */
  .search-bar-top-actions :global(.btn),
  .search-bar-top-actions .btn {
    margin: 0 0 0 8px;
    font-size: 14px;
    padding: 6px 12px;
    height: 32px;
  }

  .search-bar-top-actions :global(.btn svg),
  .search-bar-top-actions .btn :global(svg) {
    width: 14px;
    height: 14px;
  }

  .search-bar-top-actions :global(.btn .icon),
  .search-bar-top-actions .btn :global(.feather) {
    margin-right: 6px;
  }
</style>
