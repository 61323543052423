import { array, object, number, string } from 'yup';
import { uniqueId } from 'lodash-es';

export type Fee = {
  id?: string;
  category?: string;
  description?: string;
  amount?: number;
};

export type AppraisalFees = {
  appraisalFee?: Fee;
  additionalFees?: Array<Fee>;
};

export const feeSchema = object().shape({
  id: number(),
  category: string().required(),
  description: string().required(),
  amount: number().positive().required(),
});

export const appraisalFeesSchema = object().shape({
  appraisalFee: feeSchema,
  additionalFees: array().of(feeSchema),
});

export function getDefaultFee(): Fee {
  return {
    id: uniqueId(),
    category: '',
    description: '',
    amount: null,
  };
}

export function getDefaultAppraisalFees(): AppraisalFees {
  return {
    appraisalFee: getDefaultFee(),
    additionalFees: [getDefaultFee()],
  };
}
