<script>
  import { getContext } from 'svelte';
  import { AlertTriangleIcon } from 'svelte-feather-icons';
  import { PlaceholderButton } from '@xpanseinc/ui-components';
  import { createEventDispatcher } from 'svelte';
  import ModuleItemCard from '../ModuleItemCard.svelte';

  export let showErrorMsg = false;
  export let contextKey = '';
  export let itemList = [];
  export let maxItems = undefined;
  export let placeholderButtonLabel = '';
  export let placeholderButtonDisabled = false;
  export let badgeLabel = '';
  export let orgType = undefined;

  const {
    component,
    getHeader = () => null,
    getHomeText = () => null,
    getPaymentText = () => null,
    getSSNText = () => null,
    isValid = () => null,
  } = getContext(contextKey);

  const dispatch = createEventDispatcher();
  let visible;
  let itemToEdit = null;

  function deleteItem(item) {
    const index = itemList.indexOf(item);
    itemList.splice(index, 1);
    itemList = itemList;
    dispatch('itemChange', itemList);
  }

  function editItem(item) {
    itemToEdit = item;
    visible = true;
  }

  function saveNewItem(item) {
    itemList.push(item);
    itemList = itemList;
    visible = false;
    dispatch('itemChange', itemList);
  }

  function saveEditedItem(payload) {
    const { existingItem, newItem } = payload;
    const index = itemList.indexOf(existingItem);
    itemList.splice(index, 1, newItem);
    itemList = itemList;
    visible = false;
    itemToEdit = null;
    dispatch('itemChange', itemList);
  }

  function formatMultipleEntries(entries, method) {
    return entries
      ?.filter((x) => x.method === method)
      .map((entry) => entry.details)
      .join(', ');
  }

  $: if (!visible) {
    itemToEdit = null;
  }
</script>

{#each itemList as item}
  <ModuleItemCard
    badgeText="{badgeLabel}"
    headerText="{getHeader(item)}"
    homeText="{getHomeText(item)}"
    emailText="{formatMultipleEntries(item.contactMethods, 'Email')}"
    phoneText="{formatMultipleEntries(item.contactMethods, 'Phone')}"
    faxText="{formatMultipleEntries(item.contactMethods, 'Fax')}"
    paymentText="{getPaymentText(item)}"
    ssnText="{getSSNText(item)}"
    showErrorMsg="{showErrorMsg && !isValid(item)}"
    on:edit="{() => editItem(item)}"
  />
{/each}

{#if !maxItems || itemList.length < maxItems}
  <PlaceholderButton
    disabled="{placeholderButtonDisabled}"
    icon="{placeholderButtonDisabled ? AlertTriangleIcon : undefined}"
    on:click="{() => (visible = true)}"
  >
    {placeholderButtonLabel}
  </PlaceholderButton>
{/if}

<svelte:component
  this="{component}"
  visible="{visible}"
  existingItem="{itemToEdit}"
  orgType="{orgType}"
  on:close="{() => (visible = false)}"
  on:save="{(e) => saveNewItem(e.detail)}"
  on:edited="{(e) => saveEditedItem(e.detail)}"
  on:delete="{deleteItem}"
  showErrorMsg="{!!itemToEdit && showErrorMsg}"
/>
