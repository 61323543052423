<script>
  import { PermissionTypeEnum } from '@xpanseinc/authorization-service-api-rest';
  import { DownloadIcon } from 'svelte-feather-icons';
  import { eventApi, orderApi } from '../stores/api';
  import { downloadFile } from '../utils/download';
  import { getAdminStatus } from '../stores/users';
  import { PlaceOrderProductTypeEnum } from '../stores/placeOrder';
  export let deliveryRetryCount;
  export let deliveryStatus;
  export let eventId;
  export let product;
  export let timeToDeliver;
  import { XCircleIcon } from 'svelte-feather-icons';
  import { popToast } from './../utils/toasts';

  const color = deliveryStatus.toLowerCase().split(' ').join('-');
  const isOrderAdmin = getAdminStatus(PermissionTypeEnum.OrderAdmin);
  const validStatuses = [
    'Delivery Retrying',
    'Delivery Failed',
    'Delivered',
    'Delivered (NACK)',
    'Delivery Pending',
  ];
  const hasValidDeliveryStatus = validStatuses.some((status) => status === deliveryStatus);
  const isPayment = product === PlaceOrderProductTypeEnum.Payment;

  const downloadResponse = async () => {
    const response = await $eventApi
      .downloadIntegratorXMLRaw({
        eventId,
      })
      .catch((err) => console.error(err));
    downloadFile(response.raw.blob(), `event_${eventId}_response.${isPayment ? 'json' : 'xml'}`);
  };

  const downloadIntegrator = async () => {
    const response = await $eventApi
      .downloadXMLRaw({
        eventId,
      })
      .catch((err) => console.error(err));
    downloadFile(response.raw.blob(), `event_${eventId}_integrator.${isPayment ? 'json' : 'xml'}`);
  };
  const cancelEvent = async () => {
    try {
      const response = await $orderApi.cancelTransactionEvent({ eventId });
      if (response.cancelSuccess) {
        popToast({
          description: 'Reload Order Events',
          title: 'Event successfully cancelled',
          type: 'success',
          duration: 6000,
          onClose: () => {
            window.location.reload();
          },
        });
      } else {
        popToast({
          description: 'Error Cancelling Order Event',
          title: 'Error cancelling event',
          type: 'error',
          duration: 6000,
        });
      }
      return response;
    } catch (error) {
      popToast({
        description: 'Error Cancelling Order Event',
        title: 'Error cancelling event',
        type: 'error',
        duration: 6000,
      });
    }
  };
</script>

<div class="log-status">
  <div class="details">
    {#if deliveryStatus}
      <div class="status body-small {color}">{deliveryStatus}</div>
    {/if}
    {#if deliveryRetryCount}
      <div class="caption">{deliveryRetryCount} Attempt(s)</div>
    {/if}
    {#if timeToDeliver}
      <div class="caption">{timeToDeliver}</div>
    {/if}
  </div>
  <div class="status-actions">
    {#if isOrderAdmin}
      <button class="btn-unstyled link download" on:click="{downloadIntegrator}">
        <div class="icon"><DownloadIcon size="16" /></div>
        Integrator {isPayment ? 'JSON' : 'XML'}
      </button>
      <button
        class="btn-unstyled download {hasValidDeliveryStatus ? 'link' : 'btn-disabled'}"
        disabled="{!hasValidDeliveryStatus}"
        on:click="{downloadResponse}"
      >
        <div class="icon"><DownloadIcon size="16" /></div>
        Response {isPayment ? 'JSON' : 'XML'}
      </button>
    {/if}
    {#if isOrderAdmin && (deliveryStatus === 'Delivery Pending' || deliveryStatus === 'Delivery Retrying')}
      <button on:click="{cancelEvent}" class="btn-cancel-unstyled download">
        <div class="icon"><XCircleIcon size="16" /></div>
        Cancel Event
      </button>
    {/if}
  </div>
</div>

<style>
  .log-status {
    align-items: center;
    background-color: var(--white);
    display: flex;
    min-height: 32px;
    min-width: 340px;
    justify-content: space-between;
    padding: 4px 8px;
    border-top: 1px solid var(--gray2);
    border-bottom: 1px solid var(--gray2);
    color: var(--textDefault);
  }

  .details > div {
    display: inline-flex;
    padding: 0 8px;
  }

  .details > div:first-child {
    padding: 0 8px 0 0;
  }

  .details > div + div {
    border-left: 1px solid var(--textMid);
    border-radius: 2px;
  }

  .status {
    font-size: 12px;
  }

  .btn-unstyled {
    color: var(--gray6);
    font-weight: 500 !important;
  }

  .btn-cancel-unstyled {
    color: var(--negativeDark) !important;
    background-color: transparent !important;
    border: none !important;
    font-weight: 500 !important;
  }

  .download {
    align-items: center;
    display: flex;
    font-size: 11px;
    font-weight: 700;
    line-height: 16px;
    margin-left: 16px;
  }

  .btn-disabled {
    cursor: auto;
  }

  .icon {
    margin: 3px 6px 0 0;
  }

  .delivery-pending {
    color: var(--cautionDark);
  }

  .delivered,
  .processed {
    color: var(--textDefault);
  }

  .received {
    color: var(--textDefault);
  }

  .delivery-failed {
    color: var(--negativeDark);
  }

  .status-actions {
    display: flex;
  }

  .status-actions :global(.link:hover) {
    color: var(--textDark);
  }
</style>
