import { array, object, string, boolean } from 'yup';

import type { Applicant } from './applicant';
import { ApplicationTypeEnum } from '../../stores/placeOrder';
import { TaxProductsEnum } from '../../constants/place-order';

export type Income = {
  // loanNumber: String;
  // mortgageType:String;
  // loanPurpose: String;
  // propertyAddress: String;

  provider: String;
  product: String;
  clientContact: String;
  email: String;
  applications: Application[];
};
export type Product = { name: String; transcriptType: String; years: {} } | {};

export enum AttachmentTypeEnum {
  E_SIGN = 'Upload E-Sign Document',
  WET_SIGNED = 'Upload Wet Signed Document',
}

export enum TranscriptIndicatorEnum {
  RETURN = 'returnTranscriptIndicator',
  ACCOUNT = 'accountTransriptIndicator', // @TODO typo in BE side, need to be fixed after BE fix.
  RECORD = 'recordOfAccountIndicator',
}

export enum DocumentTypeEnum {
  TYPE_4506_C = '4506-C',
  TYPE_AUDIT_LOG = 'Audit Log',
  TYPE_8821 = '8821',
  TYPE_8821_AUDIT_LOG = '8821 Halcyon Consent Form',
}

export type Application = {
  uid: String;
  applicationType: String;
  attachmentType: String;
  applicant: Applicant[];
  coApplicant?: Applicant[];
  products: { Business: Product; Individual: Product; Joint: Product } | {};
  attachments: Attachment[];
  businessName?: String;
  signersTitle?: String;
  ein?: String;
};

type Attachment = {
  fileName: String;
  mimeTypeId: String;
  usageCode: String;
  usageCodeDescription: String;
};

export const attachmentSchema = object().shape({
  fileName: string(),
  mimeTypeId: string(),
  usageCodeDescription: string(),
});

const getProductSchema = (taxProduct) => {
  if (taxProduct === TaxProductsEnum.PRODUCT_8821) {
    return object()
      .shape({
        name: string(),
        years: object(),
        selected: boolean().nullable(),
      })
      .test(
        'at_least_one_year_select',
        'Please select at least one year',
        (product) =>
          !product?.selected ||
          Object.keys(product.years).filter((year) => product.years[year]).length > 0,
      );
  }

  return object()
    .shape({
      name: string(),
      years: object(),
      selected: boolean().nullable(),
    })
    .test(
      'select_transcript',
      'Please select transcript type',
      (product) => !product?.selected || product?.transcriptType,
    )
    .test(
      'at_least_one_year_select',
      'Please select at least one year',
      (product) =>
        !product?.selected ||
        Object.keys(product.years).filter((year) => product.years[year]).length > 0,
    );
};

function checkProduct(products) {
  return (
    products &&
    products[this.parent.applicationType]?.filter((product) => product.selected).length > 0
  );
}

function checkAuditLogAttachment(attachments) {
  if (this.parent.attachmentType === AttachmentTypeEnum.E_SIGN) {
    return !!attachments.find(
      (a) => a.fileName && a.usageCodeDescription === DocumentTypeEnum.TYPE_AUDIT_LOG,
    );
  }

  return true;
}

function checkJointCoApplicant(coApplicant) {
  if (this.parent.applicationType === ApplicationTypeEnum.Joint) {
    return coApplicant.length > 0;
  }
  return true;
}

function checkBusinessName(businessName) {
  if (this.parent.applicationType === ApplicationTypeEnum.Business) {
    return businessName && businessName.length > 0;
  }
  return true;
}

function checkEin(ein) {
  if (this.parent.applicationType === ApplicationTypeEnum.Business) {
    return ein && ein.length > 0;
  }
  return true;
}

export const applicationSchema = (attachmentsSchema, taxProduct) => {
  const productSchema = getProductSchema(taxProduct);
  return object().shape({
    uid: string().required(),
    applicationType: string().required(),
    attachmentType: string().required(),
    applicant: array().required().min(1, 'At least one Applicant is required'),
    businessName: string().test('business_check', 'Business name is required', checkBusinessName),
    ein: string().test('ein_check', 'Employee Identity Number is required', checkEin),
    coApplicant: array().test(
      'co_applicant_required',
      'Co-Applicant is required',
      checkJointCoApplicant,
    ),
    products: object()
      .shape({
        Individual: array().of(productSchema),
        Joint: array().of(productSchema),
        Business: array().of(productSchema),
      })
      .test('at_least_one_product_checked', 'At least one product should be checked', checkProduct),
    attachments: attachmentsSchema,
  });
};

const getAttachmentsSchema = (taxProduct) => {
  if (taxProduct === TaxProductsEnum.PRODUCT_8821) {
    return array()
      .of(attachmentSchema)
      .test(
        '8821_attachment_required',
        '8821 document is required',
        (attachments) =>
          !!attachments.find(
            (a) => a.fileName && a.usageCodeDescription === DocumentTypeEnum.TYPE_8821,
          ),
      )
      .test(
        '8821_audit_attachment_required',
        '8821 Audit document is required',
        (attachments) =>
          !!attachments.find(
            (a) => a.fileName && a.usageCodeDescription === DocumentTypeEnum.TYPE_8821_AUDIT_LOG,
          ),
      );
  }

  return array()
    .of(attachmentSchema)
    .test(
      '4506_c_attachment_required',
      '4506-C document is required',
      (attachments) =>
        !!attachments.find(
          (a) => a.fileName && a.usageCodeDescription === DocumentTypeEnum.TYPE_4506_C,
        ),
    )
    .test(
      'audit_log_attachment_required',
      'Audit Log document is required',
      checkAuditLogAttachment,
    );
};

export const getTaxSchema = (taxProduct) => {
  const attachmentsSchema = getAttachmentsSchema(taxProduct);

  return object().shape({
    applications: array().of(applicationSchema(attachmentsSchema, taxProduct)),
    lender: string(),
    clientContact: string(),
    email: string().required(),
  });
};
