import {
  AlertCircleIcon,
  CalendarIcon,
  UploadCloudIcon,
  MailIcon,
  CheckCircleIcon,
  PauseCircleIcon,
  UserPlusIcon,
  XCircleIcon,
  MessageCircleIcon,
  PlayCircleIcon,
  FileTextIcon,
  InfoIcon,
} from 'svelte-feather-icons';

export const orderLogIcons = {
  UpdateStatus: {
    AcceptOrder: CheckCircleIcon,
    AddExecutedDocs: UploadCloudIcon,
    ApproveSS: CheckCircleIcon,
    CancelAllDocs: XCircleIcon,
    'CancelAllDocs-Confirmed': CheckCircleIcon,
    CancelOrder: XCircleIcon,
    CancelOrderConfirmed: AlertCircleIcon,
    ClearedTitle: CheckCircleIcon,
    ClearToFund: CheckCircleIcon,
    ClosingPackageApprovedByVendor: CheckCircleIcon,
    ClosingPackageRejectedByVendor: XCircleIcon,
    CompletedSigning: CheckCircleIcon,
    CompleteOrder: CheckCircleIcon,
    CompleteSSRevision: CheckCircleIcon,
    CurativePending: UploadCloudIcon,
    DeliverClosingPackage: MailIcon,
    DeliverFinalSS: CheckCircleIcon,
    DeliverLenderSS: MailIcon,
    DeliverPolicy: MailIcon,
    DeliverProduct: MailIcon,
    DeliverVendorSS: MailIcon,
    DenyOrder: XCircleIcon,
    Escalation: MessageCircleIcon,
    EscalationResponse: MessageCircleIcon,
    FailedSigning: XCircleIcon,
    FundedLoanByLender: CheckCircleIcon,
    FundedLoanByVendor: CheckCircleIcon,
    FundsReceived: CheckCircleIcon,
    'LenderSS-MissingInformation': AlertCircleIcon,
    NotClearToFund: XCircleIcon,
    OrderReopenApproved: CheckCircleIcon,
    OrderReopenRequest: AlertCircleIcon,
    QCCorrectionsComplete: AlertCircleIcon,
    QCCorrectionsPending: AlertCircleIcon,
    ReceiveClosingPackage: CheckCircleIcon,
    RequestSSRevision: AlertCircleIcon,
    ResumeOrder: PlayCircleIcon,
    RevisedDeliverProduct: CheckCircleIcon,
    RevisionRequestApproved: CheckCircleIcon,
    RequestRevision: AlertCircleIcon,
    RevisionRequestNotApproved: XCircleIcon,
    'Subo-ApprovedByVendor': CheckCircleIcon,
    'Subo-Cancelled': XCircleIcon,
    'Subo-CompletedByLender': CheckCircleIcon,
    'Subo-Incomplete': XCircleIcon,
    'Subo-JLHRqsSentToLender': MailIcon,
    'Subo-LenderDelay': PauseCircleIcon,
    'Subo-LenderSignatureRequired': AlertCircleIcon,
    'Subo-NotRequired': CheckCircleIcon,
    'Subo-Required': AlertCircleIcon,
    'Subo-RequirementsInProcess': CheckCircleIcon,
    'Subo-RequirementsNeeded': AlertCircleIcon,
    'Subo-RequirementsSentToVendor': MailIcon,
    'Subo-Revision Request': AlertCircleIcon,
    'Subo-RevisionDelivered': MailIcon,
    'Subo-SubPackageSentToJLH': MailIcon,
    SuspendOrder: PauseCircleIcon,
    VendorDisbursedFunds: CheckCircleIcon,
    VendorQC: AlertCircleIcon,
  },
  AddAttachment: UploadCloudIcon,
  AddNote: MessageCircleIcon,
  AddNoteAck: MessageCircleIcon,
  AppraisalOrderUpdate: AlertCircleIcon,
  AssignedInformation: InfoIcon,
  ClosingPackageSentToNotary: MailIcon,
  CompletedRecording: CheckCircleIcon,
  DeedPrepApproved: CheckCircleIcon,
  DeedPrepCancelled: XCircleIcon,
  DeedPrepComplete: CheckCircleIcon,
  DeedPrepInProcess: CheckCircleIcon,
  DeedPrepOnHold: PauseCircleIcon,
  DeedPrepRequested: AlertCircleIcon,
  DeedPrepResume: PlayCircleIcon,
  DeedRevisionToProvider: CheckCircleIcon,
  DocShipInfo: CalendarIcon,
  FHACaseNumberRequest: InfoIcon,
  FinalInvoice: FileTextIcon,
  Inspected: CheckCircleIcon,
  NotaryAssigned: UserPlusIcon,
  PlaceOrder: CheckCircleIcon,
  POACancelled: XCircleIcon,
  POAInProcess: CheckCircleIcon,
  POANotPermitted: XCircleIcon,
  POAOnHold: PauseCircleIcon,
  POAReasonApproved: CheckCircleIcon,
  POAReceived: UploadCloudIcon,
  POARequested: AlertCircleIcon,
  POARequestedFromBorrower: AlertCircleIcon,
  POAResume: PlayCircleIcon,
  POAToBorrower: CheckCircleIcon,
  ProviderWelcomePackage: UploadCloudIcon,
  ReadyToRescheduleSigning: CalendarIcon,
  ReadyToSchedule: CalendarIcon,
  'Re-Scheduled': CalendarIcon,
  RescheduleConfirmed: CalendarIcon,
  RescheduledSigning: CalendarIcon,
  RescheduleRequired: CalendarIcon,
  RescheduleRequiredConfirmed: CalendarIcon,
  Scheduled: CalendarIcon,
  ScheduleConfirmed: CalendarIcon,
  ScheduledSigning: CalendarIcon,
  SentToRecord: CheckCircleIcon,
  SignedPackageSentToLender: MailIcon,
  UpdateOrderInfo: CheckCircleIcon,
};
