<script>
  import { Button, Modal, RadioButton, TextInput } from '@xpanseinc/ui-components';
  import { profile } from '../../stores/profile';
  import { savedSearches } from '../../stores/orders';

  export let visible = false;
  export let searchName;
  export let buttonLabel = 'Save Search';
  export let title = 'Save Search Shortcut';
  export let isPublic = false;
  export let previousSearchName = undefined;

  const searchNameMaxLength = 30;
  let invalidSearchName = false;
  let existingSearchNames = [];

  const sharedOptions = [
    {
      label: 'Only people in your organization will see this search (internal only)',
      value: false,
    },
    { label: 'Anyone with access to these orders will see this search', value: true },
  ];

  savedSearches?.subscribe((value) => {
    existingSearchNames = value?.map(({ name }) => name.toLowerCase());
  });
</script>

<Modal
  bind:visible
  on:close="{() => {
    visible = false;
    searchName = '';
    invalidSearchName = false;
  }}"
>
  <div class="modal-body">
    <header>
      <h3 class="header-l header-l--bold">{title}</h3>
      {#if !$profile.hasLenderAccess}
        <div class="subtitle-small">
          This report will be shared with people in your organization
        </div>
      {/if}
    </header>
    <TextInput
      invalid="{!searchName && invalidSearchName}"
      name="save-search"
      label="Search Name"
      on:blur="{() => {
        invalidSearchName = !searchName;
      }}"
      on:change="{(e) => {
        searchName = e.target.value;
        invalidSearchName = !searchName;
      }}"
      bind:value="{searchName}"
    />
    {#if existingSearchNames?.includes(searchName?.toLowerCase()) && previousSearchName !== searchName}
      <div class="text-error">This search name is already in use. Please enter a unique name.</div>
    {/if}
    {#if searchName.length > searchNameMaxLength}
      <div class="text-error">Search names must be less than 30 characters</div>
    {/if}
    {#if $profile.hasLenderAccess}
      <div class="shared">
        <h4 class="header-m header-m--bold">Sharing</h4>
        <RadioButton bind:value="{isPublic}" options="{sharedOptions}" name="shared-searches" />
      </div>
    {/if}
    <div class="btn-container">
      <Button
        name="save-search"
        disabled="{(existingSearchNames?.includes(searchName.toLowerCase()) &&
          searchName !== previousSearchName) ||
          (!searchName && invalidSearchName) ||
          searchName.length > searchNameMaxLength}"
        label="{buttonLabel}"
        color="primary"
        on:click
      />
    </div>
  </div>
</Modal>

<style>
  .modal-body {
    display: flex;
    flex-wrap: wrap;
  }

  header {
    margin-bottom: 24px;
  }

  .subtitle-small {
    color: var(--gray7);
  }

  .modal-body :global(.input-highlight) {
    width: 100%;
  }

  .shared {
    margin-top: 10px;
  }

  .header-m {
    margin: 8px 0;
  }

  .btn-container {
    display: flex;
    flex: 1;
    justify-content: flex-end;
  }

  .btn-container :global(.btn) {
    width: 160px;
    margin-top: 52px;
  }

  .text-error {
    margin-top: 8px;
  }
</style>
