import { startCase, camelCase } from 'lodash-es';
import { alphabetically } from './alphabetically';

type NameAndId = {
  name?: string;
  id?: string;
};

type DropdownOption = {
  label: string;
  value: string;
};

export function mapDropdownOptions(array: NameAndId[] | any[], sort = true) {
  let optionsList: DropdownOption[] = [];
  if (array && array.length > 0) {
    optionsList = array
      .filter((option) => option)
      .map((option) =>
        option.name
          ? { label: option.name, value: option.id || option.name }
          : {
              label: option,
              value: option,
            },
      );
  }

  if (sort) {
    optionsList = optionsList.sort(alphabetically);
  }

  return optionsList;
}

const EnumLabelOverrideMap = {
  Llc: 'LLC',
  Fnma: 'FNMA',
  Fha: 'FHA',
  Fhlmc: 'FHLMC',
  Va: 'VA',
  Usda: 'USDA',
  UsdaRuralDevelopment: 'USDA Rural Development',
};

export function mapDropdownOptionsFromEnum(
  enumToMap,
  sort = true,
  labelTextOrigin = 'value',
  omittedValues = [],
) {
  let optionsList: DropdownOption[] = [];

  if (enumToMap) {
    Object.keys(enumToMap).forEach((key) => {
      const isOmittedValue = omittedValues.includes(enumToMap[key]);

      if (!isOmittedValue && labelTextOrigin === 'key') {
        optionsList.push({
          label: EnumLabelOverrideMap[key] ? EnumLabelOverrideMap[key] : startCase(camelCase(key)),
          value: enumToMap[key],
        });
      }
      if (!isOmittedValue && labelTextOrigin === 'value') {
        optionsList.push({ label: enumToMap[key], value: key });
      }
    });
  }

  if (sort) {
    optionsList = optionsList.sort(alphabetically);
  }

  return optionsList;
}
