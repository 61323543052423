import { EventIdEnum } from './place-order/typesAndEnums';

export const getLabel = (key: string, event: string = null): string => {
  const labels = {
    eventId: 'Event ID',
    comment: 'Comment',
    conditionSatisfactionResponsibleParty: 'Responsible Party',
    documentExpTime: 'Document Expiration Date',
    orderIdentifier: 'Provider Order ID',
    timeZone: 'Time Zone',
    // address
    city: 'City',
    county: 'County',
    line1: 'Address Line 1',
    line2: 'Address Line 2',
    state: 'State',
    unitDesignatorType: 'Unit Designator',
    unitDesignatorTypeOtherDescription: 'Unit Designator Description',
    zip: 'Zip Code',
    // attachment
    files: 'Upload',
    usageCode: 'Document Type',
    usageCodeDescription: 'Document Description',
    // closingData
    closingDate: 'Closing Date',
    closingTime: 'Closing Time',
    closingLocationDescription: 'Closing Location Description',
    closingLocationType: 'Closing Location Type',
    deedPageCount: 'Deed Page Count',
    loanPurposeType: 'Loan Purpose Type',
    loanPurposeTypeOtherDescription: 'Loan Purpose Type Description',
    mortgagePageCount: 'Mortgage Page Count',
    // declarationDetail
    bankruptcyIndicator: 'Bankruptcy',
    borrowerFirstTimeHomeBuyerIndicator: 'Borrower First Time Home Buyer',
    partyToLawsuitIndicator: 'Party To Lawsuit',
    priorPropertyForeclosureCompletedIndicator: 'Prior Property Foreclosure Completed',
    // finalInvoice
    invoiceAmount: 'Invoice Amount',
    invoiceDate: 'Invoice Date',
    // party
    contactType: 'Contact',
    email: 'Email',
    emailRole: 'Email Role',
    firstName: 'First Name',
    fulfillmentPartyRoleType: 'Fulfillment Party Role',
    fullName: 'Full Name',
    lastName: 'Last Name',
    maritalStatus: 'Marital Status',
    maritalStatusOtherDescription: 'Marital Status Description',
    middleName: 'Middle Name',
    partyRole: 'Party Role',
    phone: 'Phone',
    phoneRole: 'Phone Role',
    relationshipVesting: 'Relationship Vesting',
    // reason
    reasonCode: 'Reason Code',
    // recordingEndorsement
    feeAmount: 'Fee Amount',
    firstPageNumberValue: 'First Page Number',
    instrumentNumberIdentifier: 'Instrument Number ID',
    recordedDatetime: 'Recorded Date & Time',
    recordingEndorsementType: 'Recording Endorsement Type',
    recordingEndorsementTypeOtherDescription: 'Recording Endorsement Type Description',
    volumeIdentifier: 'Volume ID',
    volumeType: 'Volume Type',
    volumeTypeOtherDescription: 'Volume Type Description',
    // taxpayerIdentifier
    taxpayerIdentifierType: 'Taxpayer Identifier Type',
    taxpayerIdentifierValue: 'Taxpayer Identifier Value',
    // trackingInfo
    deliveryInitiateDate: 'Shipping Date & Time',
    deliveryMethodType: 'Delivery Method',
    trackingNumber: 'Tracking Number',
    requestedDueDate: 'Service Requested Due Date',
    fhaCaseIdentifier: 'FHA Case Identifier',
    inspectionScheduleDate: 'Scheduling Date',
    inspectionScheduleTime: 'Scheduling Time',
    inspectedDate: 'Appraisal Inspection Date',
    // appraiser
    appraiserCompany: 'Appraiser Company',
    appraiserLicense: 'Appraiser License',
    amount: event === EventIdEnum.PAYMENT_REFUND_REQUESTED ? 'Amount to refund' : 'Fee amount',
  };
  return labels[key];
};
