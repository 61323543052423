export const eventError = {
  appraiser: {
    message: 'First/Last name and Appraiser License required',
  },
  attachments: {
    message: 'At least one Attachment required',
    wrongExtension: {
      message: 'Invalid file extension',
    },
    fileName: {
      message: 'Please upload a file',
    },
    totalFileSize: {
      message: 'Total of files upload cannot exceed 50MB',
    },
    fileSizeTooLarge: {
      message: 'File size cannot be greater than 20MB',
    },
    usageCode: {
      message: 'Please select a Document Type',
    },
    usageCodeDescription: {
      message: 'Document Type of Other requires a description',
    },
  },
  conditionSatisfactionResponsibleParty: {
    message: 'Please select a responsible party',
  },
  closingData: {
    message: 'All Address Closing Information fields required',
  },
  closingLocationType: {
    message: 'Closing Location Type required',
  },
  closingLocationDescription: {
    message: 'Closing Location Type of Other requires a description',
  },
  comment: {
    message: 'Comment required',
  },
  deedPageCount: {
    message: 'Deed Page Count is required',
  },
  deliveryType: {
    message: 'Delivery type required',
  },
  duplicateSLAValues: {
    dynamicMessage: (start, end, sla, target) =>
      `A score card already exists with the Start Event "${start}", End Event "${end}", SLA "${sla}", and Target ${target}%. Modify or delete the existing card in order to create a new one.`,
  },
  fulfillmentCenter: {
    message: 'Fulfillment Center required',
  },
  loanPurposeType: {
    message: 'Loan Purpose required',
  },
  loanPurposeTypeOtherDescription: {
    message: 'Loan Purpose of Other requires a description',
  },
  mortgagePageCount: {
    message: 'Mortgage Page Count is required',
  },
  orderIdentifier: {
    message: 'Provider Order ID required',
  },
  parties: {
    message: 'At least one Contact required',
  },
  reasonCode: {
    message: 'Reason Code required for each row',
  },
  recordingEndorsement: {
    message: 'All Shipping Details required',
    volumeTypeOtherDescription: {
      message: 'Volume Type of Other requires a description',
    },
    recordingEndorsementTypeOtherDescription: {
      message: 'Recording Endorsement Type of Other requires a description',
    },
  },
  scheduleDate: {
    message: 'Closing Date & Time required',
  },
  scheduleLocation: {
    message: 'Address required',
  },
  trackingInfo: {
    message: 'Tracking Information required',
  },
  requestedDueDate: {
    message: 'Service Requested Due Date is required',
  },
  fhaCaseIdentifier: {
    message: 'FHA Case Identifier required and must be numbers only',
  },
  inspectionSchedule: {
    message: 'Inspection Schedule date & time required',
    lessThanCurrentDate: {
      message: 'Inspection Date should be greater than equal to current date',
    },
  },
  inspectionDate: {
    message: 'Inspection Date required',
  },
  feeModificationAmount: {
    message: 'Fee Amount required',
  },
  feeModificationPayments: {
    message: 'Payment required',
  },
  paymentRefundRequested: {
    message: 'Amount to refund cannot exceed the available amount',
  },
  paymentRefundRequestedNegative: {
    message: 'Amount to refund cannot be negative',
  },
  invoiceAmount: {
    message: 'Invoice Amount required',
  },
  invoiceDate: {
    message: 'Invoice Date required',
  },
  enoteVaultTransactionId: {
    message: 'Vault Transaction ID required',
  },
  enoteDocProfileSID: {
    message: 'Doc Profile SID required',
  },
};
