import { isValid, parseISO } from 'date-fns';

export const userFullNameIsValid = (name: string): boolean => !!name.match(/^[a-z ,.'-]{2,}$/i);
export const ssnIsValid = (ssn: string): boolean => /^\d{9}$/.test(ssn);
export const phoneIsValid = (phone: string): boolean => /^\d{10}$/.test(phone);
export const emailIsValid = (email: string): boolean =>
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(
    email,
  );
export const zipIsValid = (zip: string): boolean => /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(zip);

export const dateOfBirthIsValid = (dob: string): boolean => {
  const regex = /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])$/;
  if (!regex.test(dob)) {
    return false;
  }

  const date = parseISO(dob);
  if (!isValid(date)) {
    return false;
  }

  const currentYear = new Date().getFullYear();
  const year = date.getFullYear();

  const minYear = currentYear - 100;
  const maxYear = currentYear - 18;

  return year >= minYear && year <= maxYear;
};

