<script>
  import { querystring } from 'svelte-spa-router';
  import { Button, LoadingSpinner, ThemeColor, Toast } from '@xpanseinc/ui-components';
  import { sub, formatISO } from 'date-fns';
  import Layout from '../components/Layout.svelte';
  import OrderCardList from '../components/OrderCardList.svelte';
  import OrderCardPlaceholders from '../components/OrderCardPlaceholders.svelte';
  import ViewHeader from '../components/ViewHeader.svelte';
  import ReportViewer from '../components/ReportViewer.svelte';
  import { reportSearchApi, saveSearchApi } from '../stores/api';
  import {
    activeFilter,
    allSearchResults,
    getDefaultSearchFilter,
    previousScreen,
    savedSearches,
    searchFilter,
    totalSearchResults,
  } from '../stores/orders';
  import { profile } from '../stores/profile';
  import { downloadFile } from '../utils/download';
  import { renderErrorToast, renderInformationToast } from '../utils/renderToasts';
  import AdvancedSearchBar from '../components/advanced-search/AdvancedSearchBar.svelte';
  import AdvancedSearchSavedSearch from '../components/advanced-search/AdvancedSearchSavedSearch.svelte';
  import AdvancedSearchSaveModal from '../components/advanced-search/AdvancedSearchSaveModal.svelte';
  import { popToast } from '../utils/toasts';
  import { permissions } from '../stores/users';
  import urls from '../urls';
  import pageTitle from '../utils/pageTitle';
  import { KEYSTONE_CLIENT_URL, KEYSTONE_VENDOR_URL } from '../const';

  const title = 'Order Fulfillment';

  $previousScreen = { label: 'Orders', url: urls.orders };
  const keystoneUrl = $profile?.hasProviderAccess ? KEYSTONE_VENDOR_URL : KEYSTONE_CLIENT_URL;
  let foundInKeystone;
  let queryString = null;

  $allSearchResults = [];
  $totalSearchResults = null;

  let downloading = false;
  let isPublic = false;

  async function onDownloadCsv() {
    if (downloading) {
      return;
    }
    downloading = true;
    try {
      const response = await $reportSearchApi.downloadSearchRaw({
        searchRequest: {
          ...$searchFilter,
          pageSize: $totalSearchResults,
        },
      });
      const fromISO = formatISO($searchFilter.from, { representation: 'date' });
      const toISO = formatISO($searchFilter.to, { representation: 'date' });
      await downloadFile(response.raw.blob(), `Orders-${fromISO}--${toISO}.csv`);
    } catch (error) {
      console.error(error);
    }
    downloading = false;
  }

  async function fetchPage(filter, queryString) {
    if (queryString) {
      const string = new URLSearchParams(queryString);
      const loanNo = string.get('loanNo');
      $searchFilter.loan = loanNo;
    }

    if (filter.pageNumber === 0) {
      $allSearchResults = [];
      $totalSearchResults = null;
    }
    const results = await $reportSearchApi.getSearchReport({
      searchRequest: {
        ...filter,
      },
    });
    if (results.unauthorizedProduct.length) {
      renderInformationToast(
        'Some results could not be shown because you don’t have permission to view the orders product.',
      );
    }
    if (filter.product.includes('Closing') && $permissions) foundInKeystone = results.keystone;
    allSearchResults.update((x) =>
      filter.pageNumber === 0 ? [...results.orders] : [...x, ...results.orders],
    );
    totalSearchResults.set(results.total);
  }

  function clearFilter() {
    $searchFilter = getDefaultSearchFilter();
  }

  function onClearedField(field) {
    if (field === 'loan' || field === 'all') {
      queryString = null;
      $searchFilter.loan = '';
    }
  }

  let saveModalVisibile = false;
  let searchName = '';
  let displayedChips = {};

  const saveFilters = () => {
    saveModalVisibile = true;
  };

  const saveSearch = async () => {
    if (searchName.length) {
      try {
        let newSearch = await $saveSearchApi.saveSearch({
          saveSearchRequest: {
            name: searchName,
            search: JSON.stringify({ ...$searchFilter, pageNumber: 0 }),
            isPublic,
          },
        });
        newSearch.search = JSON.parse(newSearch.search);
        newSearch.search.from = new Date(newSearch.search.from);
        newSearch.search.to = new Date(newSearch.search.to);
        savedSearches.set([...$savedSearches, newSearch]);

        $activeFilter.name = newSearch.name;
        $activeFilter.id = newSearch.id;
        searchName = '';
        saveModalVisibile = false;
        isPublic = false;
      } catch (error) {
        renderErrorToast('Error: Failed to save search', error);
      }
    } else {
      popToast({
        title: 'Error: Please enter a search name',
        description: 'Retry',
        duration: 6000,
      });
    }
  };

  $: if (!$searchFilter?.event?.length) {
    $searchFilter.eventSentFrom = 'BOTH';
    $searchFilter.eventReceivedStatus = 'Is the latest';
  }

  const fiftyYearsAgo = sub(new Date(), { years: 50 });
  $: if ($searchFilter.from.valueOf() < fiftyYearsAgo.valueOf()) {
    $searchFilter.from = fiftyYearsAgo;
  }
  $: fetchingPage = fetchPage($searchFilter, queryString);
  $: hasMore = ($searchFilter.pageNumber + 1) * $searchFilter.pageSize < $totalSearchResults;
  $: queryString = $querystring;
</script>

<svelte:head>
  <title>{pageTitle(title)}</title>
</svelte:head>

<Layout>
  <div slot="left">
    <div class="breadcrumb-container">
      <div class="breadcrumb-item breadcrumb-item--active">Home</div>
    </div>
    <ViewHeader>Orders</ViewHeader>
    <div class="saved-search">
      <AdvancedSearchSavedSearch />
    </div>
  </div>
  <div slot="center" class="center">
    <AdvancedSearchBar
      on:save="{saveFilters}"
      bind:displayedChips
      on:clearedField="{(e) => onClearedField(e.detail)}"
    />
    {#if saveModalVisibile}
      <AdvancedSearchSaveModal
        bind:visible="{saveModalVisibile}"
        bind:isPublic
        on:click="{() => {
          saveSearch();
        }}"
        bind:searchName
      />
    {/if}
    {#if $totalSearchResults > 0}
      <ReportViewer
        total="{$totalSearchResults}"
        onDownloadCsv="{onDownloadCsv}"
        bind:downloading
      />
    {/if}

    {#if $searchFilter.pageNumber === 0}
      {#await fetchingPage}
        <OrderCardPlaceholders />
      {/await}
    {/if}

    <OrderCardList
      orders="{$allSearchResults}"
      totalSearchResults="{$totalSearchResults}"
      foundInKeystone="{foundInKeystone}"
      keystoneUrl="{keystoneUrl}"
      bind:displayedChips
      on:save="{saveFilters}"
    />

    {#await fetchingPage}
      {#if $searchFilter.pageNumber > 0}
        <div style="margin-top: 2rem;">
          <LoadingSpinner />
        </div>
      {/if}
    {:then}
      {#if hasMore}
        <div class="view-more">
          <Button
            name="view-more"
            label="View More"
            color="{ThemeColor.Primary}"
            on:click="{() => {
              $searchFilter.pageNumber += 1;
              $searchFilter = $searchFilter;
            }}"
          />
        </div>
      {/if}
    {:catch error}
      <Toast text="An error occurred while fetching orders" on:action="{clearFilter}" />
    {/await}
  </div>
</Layout>

<style>
  .center {
    margin: 0 0 75px;
  }

  .center :global(.chart) {
    margin: 16px 0 16px;
  }

  .view-more {
    margin: 50px 0 0;
    text-align: center;
  }

  .center :global(.placeholders) {
    margin-top: 48px;
  }
</style>
