<script>
  import { Skeleton } from '@xpanseinc/ui-components';
  import { link } from 'svelte-spa-router';
  import TotalsBar from '../components/milestone-dashboard/TotalsBar.svelte';
  import DateBar from '../components/milestone-dashboard/DateBar.svelte';
  import EventRow from '../components/milestone-dashboard/EventRow.svelte';
  import { milestoneApi, reportApi } from '../stores/api';
  import { vendors, fetchVendors } from '../stores/reports';
  import { mapDropdownOptions } from '../utils/mapDropdownOptions';
  import ViewHeader from '../components/ViewHeader.svelte';
  import add from 'date-fns/add';
  import { milestoneThresholds } from '../constants/milestoneThresholds';
  import OrderCard from '../components/OrderCard.svelte';
  import DownloadBar from '../components/milestone-dashboard/DownloadBar.svelte';
  import { downloadFile } from '../utils/download';
  import { formatISO } from 'date-fns';
  import eachDayOfInterval from 'date-fns/eachDayOfInterval';
  import OrderCardPlaceholders from '../components/OrderCardPlaceholders.svelte';
  import { searchDates } from '../stores/milestone';
  import { previousScreen } from '../stores/orders';
  import { popToast } from '../utils/toasts';
  import Layout from '../components/Layout.svelte';
  import urls from '../urls';
  import pageTitle from '../utils/pageTitle';
  $previousScreen = { label: 'Milestone Dashboard', url: urls.milestoneDashboard };

  const title = 'Milestone Dashboard';

  let product = 'Closing';
  let vendor;
  let milestoneData = [];
  let orders = [];
  let total = 0;
  let pageNumber = 0;
  let downloadBarVisible = true;
  let downloading = false;
  let loadingOrders = false;

  const from = add(new Date(), { days: -3 });
  const to = add(new Date(), { days: 6 });

  const range = eachDayOfInterval({ start: from, end: to });

  fetchVendors();

  async function fetchMilestones(product) {
    let milestones = await $milestoneApi.getMilestoneTable({
      milestoneTableRequest: {
        from: new Date(from.setUTCHours(0, 0, 0, 0)),
        to: to,
        products: [product],
        milestoneType: 'TABLE',
        milestoneDateType: 'ClearToClose',
        eventThresholds: milestoneThresholds,
      },
    });
    return milestones.events.sort((a, b) =>
      a.name.toLocaleLowerCase() < b.name.toLocaleLowerCase()
        ? -1
        : a.name.toLocaleLowerCase() > b.name.toLocaleLowerCase()
        ? 1
        : 0,
    );
  }

  milestoneData = fetchMilestones(product);

  $: milestoneSearchRequest = {
    pageSize: 100,
    milestoneDateType: 'ClearToClose',
    pageNumber,
    searchDates: $searchDates,
    products: [product],
  };

  async function fetchOrders(milestoneSearchRequest) {
    loadingOrders = true;
    if ($searchDates.length) {
      if (pageNumber === 0) {
        orders = [];
      }
      let response = await $milestoneApi.getMilestoneSearch({ milestoneSearchRequest });
      orders = pageNumber === 0 ? response.orders : [...orders, ...response.orders];
      total = response.total;
    } else {
      orders = [];
    }
    loadingOrders = false;
  }

  async function downloadCSV() {
    downloading = true;
    try {
      const response = await $milestoneApi.getMilestoneCsvRaw({ milestoneSearchRequest });
      const fromISO = formatISO(from, { representation: 'date' });
      const toISO = formatISO(to, { representation: 'date' });
      await downloadFile(response.raw.blob(), `Milestone-Orders-${fromISO}--${toISO}.csv`);
    } catch (error) {
      console.error(error);
      popToast({
        title: 'Error: Please refresh your screen and reattempt your download',
        description: 'Retry',
        duration: 6000,
      });
    }
    downloading = false;
  }
  let downloadBar;

  $: fetchOrders(milestoneSearchRequest);

  $: if (orders.length) {
    downloadBar = document.querySelector('.title');
  }
  $: if (downloadBar) {
    window.addEventListener('scroll', function () {
      var position = downloadBar.getBoundingClientRect();
      if (position.top < window.innerHeight && position.y >= 0) {
        downloadBarVisible = true;
      } else {
        downloadBarVisible = false;
      }
    });
  }
</script>

<svelte:head>
  <title>{pageTitle(title)}</title>
</svelte:head>

<Layout>
  <div slot="center" class="dashboard">
    <header>
      <div class="top-row">
        <div class="breadcrumb-container">
          <div class="breadcrumb-item breadcrumb-item--active">Milestones</div>
        </div>
        <ViewHeader>Milestone Dashboard</ViewHeader>

        <!-- <Dropdown
        name="productOptions"
        options="{mapDropdownOptions(['Closing', 'Title'])}"
        bind:value="{product}"
      /> -->
      </div>
      <div class="bottom-row" class:fixed="{!downloadBarVisible}">
        <!-- <Dropdown
        name="vendorOptions"
        options="{$vendors}"
        placeholder="All providers"
        bind:value="{vendor}"
      /> -->

        {#if orders.length}
          <DownloadBar
            downloadBarVisible="{downloadBarVisible}"
            total="{total}"
            bind:downloading
            on:click="{downloadCSV}"
          />
        {/if}
      </div>
    </header>
    <div class="table-container">
      <DateBar />
      {#await milestoneData}
        <div class="top-loader">
          <Skeleton height="100%" />
        </div>
      {:then data}
        <TotalsBar range="{range}" counts="{data}" />
        {#each data as row, i}
          <EventRow
            rowIndex="{i}"
            range="{range}"
            label="{row.name}"
            code="{row.code}"
            counts="{row.milestoneEventDates}"
            bind:pageNumber
          />
        {/each}
        {#if loadingOrders}
          <div class="placeholder-wrap">
            <OrderCardPlaceholders />
          </div>
        {/if}
        {#if orders.length}
          <div class="orders" class:fixed-download="{!downloadBarVisible}">
            {#each orders as order}
              <a href="{urls.orderDetail + order.id}" use:link>
                <OrderCard
                  city="{order.city}"
                  county="{order.county}"
                  state="{order.state}"
                  clientName="{order.client}"
                  eventStatus="{order.eventstatus}"
                  loanNumber="{order.loan}"
                  orderDate="{order.date}"
                  orderStatus="{order.status}"
                  productName="{order.product}"
                  transactionCode="{order.code}"
                  clientId="{order.tcid}"
                  deliveryStatus="{order.deliverystatus}"
                  closingDate="{order.closingDate}"
                />
              </a>
            {/each}
          </div>
        {:else}
          <div class="no-orders">
            <h3 class="header-m header-m--bold">No Orders Selected</h3>
            <div class="body">Select a cell or cells above to view orders.</div>
          </div>
        {/if}
      {/await}
    </div>
  </div>
</Layout>

<style>
  .table-container {
    position: relative;
    background-color: var(--white);
    box-shadow: 0 0 2px rgba(19, 20, 22, 0.08), 0 0 4px rgba(19, 20, 22, 0.12);
    border-radius: 4px;
    padding: 16px 16px;
  }

  .top-loader {
    width: 100%;
    height: 300px;
    margin-top: 12px;
    position: absolute;
    top: -1rem;
    left: 0;
  }

  .dashboard {
    display: inline-block;
    flex: 1;
    max-width: 1040px;
  }

  header {
    width: 100%;
  }

  .header-m {
    color: var(--textDefault);
    margin: 0 0;
  }

  .body {
    color: var(--textDefault);
    font-size: 14px;
  }

  .top-row :global(.dropdown) {
    min-width: 108px;
  }

  .bottom-row {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-bottom: 8px;
    min-height: 40px;
  }

  .bottom-row :global(.dropdown) {
    min-width: 186px;
  }

  .orders {
    padding: 32px 0 32px 0;
    position: absolute;
    width: 100%;
    left: 0;
  }

  .orders > a {
    display: block;
    text-decoration: none;
    margin: 0 0 8px;
  }

  .no-orders {
    position: absolute;
    width: 100%;
    left: 0;
    margin-top: 2rem;
    padding: 1rem 1rem;
    border-radius: 4px;
    border: 1px solid var(--gray2);
  }

  .fixed {
    position: fixed;
    top: 72px;
    margin-left: 0;
    justify-content: initial;
  }

  .placeholder-wrap {
    position: absolute;
    left: 0;
    width: 100%;
    margin-top: 1.5rem;
    z-index: 9;
  }
</style>
