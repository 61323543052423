import { writable } from 'svelte/store';
import { reportApi } from './api';

export const products = writable([]);

export async function fetchProducts() {
  products.subscribe((productsArr) => {
    if (!productsArr.length) {
      reportApi.subscribe(async (api) => {
        if (api) {
          const productsRaw = await api.getProducts();
          products.set([
            ...productsRaw.map((product) => ({
              label: product,
              value: product,
            })),
          ]);
        }
      });
    }
  });
}
