<script>
  import Header from './Header.svelte';
  export let contentMaxWidth = '1224px';

  let slots = $$props.$$slots;
</script>

<div class="layout">
  <Header />
  <div class="content" style="max-width: {contentMaxWidth};">
    <slot name="left" />
    <div class="center">
      <slot name="center" />
    </div>
    <slot name="right" />
    {#if false}<slot />{/if}
  </div>

  {#if slots.footer}
    <div class="footer">
      <slot name="footer" />
    </div>
  {/if}
</div>

<style>
  .layout {
    background: var(--gray1);
    display: flex;
    flex: 1;
    flex-direction: column;
    height: 100%;
    position: relative;
    width: 100vw;
    max-width: 100vw;
    overflow-x: hidden;
  }

  @media only screen and (max-width: 1245px) {
    .layout {
      overflow-x: scroll;
    }
  }

  @media only screen and (max-width: 1330px) {
    .layout {
      padding-left: 56px;
    }
  }

  .content {
    display: flex;
    flex: 1;
    position: relative;
    padding: 1.2rem 0 0;
    margin: 0 auto;
    width: 100%;
  }

  .content > :global(:first-child:not(.center)) {
    padding: 0 16px 40px 40px;
    z-index: 5;
  }

  .content > :global(:last-child:not(.center)) {
    padding: 0 16px 16px 16px;
    position: sticky;
    margin-left: auto;
    top: 124px;
  }

  .content > :global(:not(.center)) {
    position: fixed;
    width: 290px;
    min-width: 290px;
  }

  .center {
    flex: 1;
    padding: 0 16px 24px 290px;
    min-width: 900px;
  }

  .content > :global(:first-child) {
    padding-left: 40px;
  }

  .content > :global(:last-child) {
    padding-right: 40px;
  }

  .footer {
    position: fixed;
    bottom: 0;
    width: calc(100% - 56px);
    backdrop-filter: blur(4px);
    background-color: rgba(244, 244, 245, 0.65);
    z-index: 1;
  }
</style>
