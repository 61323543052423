import {
  LoanIdentifierLoanIdentifierTypeEnum,
  TermsOfLoanLoanPurposeTypeEnum,
} from '@xpanseinc/ui-backend-api';
import { writable } from 'svelte/store';
import { uniqueId } from 'lodash-es';
import { getDefaultSearchFilter } from './orders';
import type { ExistingLoan, NewLoan, Order, ProductDetails } from '../schemas/place-order';
import type { ValidationResult } from '../schemas/validate';

export enum PlaceOrderProductTypeEnum {
  Title = 'TITLE',
  Closing = 'CLOSING',
  Appraisal = 'APPRAISAL',
  Income = 'TAX',
  Payment = 'PAYMENT',
  SSN_Verification = 'SSN_VERIFICATION',
  // Flood = 'FLOOD',
}
export enum ApplicationTypeEnum {
  Individual = 'Individual',
  Joint = 'Joint',
  Business = 'Business',
}
export enum LoanSource {
  New = 'new',
  Existing = 'existing',
  Bulk = 'bulk',
}
export type LoanDetailsState = {
  loanSource: LoanSource;
  newLoan?: NewLoan;
  existingLoan?: ExistingLoan;
  bulkFiles?: File[];
  bulkValidationErrors?: any[];
};
export type PlaceOrderFormState = {
  order: Order;
  loanDetails: LoanDetailsState;
  productDetails?: ProductDetails;
};

export const existingLoanSearchFilter = writable(getDefaultSearchFilter());
export const existingLoanSearchResults = writable([]);
export const existingLoanDetailsResults = writable([]);
export const productDetailsProviderOptions = writable({
  [PlaceOrderProductTypeEnum.Title]: [],
  [PlaceOrderProductTypeEnum.Appraisal]: [],
  [PlaceOrderProductTypeEnum.Income]: [],
  [PlaceOrderProductTypeEnum.SSN_Verification]: [],
});
export const productDetailsProductOptions = writable({
  [PlaceOrderProductTypeEnum.Title]: [],
  [PlaceOrderProductTypeEnum.Closing]: [],
  [PlaceOrderProductTypeEnum.Appraisal]: [],
  [PlaceOrderProductTypeEnum.SSN_Verification]: [],
});
export const appraisalProviderAllocation = writable(null);

export const selectedAppraisalProduct = writable(null);

export function getInitialPlaceOrderState(): PlaceOrderFormState {
  return {
    order: {
      lender: '',
      lenderName: '',
      comments: '',
      clientId: null,
      products: [],
    },
    loanDetails: {
      loanSource: LoanSource.New,
      newLoan: {
        xml: {
          uploaded: false,
        },
        loanAmountMasked: '',
        subjectProperty: [],
        borrowers: [],
        contacts: [],
        loanPurpose: TermsOfLoanLoanPurposeTypeEnum.Refinance,
        loanIdType: LoanIdentifierLoanIdentifierTypeEnum.LenderLoan,
      },
      existingLoan: null,
    },
    productDetails: {
      [PlaceOrderProductTypeEnum.Title]: {
        provider: '',
        product: '',
        orderInstructions: '',
        attachments: [],
      },
      [PlaceOrderProductTypeEnum.Closing]: {
        provider: '',
        product: '',
        orderInstructions: '',
        attachments: [],
      },
      [PlaceOrderProductTypeEnum.Appraisal]: {
        provider: null,
        product: '',
        duReferenceNumber: '',
        specialFeatureCode: '',
        orderInstructions: '',
        attachments: [],
        fees: {
          appraisalFee: null,
          additionalFees: [],
        },
        payments: [],
      },
      [PlaceOrderProductTypeEnum.Income]: {
        provider: '',
        product: '',
        email: '',
        clientContact: '',
        applications: [
          {
            uid: uniqueId(),
            applicationType: '',
            attachmentType: '',
            applicant: [],
            coApplicant: [],
            products: {},
            attachments: [
              {
                fileName: '',
                mimeTypeId: '',
                usageCode: '',
                usageCodeDescription: '',
              },
            ],
          },
        ],
      },
      [PlaceOrderProductTypeEnum.Payment]: {
        paymentPurpose: '',
        provider: 'AFS',
        loanNumber: '',
        paymentType: 'Pre-paid',
        feeAmount: '',
        email: '',
        firstName: '',
        lastName: '',
        line1: '',
        line2: '',
        zip: '',
        city: '',
        state: '',
        county: '',
        payees: [],
        transactionCode: '',
      },
      // [PlaceOrderProductTypeEnum.Flood]: {},
      [PlaceOrderProductTypeEnum.SSN_Verification]: {
        clientContact: '',
        email: '',
        product: '',
        provider: '',
      },
    },
  };
}

export const form = writable<PlaceOrderFormState>(getInitialPlaceOrderState());

export type ValidationResultsState = {
  orderDetails: ValidationResult;
  loanDetails: ValidationResult;
  productDetails: {
    [PlaceOrderProductTypeEnum.Title]: ValidationResult;
    [PlaceOrderProductTypeEnum.Closing]: ValidationResult;
    [PlaceOrderProductTypeEnum.Appraisal]: ValidationResult;
    [PlaceOrderProductTypeEnum.Income]: ValidationResult;
    [PlaceOrderProductTypeEnum.Payment]: ValidationResult;
    // [PlaceOrderProductTypeEnum.Flood]: ValidationResult;
    [PlaceOrderProductTypeEnum.SSN_Verification]: ValidationResult;
  };
};

export const validationResults = writable<ValidationResultsState>({
  orderDetails: {
    errors: {},
    valid: false,
  },
  loanDetails: {
    errors: {},
    valid: false,
  },
  productDetails: {
    [PlaceOrderProductTypeEnum.Title]: {
      errors: {},
      valid: false,
    },
    [PlaceOrderProductTypeEnum.Closing]: {
      errors: {},
      valid: false,
    },
    [PlaceOrderProductTypeEnum.Appraisal]: {
      errors: {},
      valid: false,
    },
    [PlaceOrderProductTypeEnum.Income]: {
      errors: {},
      valid: false,
    },
    [PlaceOrderProductTypeEnum.Payment]: {
      errors: {},
      valid: false,
    },
    // [PlaceOrderProductTypeEnum.Flood]: {
    //   errors: {},
    //   valid: false,
    // },
    [PlaceOrderProductTypeEnum.SSN_Verification]: {
      errors: {},
      valid: false,
    },
  },
});
