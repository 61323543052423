<script lang="ts">
  import { Button, LoadingSpinner, ThemeColor } from '@xpanseinc/ui-components';
  import {
    existingLoanSearchFilter,
    existingLoanSearchResults,
    existingLoanDetailsResults,
    form,
    validationResults,
  } from '../../../stores/placeOrder';
  import { validate } from '../../../schemas/validate';
  import { existingLoanSchema } from '../../../schemas/place-order';
  import { orderDetailApi, reportSearchApi } from '../../../stores/api';
  import { TextInput } from '@xpanseinc/ui-components';
  import OrderCardPlaceholders from '../../OrderCardPlaceholders.svelte';
  import LoanCard from './LoanCard.svelte';

  let isLoadingDetails = false;
  let currentPageNumber = 0;
  let totalExistingLoanSearchResults = null;

  function loanNumberKeyup(e) {
    $existingLoanSearchFilter.loan = e.target.value;
    currentPageNumber = 0;
    if (e.key === 'Enter' && $existingLoanSearchFilter.loan) {
      searchExistingLoans($existingLoanSearchFilter, currentPageNumber);
    }
  }

  async function searchExistingLoans(filter, pageNumber) {
    if (pageNumber === 0) {
      $existingLoanSearchResults = [];
      $existingLoanDetailsResults = [];
    }

    isLoadingDetails = true;

    const results = await $reportSearchApi.getSearchReport({
      searchRequest: {
        ...filter,
        pageSize: 5,
        pageNumber,
      },
    });

    totalExistingLoanSearchResults = results.total;
    existingLoanSearchResults.update((x) =>
      pageNumber === 0 ? [...results.orders] : [...x, ...results.orders],
    );

    getLoanDetails(results.orders).then((details) => {
      existingLoanDetailsResults.set([...$existingLoanDetailsResults, ...details]);
      isLoadingDetails = false;
    });
  }

  async function getLoanDetails(newOrders) {
    return Promise.all(
      newOrders.map(async (order) => {
        return await $orderDetailApi?.getOrderDetail2({ orderId: order.id });
      }),
    );
  }

  $: $validationResults.loanDetails = validate(existingLoanSchema, $form.loanDetails.existingLoan);

  console.log($validationResults);
</script>

<div class="search-for-existing-loan">
  <TextInput
    name="advancedSearch"
    id="advancedSearch"
    type="search"
    placeholder="Loan no. or Transaction ID"
    value="{$existingLoanSearchFilter.loan}"
    appendedButton
    on:click="{() => {
      currentPageNumber = 0;
      searchExistingLoans($existingLoanSearchFilter, currentPageNumber);
    }}"
    on:keyup="{(e) => loanNumberKeyup(e)}"
  />
</div>
{#if !isLoadingDetails && !$existingLoanDetailsResults.length}
  <div class="no-results">
    <div class="header-m header-m--bold">No Search Results</div>
    <div class="header-s header-s--light">
      Please use the search bar above to find the loan you need for this credit request.
    </div>
  </div>
{/if}

{#if isLoadingDetails}
  <OrderCardPlaceholders />
{/if}

{#each $existingLoanDetailsResults as loan, i}
  <LoanCard loan="{loan}" i="{i}" />
{/each}

{#if isLoadingDetails && currentPageNumber > 0}
  <LoadingSpinner />
{/if}

{#if totalExistingLoanSearchResults > $existingLoanDetailsResults.length}
  <div class="view-more">
    <Button
      name="view-more"
      label="View More"
      color="{ThemeColor.Primary}"
      on:click="{() => {
        currentPageNumber += 1;
        searchExistingLoans($existingLoanSearchFilter, currentPageNumber);
      }}"
    />
  </div>
{/if}

<style>
  .search-for-existing-loan {
    position: relative;
    min-width: 375px;
    margin-bottom: 1rem;
    max-width: 450px;
  }

  .no-results {
    background: var(--white);
    color: var(--textDefault);
    margin: 8px 0;
    padding: 1rem;
    border-radius: 4px;
    box-shadow: 0 0 2px rgba(19, 20, 22, 0.08), 0 0 4px rgba(19, 20, 22, 0.12);
  }

  .no-results .header-s--light {
    margin-top: 4px;
  }

  .view-more {
    margin: 30px 0;
    text-align: center;
  }
</style>
