<script>
  export let icon;
  export let label;
</script>

<button class="link btn-unstyled" on:click>
  <svelte:component this="{icon}" />{label}
</button>

<style>
  button {
    align-items: center;
    display: inline-flex;
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 16px;
  }

  button > :global(.feather) {
    margin: 0 5px 0 0;
    width: 24px;
  }
</style>
