<script>
  import { location, pop, push } from 'svelte-spa-router';
  import { sum } from 'lodash-es';
  import { v4 as uuidv4 } from 'uuid';
  import { Button, LoadingModal, ThemeColor } from '@xpanseinc/ui-components';
  import { OrganizationOrgTypeEnum } from '@xpanseinc/ui-backend-api';
  import ViewHeader from '../../components/ViewHeader.svelte';
  import { link } from 'svelte-spa-router';
  import {
    EventRequestVendorIdTypeEnum,
    LoanIdentifierLoanIdentifierTypeEnum,
    TaxpayerIdentifierTaxpayerIdentifierTypeEnum,
  } from '@xpanseinc/tax-service-api';
  import { ArrowRightIcon } from 'svelte-feather-icons';
  import { popToast } from '../../utils/toasts';
  import Layout from '../../components/Layout.svelte';
  import ConfirmDiscardDialog from '../../components/place-order/ConfirmDiscardDialog.svelte';
  import {
    attachmentApi,
    bulkApi,
    eventApi,
    taxApi,
    paymentApi,
    newEventProcessorApiConfiguration,
  } from '../../stores/api';
  import { getDefaultSearchFilter } from '../../stores/orders';
  import { profile, userOrgType } from '../../stores/profile';
  import { currentUser } from '../../stores/users';
  import urls from '../../urls';
  import {
    OrgTypeEnum,
    EventIdEnum,
    SsnVerificationAttachmentCode,
  } from '../../constants/place-order';
  import {
    appraisalProviderAllocation,
    form,
    existingLoanDetailsResults,
    existingLoanSearchResults,
    existingLoanSearchFilter,
    validationResults,
    getInitialPlaceOrderState,
    LoanSource,
    PlaceOrderProductTypeEnum,
    ApplicationTypeEnum,
  } from '../../stores/placeOrder';
  import { fade } from 'svelte/transition';
  import pageTitle from '../../utils/pageTitle';
  import { AttachmentTypeEnum } from '../../schemas/place-order/income';
  import { detectDomain, DomainTypes } from '../../utils/detectDomain';

  const title = 'Place Order';
  const isBroker = $profile.organization?.orgType === OrganizationOrgTypeEnum.Broker;
  let showConfirmDiscardDialog = false;
  let allFormsValid = false;
  let isSavingOrder = false;
  let appraisalFeesTotal;

  $: isOnlyPaymentOrder =
    $form.order.products.length === 1 &&
    $form.order.products[0] === PlaceOrderProductTypeEnum.Payment;

  $: isOnlySsnVerificationOrder =
    $form.order.products.length === 1 &&
    $form.order.products[0] === PlaceOrderProductTypeEnum.SSN_Verification;

  function getVtpClientId(product, productDetail) {
    if ([PlaceOrderProductTypeEnum.Title, PlaceOrderProductTypeEnum.Closing].includes(product)) {
      return productDetail.workflowVersion;
    }

    const isAppraisal = product === PlaceOrderProductTypeEnum.Appraisal;

    if (isAppraisal) {
      const domain = detectDomain();
      const isWholesaleOrBroker =
        $userOrgType === OrgTypeEnum.WHOLESALE || $userOrgType === OrgTypeEnum.BROKER;

      if (domain === DomainTypes.PROD) return isWholesaleOrBroker ? 304 : 204;
      if (domain === DomainTypes.UAT) return isWholesaleOrBroker ? 701 : 600;

      return isWholesaleOrBroker ? 703 : 602;
    }
  }

  function toast(isSuccess, productName, orderNo) {
    if (isSuccess) {
      popToast({
        title: `${productName} order for ${orderNo} successfully placed`,
        type: 'success',
        duration: 6000,
      });
    } else {
      popToast({
        title: 'Error: Failed to place order',
        description: 'Reload Page',
        duration: 6000,
        onClick: () => {
          window.location.reload();
        },
      });
    }
  }

  async function toastError(e) {
    console.error(e);
    let errorBody;
    let errorId;
    try {
      errorBody = await e.response.json();
      errorId = errorBody.errorId;
    } catch (ex) {
      // when network error happens
      console.log(ex);
    }

    if (errorId) {
      popToast({
        title: 'Error: Failed to place order. Reference Id:' + errorId,
        description: 'Copy ID',
        duration: 30000,
        onClick: () => {
          if (navigator.clipboard) {
            navigator.clipboard.writeText(errorId);
          }
        },
      });
    } else {
      popToast({
        title: 'Error: Failed to place order.',
        description: 'Reload Page',
        duration: 30000,
        onClick: () => {
          window.location.reload();
        },
      });
    }
  }

  function onDiscardClick() {
    showConfirmDiscardDialog = true;
  }

  function onBackClick() {
    if (($form.loanDetails.loanSource = LoanSource.Bulk)) {
      $form.loanDetails.loanSource = LoanSource.New;
      $form.loanDetails.bulkFiles = [];
      $form.loanDetails.bulkValidationErrors = null;
    }
    if ($location === urls.placeOrderLoanDetails) {
      push(urls.placeOrder);
      return;
    }
    if ($location === urls.placeOrderProductDetails && !isSavingOrder) {
      if (isOnlyPaymentOrder || isOnlySsnVerificationOrder) {
        push(urls.placeOrder);
      } else {
        push(urls.placeOrderLoanDetails);
      }
    }
  }

  function backToSearch(loanNo) {
    resetPlaceOrder();

    if (loanNo) {
      push(`/orders?loanNo=${loanNo}`);
    } else {
      push('/orders');
    }
  }

  function resetPlaceOrder() {
    $form = getInitialPlaceOrderState();
    $existingLoanSearchFilter = getDefaultSearchFilter();
    $existingLoanSearchResults = [];
    $existingLoanDetailsResults = [];
    $appraisalProviderAllocation = null;
  }

  async function buildExistingLoanRequest(product) {
    isSavingOrder = true;
    const { orderId } = $form.loanDetails.existingLoan;
    const { order, productDetails } = $form;
    const requestBody = {
      clientId: order.lender,
      vendorId: productDetails[product].provider,
      productId: productDetails[product].product,
      brokerId: isBroker ? $profile.organization?.externalId : null,
      existingOrderId: orderId,
      attachments: productDetails[product].attachments,
      orderInstructions: productDetails[product].orderInstructions,
      vtpClientId: getVtpClientId(product, productDetails[product]),
      appraisalFee: {
        amount: appraisalFeesTotal,
        billToPartyRoleType: productDetails.APPRAISAL?.payments[0]?.payer,
        paymentMode: null,
        billToPartyEmailAddress: productDetails.APPRAISAL?.payments[0]?.email,
      },
      fnmaUpdates: {
        duReferenceNumber: productDetails.APPRAISAL?.duReferenceNumber,
        specialFeatureCode: productDetails.APPRAISAL?.specialFeatureCode,
      },
    };

    if (requestBody.attachments) {
      requestBody.attachments = requestBody.attachments.filter((attachment) => attachment.fileName);
      if (requestBody.attachments.length > 0) {
        await handleAttachments(requestBody.attachments);
      } else {
        delete requestBody.attachments;
      }
    }

    try {
      const result = await $eventApi.placeOrderFromExistingOrder({
        placeOrderFromExistingLoan: requestBody,
      });
      toast(true, result.productName, result.orderNumber);
      backToSearch(result.loanNumber);
    } catch (error) {
      toast(false);
      console.error(error);
    }
    isSavingOrder = false;
  }

  async function buildNewLoanRequest(product) {
    isSavingOrder = true;
    const { order, productDetails } = $form;
    const { newLoan } = $form.loanDetails;
    const property = newLoan.subjectProperty[0];
    const requestBody = {
      clientId: order.lender,
      vendorId: productDetails[product].provider,
      brokerId: isBroker ? $profile.organization?.externalId : null,
      productId: productDetails[product].product,
      costCenter: null,
      vtpClientId: getVtpClientId(product, productDetails[product]),
      propertyAddress: {
        line1: property.addressLine1,
        line2: property.addressLine2,
        state: property.state,
        zip: property.zipCode,
        county: property.county,
        city: property.city,
        unitDesignatorType: property.addressUnitDesignationType,
      },
      buildingPermit: {
        buildingPermitUsageStandardType: property.buildingPermissionUsageType,
        buildingPermitUsageStandardTypeOtherDescription:
          property.buildingPermissionUsageTypeOtherDescription,
      },
      propertyDetail: {
        estateType: property.estateType,
        estateTypeDescription: property.estateTypeDescription,
        occupancyType: property.occupancyType,
        constructionMethodType: property.constructionMethod,
        constructionMethodTypeDescription: property.constructionMethodTypeDescription || null,
        pudIndicator: property.pud,
      },
      timeZoneId: productDetails.Closing?.closingDateTimezone || null,
      parcelIdentification: {
        parcelIdentificationType: property.parcelIdType,
        parcelIdentifier: property.parcelId,
      },
      plattedLand: {
        platBlockIdentifier: property.platBlockIdentifier,
        platLotIdentifier: property.platLotIdentifier,
        platType: property.platType,
      },
      termsOfLoan: {
        loanPurposeType: newLoan.loanPurpose,
        mortgageType: newLoan.mortgageLoanType,
        baseLoanAmount: newLoan.loanAmount,
        lienPriorityType: newLoan.lienPriority,
      },
      salesContractAmount: newLoan.salesContractAmount,
      loanIdentifier: {
        loanIdentifier: newLoan.loanNumber,
        loanIdentifierType: newLoan.loanIdType,
      },
      ltvRatioPercent: newLoan.ltv,
      combinedLtvRatioPercent: newLoan.cltv,
      loanDetail: {
        employeeLoanProgramIndicator: newLoan.employeeLoan,
        piggybackLoanIndicator: newLoan.piggyback,
      },
      refinance: {
        refinanceCashOutAmount: newLoan.cashOutAmount,
        refinanceCashOutDeterminationType: newLoan.cashOutType,
      },
      serviceExpediteIndicator: newLoan.serviceExpiditeIndicator,
      governmentBondFinanceIndicator: newLoan.govtBondFinance,
      governmentLoan: {
        governmentRefinanceType: newLoan.govtRefinanceType,
        fhaCaseIdentifier: newLoan.fhaCaseNumber,
      },
      parties: [...newLoan.borrowers, ...newLoan.contacts],
      attachments: productDetails[product].attachments,
      orderInstructions: productDetails[product].orderInstructions,
      loanEstimatedClosingDate: productDetails.CLOSING?.closingDate,
      appraisalFee: {
        amount: appraisalFeesTotal,
        billToPartyRoleType: productDetails.APPRAISAL?.payments[0]?.payer,
        paymentMode: null,
        billToPartyEmailAddress: productDetails.APPRAISAL?.payments[0]?.email,
      },
      fnmaUpdates: {
        duReferenceNumber: productDetails.APPRAISAL?.duReferenceNumber,
        specialFeatureCode: productDetails.APPRAISAL?.specialFeatureCode,
      },
    };

    if (requestBody.attachments) {
      requestBody.attachments = requestBody.attachments.filter((attachment) => attachment.fileName);
      if (requestBody.attachments.length > 0) {
        await handleAttachments(requestBody.attachments);
      } else {
        delete requestBody.attachments;
      }
    }

    if (requestBody.parties.length) {
      requestBody.parties = formatParties(requestBody.parties);
    }

    try {
      const result = await $eventApi.placeOrder({
        placeOrder: requestBody,
      });
      toast(true, result.productName, result.orderNumber);
      backToSearch(result.loanNumber);
    } catch (error) {
      toastError(error);
    }

    isSavingOrder = false;
  }

  async function buildIncomeTaxRequest() {
    isSavingOrder = true;
    const { order, productDetails } = $form;

    const { newLoan } = $form.loanDetails;
    const product = productDetails[PlaceOrderProductTypeEnum.Income];
    const productNames = [];
    const requestBody = {
      clientId: order.lender,
      eventId: EventIdEnum.PLACE_ORDER,
      username: product.email,
      vendorId: product.provider,
      vendorIdType: EventRequestVendorIdTypeEnum.Internal,
    };

    const transactionCode = `${uuidv4()}`;

    const promises = product.applications.map(async (application) => {
      const borrowers = application.applicant
        .concat(application?.coApplicant || [])
        .map(({ partyRole, address, firstName, middleName, lastName, ssn, contactMethods }) => {
          let taxpayerIdentifier = {
            taxpayerIdentifierValue: ssn,
            taxpayerIdentifierType:
              TaxpayerIdentifierTaxpayerIdentifierTypeEnum.SocialSecurityNumber,
          };
          if (application.applicationType == ApplicationTypeEnum.Business) {
            taxpayerIdentifier = {
              taxpayerIdentifierValue: application.ein,
              taxpayerIdentifierType:
                TaxpayerIdentifierTaxpayerIdentifierTypeEnum.EmployerIdentificationNumber,
            };
          }
          const borrower = {
            partyRole,
            address: {
              ...address,
              zip: address.zipCode,
            },
            firstName,
            middleName,
            lastName,
            taxpayerIdentifier,
          };

          contactMethods.forEach((method) => {
            if (method.method === 'Email') {
              borrower.email = { value: method.details };
            }
            if (method.method === 'Phone') {
              borrower.phone = { value: method.details };
            }
          });
          return borrower;
        });

      const {
        applicationType,
        products,
        attachmentType,
        attachments: attachmentsRaw,
      } = application;
      const services = products[applicationType]
        .filter((product) => product?.selected)
        .map((product) => {
          productNames.push(product.name);
          return {
            productId: product.productId,
            taxRequest: {
              [product.transcriptType]: true,
              applicationType: applicationType.toUpperCase(),
              electronicSignature: attachmentType === AttachmentTypeEnum.E_SIGN,
              years: Object.keys(product.years).filter((year) => product.years[year]),
            },
          };
        });

      const attachments = attachmentsRaw
        .filter((item) => item.fileName)
        .map((item) => ({ ...item, usageCode: item.usageCodeDescription }));
      await handleAttachments(attachments);

      return Promise.all(
        services.map(async (service) => {
          const payload = {
            ...requestBody,
            deal: {
              entity: {
                fullName:
                  application.applicationType === ApplicationTypeEnum.Business
                    ? application.businessName
                    : $profile.organization.name,
              },
              loan: {
                loanIdentifiers: [
                  {
                    loanIdentifier: newLoan.loanNumber,
                    loanIdentifierType: LoanIdentifierLoanIdentifierTypeEnum.LenderLoan,
                  },
                ],
              },
              borrowers,
              service,
              attachments,
            },
            transactionCode,
            spi: service?.productId,
            spni: uuidv4(),
          };
          return $taxApi.placeOrder({ eventRequest: payload });
        }),
      );
    });

    try {
      await Promise.all(promises);
      toast(true, 'Income Tax', productNames.join(','));
      setTimeout(() => {
        // Hack to give BE time for next fetch as post data / response different from order list
        backToSearch(transactionCode);
      }, 1000);
    } catch (e) {
      toastError(e);
    }
    isSavingOrder = false;
  }

  async function buildPaymentsRequest() {
    isSavingOrder = true;

    const {
      city,
      county,
      email,
      feeAmount,
      firstName,
      lastName,
      line1,
      line2,
      loanNumber,
      paymentPurpose,
      state,
      zip,
      payees,
      transactionCode,
    } = $form.productDetails[PlaceOrderProductTypeEnum.Payment];

    if (payees.length > 0) {
      const payload = {
        clientId: $form.order.lender,
        paymentPurpose,
        payees,
        transactionCode,
      };

      try {
        await $paymentApi.bulkImport({
          bulkImportRequest: payload,
        });
        toast(true, 'Payment', payload.transactionCode);
        backToSearch(payload.transactionCode);
      } catch (error) {
        toast(false);
        console.error(error);
      }
    } else {
      const payload = {
        deal: {
          subjectProperty: {
            address: {
              line1: line1,
              line2: line2,
              state: state,
              zip: zip,
              county: county,
              city: city,
            },
          },
          loan: {
            loanIdentifiers: [
              {
                loanIdentifier: loanNumber,
                loanIdentifierType: 'LENDER_LOAN', // STATIC
              },
            ],
            comment: null, // Field not present on form
          },
          borrowers: [
            {
              partyRole: 'PAYEE',
              firstName: firstName,
              middleName: null, // Field not present on form
              lastName: lastName,
              email: {
                value: email,
              },
            },
          ],
          service: {
            paymentRequest: {
              feeModificationAmount: feeAmount,
              payeeFirstName: firstName,
              payeeLastName: lastName,
              payeeEmailAddress: email,
              purpose: paymentPurpose,
            },
          },
        },
        clientId: $form.order.lender,
        transactionCode: uuidv4(),
        spi: 'ASP', // STATIC
        spni: uuidv4(),
        username: $currentUser.email,
        eventId: 'PlaceOrder', // STATIC
      };

      try {
        await $paymentApi.placeOrder({
          eventRequest: payload,
        });
        toast(true, 'Payment', loanNumber);
        backToSearch(loanNumber);
      } catch (error) {
        toastError(error);
      }
    }

    isSavingOrder = false;
  }

  async function submitSSNVerification() {
    isSavingOrder = true;

    const {
      loanDetails: { newLoan },
      order: { lender },
      productDetails,
    } = $form;

    const { borrowers, loanNumber, loanIdType } = newLoan;

    const { dateOfBirth, firstName, middleName, lastName, ssn, taxIdType } = borrowers[0];

    const { attachments, email, provider } =
      productDetails[PlaceOrderProductTypeEnum.SSN_Verification];

    const transactionCode = `${uuidv4()}`;

    // attachments props are reassigned to what is expected by the service in this function
    await handleAttachments(attachments);

    const payload = {
      deal: {
        loan: {
          loanIdentifiers: [
            {
              loanIdentifier: loanNumber,
              loanIdentifierType: loanIdType,
            },
          ],
        },
        entity: {
          fullName: 'Xpanse',
        },
        borrowers: [
          {
            partyRole: 'Borrower',
            firstName,
            middleName,
            lastName,
            dateOfBirth,
            taxpayerIdentifier: {
              taxpayerIdentifierType: taxIdType,
              taxpayerIdentifierValue: ssn,
            },
          },
        ],
        service: {
          productId: SsnVerificationAttachmentCode.SSA_89,
          productType: 'SSNVerification',
        },
        attachments,
      },
      clientId: lender,
      clientIdType: EventRequestVendorIdTypeEnum.Internal,
      vendorId: provider,
      vendorIdType: EventRequestVendorIdTypeEnum.External,
      transactionCode,
      spi: SsnVerificationAttachmentCode.SSA_89,
      // spni must be same value as transaction code
      spni: transactionCode,
      username: email,
      eventId: 'PlaceOrder',
      pipelineId: 'fmcssnvxactus',
    };

    const accessToken = await $newEventProcessorApiConfiguration.accessToken();

    try {
      // Doing it this way because the client for this call is only published to code artifact
      // while the rest of the dependencies are published to artifactory
      const response = await fetch(
        `${$newEventProcessorApiConfiguration.basePath}/api/web-ui/events/create-order`,
        {
          method: 'POST',
          headers: {
            Authorization: accessToken,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(payload),
        },
      );

      const res = await response.json();
      isSavingOrder = false;
      toast(true, res.productType, res.transactionCode);
      backToSearch();
    } catch (error) {
      isSavingOrder = false;
      toastError(error);
    }
  }

  async function onPlaceOrder() {
    const { loanSource } = $form.loanDetails;

    $form.order.products.forEach(async (product) => {
      if (product === PlaceOrderProductTypeEnum.Income) {
        await buildIncomeTaxRequest(loanSource);
        return;
      }
      if (product === PlaceOrderProductTypeEnum.Payment) {
        await buildPaymentsRequest();
        return;
      }
      if (product === PlaceOrderProductTypeEnum.SSN_Verification) {
        await submitSSNVerification();
        return;
      }
      if (loanSource === 'existing') {
        await buildExistingLoanRequest(product);
        return;
      }
      if (loanSource === 'new') {
        await buildNewLoanRequest(product);
        return;
      }
    });
  }

  async function onBulkSubmit() {
    $bulkApi
      .createBulkImportRaw({
        clientId: $form.order.lender,
        productType: 'TITLE',
        file: $form.loanDetails.bulkFiles?.[0],
      })
      .then(async (response) => {
        const json = await response.raw.json();
        if (json?.validationErrors) {
          $form.loanDetails.bulkValidationErrors = json?.validationErrors;
        } else {
          popToast({
            title: `Bulk import completed successfully`,
            type: 'success',
            duration: 6000,
          });
          backToSearch();
        }
      })
      .catch((error) => {
        popToast({
          title: `Failed to bulk import: ${error.message}`,
          type: 'error',
          duration: 6000,
        });
      });
  }

  async function uploadToS3(url, file) {
    return fetch(url, {
      method: 'PUT',
      body: file,
    }).catch((error) => {
      console.error('upload:', error);
      popToast({
        title: 'Error: Failed To Upload, please try again',
        description: 'Retry',
        duration: 0,
      });
    });
  }

  async function handleAttachments(attachmentsArr) {
    const uploadUrls = await $attachmentApi.getUploadUrl({
      count: attachmentsArr.length,
    });
    await Promise.all(
      attachmentsArr.map(async (attachment, i) => {
        /* eslint-disable no-param-reassign */
        attachment.objectId = uploadUrls[i].objectId;
        const signedURL = uploadUrls[i].url;
        const { file } = attachment;
        delete attachment.file;
        return uploadToS3(signedURL, file);
        /* eslint-enable no-param-reassign */
      }),
    );
  }

  function formatParties(parties) {
    return parties.map((party) => {
      const {
        partyRole,
        contactType,
        companyName,
        firstName,
        middleName,
        lastName,
        address,
        contactMethods,
        relationshipVestingType,
      } = party;
      return {
        partyRole,
        contactType,
        firstName,
        middleName,
        lastName,
        address,
        fullName: companyName ? companyName : `${firstName} ${middleName} ${lastName}`,
        email: contactMethods.find((x) => x.method === 'Email')?.details || null,
        emailRole: 'HOME',
        phone: contactMethods.find((x) => x.method === 'Phone')?.details || null,
        phoneRole: 'MOBILE',
        relationshipVesting: relationshipVestingType || null,
      };
    });
  }

  $: {
    const { orderDetails, loanDetails, productDetails } = $validationResults;

    const allProductsValid = !$form.order.products
      .map((product) => productDetails[product].valid)
      .includes(false);

    allFormsValid =
      (orderDetails.valid && (isOnlyPaymentOrder ? true : loanDetails.valid) && allProductsValid) ||
      ($form.loanDetails.loanSource === LoanSource.Bulk &&
        $form.loanDetails.bulkFiles?.length > 0 &&
        !$form.loanDetails.bulkValidationErrors);

    if ($location.indexOf(urls.placeOrder) === -1) {
      // TODO: find a way to prevent leaving "/place-order" with a route guard in order to use the warning toast
      // Currently no route guards exist on leave, only on enter => https://github.com/ItalyPaleAle/svelte-spa-router
      resetPlaceOrder();
    }
  }

  $: appraisalFeesTotal = sum([
    $form.productDetails[PlaceOrderProductTypeEnum.Appraisal].fees?.appraisalFee?.amount,
    ...$form.productDetails[PlaceOrderProductTypeEnum.Appraisal].fees?.additionalFees?.map(
      (x) => x.amount,
    ),
  ]);

  // If the subject property address is changed, update any borrower whose address is the same as the subject property
  $: if ($form.loanDetails.newLoan?.subjectProperty[0]) {
    const { borrowers } = $form.loanDetails.newLoan;
    const { addressLine1, addressLine2, city, county, state, zipCode } =
      $form.loanDetails.newLoan.subjectProperty[0];

    if (borrowers.length) {
      borrowers.forEach((borrower) => {
        if (borrower.sameAsSubjectProperty) {
          borrower.address = {
            line1: addressLine1,
            line2: addressLine2,
            zipCode,
            city,
            county,
            state,
          };
        }
      });
    }
  }
</script>

<svelte:head>
  <title>{pageTitle(title)}</title>
</svelte:head>
<div in:fade="{{ duration: 500 }}">
  <Layout>
    <div slot="center" class="place-order-wrapper">
      <div class="header">
        <div class="breadcrumb-container">
          <div class="breadcrumb-item">
            <a href="{urls.orders}" use:link>Orders</a>
          </div>
          <div class="breadcrumb-item breadcrumb-item--active">Place Order</div>
        </div>
        <ViewHeader>Place Order</ViewHeader>
      </div>
      <div class="content">
        <slot />

        <ConfirmDiscardDialog
          bind:visible="{showConfirmDiscardDialog}"
          on:confirm="{() => backToSearch(null)}"
        />
      </div>
    </div>
    <div slot="footer">
      <div class="footer">
        <Button
          name="cancel"
          label="Cancel"
          color="{ThemeColor.Basic}"
          on:click="{onDiscardClick}"
        />

        {#if !($location === urls.placeOrder)}
          <Button
            name="back"
            label="Back"
            color="{ThemeColor.Secondary}"
            on:click="{onBackClick}"
          />
        {/if}

        {#if $form.loanDetails.loanSource === LoanSource.Bulk}
          <Button
            name="submit-bulk"
            label="Submit"
            color="{ThemeColor.Primary}"
            disabled="{!allFormsValid}"
            on:click="{onBulkSubmit}"
          />
        {/if}

        {#if $location === urls.placeOrder && $form.loanDetails.loanSource !== LoanSource.Bulk}
          <Button
            color="primary"
            label="{isOnlyPaymentOrder || isOnlySsnVerificationOrder
              ? 'Enter Product Details'
              : 'Enter Loan Details'}"
            name="enterLoanDetails"
            icon="{ArrowRightIcon}"
            disabled="{!$validationResults.orderDetails.valid}"
            appendIcon
            on:click="{() => {
              push(urls.placeOrderLoanDetails);
            }}"
          />
        {/if}

        {#if $location === urls.placeOrderLoanDetails && $form.loanDetails.loanSource !== LoanSource.Bulk}
          <Button
            color="primary"
            label="Enter Product Details"
            name="enterProductDetails"
            icon="{ArrowRightIcon}"
            disabled="{!$validationResults.loanDetails.valid}"
            appendIcon
            on:click="{() => {
              push(urls.placeOrderProductDetails);
            }}"
          />
        {/if}

        {#if $location === urls.placeOrderProductDetails && !isSavingOrder}
          <Button
            name="submit-place-order"
            label="Place Order"
            color="{ThemeColor.Primary}"
            disabled="{!allFormsValid}"
            on:click="{onPlaceOrder}"
          />
        {/if}
      </div>
    </div>
  </Layout>
  {#if isSavingOrder}
    <LoadingModal text="Placing Order" isFullScreen="true" />
  {/if}
</div>

<style>
  .content {
    width: 912px;
    padding-bottom: 50px;
  }

  .footer {
    display: flex;
    justify-content: flex-end;
    padding: 24px 0;
    max-width: 675px;
    margin: 0 auto;
  }

  .footer :global(button) {
    margin-left: 1rem;
  }

  .header {
    overflow: hidden;
  }
</style>
