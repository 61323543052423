<script lang="ts">
  import {
    Button,
    Dropdown,
    IconButton,
    LoadingModal,
    Modal,
    TextInput,
    Tooltip,
  } from '@xpanseinc/ui-components';
  import { createEventDispatcher } from 'svelte';
  import { SlaScorecardRequestTimeUnitEnum } from '@xpanseinc/report-service-api';
  import { eventError } from '../constants/errorMessages';
  import { reportSlaApi } from '../stores/api';
  import { reportApi } from '../stores/api';
  import { alphabetically } from '../utils/alphabetically';
  import { products } from '../stores/products';
  import { fetchStates, usStates } from '../stores/usStates';
  import { PlusIcon, Trash2Icon } from 'svelte-feather-icons';
  import { mapDropdownOptionsFromEnum } from '../utils/mapDropdownOptions';
  import { validate } from '../schemas/validate';
  import type { DirtyMap } from '../schemas/SLA/DirtyMap';
  import type { ValidationResult } from '../schemas/validate';
  import { slaTimerRequestSchema } from '../schemas/SLA/AddSLA';
  import type { SLATimerRequest } from '../schemas/SLA/AddSLA';
  import { popToast } from '../utils/toasts';

  function getDefaultDirtyMap(): DirtyMap {
    return {
      endEvent: false,
      service: false,
      sla: false,
      startEvent: false,
      target: false,
      timeUnit: false,
    };
  }

  function getDefaultSLATimerRequest(): SLATimerRequest {
    return {
      id: null,
      endEvent: '',
      endEventDescription: '',
      service: '',
      sla: null,
      stateSlas: [],
      startEvent: '',
      startEventDescription: '',
      target: 0,
      timeUnit: SlaScorecardRequestTimeUnitEnum.Days,
    };
  }

  export let slaTimerRequest = getDefaultSLATimerRequest();
  export let visible = false;
  const dispatch = createEventDispatcher();

  let checkValid = false;
  let dirtyMap = getDefaultDirtyMap();
  let eventStatusOptions = [];
  let maxSlaGroups = 5;
  let opened = true;
  let selectedStates = [];
  let selectedTempStates = {};
  let sending = false;
  $: stateOptions = $usStates;
  let validationResult: ValidationResult;
  let eventCodes = [];

  async function fetchEvents(product) {
    if (product) {
      const eventCodesRaw =
        (await $reportApi.getEventCodes1({ product: product, showAll: true })) || [];
      eventCodes = eventCodesRaw
        .map((event) => ({
          label: event.name,
          value: event.code,
        }))
        .sort(alphabetically);
      eventStatusOptions = [...eventCodes];
    }
  }

  $: {
    if (slaTimerRequest.service && opened) {
      opened = false;
      fetchEvents(slaTimerRequest.service);
      fetchStates();
    }
  }

  function reset() {
    checkValid = false;
    dirtyMap = getDefaultDirtyMap();
    eventStatusOptions = [];
    opened = true;
    selectedStates = [];
    selectedTempStates = {};
    sending = false;
    slaTimerRequest = getDefaultSLATimerRequest();
    visible = false;
  }

  async function onSubmit() {
    checkValid = true;
    let stateRowsValid = true;
    if (slaTimerRequest?.stateSlas?.length) {
      slaTimerRequest.stateSlas.map((stateSla) => {
        if (stateRowsValid) {
          stateRowsValid =
            (stateSla.states.length > 0 && !!stateSla.sla) ||
            (stateSla.states.length === 0 && !stateSla.sla);
        }
      });
    }
    if (validationResult.valid && stateRowsValid) {
      await callAddSlaScorecard(slaTimerRequest);
    }
  }

  async function callAddSlaScorecard(data) {
    sending = true;
    try {
      let postSlaCardPromise = data.id
        ? $reportSlaApi.updateSlaScorecard({ slaScorecardRequest: data })
        : $reportSlaApi.addSlaScorecard({ slaScorecardRequest: data });
      await postSlaCardPromise;
      dispatch('submittedCard');
      sending = false;
      reset();
    } catch (error) {
      const stream = await error.body?.getReader().read();
      const message = String.fromCharCode.apply(null, stream?.value) || '';
      const toastMessage = eventError.duplicateSLAValues.dynamicMessage(
        data.startEvent,
        data.endEvent,
        data.sla,
        data.target,
      );
      console.error('Error:', error?.status, message || error?.statusText);
      sending = false;
      popToast({
        type: 'error',
        title: toastMessage,
        duration: 6000,
      });
    }
  }

  function updateSelectedState(state: string) {
    const index = selectedStates.indexOf(state);
    if (index < 0) {
      selectedStates = [...selectedStates, state];
    } else {
      selectedStates = selectedStates.slice(0, index).concat(selectedStates.slice(index + 1));
    }
  }

  function filterSelectedStates(selection, options, i) {
    if (selection === 'all') {
      options.forEach((state) => updateSelectedState(state));
    } else if (selection === 'clear') {
      selectedTempStates[i].forEach((state) => updateSelectedState(state));
    } else {
      updateSelectedState(selection.value);
    }
  }

  $: validationResult = validate(slaTimerRequestSchema, slaTimerRequest);

  $: if (slaTimerRequest.startEvent) {
    slaTimerRequest.startEventDescription =
      eventStatusOptions[
        eventStatusOptions?.map((e) => e.value).indexOf(slaTimerRequest.startEvent)
      ]?.label;
  }
  $: if (slaTimerRequest.endEvent) {
    slaTimerRequest.endEventDescription =
      eventStatusOptions[
        eventStatusOptions?.map((e) => e.value).indexOf(slaTimerRequest.endEvent)
      ]?.label;
  }

  $: slaTimerRequest = slaTimerRequest;
</script>

<Modal size="jumbo" on:close="{reset}" bind:visible>
  <h2 class="header-xxl header-xxl--bold">Add SLA Card</h2>
  <form on:submit|preventDefault="{() => {}}">
    <div class="row">
      <h4 class="header-l">Event transitons to watch</h4>

      <div class="formfield">
        <Dropdown
          invalid="{(dirtyMap.service || checkValid) && validationResult.errors.service}"
          label="Product *"
          name="product"
          options="{$products}"
          placeholder="Select One"
          on:blur="{() => {
            dirtyMap.service = true;
          }}"
          on:select="{() => {
            fetchEvents(slaTimerRequest.service);
          }}"
          bind:value="{slaTimerRequest.service}"
        />
        <Dropdown
          disabled="{!eventStatusOptions?.length}"
          invalid="{(dirtyMap.startEvent || checkValid) && validationResult.errors.startEvent}"
          label="Start Event *"
          name="startEvent"
          options="{eventStatusOptions?.filter(
            (option) => option.value != slaTimerRequest.endEvent,
          )}"
          placeholder="Select One"
          showTextFilter
          on:blur="{() => {
            dirtyMap.startEvent = true;
          }}"
          bind:value="{slaTimerRequest.startEvent}"
        />
        <Dropdown
          disabled="{!eventStatusOptions?.length}"
          invalid="{(dirtyMap.endEvent || checkValid) && validationResult.errors.endEvent}"
          label="End Event *"
          name="endEvent"
          options="{eventStatusOptions?.filter(
            (option) => option.value != slaTimerRequest.startEvent,
          )}"
          placeholder="Select One"
          showTextFilter
          on:blur="{() => {
            dirtyMap.endEvent = true;
          }}"
          bind:value="{slaTimerRequest.endEvent}"
        />
      </div>
      <div class="errors">
        {#if (dirtyMap.service || checkValid) && validationResult.errors.service}
          <p class="body-small text-error">A Product type must be selected.</p>
        {/if}
        {#if (dirtyMap.startEvent || checkValid) && validationResult.errors.startEvent}
          <p class="body-small text-error">A Start Event must be selected.</p>
        {/if}
        {#if (dirtyMap.endEvent || checkValid) && validationResult.errors.endEvent}
          <p class="body-small text-error">A End Event must be selected.</p>
        {/if}
      </div>
    </div>
    <div class="row">
      <h4 class="header-l">Primary SLA information</h4>
      <div class="formfield">
        <div class="half-width">
          <TextInput
            class="half-width"
            invalid="{(dirtyMap.sla || checkValid) && validationResult.errors.sla}"
            label="SLA *"
            name="DefaultSLADays"
            value="{slaTimerRequest.sla}"
            on:blur="{() => {
              dirtyMap.sla = true;
            }}"
            on:change="{(e) => {
              slaTimerRequest.sla = parseInt(e.target.value);
            }}"
          />
        </div>
        <div class="half-width">
          <Dropdown
            class="half-width"
            invalid="{(dirtyMap.timeUnit || checkValid) && validationResult.errors.timeUnit}"
            label="Time frame"
            name="timeUnit"
            options="{mapDropdownOptionsFromEnum(SlaScorecardRequestTimeUnitEnum, true, 'key')}"
            on:blur="{() => {
              dirtyMap.timeUnit = true;
            }}"
            bind:value="{slaTimerRequest.timeUnit}"
          />
        </div>
        <div class="target-text-input">
          <TextInput
            invalid="{(dirtyMap.target || checkValid) && validationResult.errors.target}"
            label="Target % meeting SLA *"
            name="slaTargetPercentage"
            prepend="%"
            value="{slaTimerRequest.target > 0 ? slaTimerRequest.target : ''}"
            on:blur="{() => {
              dirtyMap.target = true;
            }}"
            on:change="{(e) => {
              slaTimerRequest.target = parseInt(e.target.value);
            }}"
          />
        </div>
      </div>
      <div class="errors">
        {#if (dirtyMap.sla || checkValid) && validationResult.errors.sla}
          <p class="body-small text-error">Primary SLA time must be between 1 and 100.</p>
        {/if}
        {#if (dirtyMap.target || checkValid) && validationResult.errors.target}
          <p class="body-small text-error">Primary Target % must be between 5 and 100.</p>
        {/if}
      </div>
    </div>
    <div class="row">
      <h4 class="header-l">State SLA variation groups</h4>
      <p class="header-s">
        <strong>You may add up to {maxSlaGroups} SLA state groups</strong>
      </p>
      {#each slaTimerRequest.stateSlas as state, i}
        <div class="formfield state-group">
          <div class="half-width">
            <TextInput
              invalid="{(checkValid && validationResult.errors[`stateSlas[${i}].sla`]) ||
                (checkValid && state.states.length > 0 && !state.sla)}"
              label="SLA *"
              name="slasDays"
              value="{state.sla || ''}"
              on:change="{(e) => {
                state.sla = parseInt(e.target.value);
              }}"
            />
          </div>
          <div class="half-width">
            <Dropdown
              class="half-width"
              invalid="{checkValid && validationResult.errors[`stateSlas[${i}].timeUnit`]}"
              label="Time frame"
              name="timeUnit"
              options="{mapDropdownOptionsFromEnum(SlaScorecardRequestTimeUnitEnum, true, 'key')}"
              bind:value="{slaTimerRequest.stateSlas[i].timeUnit}"
            />
          </div>
          {#await stateOptions then stateOptions}
            <Dropdown
              disabled="{!eventStatusOptions?.length}"
              invalid="{(checkValid && validationResult.errors[`stateSlas[${i}].states`]) ||
                (checkValid && slaTimerRequest.stateSlas[i].states.length === 0 && state.sla)}"
              label="States *"
              multiple
              name="states"
              options="{stateOptions?.filter(
                (option) =>
                  !selectedStates?.includes(option.value) || state?.states?.includes(option.value),
              ) || []}"
              placeholder="Select one or more"
              showSelectAll
              on:select="{(e) => {
                filterSelectedStates(e.detail, slaTimerRequest.stateSlas[i].states, i);
                selectedTempStates[i] = slaTimerRequest.stateSlas[i].states;
              }}"
              bind:value="{slaTimerRequest.stateSlas[i].states}"
            />
          {/await}
          <Tooltip text="Delete row" position="right">
            <IconButton
              ariaLabel="delete row"
              color="warning"
              icon="{Trash2Icon}"
              name="delete-row"
              on:click="{() => {
                slaTimerRequest.stateSlas[i].states.forEach((state) => {
                  let index = selectedStates.indexOf(state);
                  selectedStates = selectedStates
                    .slice(0, index)
                    .concat(selectedStates.slice(index + 1));
                });
                delete selectedTempStates[i];
                slaTimerRequest.stateSlas.splice(i, 1);
                slaTimerRequest = slaTimerRequest;
              }}"
            />
          </Tooltip>
          <div class="errors">
            {#if (checkValid && validationResult.errors[`stateSlas[${i}].states`]) || (checkValid && state.states.length === 0 && state.sla)}
              <p class="body-small text-error">
                At least one State must be selected if a SLA is set.
              </p>
            {/if}
            {#if (checkValid && validationResult.errors[`stateSlas[${i}].sla`]) || (checkValid && state.states.length > 0 && !state.sla)}
              <p class="body-small text-error">Must set a SLA time between 1 and 100.</p>
            {/if}
          </div>
        </div>
      {/each}
      {#if slaTimerRequest.stateSlas.length < maxSlaGroups}
        <Button
          color="basic"
          icon="{PlusIcon}"
          label="Add SLA Rule"
          name="add-SLA-rule"
          on:click="{() => {
            slaTimerRequest.stateSlas = [
              ...slaTimerRequest.stateSlas,
              { sla: undefined, states: [], timeUnit: SlaScorecardRequestTimeUnitEnum.Days },
            ];
          }}"
        />
      {/if}
    </div>
    <div class="save">
      <Button
        color="primary"
        disabled="{false}"
        label="Save"
        name="save-SLA"
        on:click="{onSubmit}"
      />
    </div>
  </form>
</Modal>
{#if sending}
  <LoadingModal visible text="Saving Card..." isFullScreen="false" />
{/if}

<style>
  .row {
    margin-bottom: 8px;
  }

  .formfield {
    display: inline-flex;
    align-items: center;
    gap: 24px;
    margin-top: 16px;
    margin-bottom: 8px;
    width: 100%;
  }

  .formfield > :global(.text-input) {
    min-width: 260px;
    width: 260px;
  }

  form .row :global(.dropdown) {
    min-width: 260px !important;
  }

  .formfield :global(.target) {
    position: relative;
    top: 10px;
  }

  .formfield > .half-width :global(.dropdown) {
    width: 126px;
    min-width: 126px !important;
  }

  .formfield > .half-width :global(.text-input) {
    width: 110px;
    min-width: 110px !important;
  }

  .errors {
    min-height: 32px;
  }

  .errors p {
    margin-bottom: 0;
  }

  .state-group.formfield {
    margin-top: 8px;
  }

  .save {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 160px;
  }

  .save :global(button) {
    min-width: 160px;
  }

  .header-xxl {
    margin-bottom: 1rem;
  }

  .header-s {
    margin: 0.5rem 0 !important;
  }

  .target-text-input :global(.text-input) {
    padding: 6px 8px;
  }
</style>
